import { FunctionComponent, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { noteContentToTimeLineItem, TimelineItem } from './TimelineItem'
import { TimelineForm } from './TimelineForm'
import Icon from 'shared/components/Icon'
import { IconType } from 'components/Icons'
import dateFormat from 'shared/helper/dateFormat'
import { useUserRecord } from 'hooks/useUserRecord'
import useApi, { QueryKey } from 'hooks/useApi'
import { useQuery } from 'react-query'

export const Timeline: FunctionComponent = () => {
	const { t } = useTranslation()
	const { userId } = useUserRecord()
	const api = useApi()

	const notesQuery = useQuery([QueryKey.pensionerNotes, { pensionerId: userId }], api.getPensionerNotes, {
		enabled: !!userId,
	})
	const bottomRef = useRef<HTMLDivElement>(null)

	const scrollToBottom = () => {
		if (bottomRef.current) {
			bottomRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}

	useEffect(() => {
		if (notesQuery.isSuccess) {
			scrollToBottom()
		}
	}, [notesQuery.isSuccess])

	const timelineItems = useMemo(() => {
		if (notesQuery.data) {
			const { notes } = notesQuery.data
			const items = notes.map(noteContentToTimeLineItem)
			return items
		} else {
			return []
		}
	}, [notesQuery.data])

	const renderItems = () => {
		return timelineItems?.map((item) => {
			const { type, createdAt, itemData } = item
			return <TimelineItem type={type} createdAt={createdAt} itemData={itemData} refreshHook={refreshTimeline} />
		})
	}

	const currentDate = dateFormat(new Date(), {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	})

	const refreshTimeline = () => {
		notesQuery.refetch()
	}

	return (
		<>
			<h1 className="view-header__headline giant-headline">{t('view.pensionerTimeline.headline')}</h1>
			<div className="timeline">
				<div className="timeline-entry">
					<div className="timeline-entry__header"></div>
					<div className="timeline-entry__separator">
						<div className="verticalLine"></div>
					</div>
					<div className="timeline-entry__content"></div>
				</div>
				{renderItems()}
				<div className="timeline-footer">
					<div className="timeline-footer__left"></div>
					<div className="timeline-footer__separator">
						<div className="verticalLine"></div>
					</div>
					<div className="timeline-footer__right"></div>
				</div>
				<div className="timeline-footer">
					<div className="timeline-footer__left">{currentDate}</div>
					<div className="timeline-footer__separator">
						<Icon type={IconType.notizen} className="margin--horizontal margin--default no-margin--left" />
					</div>
					<div className="timeline-footer__right">{t('view.pensionerTimeline.form.label')}</div>
				</div>
				<TimelineForm refreshHook={refreshTimeline} />
				<div ref={bottomRef} />
			</div>
		</>
	)
}
