import React, { useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { AuthError, ErrorType, useAuth } from '../hooks/useAuth'
import Button, { ButtonType } from '../shared/components/Button'
import { TextInputType } from '../shared/components/TextInput'
import isDev from '../shared/helper/isDev'

const ResetPassword = () => {
	const { navigateTo, getMainPath } = useRouteHelper()
	const { t } = useTranslation()
	const { userData, sendPasswordResetEmail, confirmPasswordReset } = useAuth()
	const [username, setUsername] = useState<string | null>(null)
	const enteredUsername = useRef<string | null>(sessionStorage.getItem('username'))
	const [errorMessages, setErrorMessages] = useState<string[]>()

	const inputElements: FormFields = {
		username: {
			value: null !== enteredUsername.current ? enteredUsername.current : '',
			required: true,
			type: null === username ? TextInputType.text : TextInputType.hidden,
			label: t('generic.userId'),
			autoComplete: 'username',
			disableDelete: true,
			fieldType: FormFieldType.text,
		},
		code: {
			value: isDev() ? '123456' : '',
			required: true,
			type: TextInputType.text,
			label: t('view.resetPassword.formFieldLabels.code'),
			autoComplete: 'none',
			disableDelete: true,
			fieldType: FormFieldType.text,
		},
		newPassword: {
			value: isDev() ? 'testPassword??123456' : '',
			required: true,
			type: TextInputType.password,
			label: t('view.resetPassword.formFieldLabels.setNewPassword'),
			autoComplete: 'new-password',
			fieldType: FormFieldType.text,
		},
		confirmNewPassword: {
			value: isDev() ? 'testPassword??123456' : '',
			required: true,
			type: TextInputType.password,
			label: t('view.resetPassword.formFieldLabels.confirmNewPassword'),
			fieldType: FormFieldType.text,
		},
	}

	if (userData) {
		return (
			<div className="auth">
				<h1>
					<Trans i18nKey="view.resetPassword.pageTitle" />
				</h1>

				<h3>
					<Trans i18nKey="view.login.alreadyLoggedIn" />
				</h3>
				<Button
					onClick={() => navigateTo(routesDictionary.dashboard.path)}
					label={t('view.login.goToDashboard')}
					type={ButtonType.secondary}
				/>
			</div>
		)
	}

	return (
		<div className="auth">
			<div>
				<h1 className="text-color-gold text-transform-uppercase">
					<Trans i18nKey="view.resetPassword.pageTitle" />
				</h1>

				<p>
					<Trans i18nKey="view.resetPassword.bodytext" />
				</p>
			</div>

			{username === null && (
				<Form
					className="auth"
					fields={{ username: inputElements.username }}
					onSubmit={(submittedFields: { [key: string]: string }) => {
						enteredUsername.current = submittedFields.username
						return sendPasswordResetEmail(submittedFields)
					}}
					onSuccess={() => {
						sessionStorage.removeItem('username')

						setUsername(enteredUsername.current as string)
					}}
					onError={(error: AuthError) => {
						switch (error.code) {
							case ErrorType.limitExceeded:
								setErrorMessages([t('generic.formErrors.limitExceeded')])
								break

							case ErrorType.userNotFound:
								setErrorMessages([t('generic.formErrors.wrongUserId')])
								break

							default:
								setErrorMessages([t('generic.formErrors.default')])
						}
					}}
					errorMessages={errorMessages}
					submitLabel={t('view.resetPassword.submit')}
				/>
			)}

			{username !== null && (
				<Form
					className="auth"
					fields={inputElements}
					onSubmit={(submittedFields: { [key: string]: string }) => {
						if (submittedFields.newPassword !== submittedFields.confirmNewPassword) {
							setErrorMessages([t('generic.formErrors.passwordError')])
							return false
						}

						return confirmPasswordReset(submittedFields)
					}}
					onSuccess={() => navigateTo(getMainPath(routesDictionary.login))}
					onError={(error: AuthError) => {
						switch (error.code) {
							case ErrorType.limitExceeded:
								setErrorMessages([t('generic.formErrors.limitExceeded')])
								break

							case ErrorType.codeMismatch:
								setErrorMessages([t('generic.formErrors.codeMismatch')])
								break

							case ErrorType.invalidParameter:
								setErrorMessages([t('generic.formErrors.invalidParameter')])
								break

							default:
								setErrorMessages([t('generic.formErrors.default')])
						}
					}}
					errorMessages={errorMessages}
					submitLabel={t('view.resetPassword.submit')}
				/>
			)}
		</div>
	)
}

export default ResetPassword
