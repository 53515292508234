import { IconType } from 'components/Icons'
import { useAuth } from 'hooks/useAuth'
import { Link } from 'raviger'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMeasure } from 'react-use'
import routesDictionary from 'routes'
import Icon from 'shared/components/Icon'
import VersionNumber from 'shared/components/VersionNumber'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import Logo from './Logo'
import Navigation from './Navigation'

const Footer: React.FC = () => {
	const { getMainPath } = useRouteHelper()
	const { t } = useTranslation()
	const { userData } = useAuth()
	const [ref, { height }] = useMeasure<HTMLElement>()

	useEffect(() => {
		if (undefined === height) {
			return
		}

		document.documentElement.style.setProperty('--footer-height', `${height}px`)
	}, [height])

	return (
		<footer ref={ref} className="footer">
			<div className="footer__inner-wrapper">
				<Logo className="footer__logo" />

				<Navigation className="footer__navigation" />

				{userData ? (
					<>
						<div className="footer__user flex flex--justify-content-between flex--align-items-center">
							<div className="text-transform-uppercase font-size-s">
								<b className="text-color-gold">{t('view.dashboard.loggedInAs')}</b>
								<br />
								<b className="text-color-white">
									{userData.given_name} {userData.name}
								</b>
							</div>
						</div>

						{undefined !== getMainPath(routesDictionary.settings) && (
							<Link className="footer__settings" href={getMainPath(routesDictionary.settings)!}>
								<Icon
									className="icon--settings  margin--small margin--horizontal no-margin--left"
									type={IconType.settings}
								/>
							</Link>
						)}
					</>
				) : (
					<></>
				)}
				<VersionNumber />
			</div>
			<div id="softphone-portal"></div>
		</footer>
	)
}

export default Footer
