import { ConnectSoftphone } from 'components/Connect/ConnectSoftphone'
import { ConnectWrapper } from 'components/Connect/ConnectWrapper'
import Footer from 'partials/Footer'
import Sidebar from 'partials/Sidebar'
import { usePath, useRoutes } from 'raviger'
import React, { useEffect, useMemo } from 'react'
import routesDictionary from 'routes'
import AppUpdateModal from 'shared/components/AppUpdateModal'
import GlobalModal from 'shared/components/GlobalModal'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import useNavigationState, { useNavigationBetriebState } from 'state/useNavigationState'
import useUIState from 'state/useUiState'
import { useAuth } from '../hooks/useAuth'
import SidebarBetrieb from '../partials/SidebarBetrieb'
import NotFound from './NotFound'
import { useUserPermission } from 'hooks/useUserPermission'

let redirectTimeout: NodeJS.Timeout

const RouteWrapper: React.FC = () => {
	const { navigateTo, routes, getMainPath, filteredRoutesDictionary } = useRouteHelper()
	const { userData, connectInitialized } = useAuth()
	const routeResult = useRoutes(routes)
	const path = usePath()
	const [navigationState, navigationActions] = useNavigationState()
	const [navigationBetriebState, navigationBetriebActions] = useNavigationBetriebState()
	const [UIState] = useUIState()
	const { canUseSoftphone } = useUserPermission()

	/**
	 * save the initially visited path, to make sure to redirect the user to the
	 * desired page after authentication.
	 * Authentication only runs after the first render,
	 * where the private routes are not available, yet.
	 *
	 * When the user is authenticated an effect runs and navigates to the initial path.
	 */
	if (undefined === userData && null === sessionStorage.getItem('initialPath')) {
		if (
			null !== path &&
			!path.startsWith(routesDictionary.logout.path) &&
			!path.startsWith(routesDictionary.login.path)
		) {
			sessionStorage.setItem('initialPath', path)
		} else {
			sessionStorage.setItem('initialPath', 'dynamic')
		}
	}

	useEffect(() => {
		if (undefined !== userData) {
			let initialPath: string | null | undefined = sessionStorage.getItem('initialPath')

			if (null === initialPath) {
				return
			}

			// if the saved path is not found in filteredRoutesDictionary, set initialPath to 'dynamic'
			// if (false === filteredRoutesDictionary.some((route) => route.path === initialPath)) {
			// 	initialPath = 'dynamic'
			// }

			// if initialPath is 'dynamic', visit the first valid route
			if ('dynamic' === initialPath) {
				initialPath = getMainPath(filteredRoutesDictionary[0])
			}

			if (initialPath) {
				sessionStorage.removeItem('initialPath')

				navigateTo(initialPath, true)
			}
		}
	}, [filteredRoutesDictionary, getMainPath, navigateTo, userData])

	useEffect(() => {
		document.documentElement.classList.toggle('sidebar-visible', navigationState.visible)
		document.documentElement.classList.toggle('sidebar-closed', !navigationState.open)
		document.documentElement.classList.toggle('sidebar-open', navigationState.open)
		// document.documentElement.dataset.uiBackgroundColor = UIState.backgroundColor
	}, [navigationState, UIState])

	useEffect(() => {
		document.documentElement.classList.toggle('sidebar-betrieb-visible', navigationBetriebState.visible)
	}, [navigationBetriebState, UIState])

	useEffect(() => {
		const pensionerPath = getMainPath(routesDictionary.pensioner)
		if (undefined !== pensionerPath) {
			navigationActions.setVisible(path?.startsWith(pensionerPath) || false)
		}
	}, [getMainPath, navigationActions, navigationState.visible, path])

	useEffect(() => {
		const adminPath = getMainPath(routesDictionary.admin)

		if (undefined !== adminPath) {
			navigationBetriebActions.setVisible(path?.startsWith(adminPath) || false)
		}
	}, [getMainPath, navigationBetriebActions, navigationBetriebState.visible, path])

	const ForceRedirect = useMemo(() => {
		clearTimeout(redirectTimeout)

		if (undefined === userData && !routeResult) {
			redirectTimeout = setTimeout(() => {
				if (path !== routesDictionary.login.path) {
					navigateTo(routesDictionary.login.path, true)
				}
			})
		}

		return null
		// eslint-disable-next-line
	}, [userData, routeResult])

	return (
		<>
			<Sidebar />
			<SidebarBetrieb />
			<main className="main">{routeResult || (userData ? <NotFound /> : ForceRedirect)}</main>
			<Footer />
			{connectInitialized && <ConnectSoftphone />}
			{canUseSoftphone && <ConnectWrapper />}
			<GlobalModal />
			<AppUpdateModal />
			<LoadingSpinner />
		</>
	)
}

export default RouteWrapper
