import { FunctionComponent, useEffect, useRef } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'
import convertToDashCase from 'shared/helper/convertToDashCase'

export enum LoadingSpinnerArea {
	indiceComparisonDashboard = 'indiceComparisonDashboard',
	pensionAssetsDashboard = 'pensionAssetsDashboard',
	mfa = 'mfa',
	header = 'header',
	center = 'center',
}

export interface LoadingSpinnerProps {
	area?: LoadingSpinnerArea | string
	delay?: number
	className?: string
	onPromiseInProgressChange?: (promiseInProgress: boolean) => void
}

const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = (props) => {
	const { delay = 500, area, onPromiseInProgressChange } = props
	const { promiseInProgress } = usePromiseTracker({ delay, area })
	const loader = useRef<HTMLDivElement>(null)
	const timeout = useRef<NodeJS.Timeout>()

	useEffect(() => {
		if (undefined !== timeout.current) {
			clearTimeout(timeout.current)
		}

		loader.current?.classList.toggle(`loading-spinner--visible`, promiseInProgress)

		switch (promiseInProgress) {
			case true:
				loader.current?.classList.add(`loading-spinner--animate`)
				break

			case false:
				timeout.current = setTimeout(() => {
					loader.current?.classList.remove(`loading-spinner--animate`)
				}, 500)
				break
		}

		onPromiseInProgressChange?.(promiseInProgress)

		return () => {
			timeout.current && clearTimeout(timeout.current)

			onPromiseInProgressChange?.(false)
		}
	}, [onPromiseInProgressChange, promiseInProgress])

	const getClassNames = (): string => {
		const classPrefix: string = 'loading-spinner--'
		const classes: string[] = ['loading-spinner']

		if (undefined !== area) {
			classes.push(classPrefix + convertToDashCase(area))
		} else {
			classes.push(classPrefix + 'default')
		}

		if (undefined !== props.className) {
			classes.push(classPrefix + props.className)
		}

		return classes.join(' ')
	}

	return (
		<span className={getClassNames()} ref={loader}>
			<svg className="loading-spinner__wrapper" viewBox="0 0 66 66" width="65px" height="65px">
				<circle
					className="loading-spinner__circle"
					stroke="var(--svg-color)"
					fill="none"
					strokeWidth="6"
					strokeLinecap="round"
					cx="33"
					cy="33"
					r="30"
				/>
			</svg>
		</span>
	)
}

export default LoadingSpinner
