import classNames from 'classnames'
import { IConnectCallerDetailsResponse } from 'hooks/useConnectApi'
import { useSoftphone } from 'hooks/useSoftphone'
import { FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { uid } from 'react-uid'
import { ConnectSuggestedAction } from './ConnectSuggestedAction'

const fieldConfiguration: {
	[key in keyof IConnectCallerDetailsResponse]?: {
		bold?: true
	}
} = {
	vorname: { bold: true },
	nachname: { bold: true },
	identNumber: {},
	personalnummer: {},
}

export const ConnectCallInformation: FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
	const { contactAttributes, contactData, contactQueue } = useSoftphone()
	const { t } = useTranslation()

	return (
		<div className={classNames(className, 'connect-call-information')}>
			{contactAttributes?.phoneNumber && (
				<div className="flex flex--direction-column connect-call-information__row">
					<span className="bold-small-heading">{t('component.connect.callInformation.phoneNumber')}</span>
					<span>{contactAttributes?.phoneNumber.value}</span>
				</div>
			)}
			<div className="flex flex--direction-column connect-call-information__row">
				<span className="bold-small-heading">{t('component.connect.callInformation.queueName')}</span>
				<span>{contactQueue?.name}</span>
			</div>
			{Object.entries(fieldConfiguration).map(([key, value]) => {
				const attribute = contactData?.[key as keyof IConnectCallerDetailsResponse]

				if (attribute) {
					return (
						<div key={uid(key)} className="flex flex--direction-column connect-call-information__row">
							<span className="bold-small-heading">
								{t([
									`component.connect.callInformation.queue.${contactQueue?.name}.${key}`,
									`component.connect.callInformation.${key}`,
								])}
							</span>
							<span className={classNames({ bold: value.bold })}>{attribute}</span>
						</div>
					)
				}

				return null
			})}

			<ConnectSuggestedAction />
		</div>
	)
}
