import GridList from 'components/GridList'
import GridListCell from 'components/GridListCell'
import GridListRow from 'components/GridListRow'
import { IconType } from 'components/Icons'
import useApi, { QueryKey, ITicketType } from 'hooks/useApi'
import i18next from 'i18next'
import { FunctionComponent, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import Button, { ButtonType } from 'shared/components/Button'
import { DateInputProps, DateInput } from 'shared/components/DateInput'
import SelectInput, { SelectOption } from 'shared/components/SelectInput'
import SwitchSelect, { SwitchSelectProps } from 'shared/components/SwitchSelect'
import { getSessionStorage } from 'shared/helper/sessionStorage'

export interface IInboxListFooter {
	type: string
	firstPage?: boolean
	lastPage?: boolean
	displayedRange?:
		| {
				from: string
				to: string
		  }
		| undefined
	onDateChange: DateInputProps['onChange']
	onTypeChange: (selectedOption: string) => void
	onProblemStateChange?: SwitchSelectProps['onChange']
	onNavigateForwards: () => void
	onNavigateBackwards: () => void
}

export const InboxListFooter: FunctionComponent<IInboxListFooter> = ({
	type,
	firstPage,
	lastPage,
	displayedRange,
	onDateChange,
	onTypeChange,
	onProblemStateChange,
	onNavigateBackwards,
	onNavigateForwards,
}) => {
	const { t } = useTranslation()
	const api = useApi()
	const { data: ticketTypesData } = useQuery([QueryKey.ticketTypes], api.getTicketTypes)
	const filterByDateDefaultValue = useRef<string | undefined>(getSessionStorage(`inbox-${type}-dateFilter`))
	const filterByTypeDefaultValue = useRef<string | undefined>(getSessionStorage(`inbox-${type}-documentTypeFilter`))
	const filterByProblemStateDefaultValue = useRef<string | undefined>(
		getSessionStorage(`inbox-${type}-problemStateFilter`)
	)
	const today = useRef<Date>(new Date())

	const ticketTypes: SelectOption[] | undefined = useMemo(() => {
		const allTypesFilter: SelectOption = { label: t('component.inboxListFooter.allTypesLabel'), value: '' }

		if (undefined === ticketTypesData) {
			return undefined
		}

		const mappedTypes = ticketTypesData
			.reduce((filteredTypes: ITicketType[], currentType: ITicketType) => {
				if (filteredTypes.some((filteredType) => currentType.type === filteredType.type)) {
					return filteredTypes
				}

				filteredTypes.push(currentType)
				return filteredTypes
			}, [])
			.map(
				(item): SelectOption => ({
					label: t(`component.documentEntry.type.${item.type}`),
					value: item.type,
				})
			)
			.sort((a, b) => String(a.label).localeCompare(String(b.label), i18next.language, { sensitivity: 'base' }))

		return [allTypesFilter, ...mappedTypes].filter(Boolean)
	}, [t, ticketTypesData])

	return (
		<GridList columnCount={6} className="inbox-list-footer">
			<GridListRow>
				<GridListCell>
					<DateInput
						value={
							filterByDateDefaultValue.current ? new Date(filterByDateDefaultValue.current) : undefined
						}
						onChange={onDateChange}
						label={t('component.inboxListFooter.filterByDate')}
						maxDate={today.current}
					/>
				</GridListCell>
				<GridListCell className="inbox-list-footer__filter-by-type">
					{ticketTypes && (
						<SelectInput
							value={filterByTypeDefaultValue.current}
							alternativeStyle={true}
							label={t('component.inboxListFooter.filterByType')}
							options={ticketTypes}
							onChange={onTypeChange}
						/>
					)}
				</GridListCell>
				{onProblemStateChange && (
					<GridListCell className="inbox-list-footer__filter-by-problem-state">
						<SwitchSelect
							className="flex--grow"
							label={t('component.inboxListFooter.filterByProblemState.label')}
							value={filterByProblemStateDefaultValue.current || ''}
							options={[
								{ value: '', label: t('component.inboxListFooter.filterByProblemState.all') },
								{
									value: 'default',
									label: t('component.inboxListFooter.filterByProblemState.default'),
								},
							]}
							onChange={onProblemStateChange}
						/>
					</GridListCell>
				)}
				<GridListCell className="inbox-list-footer__pagination">
					{displayedRange && (
						<div className="flex flex--direction-column">
							<span className="bold-small-heading">{t('component.inboxListFooter.displayedRange')}</span>
							<span className="inbox-list-footer__displayed-range-value">
								{t('component.inboxListFooter.fromDateToDate', {
									fromDate: displayedRange.from,
									toDate: displayedRange.to,
								})}
							</span>
						</div>
					)}

					<Button
						type={ButtonType.round}
						icon={IconType.pageUp}
						className="margin--left margin--large"
						onClick={onNavigateBackwards}
						disabled={firstPage}
					/>
					<Button
						type={ButtonType.round}
						icon={IconType.pageUp}
						iconRotate={180}
						className="margin--left"
						onClick={onNavigateForwards}
						disabled={lastPage}
					/>
				</GridListCell>
			</GridListRow>
		</GridList>
	)
}
