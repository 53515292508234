import { ViewHeader } from 'components/ViewHeader'
import BackButton from 'partials/BackButton'
import { useTranslation } from 'react-i18next'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath } from 'state/useNavigationState'
import routesDictionary from '../routes'
import Button, { ButtonType } from '../shared/components/Button'

const Settings = () => {
	const { navigateTo, getMainPath } = useRouteHelper()
	const { t } = useTranslation()
	useBackButtonPath(getMainPath(routesDictionary.dashboard))

	return (
		<>
			<ViewHeader headline={t('view.settings.headline')} button={<BackButton />} />

			<div className="centered-view">
				<Button
					type={ButtonType.text}
					label={t('view.settings.button.changeWebAuthDevice')}
					onClick={() => navigateTo(getMainPath(routesDictionary.webAuthSetup))}
				/>
				<hr className="hr hr--narrow" />
				<Button
					type={ButtonType.secondary}
					label={t('view.settings.button.logout')}
					onClick={() => navigateTo(getMainPath(routesDictionary.logout))}
				/>
			</div>
		</>
	)
}

export default Settings
