import { DefaultTFuncReturn } from 'i18next'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Form, { FormFieldType, FormFields } from 'shared/components/Form'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { AuthError, ErrorType, useAuth } from '../hooks/useAuth'
import Button, { ButtonType } from '../shared/components/Button'
import { TextInputType } from '../shared/components/TextInput'
import isDev from '../shared/helper/isDev'

export const SetNewPassword = () => {
	const { navigateTo, getMainPath } = useRouteHelper()
	const { t } = useTranslation()
	const { userData, completeNewPassword } = useAuth()

	const [errorMessages, setErrorMessages] = useState<(string | React.ReactElement | DefaultTFuncReturn)[]>()

	const inputElements: FormFields = {
		given_name: {
			value: isDev() ? 'Till' : '',
			required: true,
			type: TextInputType.text,
			label: t('view.setNewPassword.formFieldLabels.firstName'),
			autoComplete: 'given-name',
			fieldType: FormFieldType.text,
		},
		name: {
			value: isDev() ? 'Schweneker' : '',
			required: true,
			type: TextInputType.text,
			label: t('view.setNewPassword.formFieldLabels.lastName'),
			autoComplete: 'family-name',
			fieldType: FormFieldType.text,
		},
		newPassword: {
			value: isDev() ? 'testPassword??123456' : '',
			required: true,
			type: TextInputType.password,
			label: t('view.setNewPassword.formFieldLabels.setNewPassword'),
			autoComplete: 'new-password',
			fieldType: FormFieldType.text,
		},
		confirmNewPassword: {
			value: isDev() ? 'testPassword??123456' : '',
			required: true,
			type: TextInputType.password,
			label: t('view.setNewPassword.formFieldLabels.confirmNewPassword'),
			fieldType: FormFieldType.text,
		},
	}

	if (userData) {
		return (
			<div className="auth">
				<h1>
					<Trans i18nKey="view.setNewPassword.pageTitle" />
				</h1>

				<h3>
					<Trans i18nKey="view.login.alreadyLoggedIn" />
				</h3>
				<Button
					onClick={() => navigateTo(routesDictionary.dashboard.path)}
					label={t('view.login.goToDashboard')}
					type={ButtonType.secondary}
				/>
			</div>
		)
	}

	const onErrorHandle = (error: AuthError) => {
		if (ErrorType.mfaSetup === error?.code) {
			navigateTo(getMainPath(routesDictionary.mfaSetup))
			return
		}

		if (ErrorType.mfa === error?.code) {
			navigateTo(getMainPath(routesDictionary.mfa))
			return
		}

		if (ErrorType.webAuthSetup === error?.code) {
			navigateTo(getMainPath(routesDictionary.webAuthSetup))
			return
		}

		switch (error.code) {
			case ErrorType.passwordMismatch:
				setErrorMessages([t('generic.formErrors.passwordError')])
				break

			case ErrorType.limitExceeded:
				setErrorMessages([t('generic.formErrors.limitExceeded')])
				break

			case ErrorType.invalidParameter:
				setErrorMessages([t('generic.formErrors.invalidParameter')])
				break

			case ErrorType.sessionInvalid:
				setErrorMessages([
					t('generic.formErrors.sessionInvalid.text'),
					<Button
						onClick={() => navigateTo(getMainPath(routesDictionary.login))}
						label={t('generic.formErrors.sessionInvalid.link')}
						type={[ButtonType.text, ButtonType.link]}
					/>,
				])
				break

			default:
				setErrorMessages([t('generic.formErrors.default')])
		}
	}

	return (
		<div className="auth">
			<div>
				<h1 className="text-color-gold text-transform-uppercase">
					<Trans i18nKey="view.setNewPassword.pageTitle" />
				</h1>

				<p>
					<Trans i18nKey="view.setNewPassword.bodytext" />
				</p>
			</div>
			<Form
				className="auth"
				fields={inputElements}
				onSubmit={(submittedFields: { [key: string]: string }) => {
					if (submittedFields.newPassword !== submittedFields.confirmNewPassword) {
						return {
							successful: false,
							code: ErrorType.passwordMismatch,
						}
					}

					return completeNewPassword(submittedFields)
				}}
				onError={onErrorHandle}
				errorMessages={errorMessages}
				submitLabel={t('view.setNewPassword.submit')}
			/>
		</div>
	)
}
