import React from 'react'
import globalHook, { Store } from 'use-global-hook'

export enum MainBackgroundColor {
	dark = 'dark',
	light = 'light',
}

interface UIState {
	backgroundColor: MainBackgroundColor
}

interface UIActions {
	setBackgroundColor: (backgroundColor: MainBackgroundColor) => void
	resetState: () => void
}

const setBackgroundColor = (store: Store<UIState, UIActions>, backgroundColor: MainBackgroundColor) => {
	if (backgroundColor === store.state.backgroundColor) {
		return
	}

	store.setState({ ...store.state, backgroundColor })
}

const resetState = (store: Store<UIState, UIActions>) => {
	store.setState(initialState)
}

const initialState: UIState = {
	backgroundColor: MainBackgroundColor.light,
}

// actions passed to globalHook do not need to be typed
const actions = {
	setBackgroundColor,
	resetState,
}

const useUIState = globalHook<UIState, UIActions>(React, initialState, actions)

export default useUIState
