import { useTranslation } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'
import TextInput from 'shared/components/TextInput'
import { StatusFlag } from './DocumentCard'
import { TDocumentType } from './document-view-types'
import { FunctionComponent } from 'react'
import Checkbox from 'shared/components/Checkbox'

type TAddDocumentProps = {
	addDocument: (checked: boolean, type: TDocumentType, title: string) => void
	handleToggleNewDocument: () => void
	toggleNewDocument: boolean
}
// AddDocument Komponente. Hier können weitere Dokumente hinzugefügt werden.
const AddDocument: FunctionComponent<TAddDocumentProps> = ({
	addDocument,
	handleToggleNewDocument,
	toggleNewDocument,
}) => {
	const { t } = useTranslation()
	const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		if (e.relatedTarget?.tagName === 'BUTTON') {
			return
		}
		const updatedTitle = e.target.value
		addDocument(false, 'SONSTIGE_NACHWEISE', updatedTitle)
	}
	return (
		<div className="add-other-document">
			{toggleNewDocument && (
				<>
					<div className="add-other-document__inputs">
						<Checkbox size="large" value={'SONSTIGE_NACHWEISE'} />

						<TextInput
							label={t('view.dokumente.add')}
							className="add-other-document__textinput"
							onBlur={handleBlur}
						/>

						<StatusFlag status="nochNichtAngefragt" />
					</div>
				</>
			)}

			<Button
				className="document-card__content-container--document-button"
				type={[ButtonType.small, ButtonType.secondary]}
				onClick={handleToggleNewDocument}
			>
				{toggleNewDocument ? 'Abbrechen' : 'Weitere Dokumente anfragen'}
			</Button>
		</div>
	)
}

export default AddDocument
