import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import useApi, { EmployeeMasterData, PensionRuleSetCode, QueryKey } from './useApi'

export interface ClientData {
	firstName?: string
	lastName?: string
	address?: string
	zipCode?: number
	city?: string
	phoneNumber?: string
	mobileNumber?: string
	birthdate?: string
	email?: string
}

export interface BankData {
	iban: string
	bic: string
	bank: string
	accountOwner: string
}

export interface PensionData {
	pensionTotal: number
	pensionPaid: number
	pensionTime: number
	pensionPayments: PensionPayment[]
}

export type IPensionerBaseData = EmployeeMasterData
export interface VersorgungsausgleichStatusInfoAdminApi {
	readonly statusCode: VersorgungsausgleichStatusCode
	readonly statusText: string
	readonly changedAt: string
	readonly role?: 'Ausgleichsberechtigt' | 'Ausgleichspflichtig' | undefined | null
	readonly connectedPerson?: {
		readonly identNumber: string
		readonly name: string
	}
	readonly ehe: {
		readonly nummer: number
		readonly von?: string
		readonly bis?: string
		readonly note: string
	}
	readonly gericht: Readonly<
		Partial<{
			gerichtsname: string
			aktenzeichen: string
			anfragedatum: string
			urteilVom: string
			rechtskraeftigAb: string
			kenntnisnahmeRechtskraeftigAb: string
		}>
	>
}

export type VersorgungsausgleichStatusCode = 1 | 2 | 9

export enum PensionPaymentStatus {
	paid = 'paid',
	unpaid = 'unpaid',
}

export interface PensionPayment {
	status: PensionPaymentStatus
	amount: number
	date: string
}

export enum ContactStatus {
	success = 'success',
	pending = 'pending',
	rejected = 'rejected',
}

export enum ContactMedium {
	email = 'email',
	mail = 'mail',
	phone = 'phone',
}

export interface ContactData {
	status: ContactStatus
	date: string
	medium: ContactMedium
	subject: string
	randomString: string
}

export interface RecordData {
	clientData?: ClientData
	bankData?: BankData
	pensionData?: PensionData
	contactData?: ContactData[]
	navigationData?: UserNavigationData
	baseData?: IPensionerBaseData
}

export interface ClientCredentials {
	id: number
	password: string
}

interface UserConsent {
	hasAccepted: boolean
	lastUpdateTime: string
}

export type UserNavigationData = Pick<IPensionerBaseData['contactDetails'], 'firstName' | 'lastName' | 'identNumber'>

export interface UserRecordContext {
	userId?: string
	navigationData?: UserNavigationData
	firstnamesGenitive?: string
	firstname?: string
	lastname?: string
	openRecord(id: string): Promise<void>
	closeRecord(): Promise<void>
	// setClientData: (clientData: ClientData) => void
	// setBankData: (bankData: BankData) => void
	// setPensionData: (pensionData: PensionData) => void
	// setContactData: (contactData: ContactData) => void
}

const userRecordContext = createContext<UserRecordContext>({
	openRecord: async (id: string) => {},
	closeRecord: async () => {},
	// setClientData: (clientData: ClientData) => {
	// 	return
	// },
	// setBankData: (bankData: BankData) => {
	// 	return
	// },
	// setPensionData: (pensionData: PensionData) => {
	// 	return
	// },
	// setContactData: (contactData: ContactData) => {
	// 	return
	// },
})

export function ProvideUserRecord({ children }: any) {
	const userRecord: any = useProvideUserRecord()
	return <userRecordContext.Provider value={userRecord}>{children}</userRecordContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useUserRecord = () => {
	return useContext(userRecordContext)
}

// Provider hook that creates auth object and handles state
function useProvideUserRecord() {
	const { t } = useTranslation()

	const [userId, setUserId] = useState<string>()
	const [navigationData, setNavigationData] = useState<UserNavigationData>()
	const queryClient = useQueryClient()
	const api = useApi()

	const { data: baseDataRequest, status: baseDataRequestStatus } = useQuery(
		[QueryKey.pensionerBaseData, { userId }],
		api.getPensionerBaseData,
		{
			enabled: !!userId,
		}
	)

	const openRecord = async (id: string) => {
		setUserId(undefined)
		setNavigationData(undefined)

		const promises = []

		const queriesToRemoveFromCache = [
			QueryKey.pensionerBaseData,
			QueryKey.pensionerDocuments,
			QueryKey.pensionerConfirmedCompensationsTable,
			QueryKey.pensionerPensionAssets,
		]

		for (const query of queriesToRemoveFromCache) {
			promises.push(queryClient.cancelQueries(query))
		}

		await Promise.all(promises)

		sessionStorage.setItem('pensioner', id)
		setUserId(id)
	}

	const closeRecord = async () => {
		sessionStorage.removeItem('pensioner')
		setUserId(undefined)
		setNavigationData(undefined)
	}

	const firstnamesGenitive: string = useMemo(() => {
		const suffixes = t('generic.genitive.suffixes', { returnObjects: true }) as string[]
		const firstName = navigationData?.firstName

		if (undefined === firstName) {
			return ''
		}

		return suffixes.some((suffix) => firstName.toLowerCase().endsWith(suffix))
			? t('generic.genitive.matchedSuffix', { value: firstName })
			: t('generic.genitive.unmatchedSuffix', { value: firstName })
	}, [navigationData, t])

	const lastname: string = useMemo(() => {
		const lastName = navigationData?.lastName
		if (undefined === lastName) {
			return ''
		}
		return lastName
	}, [navigationData])
	const firstname: string = useMemo(() => {
		const firstName = navigationData?.firstName
		if (undefined === firstName) {
			return ''
		}
		return firstName
	}, [navigationData])

	useEffect(() => {
		if ('success' === baseDataRequestStatus && undefined !== baseDataRequest) {
			const { firstName, lastName, identNumber } = baseDataRequest.contactDetails
			setNavigationData({ firstName, lastName, identNumber })
		}
	}, [baseDataRequest, baseDataRequestStatus])

	/**
	 * 	this effect makes sure that the current user is loaded again when reloading the page
	 */
	// useEffect(() => {
	// 	const currentUserRecord = localStorage.getItem('userRecord')

	// 	if (null === currentUserRecord) {
	// 		return setData(undefined)
	// 	}

	// 	setData(JSON.parse(currentUserRecord) as RecordData)

	// 	// Auth.currentAuthenticatedUser({
	// 	// 	bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
	// 	// })
	// 	// 	.then(currentUser => {
	// 	// 		return setUser(currentUser.signInUserSession.idToken.payload)
	// 	// 	})
	// 	// 	.catch(err => {
	// 	// 		// TODO: send to login or error page if the user is not authorized anymore
	// 	// 		return setUser(undefined)
	// 	// 	})

	// 	// Cleanup subscription on unmount
	// }, [])

	return {
		userId,
		navigationData,
		firstnamesGenitive,
		firstname,
		lastname,
		openRecord,
		closeRecord,
	}
}
