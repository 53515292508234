/**
 * parses a dot-separated date string
 * into a dash-seperated iso date string
 * @param dateString string
 */
const parseDate = (dateString: any): string => {
	dateString = dateString.trim()

	if (dateString.includes('.')) {
		dateString = dateString.split('.').reverse().join('-')
	}

	return dateString
}

export const DATE_STRING_VALIDATION = '\\d{2}\\.\\d{2}\\.\\d{4}'

export default parseDate
