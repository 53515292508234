import ConfirmedAuszahlungOption from 'components/ConfirmedAuszahlungOption'
import { ViewHeader } from 'components/ViewHeader'
import useApi, { QueryKey } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import { TFunction, t } from 'i18next'
import { useEffect, useState, ChangeEvent } from 'react'
import { Trans } from 'react-i18next'
import { QueryFunctionContext, useQuery } from 'react-query'
import routesDictionary from 'routes'
import Form, { FormFieldType, FormFields } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import dateFormat from 'shared/helper/dateFormat'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import PensionerLeistungsdatenTable from '../../components/PensionerLeistungsdatenTable'
import Button, { ButtonType } from '../../shared/components/Button'
import Relations from './Relations'
import Versorgungsausgleich from './Versorgungsausgleich'
import { FormField } from '../../shared/components/Form'
import { IPensionerBaseData } from '../../hooks/useUserRecord'
import { AustritttaskButton } from './AustritttaskButton'
import { components } from 'types/api-interface'
import { versorgungsausgleichFlag } from 'shared/components/flags'

type ILeavingInformation = {
	eintrittLeistungsfallAusLeistungsdaten?: string
	leistungsbeginn?: string
	austritt?: (string | undefined)[]
	anspruchUnverfallbar?: boolean
	anspruchVertraglichUnverfallbar?: boolean | null
	altersTeilzeit?: boolean
	altersTeilzeitZeitraum?: (string | undefined)[]
	emBuAuszahlungen?: LeistungsfallEmBuType;
	emBuAuszahlungenBefristetBis?: string | undefined;
} & { [key: EmptyCellKey]: '' }

type EmptyCellKey = `emptyCell${string}`
type LeistungsfallEmBuType = LeistungsfallEmBu['type']
type LeistungsfallEmBuTypeWithDate = Extract<LeistungsfallEmBuType, 'EM_TEIL_BEFR' | 'EM_VOLL_BEFR' | 'BU'>
type OnAnspruchVertraglichUnverfallbarChanged = (value: AnspruchVertraglichUnverfallbarValue) => unknown
type OnEmBuAuszahlungenChanged = (value: LeistungsfallEmBuType) => unknown
type OnEmBuAuszahlungenBefristetBisChanged = (value: string, type: LeistungsfallEmBuTypeWithDate) => unknown
type LeistungsfallEmBu = components['schemas']['LeistungsfallEmBu']

type AnspruchVertraglichUnverfallbarValue = Exclude<
	IPensionerBaseData['pensionPlan']['participationState']['anspruchVertraglichUnverfallbar'],
	undefined
>

const NOT_SET_VALUE = '-'

const processValue =
	(t: TFunction<'translation'>) =>
		(value: string, { fieldKey }: { fieldKey: keyof ILeavingInformation }) => {
			switch (fieldKey) {
				case 'altersTeilzeit':
				case 'anspruchUnverfallbar':
				case 'anspruchVertraglichUnverfallbar':
					return t(`view.pensionerLeavingInformation.fields.${fieldKey}.${value}`)
				case 'altersTeilzeitZeitraum':
					if (value.length === 2) {
						const atzBeginn = value[0] ? dateFormat(new Date(value[0])) : '–'
						const atzEnde = value[1] ? dateFormat(new Date(value[1])) : '–'
						if (atzBeginn === '–' && atzEnde === '–') {
							return '–'
						} else {
							return atzBeginn + ' - ' + atzEnde
						}
					}
					return value
				case 'eintrittLeistungsfallAusLeistungsdaten':
					return value
				case 'austritt':
					const austrittsDatum = value[0] ? dateFormat(new Date(value[0])) : '–'
					const austrittsGrund = value[1] ? value[1] : '–'
					if (austrittsDatum === '–' && austrittsGrund === '–') {
						return '–'
					} else {
						return austrittsDatum + ', ' + austrittsGrund
					}
				default:
					return value
			}
		}

const getLeavingInformationFields = (
	leavingInformationData: ILeavingInformation,
	t: TFunction<'translation'>,
	{
		leistungsfallEmBu,
		onAnspruchVertraglichUnverfallbarChanged,
		anspruchVertraglichUnverfallbarError,
		onEmBuAuszahlungenChanged,
		onEmBuAuszahlungenBefristetBisChanged,
		befristetLeistungsfall
	}: {
		leistungsfallEmBu: LeistungsfallEmBu | undefined,
		onAnspruchVertraglichUnverfallbarChanged: OnAnspruchVertraglichUnverfallbarChanged
		anspruchVertraglichUnverfallbarError: string
		onEmBuAuszahlungenChanged: OnEmBuAuszahlungenChanged
		onEmBuAuszahlungenBefristetBisChanged: OnEmBuAuszahlungenBefristetBisChanged,
		befristetLeistungsfall: LeistungsfallEmBuTypeWithDate | undefined
	}
) => {
	return Object.keys(leavingInformationData).reduce((fields: FormFields, fieldKey: string) => {
		if (fieldKey.startsWith('emptyCell' satisfies EmptyCellKey)) {
			fields[fieldKey] = createEmptyCell()
			return fields
		}
		const value = leavingInformationData[fieldKey as keyof ILeavingInformation]
		const valueIsNotSet = undefined === value || '' === value || null === value
		const fieldValue = valueIsNotSet ? NOT_SET_VALUE : typeof value !== 'object' ? String(value) : (value as any)
		const fieldLabel = t(
			`view.pensionerLeavingInformation.fields.${fieldKey}.label`,
			t(`view.pensionerLeavingInformation.fields.${fieldKey}`)
		)
		if (fieldKey === ('anspruchVertraglichUnverfallbar' satisfies keyof ILeavingInformation)) {
			fields[fieldKey] = createAnspruchVertraglichUnverfallbarField({
				fieldLabel,
				fieldValue,
				onAnspruchVertraglichUnverfallbarChanged,
				anspruchVertraglichUnverfallbarError,
			})
		} else if (fieldKey === ('emBuAuszahlungen' satisfies keyof ILeavingInformation)) {
			fields[fieldKey] = createEmBuAuszahlungenField({
				fieldLabel,
				fieldValue,
				leistungsfallEmBu,
				onEmBuAuszahlungenChanged,
				t,
			})
		} else if (fieldKey === ('emBuAuszahlungenBefristetBis' satisfies keyof ILeavingInformation)) {
			fields[fieldKey] = createEmBuAuszahlungenBefristetBisField({
				fieldLabel,
				fieldValue,
				onEmBuAuszahlungenBefristetBisChanged,
				leistungsfallEmBu,
				befristetLeistungsfall
			})
		} else {
			fields[fieldKey] = createTextField({ fieldLabel, fieldKey, fieldValue })
		}
		return fields
	}, {})
}

const createTextField = ({
	fieldValue,
	fieldKey,
	fieldLabel,
}: {
	fieldKey: string
	fieldValue: any
	fieldLabel: string
}): FormField => {
	return {
		value: fieldValue,
		type: TextInputType.text,
		label: fieldLabel,
		valueFunction:
			fieldValue === NOT_SET_VALUE
				? undefined
				: {
					name: processValue(t),
					parameters: { fieldKey },
				},
		fieldType: FormFieldType.text,
		disabled: true,
	}
}

const createEmptyCell = (): FormField => {
	return {
		value: '',
		type: TextInputType.text,
		label: '',
		fieldType: FormFieldType.text,
		disabled: true,
	}
}

const createAnspruchVertraglichUnverfallbarField = ({
	fieldValue,
	fieldLabel,
	onAnspruchVertraglichUnverfallbarChanged,
	anspruchVertraglichUnverfallbarError,
}: {
	fieldValue: any
	fieldLabel: string
	onAnspruchVertraglichUnverfallbarChanged: OnAnspruchVertraglichUnverfallbarChanged
	anspruchVertraglichUnverfallbarError: string
}): FormField => {
	const options = [
		{
			value: 'true',
			label: 'ja',
		},
		{
			value: NOT_SET_VALUE,
			label: NOT_SET_VALUE,
		},
	] as const
	const parseFieldValue = (fieldValue: (typeof options)[number]['value']): AnspruchVertraglichUnverfallbarValue => {
		switch (fieldValue) {
			case 'true':
				return true
			case NOT_SET_VALUE:
				return null
		}
	}
	return {
		value: fieldValue,
		label: fieldLabel,
		fieldType: FormFieldType.select,
		options: options.slice(),
		errorMessage: anspruchVertraglichUnverfallbarError,
		onChange: (event: ChangeEvent<HTMLInputElement>) => {
			// the typing above ensures that this string is only one of the given values
			const fieldValue = event.target.value as (typeof options)[number]['value']
			const vertraglichUnverfallbar = parseFieldValue(fieldValue)
			onAnspruchVertraglichUnverfallbarChanged(vertraglichUnverfallbar)
		},
	}
}

const createEmBuAuszahlungenField = ({
	fieldValue,
	fieldLabel,
	leistungsfallEmBu,
	onEmBuAuszahlungenChanged,
	t
}: {
	fieldValue: any
	fieldLabel: string
	leistungsfallEmBu: LeistungsfallEmBu | undefined
	onEmBuAuszahlungenChanged: OnEmBuAuszahlungenChanged
	t: TFunction<'translation'>
}): FormField => {
	const options = leistungsfallEmBuTypes.map((value) => ({ label: t(`view.pensionerLeavingInformation.fields.emBuAuszahlungen.${value}`), value }));
	return {
		value: fieldValue ? fieldValue : leistungsfallEmBu?.type ? leistungsfallEmBu?.type : undefined,
		label: t(fieldLabel),
		fieldType: FormFieldType.select,
		options: options.slice(),
		onChange: (event: ChangeEvent<HTMLInputElement>) => {
			// the typing above ensures that this string is only one of the given values
			const fieldValue = event.target.value as LeistungsfallEmBuType
			onEmBuAuszahlungenChanged(fieldValue)
		},
	};
}

const createEmBuAuszahlungenBefristetBisField = ({
	fieldValue,
	fieldLabel,
	onEmBuAuszahlungenBefristetBisChanged,
	leistungsfallEmBu,
	befristetLeistungsfall
}: {
	fieldValue: any
	fieldLabel: string
	onEmBuAuszahlungenBefristetBisChanged: OnEmBuAuszahlungenBefristetBisChanged,
	leistungsfallEmBu: LeistungsfallEmBu | undefined,
	befristetLeistungsfall: LeistungsfallEmBuTypeWithDate | undefined
}): FormField => {
	const enabled = befristetLeistungsfall ? befristetLeistungsfall === 'EM_TEIL_BEFR' || befristetLeistungsfall === 'EM_VOLL_BEFR' || befristetLeistungsfall === 'BU' : leistungsfallEmBu?.type === 'EM_TEIL_BEFR' || leistungsfallEmBu?.type === 'EM_VOLL_BEFR' || leistungsfallEmBu?.type === 'BU';
	const formField: FormField = {
		value: enabled ? fieldValue ? fieldValue : leistungsfallEmBuBefristetBisToFieldValue(leistungsfallEmBu?.befristetBis) : undefined,
		label: fieldLabel,
		fieldType: FormFieldType.text,
		minLength: 7,
		maxLength: 7,
		placeholder: "MM/JJJJ",
		onChange: (event: ChangeEvent<HTMLInputElement>) => {
			const fieldValue = event.target.value;
			onEmBuAuszahlungenBefristetBisChanged(fieldValue, (befristetLeistungsfall ?? leistungsfallEmBu?.type) as LeistungsfallEmBuTypeWithDate);
		},
		disabled: !enabled,
	}
	return formField;
}

const PensionerLeavingInformation: React.FC = () => {
	const { isRouteAvailable } = useRouteHelper()
	const { userId } = useUserRecord()
	const api = useApi()
	const onRedirectToEmployee = (identNumber: string) => window.open(`/pensioner/${identNumber}/profile`, '_blank')
	const { data: leistungsdaten } = useQuery([QueryKey.leistungsdaten, { userId }], api.getLeistungsdaten, {
		enabled: !!userId,
	})
	const { data: confirmedAuszahlungOption } = useQuery(
		[QueryKey.pensionerConfirmedAuszahlungOption, { userId }],
		api.getConfirmedAuszahlungOption,
		{ enabled: !!userId }
	)
	const { data: stammdaten } = useQuery(
		[QueryKey.austrittsbearbeitungen, { userId }],
		api.getAustrittsbearbeitungenStammdatenByIdentNumber,
		{
			enabled: !!userId,
		}
	)
	const { data: relations } = useQuery([QueryKey.relations, { userId }], api.getRelations, { enabled: !!userId })
	const { data: versorgungsausgleich } = useQuery(
		[QueryKey.versorgungsausgleich, { userId }],
		api.getVersorgungsausgleich,
		{ enabled: !!userId }
	)
	const { data: baseData, refetch: refetchBaseData } = useQuery(
		[QueryKey.pensionerBaseData, { userId }],
		api.getPensionerBaseData,
		{
			enabled: !!userId,
		}
	)

	const { data: flags, refetch: refetchFlags } = useQuery(
		[QueryKey.masterDataFlag, { userId }],
		api.getMasterDataFlagsByIdentNumber,
		{
			enabled: !!userId,
		}
	)

	const { data: leistungsfallEmBu, refetch: refetchLeistungplanEmBu, status } = useQuery(
		[QueryKey.leistungsfallEmBu, { userId }],
		api.getMasterDataLeistungsfallEmBu,
		{
			enabled: !!userId,
		}
	)

	const [befristetLeistungsfall, setBefristetLeistungfall] = useState<LeistungsfallEmBuTypeWithDate>()

	const [leavingInformationFields, setLeavingInfoFields] = useState<FormFields>()
	const [anspruchVertraglichUnverfallbarError, setAnspruchVertraglichUnverfallbarError] = useState<string>('')
	useEffect(
		() => {
			if (baseData && status === 'success') {
				const hasLeistungsDaten = leistungsdaten?.leistungen.length > 0
				const leavingInformationData: ILeavingInformation = {
					eintrittLeistungsfallAusLeistungsdaten: hasLeistungsDaten
						? leistungsdaten.leistungen[0].renteneintritt
						: baseData?.pensionPlan.leistungsbeginn
							? dateFormat(new Date(baseData?.pensionPlan.leistungsbeginn)) +
							t(`view.pensionerLeavingInformation.fields.eintrittLeistungsfallAusLeistungsdaten.berechnet`)
							: NOT_SET_VALUE,
					leistungsbeginn: hasLeistungsDaten ? leistungsdaten.leistungen[0].leistungsbeginn : undefined,
					austritt: [baseData?.pensionPlan.leavingDate, baseData?.pensionPlan.leavingReason] ?? '',
					emptyCellUnverfallbarkeitOneLine: '',
					anspruchUnverfallbar: baseData?.pensionPlan.participationState.anspruchUnverfallbar,
					anspruchVertraglichUnverfallbar:
						baseData?.pensionPlan.participationState.anspruchVertraglichUnverfallbar,
					altersTeilzeit: baseData?.pensionPlan.isInPartialRetirement,
					altersTeilzeitZeitraum:
						[
							baseData?.pensionPlan.partialRetirementStartDate,
							baseData?.pensionPlan.partialRetirementEndDate,
						] ?? undefined,
					emBuAuszahlungen: leistungsfallEmBu?.type,
					emBuAuszahlungenBefristetBis: leistungsfallEmBuBefristetBisToFieldValue(leistungsfallEmBu?.befristetBis),
				}
				const onAnspruchVertraglichUnverfallbarChanged: OnAnspruchVertraglichUnverfallbarChanged = (value) => {
					api.putAnspruchVertraglichUnverfallbar({
						userId: userId!,
						anspruchVertraglichUnverfallbar: value,
					}).then((response) => {
						if (response.success) {
							refetchBaseData()
						} else {
							let errorMessageKey = 'default'
							if (response.error.status === 400 && response.error.message.includes('participation')) {
								errorMessageKey = 'participation'
							} else if (response.error.status === 404) {
								errorMessageKey = '404'
							}
							const errorMessage: string = t(
								`view.pensionerLeavingInformation.fields.anspruchVertraglichUnverfallbar.errors.${errorMessageKey}`
							)
							setAnspruchVertraglichUnverfallbarError(errorMessage)
						}
					})
				}
				const updateEmBuAuszahlungenBefristetBisInfo = async ({ newEmBuAuszahlungenBefristetBis, newEmBuAuszahlungen }: { newEmBuAuszahlungenBefristetBis: string, newEmBuAuszahlungen: LeistungsfallEmBuTypeWithDate }) => {
					if (!userId) {
						return
					}
					const month = parseInt(newEmBuAuszahlungenBefristetBis.substring(0, 2), 10)
					await api.postMasterDataLeistungsfallEmBu({
						userId,
						body: {
							type: newEmBuAuszahlungen, befristetBis: {
								month: (month as components['schemas']['Month']),
								year: parseInt(newEmBuAuszahlungenBefristetBis.substring(3))

							}
						},
					});
					await refetchLeistungplanEmBu()
				}
				const onEmBuAuszahlungenChanged: OnEmBuAuszahlungenChanged = (value) => {

					updateEmBuAuszahlungenInfo({ newEmBuAuszahlungen: value }).catch((err) => {
						// network errors are handled via toast in the api
						throw err
					})
				};
				const onEmBuAuszahlungenBefristetBisChanged: OnEmBuAuszahlungenBefristetBisChanged = (value, type) => {
					if (isEmBuAuszahlungenBefristetBisValueValid(value)) {
						updateEmBuAuszahlungenBefristetBisInfo({ newEmBuAuszahlungenBefristetBis: value, newEmBuAuszahlungen: type }).catch((err) => {
							// network errors are handled via toast in the api
							throw err
						});
					}
				}
				const updateEmBuAuszahlungenInfo = async ({ newEmBuAuszahlungen }: { newEmBuAuszahlungen: LeistungsfallEmBuType }) => {
					switch (newEmBuAuszahlungen) {
						case 'EM_VOLL_BEFR':
						case 'EM_TEIL_BEFR':
						case 'BU':
							setBefristetLeistungfall(newEmBuAuszahlungen)
							return
						case 'NONE':
						case 'EM_VOLL_UNBEFR_MIT':
						case 'EM_VOLL_UNBEFR_OHNE':
						case 'EM_TEIL_UNBEFR_MIT':
						case 'EM_TEIL_UNBEFR_OHNE':
							setBefristetLeistungfall(undefined)
					}

					if (!userId || newEmBuAuszahlungen === leistungsfallEmBu?.type) {
						return
					}


					await api.postMasterDataLeistungsfallEmBu({
						userId,
						body: { type: newEmBuAuszahlungen },
					})
					await refetchLeistungplanEmBu()
				}
				const updatedleavingInformationFields = getLeavingInformationFields(leavingInformationData, t, {
					leistungsfallEmBu,
					onAnspruchVertraglichUnverfallbarChanged,
					anspruchVertraglichUnverfallbarError,
					onEmBuAuszahlungenChanged,
					onEmBuAuszahlungenBefristetBisChanged,
					befristetLeistungsfall
				})
				setLeavingInfoFields(updatedleavingInformationFields)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[leistungsdaten, baseData, anspruchVertraglichUnverfallbarError, setAnspruchVertraglichUnverfallbarError, flags, leistungsfallEmBu, befristetLeistungsfall]
	)

	const putVersorgungsausgleichFlag = async () => {
		if (!userId) {
			return
		}
		await api.putMasterDataFlagByIdentNumber({
			userId,
			body: { flagKey: versorgungsausgleichFlag },
		})
		await refetchFlags()
	}

	const deleteVersorgungsausgleichFlag = async () => {
		if (!userId) {
			return
		}
		const context: QueryFunctionContext<[QueryKey.masterDataFlag, { userId: string; flagKey: string }], any> = {
			queryKey: [QueryKey.masterDataFlag, { userId, flagKey: versorgungsausgleichFlag }],
			meta: undefined,
		}
		await api.deleteMasterDataFlagByIdentNumber(context)
		await refetchFlags()
	}

	const hasVersorgungsausgleich = flags?.some(({ flagKey }) => flagKey === 'versorgungsausgleich')

	return (
		<div className="pensioner-profile">
			<ViewHeader
				headline={<Trans i18nKey="view.pensionerLeavingInformation.section.leavingInformation" />}
			></ViewHeader>
			<>{isRouteAvailable(routesDictionary.austrittsbearbeitungen) ? <AustritttaskButton /> : ''}</>
			<Button
				type={ButtonType.primary}
				label={
					hasVersorgungsausgleich ? (
						<Trans i18nKey="component.pensionerFlags.delete.versorgungsausgleich" />
					) : (
						<Trans i18nKey="component.pensionerFlags.put.versorgungsausgleich" />
					)
				}
				iconColor={'var(--color-black)'}
				className="button--width--fit-content"
				onClick={hasVersorgungsausgleich ? deleteVersorgungsausgleichFlag : putVersorgungsausgleichFlag}
			></Button>

			<h2 className="flex flex--align-items-center margin--vertical margin--large">
				<Trans i18nKey="view.pensionerLeavingInformation.subSection.leavingInformation" />
			</h2>
			<div className="margin--horizontal margin--extra-large no-margin--right">
				{(leavingInformationFields && (
					<Form
						className={'contact'}
						disabled={true}
						fields={leavingInformationFields}
						onSubmit={() => console.log('SUBMIT')}
						hideSubmit={true}
						updatedValues={befristetLeistungsfall || ifLeistungsfallEmBuWithDate(leistungsfallEmBu?.type) ? undefined : { "emBuAuszahlungenBefristetBis": undefined }}
					/>
				)) || <Trans i18nKey="generic.noDataAvailable" />}
			</div>
			{(confirmedAuszahlungOption && <ConfirmedAuszahlungOption data={confirmedAuszahlungOption} austrittsDatum={stammdaten?.austrittsdatum} />) || (
				<>
					<h2 className="flex flex--align-items-center margin--vertical margin--large">
						<Trans i18nKey="component.confirmedAuszahlungOption.sectionOneHeader" />
					</h2>
					<div className="margin--horizontal margin--extra-large no-margin--right">
						<Trans i18nKey="generic.noDataAvailable" />
					</div>
					<h2 className="flex flex--align-items-center margin--vertical margin--large">
						<Trans i18nKey="component.confirmedAuszahlungOption.personalDataSectionHeader" />
					</h2>
					<div className="margin--horizontal margin--extra-large no-margin--right">
						<Trans i18nKey="generic.noDataAvailable" />
					</div>
				</>
			)}
			<h2 className="flex flex--align-items-center margin--vertical margin--large">
				<Trans i18nKey="view.pensionerLeavingInformation.subSection.LeistungsdatenKP104" />
			</h2>
			<div className="margin--horizontal margin--extra-large no-margin--right">
				{(leistungsdaten && leistungsdaten.leistungen.length && (
					<PensionerLeistungsdatenTable
						className="pensioner-transactions__tabelle"
						data={leistungsdaten.leistungen}
					></PensionerLeistungsdatenTable>
				)) || <Trans i18nKey="generic.noDataAvailable" />}
			</div>
			<ViewHeader
				headline={<Trans i18nKey="view.pensionerLeavingInformation.section.weitereInformationen" />}
				className={'margin--top margin--extra-large'}
			></ViewHeader>
			<h2 className="flex flex--align-items-center margin--vertical margin--large">
				<Trans i18nKey="view.pensionerProfile.section.relations" />
			</h2>
			<div className="margin--horizontal margin--extra-large no-margin--right">
				{(relations && (
					<Relations
						relationsBySourceEmployee={relations?.relationsBySourceEmployee || []}
						relationsByTargetEmployee={relations?.relationsByTargetEmployee || []}
						onRedirectToEmployee={onRedirectToEmployee}
					/>
				)) || <Trans i18nKey="generic.noDataAvailable" />}
			</div>
			<h2 className="flex flex--align-items-center margin--vertical margin--large">
				<Trans i18nKey="view.pensionerProfile.section.versorgungsausgleich" />
			</h2>
			<div className="margin--horizontal margin--extra-large no-margin--right">
				{(versorgungsausgleich && (
					<Versorgungsausgleich data={versorgungsausgleich} onRedirectToEmployee={onRedirectToEmployee} />
				)) || <Trans i18nKey="generic.noDataAvailable" />}
			</div>
		</div>
	)
}

const leistungsfallEmBuTypes: LeistungsfallEmBuType[] = ['NONE', 'EM_TEIL_BEFR', 'EM_TEIL_UNBEFR_OHNE', 'EM_TEIL_UNBEFR_MIT', 'EM_VOLL_BEFR', 'EM_VOLL_UNBEFR_MIT', 'EM_VOLL_UNBEFR_OHNE', 'BU'];

const leistungsfallEmBuBefristetBisToFieldValue = (befristetBis: LeistungsfallEmBu['befristetBis']) => {
	if (befristetBis) {
		return `${befristetBis.month.toString().padStart(2, '0')}/${befristetBis.year}`
	}
	return undefined
}

const ifLeistungsfallEmBuWithDate = (type: LeistungsfallEmBuType | undefined) => type === 'EM_VOLL_BEFR' || type === 'EM_TEIL_BEFR' || type === 'BU'

export function isEmBuAuszahlungenBefristetBisValueValid(value: string | undefined) {
	return value?.match(/^(0[1-9]|1[0-2])\/2[0-9]{3}$/);
}

export default PensionerLeavingInformation
