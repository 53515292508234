import classNames from 'classnames'
import AustrittsSchreibenButtonGroup from 'components/AustrittSchreibenButtonGroup'
import useApi from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import { FunctionComponent, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import Form, { FormFieldType, FormFields } from 'shared/components/Form'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import { currencyFormat } from 'shared/helper/numberFormats'

export type UVFallInfo = {
	abfindung: boolean
	verfallbar: boolean
	bausteinkonto: boolean
}
type TUVSchreibenProps = {
	endpoint: string
	headline: string
	uvFallInfo: UVFallInfo
	refetchHistorie: any
	isAbfindung: boolean
}


const HorizontalLine: React.FC<THorizontalRowProps> = ({ headlineKey, className }) => (
	<div className={classNames('uv-schreiben__horizontal-line', className)}>
		{headlineKey && (
			<h3 className="bold-small-heading">
				<Trans i18nKey={headlineKey} />
			</h3>
		)}

		<hr className="hr hr--gold flex--grow" />
	</div>
)

type THorizontalRowProps = {
	headlineKey?: string
	className?: string
}

const FinalUVFall: FunctionComponent<TUVSchreibenProps> = ({ endpoint, headline, refetchHistorie, uvFallInfo, isAbfindung }) => {
	const { userId } = useUserRecord()
	const api = useApi()

	const { data, status } = useQuery(['getUVschreibenOptionen', { userId }], api.getUVschreibenOptionen, {
		enabled: !!userId,
	})

	const [updatedFormFields, setUpdatedFormFields] = useState<FormFields>()

	const dcFields: FormFields = useMemo(() => {
		return {
			dcAnspruch: {
				fieldType: FormFieldType.radioButtons,
				label: <Trans i18nKey={'view.uvschreiben.content.dc.vorhanden'} />,
				name: 'DCVorhanden',
				prepend: <HorizontalLine headlineKey={'view.uvschreiben.content.dc.headline'} />,
				options: [
					{
						value: 'ANSPRUCH_VORHANDEN',
						label: <Trans i18nKey={'view.uvschreiben.content.dc.anspruch'} />,
					},
					{
						value: 'KEIN_ANSPRUCH',
						label: <Trans i18nKey={'view.uvschreiben.content.dc.kein'} />,
					},
				],
				value: data?.dcAnspruch,
			},
			betrag: {
				fieldType: FormFieldType.text,
				hidden: (updatedFormFields?.dcAnspruch?.value ? updatedFormFields?.dcAnspruch?.value !== 'ANSPRUCH_VORHANDEN' : data?.dcAnspruch !== 'ANSPRUCH_VORHANDEN'),
				label: <Trans i18nKey={'view.uvschreiben.content.dc.betrag'} />,
				value: currencyFormat(data?.betrag),
			},
		}
	}, [
		data,
		updatedFormFields?.dcAnspruch?.value,
		updatedFormFields?.betrag?.value,
	])

	return (
		<div className="uv-schreiben">
			{status === 'success' ? (!isAbfindung ? (
				<>
					<div className="uv-schreiben__optionen">
						<h2>
							<Trans i18nKey={'view.uvschreiben.headlines.optionen'} />
						</h2>

						<Form
							fields={dcFields}
							onSubmit={() => { }}
							hideSubmit={true}
							onFormChange={setUpdatedFormFields}
							className="uv-schreiben__optionen--form"
						/>
					</div>
				</>
			) : <></>) : (
				<LoadingSpinner area={'getZusageschreibenOptionen'} delay={0} />
			)}
			<AustrittsSchreibenButtonGroup
				createEndpoint={endpoint}
				headline={headline}
				closeEndpoint={'UVFall'}
				uvschreibenoption={updatedFormFields}
				refetchHistorie={refetchHistorie}
				uvFallInfo={uvFallInfo}
			/>
		</div>
	)
}

export default FinalUVFall
