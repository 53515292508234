import GridList from 'components/GridList'
import GridListCell from 'components/GridListCell'
import GridListRow from 'components/GridListRow'
import SearchInput from 'components/SearchInput'
import useApi, { AustrittsbearbeitungenRequestQueryParams, QueryKey } from 'hooks/useApi'
import i18next from 'i18next'
import { ChangeEvent, FunctionComponent, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueries, useQuery } from 'react-query'
import { IconSize } from 'shared/components/Icon'
import SelectInput, { SelectOption } from 'shared/components/SelectInput'
import { getSessionStorage } from 'shared/helper/sessionStorage'
import { components } from 'types/api-interface'
import Button, { ButtonType } from 'shared/components/Button'
import { IconType } from 'components/Icons'
import MultiSelectInput from 'shared/components/MultiSelectInput'
export interface IAustrittsbearbeitungFooter {
	type: string
	onFallartChange: (selectedOption: components['schemas']['Fallart'] | '') => void
	onVersorgungsempfaengerChange: (e: ChangeEvent<HTMLInputElement>) => void
	onHRSysChange: (selectedOption: components['schemas']['HrSystem'] | '') => void
	onPersonalNrChange: (e: ChangeEvent<HTMLInputElement>) => void
	onGeburtsdatumChange: (e: ChangeEvent<HTMLInputElement>) => void
	onAustrittsdatumChange: (e: ChangeEvent<HTMLInputElement>) => void
	onAustrittsgrundChange: (e: ChangeEvent<HTMLInputElement>) => void
	onVoChange: (selectedOption: components['schemas']['Versorgungsordnung'] | '') => void
	onGuthabenChange: (selectedOption: string) => void
	onBearbeitendeChange: (selectedOption: components['schemas']['BearbeitendePerson']['tag']) => void
	onStatusChange: (selectedOption: components['schemas']['AustrittTaskStatus'][] | '') => void
	onZuletztGeaendertChange: (e: ChangeEvent<HTMLInputElement>) => void
	firstPage?: boolean
	lastPage?: boolean
	onNavigateForwards: () => void
	onNavigateBackwards: () => void
}

export const AustrittsbearbeitungFooter: FunctionComponent<IAustrittsbearbeitungFooter> = ({
	type,
	onFallartChange,
	onVersorgungsempfaengerChange,
	onHRSysChange,
	onPersonalNrChange,
	onGeburtsdatumChange,
	onAustrittsdatumChange,
	onAustrittsgrundChange,
	onVoChange,
	onGuthabenChange,
	onStatusChange,
	onZuletztGeaendertChange,
	onBearbeitendeChange,
	lastPage,
	firstPage,
	onNavigateBackwards,
	onNavigateForwards,
}) => {
	const { t } = useTranslation()
	const api = useApi()

	const { data: bearbeitende } = useQuery(
		[QueryKey.austrittsbearbeitungen, {}],
		api.getAustrittsbearbeitungenBearbeitende
	)
	const filterTypes = ['fallart', 'hrsystem', 'versorgungsordnung', 'status']
	const [{ data: fallartTypes }, { data: hrSystemTypes }, { data: voTypes }, { data: statusTypes }] = useQueries(
		filterTypes.map((filterType) => {
			return {
				queryKey: [QueryKey.austrittsbearbeitungFilterType, { filterType }],
				queryFn: api.getAustrittsbearbeitungFilterTypes,
			}
		})
	)

	const GetSelectTypes = (types: string[] | undefined, type: string, hideAllOption = false, keepOrder = false) => {
		const result = useMemo(
			() => {
				const allTypesFilter: SelectOption = {
					label: t('component.austrittsbearbeitungFooter.allTypesLabel'),
					value: '',
				}
				if (undefined === types) {
					return undefined
				}
				const mappedTypes = types
					.reduce((filteredTypes: string[], currentType: string) => {
						if (filteredTypes.some((filteredType) => currentType === filteredType)) {
							return filteredTypes
						}

						filteredTypes.push(currentType)
						return filteredTypes
					}, types)
					.map(
						(item): SelectOption => ({
							label:
								type === 'bearbeitende'
									? item
									: t(`component.austrittsbearbeitungTable.${type}.${item}`),
							value: item,
						})
					)
					.sort((a, b) =>
						keepOrder
							? 1
							: String(a.label).localeCompare(String(b.label), i18next.language, { sensitivity: 'base' })
					)

				const returnValue = hideAllOption ? mappedTypes : [allTypesFilter, ...mappedTypes]

				return returnValue.filter(Boolean)
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[types, type]
		)
		return result
	}
	const fallartSelectTypes = GetSelectTypes(fallartTypes, 'fallart')?.filter(
		(item) => item.value !== 'RUHENDES_ARBEITSVERHAELTNIS'
	)
	const hrSystemSelectTypes = GetSelectTypes(hrSystemTypes, 'hrsystem')
	const voSelectTypes = GetSelectTypes(voTypes, 'vo')
	const statusSelectTypes = GetSelectTypes(statusTypes, 'status', true, true)
	const guthabenSelectTypes = GetSelectTypes(['ja', 'nein'], 'guthaben')
	const bearbeitendeSelectTypes = GetSelectTypes(
		bearbeitende?.map((item) => {
			return item.tag
		}),
		'bearbeitende'
	)

	/**
	 *
	 */

	const filterDefaultValues = useRef<AustrittsbearbeitungenRequestQueryParams>({
		fallart: getSessionStorage(`austrittsbearbeitung-${type}-fallart`),
		identity: getSessionStorage(`austrittsbearbeitung-${type}-identity`),
		hrSystem: getSessionStorage(`austrittsbearbeitung-${type}-hrSystem`),
		personalNummer: getSessionStorage(`austrittsbearbeitung-${type}-personalNummer`),
		geburtstag: getSessionStorage(`austrittsbearbeitung-${type}-geburtstag`),
		austrittsdatum: getSessionStorage(`austrittsbearbeitung-${type}-austrittsdatum`),
		austrittsgrund: getSessionStorage(`austrittsbearbeitung-${type}-austrittsgrund`),
		versorgungsordnung: getSessionStorage(`austrittsbearbeitung-${type}-versorgungsordnung`),
		versorgungsguthaben: getSessionStorage(`austrittsbearbeitung-${type}-versorgungsguthaben`),
		status: getSessionStorage(`austrittsbearbeitung-${type}-status`),
		bearbeitendePerson: getSessionStorage(`austrittsbearbeitung-${type}-bearbeitendePerson`),
		lastChangesAt: getSessionStorage(`austrittsbearbeitung-${type}-lastChangesAt`),
	})

	const multiSelectDefautlValues: string | string[] = useMemo(
		() => {
			if (!filterDefaultValues.current?.status) {
				return statusSelectTypes?.map((v) => v.value || '').filter((v) => v !== 'erledigt') || []
			}

			return filterDefaultValues.current?.status as string[]
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	return (
		<GridList className="austrittsbearbeitung-footer" columnCount={13}>
			<GridListRow>
				<GridListCell className="austrittsbearbeitung__filter-by-type">
					{!fallartSelectTypes && (
						<div className="no-type">{t('component.austrittsbearbeitungFooter.filter.fallart')}</div>
					)}
					{fallartSelectTypes && (
						<SelectInput
							value={
								filterDefaultValues.current?.fallart === null
									? ''
									: filterDefaultValues.current?.fallart
							}
							alternativeStyle={true}
							label={t('component.austrittsbearbeitungFooter.filter.fallart')}
							options={fallartSelectTypes}
							onChange={onFallartChange}
						/>
					)}
				</GridListCell>
				<GridListCell className="austrittsbearbeitung-footer__search">
					<SearchInput
						value={filterDefaultValues.current?.identity ?? ''}
						label={t(`component.austrittsbearbeitungFooter.filter.versorgungsempfaenger`)}
						onChange={onVersorgungsempfaengerChange}
						iconSize={IconSize.mediumLarge}
						dontFocusOnRender={true}
						disableDelete={true}
					/>
				</GridListCell>
				<GridListCell className="austrittsbearbeitung__filter-by-type">
					{!hrSystemSelectTypes && (
						<div className="no-type">{t('component.austrittsbearbeitungFooter.filter.hrsys')}</div>
					)}
					{hrSystemSelectTypes && (
						<SelectInput
							value={
								filterDefaultValues.current?.hrSystem === null
									? ''
									: filterDefaultValues.current?.hrSystem
							}
							alternativeStyle={true}
							label={t('component.austrittsbearbeitungFooter.filter.hrsys')}
							options={hrSystemSelectTypes}
							onChange={onHRSysChange}
						/>
					)}
				</GridListCell>
				<GridListCell className="austrittsbearbeitung-footer__search">
					<SearchInput
						value={filterDefaultValues.current?.personalNummer ?? ''}
						label={t(`component.austrittsbearbeitungFooter.filter.personalNr`)}
						onChange={onPersonalNrChange}
						iconSize={IconSize.mediumLarge}
						dontFocusOnRender={true}
						disableDelete={true}
					/>
				</GridListCell>
				<GridListCell>
					<SearchInput
						value={
							filterDefaultValues.current?.geburtstag
								? toInputDatum(filterDefaultValues.current.geburtstag)
								: ''
						}
						label={t(`component.austrittsbearbeitungFooter.filter.geburtsdatum`)}
						onChange={onGeburtsdatumChange}
						iconSize={IconSize.mediumLarge}
						dontFocusOnRender={true}
						disableDelete={true}
					/>
				</GridListCell>
				<GridListCell>
					<SearchInput
						value={
							filterDefaultValues.current?.austrittsdatum
								? toInputDatum(filterDefaultValues.current?.austrittsdatum)
								: ''
						}
						label={t(`component.austrittsbearbeitungFooter.filter.austrittsdatum`)}
						onChange={onAustrittsdatumChange}
						iconSize={IconSize.mediumLarge}
						dontFocusOnRender={true}
						disableDelete={true}
					/>
				</GridListCell>
				<GridListCell className="austrittsbearbeitung-footer__search">
					<SearchInput
						value={filterDefaultValues.current?.austrittsgrund ?? ''}
						label={t(`component.austrittsbearbeitungFooter.filter.austrittsgrund`)}
						onChange={onAustrittsgrundChange}
						iconSize={IconSize.mediumLarge}
						dontFocusOnRender={true}
						disableDelete={true}
					/>
				</GridListCell>
				<GridListCell className="austrittsbearbeitung__filter-by-type">
					{!voSelectTypes && (
						<div className="no-type">{t('component.austrittsbearbeitungFooter.filter.vo')}</div>
					)}
					{voSelectTypes && (
						<SelectInput
							value={
								filterDefaultValues.current?.versorgungsordnung === null
									? ''
									: filterDefaultValues.current?.versorgungsordnung
							}
							alternativeStyle={true}
							label={t('component.austrittsbearbeitungFooter.filter.vo')}
							options={voSelectTypes}
							onChange={onVoChange}
						/>
					)}
				</GridListCell>
				<GridListCell className="austrittsbearbeitung__filter-by-type">
					{!guthabenSelectTypes && (
						<div className="no-type">{t('component.austrittsbearbeitungFooter.filter.guthaben')}</div>
					)}
					{guthabenSelectTypes && (
						<SelectInput
							value={
								filterDefaultValues.current?.versorgungsguthaben === true
									? 'ja'
									: filterDefaultValues.current?.versorgungsguthaben === false
									? 'nein'
									: undefined
							}
							alternativeStyle={true}
							label={t('component.austrittsbearbeitungFooter.filter.guthaben')}
							options={guthabenSelectTypes}
							onChange={onGuthabenChange}
						/>
					)}
				</GridListCell>
				<GridListCell className="austrittsbearbeitung__filter-by-type">
					{!bearbeitendeSelectTypes && (
						<div className="no-type">{t('component.austrittsbearbeitungFooter.filter.bearbeitende')}</div>
					)}
					{bearbeitendeSelectTypes && (
						<SelectInput
							value={
								filterDefaultValues.current?.bearbeitendePerson === null
									? ''
									: filterDefaultValues.current?.bearbeitendePerson
							}
							alternativeStyle={true}
							label={t('component.austrittsbearbeitungFooter.filter.bearbeitende')}
							options={bearbeitendeSelectTypes}
							onChange={onBearbeitendeChange}
						/>
					)}
				</GridListCell>
				<GridListCell className="austrittsbearbeitung__filter-by-type">
					{!statusSelectTypes && (
						<div className="no-type">{t('component.austrittsbearbeitungFooter.filter.status')}</div>
					)}
					{statusSelectTypes && (
						<MultiSelectInput
							value={multiSelectDefautlValues}
							alternativeStyle={true}
							label={t('component.austrittsbearbeitungFooter.filter.status')}
							options={statusSelectTypes}
							onChange={onStatusChange}
							resetButtonLabel={t('component.austrittsbearbeitungFooter.resetButtonLabel')}
							resetButtonValue={statusSelectTypes
								.map((v) => v.value || '')
								.filter((v) => v !== 'erledigt')}
							nothingSelectedLabel={t('component.austrittsbearbeitungFooter.allTypesLabel')}
							modalHeadline={t('component.austrittsbearbeitungFooter.modalHeadline')}
							selectDeselectAllButton={true}
						/>
					)}
				</GridListCell>
				<GridListCell>
					<SearchInput
						value={
							filterDefaultValues.current?.lastChangesAt
								? toInputDatum(filterDefaultValues.current?.lastChangesAt)
								: ''
						}
						label={t(`component.austrittsbearbeitungFooter.filter.zuletztGeaendert`)}
						onChange={onZuletztGeaendertChange}
						iconSize={IconSize.mediumLarge}
						dontFocusOnRender={true}
						disableDelete={true}
					/>
				</GridListCell>

				<GridListCell className="austrittsbearbeitung-footer__pagination">
					<Button
						type={ButtonType.round}
						icon={IconType.pageUp}
						className="margin--left margin--large"
						onClick={onNavigateBackwards}
						disabled={firstPage}
					/>
					<Button
						type={ButtonType.round}
						icon={IconType.pageUp}
						iconRotate={180}
						className="margin--left"
						onClick={onNavigateForwards}
						disabled={lastPage}
					/>
				</GridListCell>
			</GridListRow>
		</GridList>
	)
}

function toInputDatum(str: string): string {
	return str.split('-').reverse().join('.')
}
