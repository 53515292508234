import React, { useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { AuthError, ErrorType, useAuth, UserCredentials } from '../hooks/useAuth'
import Button, { ButtonType } from '../shared/components/Button'
import { TextInputType } from '../shared/components/TextInput'

const Login = () => {
	const { navigateTo, getMainPath } = useRouteHelper()
	const { t } = useTranslation()
	const { userData, signin } = useAuth()
	const enteredUsername = useRef<string>()

	const inputElements: FormFields = {
		username: {
			value: process.env.REACT_APP_USER_ID ?? '',
			// value: isDev() ? 'fidotest' : '',
			required: true,
			type: TextInputType.text,
			label: t('generic.userId'),
			autoComplete: 'username',
			disableDelete: true,
			fieldType: FormFieldType.text,
		},
		password: {
			value: process.env.REACT_APP_PASSWORD ?? '',
			// value: isDev() ? 'S3i#rmGm' : '',
			required: true,
			type: TextInputType.password,
			label: t('generic.password'),
			autoComplete: 'current-password',
			fieldType: FormFieldType.text,
		},
	}

	if (userData) {
		return (
			<div className="auth">
				<h1>
					<Trans i18nKey="view.login.pageTitle" />
				</h1>

				<h3>
					<Trans i18nKey="view.login.alreadyLoggedIn" />
				</h3>
				<Button
					onClick={() => navigateTo(getMainPath(routesDictionary.dashboard))}
					label={t('view.login.goToDashboard')}
					type={ButtonType.secondary}
				/>
			</div>
		)
	}

	const onErrorHandle = (error: AuthError) => {
		if (ErrorType.passwordResetRequired === error?.code) {
			if (enteredUsername.current) {
				sessionStorage.setItem('username', enteredUsername.current)
			}

			navigateTo(getMainPath(routesDictionary.resetPassword))
			return
		}

		if (ErrorType.newPasswordRequired === error?.code) {
			navigateTo(getMainPath(routesDictionary.setNewPassword))
			return
		}

		if (ErrorType.mfaSetup === error?.code) {
			navigateTo(getMainPath(routesDictionary.mfaSetup))
			return
		}

		if (ErrorType.mfa === error?.code) {
			navigateTo(getMainPath(routesDictionary.mfa))
			return
		}

		if (ErrorType.webAuthSetupMissing === error?.code) {
			navigateTo(getMainPath(routesDictionary.webAuthSetupMissing))
			return
		}
	}

	return (
		<div className="auth">
			<div>
				<h1 className="text-color-gold text-transform-uppercase">
					<Trans i18nKey="view.login.pageTitle" />
				</h1>

				<p>
					<Trans i18nKey="view.login.enterCredentials" />
				</p>
			</div>
			<Form
				className="auth"
				fields={inputElements}
				onSubmit={(submittedFields: UserCredentials) => {
					enteredUsername.current = submittedFields.username
					return signin(submittedFields)
				}}
				onSuccess={() => navigateTo(getMainPath(routesDictionary.dashboard))}
				onError={onErrorHandle}
				errorMessages={[t('view.login.loginError'), t('view.login.wrongUserIdOrPassword')]}
				submitLabel={t('view.login.submit')}
			>
				<Button
					className="login__forgot-password"
					onClick={() => navigateTo(getMainPath(routesDictionary.resetPassword))}
					label={t('view.login.forgotPasswordLabel')}
					type={ButtonType.small}
				/>
			</Form>
		</div>
	)
}

export default Login
