import { useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'

export const useUserIdentificationNavigation = () => {
	const queryClient = useQueryClient()
	const { getChildPath, navigateTo } = useRouteHelper()

	const openRecords = (id: string, tenant: string) => {
		resetUserId()

		setTimeout(() => {
			queryClient.setQueryData('callerActionsIdentify', {
				tenantFromQuery: tenant,
				decadiaIdFromQuery: id,
			})

			setTimeout(() => {
				navigateTo(`${getChildPath(routesDictionary.callerActions, 'identify')}`)
			})
		})
	}

	const resetUserId = () => {
		queryClient.setQueryData('callerActionsIdentify', {
			tenantFromQuery: '',
			decadiaIdFromQuery: '',
		})
	}

	return { openRecords, resetUserId }
}
