import React from 'react'
import globalHook, { Store } from 'use-global-hook'

// Defining your own state and associated actions is required
export interface HeaderState {
	leftContent: React.ReactElement | undefined
	rightContent: React.ReactElement | undefined
}

// Associated actions are what's expected to be returned from globalHook
interface HeaderActions {
	setLeftContent: (leftContent: React.ReactElement | undefined) => void
	setRightContent: (rightContent: React.ReactElement | undefined) => void
	resetState: () => void
}

const setLeftContent = (store: Store<HeaderState, HeaderActions>, leftContent: React.ReactElement | undefined) => {
	store.setState({ ...store.state, leftContent })
}
const setRightContent = (store: Store<HeaderState, HeaderActions>, rightContent: React.ReactElement | undefined) => {
	store.setState({ ...store.state, rightContent })
}
const resetState = (store: Store<HeaderState, HeaderActions>) => {
	store.setState(initialState)
}

const initialState: HeaderState = {
	leftContent: undefined,
	rightContent: undefined,
}

const actions = {
	setLeftContent,
	setRightContent,
	resetState,
}

const useHeaderState = globalHook<HeaderState, HeaderActions>(React, initialState, actions)

export default useHeaderState
