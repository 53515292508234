import i18next from 'i18next'

interface NumberFormatOptions extends Intl.NumberFormatOptions {
	variableFractionDigits?: boolean
	fixedFractionDigits?: number
	addSignPrefix?: boolean
	round?: boolean
}

export const numberFormat = (value: string | number = 0, options?: NumberFormatOptions) => {
	value = +value || 0
	let prefix: string = ''

	if (options && options.hasOwnProperty('round')) {
		delete options.round
		value = Math.round(value)
	}

	if (options && options.hasOwnProperty('variableFractionDigits')) {
		delete options.variableFractionDigits

		options = {
			...options,
			minimumFractionDigits: 0 === value % 1 ? 0 : 2,
		}
	}

	if (options && options.hasOwnProperty('fixedFractionDigits')) {
		const digitCount = options.fixedFractionDigits
		delete options.fixedFractionDigits

		options = {
			...options,
			minimumFractionDigits: digitCount,
			maximumFractionDigits: digitCount,
		}
	}

	if (options && options.hasOwnProperty('addSignPrefix')) {
		delete options.addSignPrefix
		if (value < 0) {
			value = Math.abs(value)
			prefix = '- '
		} else {
			prefix = '+ '
		}
	}

	return `${prefix}${value.toLocaleString(i18next.language, { ...options })}`
}

export const currencyFormat = (value: string | number = 0, options?: NumberFormatOptions) => {
	return numberFormat(value, { ...options, style: 'currency', currency: 'EUR' })
}

export const percentFormat = (value: string | number = 0, options?: NumberFormatOptions) => {
	value = Number(value) / 100
	return numberFormat(value, { ...options, style: 'percent' })
}

export const IDENT_NUMBER_STRING_VALIDATION = '\\d{8}'
