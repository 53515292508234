export const dateWithinXDays = (maskedValue: string = '', x: number) => {
    const dateParts = maskedValue.split('.')
    if (dateParts.length !== 3) {
        //not a *.*.*
        return undefined
    }
    dateParts.forEach(element => {
        if (!/^\d+$/.test(element)) {
            //not a number
            return undefined
        }
    });
    const date = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]))
    if (date.getDate() !== Number(dateParts[0]) ||
        date.getMonth() !== Number(dateParts[1]) - 1 ||
        date.getFullYear() !== Number(dateParts[2]) ||
        dateParts[0].length !== 2 ||
        dateParts[1].length !== 2 ||
        dateParts[2].length !== 4
    ) {
        // parsed date is not the same as input (ex: 32.07.2021 -> 01.08.2021)
        // dateParts lengh are compared mainly to prevent leading zeros 11.001.2021
        return undefined
    }

    const today = new Date()
    var diff = today.getTime() - date.getTime();
    var daysDiff = Math.floor(diff / 1000 / 60 / (60 * 24));

    if (daysDiff < 0 || daysDiff > x) {
        return false
    }

    return true
}