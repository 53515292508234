import useApi, { IDocumentCluster, Ticket } from 'hooks/useApi'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import { FormField } from 'shared/components/Form'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import SelectInput, { SelectOption } from 'shared/components/SelectInput'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'

export interface ICloseWithoutActionModal {
	open: boolean
	onModalClose: () => void
	data: Ticket
	documentTypes: IDocumentCluster[]
	identNumberField: FormField
}

const CloseWithoutActionModal: FunctionComponent<ICloseWithoutActionModal> = (props) => {
	const { open, onModalClose, data, documentTypes, identNumberField } = props

	const modal = useRef<ModalRefActions>()
	const api = useApi()
	const { t } = useTranslation()
	const { navigateTo, getMainPath } = useRouteHelper()

	useEffect(() => {
		if (open) {
			modal.current?.openModal()
		}
	}, [open])

	const [documentType, setDocumentType] = useState(data.documentContent.content.type)

	const handleOnSubmit = async () => {
		const identNumber = identNumberField.value?.toString()
		await api
			.closeTicketWithoutAction(data.id, documentType, identNumber)
			.then(() => navigateTo(getMainPath(routesDictionary.globalInbox)))
	}

	const renderCloseWithoutActionMessage = () => {
		return (
			<div className="close-without-action-disclaimer">
				<h3>{t('component.closeWithoutActionModal.text')}</h3>
			</div>
		)
	}

	const getSelectOptions = () => {
		const options = new Array<SelectOption>();
		documentTypes.forEach((cluster) => {
			const { id, types } = cluster
			types.forEach((type) => {
				const option = {
					value: type,
					label: t(`component.documentEntry.type.${type}`),
					group: id
				};
				options.push(option)
			})
		})
		return options.sort(selectOptionSorter)
	}

	const selectOptionSorter = (a: SelectOption, b: SelectOption) => {
		const labelA: string = a.label ? a.label as string : '';
		const labelB: string = b.label ? b.label as string : '';
		return labelA.localeCompare(labelB);
	}

	const getSelectOptionGroups = () => {
		const optionGroups = documentTypes.map((cluster) => {
			const id = cluster.id;
			const label = t(`component.closeWithoutActionModal.categories.${id}`);
			const optionGroup = { id, label }
			return optionGroup;
		})
		return optionGroups;
	}

	return (
		<Modal
			ref={modal}
			forceAction={false}
			header={t('component.closeWithoutActionModal.header')}
			buttonLabel={t('generic.confirm')}
			onModalClose={onModalClose}
			onButtonClick={handleOnSubmit}
			className="modal--close-without-action"
		>
			<div className="padding--horizontal margin--vertical text-align--left">
				{renderCloseWithoutActionMessage()}
				<SelectInput
					className=""
					alternativeStyle={true}
					returnEvent={false}
					options={getSelectOptions()}
					optionGroups={getSelectOptionGroups()}
					label={t('component.closeWithoutActionModal.label')}
					value={documentType}
					onChange={setDocumentType}
				></SelectInput>
			</div>
		</Modal>
	)
}

export default CloseWithoutActionModal
