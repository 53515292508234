import GridList from 'components/GridList'
import GridListCell from 'components/GridListCell'
import GridListRow from 'components/GridListRow'
import { ITransaction } from 'hooks/useApi'
import React, { FunctionComponent } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import dateFormat from 'shared/helper/dateFormat'
import { currencyFormat } from 'shared/helper/numberFormats'

export interface PensionerTransactionProps {
  className?: string
  data: ITransaction[] | undefined;
}

const PensionerTransactionTable: FunctionComponent<PensionerTransactionProps> = props => {
  const { t } = useTranslation()
  const { data } = props
  const tableHeaders = [
    'signedAt',
    'compensationType',
    'value',
    'start',
    'isPaperBased',
  ]

  const renderTableHeader = () => {
    const headerCellClass = 'grid-list__cell--header'

    return (
      <GridListRow className={'grid-list__row--header'}>
        {tableHeaders.map((label, index) => (
          <GridListCell className={headerCellClass} key={`header-field-${index}`}>
            {t(`view.pensionerTransactions.tableHeaders.${label}`)}
          </GridListCell>
        ))}
      </GridListRow>
    )
  }

  const renderTableRows = () => {
    if (!data) {
      return
    }
    return (
      <>
        {data.map((entry, index: number) => (
          <GridListRow key={`entry-${index}`}>
            {tableHeaders.map((key, fieldIndex: number) => {
              let transformedValue

              let classes = ['user-select--all']
              if (entry['isRelevant'] === false) {
                classes.push('grid-list__row__irrelevant')
              }

              switch (key) {
                case 'signedAt':
                  if (entry[key] !== undefined && !entry['isPaperBased']) {
                    transformedValue = dateFormat(new Date(Date.parse(entry[key])), {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                    })
                  } else if (entry[key] !== undefined && entry['isPaperBased']) {
                    transformedValue = dateFormat(new Date(Date.parse(entry[key])))
                  } else {
                    transformedValue = '-'
                  }
                  break
                case 'start':
                  if (entry[key] !== undefined) {
                    transformedValue = dateFormat(new Date(Date.parse(entry[key]!)))
                  } else {
                    transformedValue = '-'
                  }
                  break
                case 'isPaperBased':
                  transformedValue = entry[key] ? 'Ja' : 'Nein'
                  break
                case 'value':
                  if (entry['unit'] === 'euro') {
                    transformedValue = currencyFormat(entry[key], { fixedFractionDigits: 2 })
                  } if (entry['unit'] === 'percent') {
                    transformedValue = entry[key] + t(`generic.${entry['unit']}`)
                  }
                  classes.push('grid-list__cell--number')
                  break
                case 'compensationType':
                  transformedValue = <Trans i18nKey={`generic.confirmedCompensationTypes.${entry[key]}`} />
                  break

              }

              const cellClasses = classes.join(" ")

              return (
                <GridListCell key={`entry-field-${index}-${fieldIndex}`} className={cellClasses}>
                  {transformedValue}
                </GridListCell>
              )
            })}
          </GridListRow>
        ))}
      </>
    )
  }


  return (
    <>
      {data?.length !== 0 && (
        <>

          <div
            className={`text-transform-uppercase text-color-gold margin--right 
						}`}
          >
            <Trans i18nKey="view.pensionerRiskOptions.history" />
          </div>
          <GridList className="delegate__results" columnCount={tableHeaders.length}>
            {renderTableHeader()}
            {renderTableRows()}
          </GridList>
        </>
      )}
    </>
  )
}

export default PensionerTransactionTable
