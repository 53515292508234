import { IconType } from 'components/Icons'
import { DefaultTFuncReturn, t } from 'i18next'
import React, {
	ChangeEvent,
	Fragment,
	FunctionComponent,
	PropsWithChildren,
	createRef,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react'
import { FormErrorType, FormSubmitFields, useForm } from 'shared/hooks/useForm'
import Button, { ButtonType } from './Button'
import Captcha from './Captcha'
import Checkbox, { CheckboxProps } from './Checkbox'
import { DateInput, DateInputProps } from './DateInput'
// import Checkbox, { CheckboxProps } from './Checkbox'
import ErrorMessage from './ErrorMessage'
import { GooglePlacesAutocomplete, IGooglePlacesAutocomplete } from './GooglePlacesAutocomplete'
import Radiobutton, { RadiobuttonGroupProps, RadiobuttonProps } from './Radiobutton'
import RadiobuttonGroup from './RadiobuttonGroup'
import SelectInput, { SelectInputProps } from './SelectInput'
import SwitchSelect, { SwitchSelectProps } from './SwitchSelect'
import TextArea, { TextAreaProps } from './TextArea'
import TextInput, { TextInputProps, TextInputType } from './TextInput'

export interface FormProps {
	fields: FormFields
	onSubmit: any
	onError?: any
	onSuccess?: any
	submitLabel?: string | DefaultTFuncReturn | React.ReactElement
	errorMessages?: (string | React.ReactElement | DefaultTFuncReturn)[]
	errorIcon?: IconType
	className?: string
	formLocked?: boolean
	formUnlockable?: boolean
	onFormUnlock?: () => Promise<boolean>
	unlockFormLabel?: string
	updatedValues?: { [key: string]: any }
	unlockFormType?: ButtonType | ButtonType[]
	onFormChange?: (fields: FormFields) => void
	hidden?: boolean
	disabled?: boolean
	onlySubmitOnChangedValues?: boolean
	hideSubmitIfFieldsEmpty?: boolean
	hideSubmit?: boolean
	captchaFieldName?: string
	alwaysAllowSubmit?: boolean
	ref?: any
	submitButtonType?: ButtonType | ButtonType[]
	promiseTracker?: {
		area: string
		delay?: number
	}
}

/**
 * FIXME: REFACTOR
 * use generics for proper typing
 */
export type FormField = (
	| (TextInputProps & { fieldType: FormFieldType.text })
	| (TextInputProps & { fieldType: FormFieldType.phoneInput })
	| (SelectInputProps & { fieldType: FormFieldType.select })
	| (SwitchSelectProps & { fieldType: FormFieldType.switchSelect })
	| (TextAreaProps & { fieldType: FormFieldType.textArea })
	| (RadiobuttonGroupProps & {
			fieldType: FormFieldType.radioButtons
	  })
	| (CheckboxProps & { fieldType: FormFieldType.checkBox })
	| (IGooglePlacesAutocomplete & { fieldType: FormFieldType.googlePlacesAutoComplete })
	| (DateInputProps & { fieldType: FormFieldType.date })
) & { error?: { type: string; message?: string | DefaultTFuncReturn } }
// | ({ value?: string | number | undefined | string[]; label?: string; options: CheckboxProps[] } & {
// 		fieldType: FormFieldType.checkBoxes
//   })

export type FormFields = {
	[key: string]: FormField
}

export enum FormFieldType {
	select = 'select',
	switchSelect = 'switchSelect',
	text = 'text',
	googlePlacesAutoComplete = 'googlePlacesAutoComplete',
	textArea = 'textArea',
	radioButtons = 'radioButtons',
	checkBoxes = 'checkBoxes',
	checkBox = 'checkBox',
	date = 'date',
	phoneInput = 'phoneInput',
}

export interface FormRefActions {
	resetForm(): void
	handleFormError(): void
	submitForm(): void
}

const Form: FunctionComponent<PropsWithChildren<FormProps>> = forwardRef<FormRefActions, PropsWithChildren<FormProps>>(
	(props, ref: any) => {
		const [formLocked, setFormLocked] = useState(props.formLocked)
		const form = useRef<HTMLFormElement>(null)
		const requestInProgress = useRef<string | undefined>(undefined)
		const initialFields = useRef(props.fields)
		const [errorMessages, setErrorMessages] = useState<(string | React.ReactElement | DefaultTFuncReturn)[]>()
		useImperativeHandle(ref, () => ({
			resetForm() {
				handleFormReset()
			},
			handleFormError() {
				handleFormError(FormErrorType.errorResponse)
			},
			submitForm() {
				handleOnSubmit()
			},
		}))

		useEffect(() => {
			if (props.formLocked !== formLocked) {
				setFormLocked(props.formLocked)
			}
		}, [formLocked, props.formLocked])

		const getPrefixedClassName = (
			partial: string,
			includeBaseClass?: boolean,
			addErrorClasses?: boolean,
			additionalClasses?: string
		): string | undefined | undefined => {
			const classes: string[] = includeBaseClass ? [partial] : []
			const customClassName = props.className ? `${props.className}__` : ''

			classes.push(`${customClassName}${partial}`)

			if (props.formLocked) {
				classes.push(`${customClassName}${partial}--${formLocked ? 'locked' : 'unlocked'}`)
			}

			if (props.hidden) {
				classes.push('hidden')
			}

			if (addErrorClasses && undefined !== formError) {
				classes.push(`${customClassName}${partial}--error-${formError}`)
			}

			additionalClasses && classes.push(additionalClasses)

			return classes.join(' ')
		}

		const onSubmit = async (submittedFields: FormSubmitFields) => {
			/**
			 * prevent multiple form submits by comparing sent data
			 *
			 * a simple boolean value would prevent the same form to be
			 * submitted with different data.
			 * This might occur if the form does not get unmounted and is used again
			 */
			if (
				undefined !== requestInProgress.current &&
				requestInProgress.current === JSON.stringify(submittedFields)
			) {
				return
			}

			requestInProgress.current = JSON.stringify(submittedFields)

			const submit = await props.onSubmit(submittedFields)

			if (typeof submit === 'object' && submit?.abort === true) {
				requestInProgress.current = undefined
				return
			}

			if (
				undefined === submit ||
				false === submit ||
				false === submit.successful ||
				(undefined !== submit.status && submit.status >= 400)
			) {
				if (submit?.errorMessages || errorMessages) {
					setErrorMessages(submit.errorMessages ? [submit.errorMessages].flat() : undefined)
				}

				onError(submit?.data || submit)
				return handleFormError(FormErrorType.errorResponse)
			}

			onSuccess()
		}

		const onSuccess = () => {
			if (props.formLocked) {
				setFormLocked(true)
				requestInProgress.current = undefined
			}

			if (props.onSuccess) {
				props.onSuccess()
			}
		}

		const onError = (submitResponse?: any) => {
			requestInProgress.current = undefined

			if (undefined === props.onError) {
				return
			}

			props.onError(submitResponse)
		}

		const onChange = (event: ChangeEvent<HTMLInputElement>) => {
			// set original field value as well to handle the value outside form component

			if ('checkbox' === event.target.type) {
				const { checked } = event.target

				;(props.fields[event.target.name] as CheckboxProps).checked = checked
			}

			if (!['checkbox'].includes(event.target.type)) {
				props.fields[event.target.name].value = event.target.value
			}

			const updatedFields = handleInputChange(event) as FormFields

			if (props.onFormChange) {
				props.onFormChange(updatedFields)
			}
		}

		// const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>, key: string) => {
		// 	// FIXME: ohne die folgenden anweisungen werden die daten in useForm nicht korrekt gespeichert
		// 	const value = event.target.value
		// 	const currentValue = (props.fields[key].value as string[]) || []

		// 	props.fields[key].value = event.target.checked
		// 		? currentValue.concat([value])
		// 		: currentValue.filter((itemValue: string) => itemValue !== value)

		// 	handleInputChange(event)
		// }
		const { inputs, formError, handleInputChange, handleSubmit, handleFormError, handleFormReset } = useForm(
			initialFields.current as { [key: string]: TextInputProps },
			onSubmit,
			onError,
			props.onlySubmitOnChangedValues,
			props.hideSubmitIfFieldsEmpty
		)

		/**
		 * creates a dummy event to change field values from outside
		 */
		useEffect(() => {
			if (undefined === props.updatedValues) {
				return
			}
			let updatedFields: { [key: string]: TextInputProps } = { ...inputs }

			for (const [key, value] of Object.entries(props.updatedValues)) {
				if (undefined === inputs[key]) {
					continue
				}

				const updateEvent = {
					type: 'changeFromOutside',
					target: {
						name: key,
						value,
					},
				}

				updatedFields = handleInputChange(updateEvent as React.ChangeEvent<HTMLInputElement>, updatedFields)
			}

			if (props.onFormChange) {
				props.onFormChange(updatedFields as FormFields)
			}
			// eslint-disable-next-line
		}, [props.updatedValues])

		const renderInput = (key: string, input: FormField, index: number): JSX.Element | void => {
			const { fieldType, ...inputProps } = input
			if (!inputs[key]) {
				return
			}
			switch (fieldType) {
				case FormFieldType.text:
				case FormFieldType.phoneInput:
					return (
						<TextInput
							{...(inputProps as TextInputProps)}
							type={
								fieldType === FormFieldType.phoneInput
									? TextInputType.tel
									: (inputProps as TextInputProps).type
							}
							className={getPrefixedClassName(
								'field',
								undefined,
								undefined,
								(inputProps as TextInputProps).className
							)}
							key={`${key}-${index}`}
							name={key}
							value={inputs[key].value || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								;(inputProps as TextInputProps).onChange?.(e)
								onChange(e)
							}}
							errorMessage={(inputProps as TextInputProps).errorMessage}
							error={inputs[key].error}
							disabled={formLocked || inputProps.disabled}
							highlight={undefined !== inputs[key].error || FormErrorType.errorResponse === formError}
							usedInForm={true}
							usePhoneInput={fieldType === FormFieldType.phoneInput}
							ref={(el) => {
								if (el) {
									inputs[key].ref = createRef()
									inputs[key].ref.current = el

									if ((inputProps as TextInputProps).ref) {
										;(inputProps as TextInputProps).ref.current = el
									}
								}
							}}
						/>
					)

				case FormFieldType.googlePlacesAutoComplete:
					return (
						<GooglePlacesAutocomplete
							{...(inputProps as IGooglePlacesAutocomplete)}
							className={getPrefixedClassName(
								'field',
								undefined,
								undefined,
								(inputProps as IGooglePlacesAutocomplete).className
							)}
							key={`${key}-${index}`}
							name={key}
							value={inputs[key].value || ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								;(inputProps as IGooglePlacesAutocomplete).onChange?.(e)
								onChange(e)
							}}
							errorMessage={(inputProps as IGooglePlacesAutocomplete).errorMessage}
							error={inputs[key].error}
							disabled={formLocked || inputProps.disabled}
							highlight={undefined !== inputs[key].error || FormErrorType.errorResponse === formError}
							usedInForm={true}
							ref={(el) => {
								if (el) {
									inputs[key].ref = createRef()
									inputs[key].ref.current = el

									if ((inputProps as IGooglePlacesAutocomplete).ref) {
										;(inputProps as IGooglePlacesAutocomplete).ref.current = el
									}
								}
							}}
						/>
					)

				case FormFieldType.date:
					return (
						<DateInput
							{...(inputProps as DateInputProps)}
							className={getPrefixedClassName(
								'field',
								undefined,
								undefined,
								[(inputProps as DateInputProps).className].flat().join(' ')
							)}
							key={`${key}-${index}`}
							name={key}
							highlight={undefined !== inputs[key].error || FormErrorType.errorResponse === formError}
							value={(inputs[key].value as unknown as Date) || ''}
							onChange={(value) => {
								// e does not exists so we have to create our own
								const event = {
									target: {
										name: key,
										value,
									},
								} as unknown as ChangeEvent<HTMLInputElement>

								;(inputProps as DateInputProps).onChange?.(value)

								onChange(event)
							}}
							error={inputs[key].error}
							disabled={formLocked || inputProps.disabled}
							ref={(el: any) => {
								if (el) {
									const input = el.closest('.react-date-picker').querySelector('input')
									inputs[key].ref = createRef()
									inputs[key].ref.current = input

									if ((inputProps as DateInputProps).ref) {
										;(inputProps as DateInputProps).ref.current = input
									}
								}
							}}
						/>
					)

				case FormFieldType.select:
					return (
						<SelectInput
							{...(inputProps as SelectInputProps)}
							key={`${key}-${index}`}
							name={key}
							className={getPrefixedClassName(
								'field',
								undefined,
								undefined,
								(inputProps as SelectInputProps).className
							)}
							returnEvent={true}
							value={(inputs[key].value as string) || ''}
							disabled={formLocked || inputProps.disabled}
							error={inputs[key].error}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								;(inputProps as SelectInputProps).onChange?.(e)
								onChange(e)
							}}
							highlight={undefined !== inputs[key].error || FormErrorType.errorResponse === formError}
							ref={(el: HTMLSelectElement) => {
								if (el) {
									inputs[key].ref = createRef()
									inputs[key].ref.current = el

									if ((inputProps as SelectInputProps).ref) {
										;(inputProps as SelectInputProps).ref.current = el
									}
								}
							}}
						/>
					)

				case FormFieldType.switchSelect:
					return (
						<Fragment key={`${key}-${index}`}>
							<input
								name={key}
								type="hidden"
								value={(inputs[key].value as string) || ''}
								ref={(el: HTMLInputElement) => {
									inputs[key].ref = createRef()
									inputs[key].ref.current = el

									if ((inputProps as RadiobuttonProps).ref) {
										;(inputProps as RadiobuttonProps).ref.current = el
									}
								}}
								disabled={formLocked || inputProps.disabled}
								required={inputProps.required}
							/>
							<SwitchSelect
								name={key}
								{...(inputProps as SwitchSelectProps)}
								disabled={formLocked || inputProps.disabled}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									;(inputProps as SwitchSelectProps).onChange?.(e)
									onChange(e)
								}}
								className={getPrefixedClassName(
									'field',
									undefined,
									undefined,
									(inputProps as SwitchSelectProps).className
								)}
							/>
						</Fragment>
					)

				case FormFieldType.radioButtons:
					return (
						<RadiobuttonGroup
							key={`${key}-${index}`}
							className={getPrefixedClassName(
								'field',
								undefined,
								undefined,
								(inputProps as RadiobuttonProps).className
							)}
							headline={inputProps.label}
							error={inputs[key].error}
							prepend={(inputProps as RadiobuttonProps).prepend}
							hidden={(inputProps as RadiobuttonProps).hidden}
						>
							<input
								name={key}
								type="hidden"
								value={(inputs[key].value as string) || ''}
								ref={(el: HTMLInputElement) => {
									inputs[key].ref = createRef()
									inputs[key].ref.current = el

									if ((inputProps as RadiobuttonProps).ref) {
										;(inputProps as RadiobuttonProps).ref.current = el
									}
								}}
								disabled={formLocked || inputProps.disabled}
								required={inputProps.required}
							/>
							{(inputProps as { options: RadiobuttonProps[] }).options.map(
								(element: RadiobuttonProps, radioIndex: number) => {
									return (
										<Radiobutton
											name={key}
											disabled={formLocked || inputProps.disabled}
											checked={inputs[key].value === element.value}
											key={`${key}-${radioIndex}`}
											{...element}
											onChange={(e: ChangeEvent<HTMLInputElement>) => {
												;(inputProps as RadiobuttonProps).onChange?.(e)
												onChange(e)
											}}
											highlight={
												undefined !== inputs[key].error ||
												FormErrorType.errorResponse === formError
											}
										/>
									)
								}
							)}
						</RadiobuttonGroup>
					)

				case FormFieldType.checkBox:
					const checkboxInput = inputs[key] as CheckboxProps

					return (
						<Checkbox
							name={key}
							key={`${key}-${index}`}
							{...(inputProps as CheckboxProps)}
							disabled={formLocked || inputProps.disabled}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								;(inputProps as CheckboxProps).onChange?.(e)
								onChange(e)
							}}
							className={getPrefixedClassName(
								'field',
								undefined,
								undefined,
								(inputProps as CheckboxProps).className
							)}
							errorMessage={(inputProps as CheckboxProps).errorMessage}
							error={inputs[key].error}
							checked={
								checkboxInput.ref?.current
									? checkboxInput.ref.current.checked
									: (inputProps as CheckboxProps).checked
							}
							highlight={undefined !== inputs[key].error || FormErrorType.errorResponse === formError}
							ref={(el: HTMLInputElement) => {
								if (el) {
									inputs[key].ref = createRef()
									inputs[key].ref.current = el

									if ((inputProps as CheckboxProps).ref) {
										;(inputProps as CheckboxProps).ref.current = el
									}
								}
							}}
						/>
					)
				case FormFieldType.textArea:
					return (
						<TextArea
							name={key}
							key={`${key}-${index}`}
							disabled={formLocked || inputProps.disabled}
							{...(inputProps as TextAreaProps)}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								;(inputProps as TextAreaProps).onChange?.(e)
								onChange(e)
							}}
							value={(inputs[key] as TextAreaProps).value || ''}
							className={getPrefixedClassName(
								'field',
								undefined,
								undefined,
								(inputProps as TextAreaProps).className
							)}
							errorMessage={(inputProps as TextAreaProps).errorMessage}
							error={inputs[key].error}
							hidden={(inputProps as TextAreaProps).hidden}
							highlight={undefined !== inputs[key].error || FormErrorType.errorResponse === formError}
							usedInForm={true}
							ref={(el: HTMLTextAreaElement) => {
								if (el) {
									inputs[key].ref = createRef()
									inputs[key].ref.current = el

									if ((inputProps as TextAreaProps).ref) {
										;(inputProps as TextAreaProps).ref.current = el
									}
								}
							}}
						/>
					)
				// case FormFieldType.checkBoxes:
				// return (
				// 	<div key={`${key}-${index}`} className="checkbox-group">
				// 		{inputProps.label ? <div>{inputProps.label}</div> : ''}

				// 		{(inputProps as { options: CheckboxProps[] }).options.map(
				// 			(element: CheckboxProps, checkBoxIndex: number) => {
				// 				return (
				// 					<Checkbox
				// 						key={`${key}-${checkBoxIndex}`}
				// 						name={key}
				// 						checked={
				// 							undefined !== inputs[key].value &&
				// 							(inputs[key].value as string[]).includes(String(element.value))
				// 						}
				// 						value={element.value}
				// 						label={element.label}
				// 						onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxChange(e, key)}
				// 					/>
				// 				)
				// 			}
				// 		)}
				// 	</div>
				// )
			}
		}

		/**
		 * submit the login form on hitting enter
		 * @param event
		 */
		const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
			if ('Enter' !== event.key || true === props.disabled) {
				return
			}

			// prevent submit if a textarea is currently focused
			if ('TEXTAREA' === document.activeElement?.nodeName) {
				return
			}

			if (FormErrorType.none !== formError) {
				event.preventDefault()
				return
			}

			event.preventDefault()

			handleOnSubmit()
		}

		const handleOnSubmit = () => {
			if (true === formLocked) {
				return
			}

			handleSubmit()
		}

		const handleOnFormUnlock = async () => {
			let updatedFormLocked = false

			if (props.onFormUnlock) {
				updatedFormLocked = await props.onFormUnlock()
			}

			setFormLocked(updatedFormLocked)
		}

		const handleOnCaptcha = (token: string | null) => {
			const updateEvent = {
				target: {
					name: props.captchaFieldName,
					value: token,
				},
			}
			handleInputChange(updateEvent as ChangeEvent<HTMLInputElement>)
		}
		return (
			<form
				ref={form}
				className={[
					getPrefixedClassName('form', true, true),
					props.hideSubmitIfFieldsEmpty ? 'form--hide-submit-if-fields-empty' : null,
				].join(' ')}
				onKeyDown={onKeyDown}
				onSubmit={(e) => {
					e.nativeEvent.preventDefault()
				}}
			>
				<div className={getPrefixedClassName('fields')}>
					<>
						{Object.keys(props.fields).map((key: string, index: number) => {
							return renderInput(key, props.fields[key], index)
						})}

						{props.captchaFieldName && <Captcha onChange={handleOnCaptcha} />}
					</>
				</div>

				{props.children}

				{FormErrorType.errorResponse === formError && (
					<ErrorMessage
						visible={true}
						className={getPrefixedClassName('error')}
						messages={props.errorMessages || errorMessages || [t('generic.formErrors.generic')]}
						errorIcon={props.errorIcon}
					/>
				)}

				{true !== props.hideSubmit && (
					<Button
						className={getPrefixedClassName('submit', false, true)}
						onClick={handleOnSubmit}
						label={props.submitLabel || t('generic.send')}
						type={props.submitButtonType || ButtonType.primary}
						promiseTracker={props.promiseTracker}
						disabled={
							props.alwaysAllowSubmit
								? true === props.disabled
								: FormErrorType.none !== formError || true === props.disabled
						}
					/>
				)}

				{true === props.formLocked && props.formUnlockable ? (
					<Button
						className={getPrefixedClassName('unlock')}
						onClick={handleOnFormUnlock}
						label={props.unlockFormLabel || t('generic.edit')}
						type={props.unlockFormType || ButtonType.secondary}
					/>
				) : (
					''
				)}
			</form>
		)
	}
)

export default Form
