import { IconType } from 'components/Icons'
import { DefaultTFuncReturn } from 'i18next'
import React, {
	forwardRef,
	FunctionComponent,
	HTMLAttributes,
	OptgroupHTMLAttributes,
	ReactElement,
	useEffect,
	useRef,
	useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import convertToDashCase from 'shared/helper/convertToDashCase'
import { useUniqueId } from 'shared/hooks/useUniqueInputId'
import Icon from './Icon'
import Checkbox from './Checkbox'
import Button, { ButtonType } from './Button'

// TODO: render modal in react portal, to prevent z-index issues when polyfilled. see safari and add z-index: 0 to parent component

export interface SelectOption {
	value?: string
	label?: string | React.ReactElement | DefaultTFuncReturn
	description?: string | DefaultTFuncReturn
	group?: MultiSelectOptionGroup['id'] | MultiSelectOptionGroup['id'][]
	disabled?: boolean
}

export interface MultiSelectOptionGroup extends OptgroupHTMLAttributes<HTMLOptGroupElement> {
	id: string
}

export interface MultiSelectInputProps extends HTMLAttributes<HTMLSelectElement> {
	options: SelectOption[]
	optionGroups?: MultiSelectOptionGroup[]
	value?: string[]
	label?: string | React.ReactElement | DefaultTFuncReturn
	className?: string
	name?: string
	buttonColor?: string
	onChange?: any
	// whether to return an obj instead of only a value
	returnEvent?: boolean
	alternativeStyle?: boolean
	disabled?: boolean
	hidden?: boolean
	required?: boolean
	showRequiredAsterisk?: boolean
	ref?: any
	error?: { type: string; message?: string | DefaultTFuncReturn }
	errorMessage?: string | DefaultTFuncReturn
	highlight?: boolean
	// wether to use REACT-SELECT component
	useCustom?: boolean
	nothingSelectedLabel: string
	resetButtonLabel: string
	resetButtonValue: string[]
	modalHeadline: string
	selectDeselectAllButton?: boolean
}

const MultiSelectInput: FunctionComponent<MultiSelectInputProps> = forwardRef<HTMLSelectElement, MultiSelectInputProps>(
	({ errorMessage, error, ...props }, ref) => {
		const radioButtonName = useUniqueId('radiobutton')
		const { t } = useTranslation()

		const [selectedOption, setSelectedOption] = useState<string[] | undefined>()

		const [preSelectedOption, setPreSelectedOption] = useState<string[] | undefined>(selectedOption)

		const modal = useRef<ModalRefActions>()
		const [modalVisible, setModalVisible] = useState(false)
		const [updatedchecked, setUpdatedChecked] = useState<boolean | undefined>(undefined)

		useEffect(() => {
			modalVisible && modal.current?.openModal()
			setUpdatedChecked(undefined)
		}, [modalVisible])

		useEffect(() => {
			if (undefined !== props.value) {
				setSelectedOption(props.value)
			}
		}, [props.value])

		const modalInputChangedHandler = (event: React.FormEvent<HTMLInputElement>) => {
			if (event.type === 'change' && event.currentTarget.name === radioButtonName) {
				event.stopPropagation()
			}
			const { checked, value } = event.currentTarget
			setPreSelectedOption((current) => {
				const updateValue = Array.isArray(current) ? [...current] : [current || '']
				if (checked) {
					return updateValue.includes(value) ? updateValue : [...updateValue, value]
				} else {
					return updateValue.filter((item) => item !== value)
				}
			})
		}

		const resetOptions = () => {
			modal.current?.closeModal()
			setPreSelectedOption(props.resetButtonValue)
			setSelectedOption(props.resetButtonValue)

			setTimeout(() => {
				setModalVisible(false)
			}, 500)
		}

		const handleSelectDeselectAllButton = () => {
			if (preSelectedOption?.length === props.options.length) {
				setUpdatedChecked(false)
				return
			}
			if (preSelectedOption?.length === 0) {
				setUpdatedChecked(true)
				return
			}
			setUpdatedChecked(!updatedchecked)
		}

		const renderModalOptions = () => {
			return props.options.map((element, index) => {
				return (
					<div key={`${props.name ? `${props.name}-` : ''}${index}`} className="select-options-modal__option">
						<Checkbox
							className="select-options-modal__radiobutton margin--bottom margin--small"
							onChange={modalInputChangedHandler}
							value={element.value}
							checked={!!(element.value && preSelectedOption?.includes(element.value))}
							label={element.label ? element.label : element.value}
							name={radioButtonName}
							updatedchecked={updatedchecked}
						/>
						{element.description && (
							<div className="select-options-modal__additional-content text-color-white margin--top">
								{element.description}
							</div>
						)}
					</div>
				)
			})
		}

		useEffect(() => {
			if (selectedOption !== props.value && undefined !== props.onChange && undefined !== selectedOption) {
				if (props.returnEvent) {
					props.onChange({ target: { name: props.name, value: selectedOption } })
				} else {
					props.onChange(selectedOption)
				}
			}
			setPreSelectedOption(selectedOption)
			// eslint-disable-next-line
		}, [selectedOption])

		const getClasses = () => {
			const classes = ['select-input']

			if (props.name) {
				classes.push(`input--${convertToDashCase(props.name)}`)
			}

			if (props.disabled) {
				classes.push('input--disabled')
			}

			if (props.hidden) {
				classes.push('input--hidden')
			}

			if (props.highlight) {
				classes.push('input--highlighted')
			}

			if (props.className) {
				classes.push(props.className)
			}

			return classes.join(' ')
		}

		const showError = (): ReactElement | undefined => {
			return (
				<span style={{ opacity: Number(undefined !== error) }} className="select-input__error">
					{error?.message}
				</span>
			)
		}

		if (!props.options || 0 === props.options.length) {
			return null
		}

		return (
			<div className={` ${getClasses()}`}>
				{props.label ? (
					<div className="select-input__label bold-small-heading">
						{props.label}
						{props.showRequiredAsterisk && (
							<>{props.required ? ' *' : ` ${t('generic.optionalFormField')}`}</>
						)}
					</div>
				) : (
					''
				)}

				<Modal
					ref={modal}
					className="multi-select-options-modal"
					onButtonClick={() => setSelectedOption(preSelectedOption)}
					onCloseClick={() => setPreSelectedOption(selectedOption)}
					onModalClose={() => {
						setModalVisible(false)
					}}
					header={props.modalHeadline}
					buttonLabel={t('generic.confirm')}
				>
					{modalVisible && renderModalOptions()}

					{props.resetButtonLabel && (
						<Button type={[ButtonType.secondary, ButtonType.small]} onClick={resetOptions}>
							{props.resetButtonLabel}
						</Button>
					)}
					{props.selectDeselectAllButton && (
						<Button type={[ButtonType.secondary, ButtonType.small]} onClick={handleSelectDeselectAllButton}>
							{'Alle ab- oder auswählen'}
						</Button>
					)}
				</Modal>

				<span
					className={`select-input__value ${!selectedOption ? 'select-input__value--nothing-selected' : ''}`}
					onClick={() => setModalVisible(true)}
				>
					{selectedOption?.length === 0 || selectedOption?.length === props.options.length
						? props.nothingSelectedLabel
						: Array.isArray(selectedOption) &&
						  selectedOption?.map((option, i) => {
								return (
									props.options.find((element) => element.value === option)?.label +
									(i < selectedOption.length - 1 ? ', ' : '')
								)
						  })}
					<Icon type={IconType.arrow} />
				</span>

				<select
					name={props.name}
					ref={ref}
					className={`select-input__select-field  pointer-events--none`}
					value={selectedOption}
					disabled={props.disabled}
					onChange={() => {}}
					required={props.required}
				></select>

				{showError()}
			</div>
		)
	}
)

export default MultiSelectInput
