import { FunctionComponent, HTMLAttributes } from 'react'

export interface GridListRowProps extends HTMLAttributes<HTMLDivElement> {
	isHeader?: boolean
}

const GridListRow: FunctionComponent<GridListRowProps> = (props) => {
	const getClasses = () => {
		const classes = ['grid-list__row']

		if (props.className) {
			classes.push(props.className)
		}
		return classes.join(' ')
	}

	return <div className={getClasses()}>{props.children}</div>
}

export default GridListRow
