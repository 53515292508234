import UnresolvedSyncConflictsResults from 'components/UnresolvedSyncConflictsResults'
import { Trans } from 'react-i18next'

const UnresolvedSyncConflicts = () => {
    return (
        <div className="pensioner-search">
            <h1 className="pensioner-search__headline margin--bottom no-margin--bottom">
                <Trans i18nKey="view.syncConflict.headline" />
            </h1>
            <div className="flex flex--align-items-center font-family-bold ">
                <div className="text-transform-uppercase text-color-gold margin--right">
                    <Trans i18nKey="view.syncConflict.subheadline" />
                </div>
                <hr className="hr hr--gold flex--grow" />
            </div>
            <UnresolvedSyncConflictsResults />
        </div>
    )
}

export default UnresolvedSyncConflicts
