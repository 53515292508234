export const NOT_RESENDABLE_POSTAUSGANG_DOCUMENTTYPE = [
	'INITIAL_PASSWORD',
	'INITIAL_PIN',
	'CALC',
	'PAPER_RISK_PARTNER',
	'VAG_BESCHLUSS',
	'VAG_BESCHLUSS_KEINE_TEILUNG',
	'VAG_RECHTSKRAFT',
	'VAG_EMPFANGBEKENNTNIS',
	'VAG_AUSKUNFTSERSUCHEN',
	'VAG_SONSTIGER_SCHRIFTVERKEHR',
]

export const POSTAUSGANG_DOCUMENTTYPE = [
	'SIGNUP',
	'SIGNUP_REPETITION',
	'SIGNUP_REMINDER',
	'INITIAL_PASSWORD',
	'INITIAL_PIN',
	'TRANSFER_LETTER',
	'TRANSFER_INFO',
	'ZFCARD',
	'BALANCE_STATUS_APPLICATION',
	'KTO-DC',
	'IND_COM',
	'CALC',
	'ZUSAGESCHREIBEN',
	'SEVERANCE',
	'NONFORFEIT',
	'PAPER_RISK_PARTNER',
	'TRANSFER_LETTER_FINAL',
	'RISK_CONFIRMATION',
	'PENSION_CONFIRMATION',
	'RISK_PARTNER',
	'CONFIRMATION_RECEIPS',
	'VAG_TEILUNGSINFORMATION',
	'VAG_BESCHLUSS',
	'VAG_BESCHLUSS_KEINE_TEILUNG',
	'VAG_RECHTSKRAFT',
	'VAG_EMPFANGBEKENNTNIS',
	'VAG_AUSKUNFTSERSUCHE',
	'VAG_SONSTIGER_SCHRIFTVERKEHR',
	'AZS',
	'ZAD',
	'PERSONALAUSWEIS',
]

export const POSTEINGANG_DOCUMENTTYPE = [
	'PAPER_RISK_OPTION',
	'PAPER_BONUS',
	'PAPER_MONTHLY_SCHEME',
	'PAPER_RISK_PARTNER',
	'PARTICIPATION_DECLARATION',
	'PERSONAL_DATA_FORM',
	'WITHDRAW_APPLICATION',
	'TRANSFER_CONFIRMATION',
	'RETOUR',
	'INFO',
	'ADDRESS',
	'BANKVERBINDUNG',
	'RENTENBESCHEINIGUNG',
	'KRANKENVERSICHERUNG',
	'DBA_UNTERLAGEN',
	'AVWL_ANTRAG',
	'MITTEILUNG',
]
