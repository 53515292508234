import React from 'react'
import { Trans } from 'react-i18next'

const NotFound = () => {
	return (
		<div className="not-found">
			<h1 className="page-title">
				<Trans i18nKey="view.notFound.pageTitle" />
			</h1>
		</div>
	)
}

export default NotFound
