import { t } from "i18next";

const getParticipationStateValue = (value: string) => {
    const splittedState = value.split('#');
    const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);
    if (splittedState.length === 1) {
        return capitalize(t(`view.pensionerProfile.profileHint.${splittedState[0]}`));
    } else if (splittedState.length === 2) {
        return (
            capitalize(t(`view.pensionerProfile.profileHint.${splittedState[0]}`)) +
            `, (${splittedState[1]})`
        );
    } else if (splittedState.length === 3) {
        return (
            t(`view.pensionerProfile.fields.participationState.state.${splittedState[0]}`) + ', ' +
            (splittedState[1] === ' ' ? '' : t(`view.pensionerProfile.fields.participationState.wayOfParticipation.${splittedState[1]}`)) +
            ` (${splittedState[2]})`
        );
    } else if (splittedState.length === 4) {
        return (
            t(`view.pensionerProfile.fields.participationState.completePaidOffOption.${splittedState[1]}`) + ', ' +
            (splittedState[2] === ' ' ? '' : t(`view.pensionerProfile.fields.participationState.wayOfParticipation.${splittedState[2]}`)) +
            ` (${splittedState[3]})`
        )
    } else {
        return '-';
    }
}
export default getParticipationStateValue;