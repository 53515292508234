import React from 'react'
import useNavigationState, { NavigationState } from 'state/useNavigationState'

const ViewTitle: React.FC = () => {
	const [viewTitle] = useNavigationState<NavigationState['viewTitle']>((state: NavigationState) => state.viewTitle)

	return <>{viewTitle || null}</>
}

export default ViewTitle
