import useApi, { QueryKey, Ticket, TicketType, VersionedTicketContent } from 'hooks/useApi'
import { ChangeEvent, FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import Button, { ButtonType } from 'shared/components/Button'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import SelectInput, { SelectOptionGroup } from 'shared/components/SelectInput'
import SwitchSelect from 'shared/components/SwitchSelect'
import TextInput from 'shared/components/TextInput'
import { arrayIsEqual } from 'shared/helper/arrayIsEqual'
import { IconType } from './Icons'

export interface ITicketModal {
	open: boolean
	onModalClose: () => void
	data: Ticket
}

const TicketModal: FunctionComponent<ITicketModal> = (props) => {
	const { open, onModalClose, data } = props

	const modal = useRef<ModalRefActions>()
	const api = useApi()
	const { t } = useTranslation()
	const queryClient = useQueryClient()

	const [predefinedRemarks, setPredefinedRemarks] = useState<string[]>(data.predefinedRemarks)
	const [customRemarks, setCustomRemarks] = useState<string[]>(data.customRemarks)
	const [problemState, setProblemState] = useState<string | null | undefined>(data.problemState)
	const customRemarkInputField = useRef<HTMLInputElement>(null)
	const [predefinedRemarkSelectInputFieldValue, setPredefinedRemarkSelectInputFieldValue] = useState<string>('')
	const [customRemarkSelectInputFieldValue, setCustomRemarkSelectInputFieldValue] = useState<string>('')

	const selectOptionGroups: SelectOptionGroup[] = useMemo(
		() => {
			const allGroups = [
				{
					id: TicketType.PAPER_RISK_OPTION,
					label: t('component.ticketModal.remarkGroups.typeSpecific'),
				},
				{
					id: TicketType.TRANSFER_CONFIRMATION,
					label: t('component.ticketModal.remarkGroups.typeSpecific'),
				},
				{
					id: TicketType.PAPER_MONTHLY_SCHEME,
					label: t('component.ticketModal.remarkGroups.typeSpecific'),
				},
				{
					id: TicketType.PAPER_BONUS,
					label: t('component.ticketModal.remarkGroups.typeSpecific'),
				},
				{
					id: 'general',
					label: t('component.ticketModal.remarkGroups.general'),
				},
			]

			return allGroups.filter(({ id }) => {
				if ('general' === id) {
					return true
				}

				return data?.documentContent?.content?.type === id
			})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[data]
	)

	const selectOptions = useMemo(
		() => {
			const allOptions = [
				{
					value: '',
					label: t('component.ticketModal.remarkLabel.pleaseSelect'),
				},
				{
					value: 'RISIKOLEISTUNG_ANTRAG_DURCHGESTRICHEN',
					label: t('component.ticketModal.remarkLabel.RISIKOLEISTUNG_ANTRAG_DURCHGESTRICHEN'),
					group: TicketType.PAPER_RISK_OPTION,
				},
				{
					value: 'RISIKOLEISTUNG_KEINE_AUSWAHL',
					label: t('component.ticketModal.remarkLabel.RISIKOLEISTUNG_KEINE_AUSWAHL'),
					group: TicketType.PAPER_RISK_OPTION,
				},
				{
					value: 'RISIKOLEISTUNG_UNTERSCHRIEBEN_KEINE_AUSWAHL',
					label: t('component.ticketModal.remarkLabel.RISIKOLEISTUNG_UNTERSCHRIEBEN_KEINE_AUSWAHL'),
					group: TicketType.PAPER_RISK_OPTION,
				},
				{
					value: 'RISIKOLEISTUNG_EM_KEIN_ZEITRAUM',
					label: t('component.ticketModal.remarkLabel.RISIKOLEISTUNG_EM_KEIN_ZEITRAUM'),
					group: TicketType.PAPER_RISK_OPTION,
				},
				{
					value: 'RISIKOLEISTUNG_EM+HIBLI_KEIN_ZEITRAUM',
					label: t('component.ticketModal.remarkLabel.RISIKOLEISTUNG_EM+HIBLI_KEIN_ZEITRAUM'),
					group: TicketType.PAPER_RISK_OPTION,
				},
				{
					value: 'HIBLI_ERHOEHUNG+ABWAHL',
					label: t('component.ticketModal.remarkLabel.HIBLI_ERHOEHUNG+ABWAHL'),
					group: TicketType.PAPER_RISK_OPTION,
				},
				{
					value: 'HIBLI_KEIN_ZEITRAUM',
					label: t('component.ticketModal.remarkLabel.HIBLI_KEIN_ZEITRAUM'),
					group: TicketType.PAPER_RISK_OPTION,
				},
				{
					value: 'UMSTELLUNGSVEREINBARUNG_NUR_EINE_SEITE',
					label: t('component.ticketModal.remarkLabel.UMSTELLUNGSVEREINBARUNG_NUR_EINE_SEITE'),
					group: TicketType.TRANSFER_CONFIRMATION,
				},
				{
					value: 'WAWU_ANTRAG_DURCHGESTRICHEN',
					label: t('component.ticketModal.remarkLabel.WAWU_ANTRAG_DURCHGESTRICHEN'),
					group: [TicketType.PAPER_MONTHLY_SCHEME, TicketType.PAPER_BONUS],
				},
				{
					value: 'WAWU_KEINE_AUSWAHL',
					label: t('component.ticketModal.remarkLabel.WAWU_KEINE_AUSWAHL'),
					group: [TicketType.PAPER_MONTHLY_SCHEME, TicketType.PAPER_BONUS],
				},
				{
					value: 'WAWU_UNKLAR_ZWEI_PROZENTWERTE',
					label: t('component.ticketModal.remarkLabel.WAWU_UNKLAR_ZWEI_PROZENTWERTE'),
					group: [TicketType.PAPER_MONTHLY_SCHEME, TicketType.PAPER_BONUS],
				},
				{
					value: 'WAWU_UNKLAR_PROZENTWERT_UND_FESTBETRAG',
					label: t('component.ticketModal.remarkLabel.WAWU_UNKLAR_PROZENTWERT_UND_FESTBETRAG'),
					group: [TicketType.PAPER_MONTHLY_SCHEME, TicketType.PAPER_BONUS],
				},
				{
					value: 'UNTERSCHRIFTSDATUM_FEHLT_UNTERSCHRIFT_GESETZT',
					label: t('component.ticketModal.remarkLabel.UNTERSCHRIFTSDATUM_FEHLT_UNTERSCHRIFT_GESETZT'),
					group: 'general',
				},
				{
					value: 'UNTERSCHRIFTSDATUM_UNPLAUSIBEL_UNTERSCHRIFT_GESETZT',
					label: t('component.ticketModal.remarkLabel.UNTERSCHRIFTSDATUM_UNPLAUSIBEL_UNTERSCHRIFT_GESETZT'),
					group: 'general',
				},
				{
					value: 'KEINE_AUSWAHL_UNTERSCHRIFT_GESETZT',
					label: t('component.ticketModal.remarkLabel.KEINE_AUSWAHL_UNTERSCHRIFT_GESETZT'),
					group: 'general',
				},
				{
					value: 'UNTERSCHRIFT_FEHLT',
					label: t('component.ticketModal.remarkLabel.UNTERSCHRIFT_FEHLT'),
					group: 'general',
				},
				{
					value: 'ANTRAG_DURCHGESTRICHEN',
					label: t('component.ticketModal.remarkLabel.ANTRAG_DURCHGESTRICHEN'),
					group: 'general',
				},
				{
					value: 'LEERER_ANTRAG',
					label: t('component.ticketModal.remarkLabel.LEERER_ANTRAG'),
					group: 'general',
				},
			]

			return allOptions.filter((option) => !(predefinedRemarks || []).includes(option.value))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[predefinedRemarks]
	)

	useEffect(() => {
		if (open) {
			modal.current?.openModal()
		}
	}, [open])

	const handleOnSubmit = async () => {
		const {
			version,
			predefinedRemarks: currentPredefinedRemarks,
			customRemarks: currentCustomRemarks,
			problemState: currentProblemState,
		} = data

		const updatedTicketInfo: Partial<VersionedTicketContent> = {
			version,
		}

		if (!arrayIsEqual(currentPredefinedRemarks, predefinedRemarks)) {
			updatedTicketInfo.predefinedRemarks = predefinedRemarks
		}

		if (!arrayIsEqual(currentCustomRemarks, customRemarks)) {
			updatedTicketInfo.customRemarks = customRemarks
		}

		if (currentProblemState !== problemState) {
			updatedTicketInfo.problemState = problemState
		}
		// if there is no data to update, do not send any request and bail early
		if (1 === Object.keys(updatedTicketInfo).length) {
			return
		}

		const response = await api.updateTicketInfo({ ticketId: data.id, body: updatedTicketInfo })

		const updatedTicketData = { ...data, ...response }

		queryClient.setQueryData([QueryKey.ticket, { ticketId: data.id }], (oldData: any) => ({
			...oldData,
			ticket: { ...updatedTicketData },
		}))
	}

	const renderProblemState = () => {
		return (
			<div className="problem-state">
				<h3>{t('component.ticketModal.problemState.headline')}</h3>

				<SwitchSelect
					value={problemState || ''}
					options={[
						{ value: '', label: t('component.ticketModal.problemState.optionLabels.ok') },
						{ value: 'default', label: t('component.ticketModal.problemState.optionLabels.default') },
					]}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						setProblemState('' === e.target.value ? null : e.target.value)
					}}
				/>
			</div>
		)
	}

	const renderCustomRemarks = () => {
		const handleOnSubmitPredefinedRemark = () => {
			if (predefinedRemarkSelectInputFieldValue) {
				setPredefinedRemarks((updatedRemarks = []) => [
					...updatedRemarks,
					predefinedRemarkSelectInputFieldValue,
				])
				setPredefinedRemarkSelectInputFieldValue('')
			}
		}

		const handleOnSubmitCustomRemark = () => {
			if (customRemarkSelectInputFieldValue) {
				setCustomRemarks((updatedRemarks = []) => [...updatedRemarks, customRemarkSelectInputFieldValue])
				setCustomRemarkSelectInputFieldValue('')
				customRemarkInputField.current!.value = ''
			}
		}

		return (
			<div className="custom-remarks">
				<h3>{t('component.ticketModal.remarks')}</h3>

				<div className="flex flex--align-items-center">
					<SelectInput
						className="input--full-width padding--right"
						value={predefinedRemarkSelectInputFieldValue}
						alternativeStyle={true}
						label={t('component.ticketModal.newRemark')}
						options={selectOptions}
						optionGroups={selectOptionGroups}
						onChange={(value: string) => {
							setPredefinedRemarkSelectInputFieldValue(value)
						}}
					/>

					<Button
						onClick={handleOnSubmitPredefinedRemark}
						type={[ButtonType.round, ButtonType.small]}
						icon={IconType.checkmarkFilled}
						disabled={predefinedRemarkSelectInputFieldValue === ''}
					/>
				</div>

				<div className="flex flex--align-items-center">
					<TextInput
						label={t('component.ticketModal.otherRemark')}
						ref={customRemarkInputField}
						onKeyUp={(e) => {
							const { key } = e

							if ('Enter' === key) {
								handleOnSubmitCustomRemark()
							}
						}}
						value={customRemarkSelectInputFieldValue}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							setCustomRemarkSelectInputFieldValue(e.target.value)
						}
						className="input--full-width padding--right"
					/>
					<Button
						onClick={handleOnSubmitCustomRemark}
						type={[ButtonType.round, ButtonType.small]}
						icon={IconType.checkmarkFilled}
						disabled={customRemarkSelectInputFieldValue === ''}
					/>
				</div>

				{predefinedRemarks?.map((remark, index) => (
					<div
						key={`predefined-remark-${index}`}
						className="flex flex flex--align-items-censter custom-remark margin--vertical margin--small"
					>
						<span className="flex--grow flex--align-self-center">
							{t(`component.ticketModal.remarkLabel.${remark}`)}
						</span>
						<Button
							onClick={() => {
								setPredefinedRemarks((remarks) =>
									remarks.filter((_, currentIndex) => currentIndex !== index)
								)
							}}
							type={[ButtonType.round, ButtonType.small]}
							icon={IconType.invalid}
						/>
					</div>
				))}

				{customRemarks?.map((remark, index) => (
					<div
						key={`custom-remark-${index}`}
						className="flex flex flex--align-items-censter custom-remark margin--vertical margin--small"
					>
						<span className="flex--grow flex--align-self-center">{remark}</span>
						<Button
							onClick={() => {
								setCustomRemarks((remarks) =>
									remarks.filter((_, currentIndex) => currentIndex !== index)
								)
							}}
							type={[ButtonType.round, ButtonType.small]}
							icon={IconType.invalid}
						/>
					</div>
				))}
			</div>
		)
	}

	return (
		<Modal
			ref={modal}
			forceAction={false}
			header={t('component.ticketModal.header')}
			buttonLabel={t('generic.save')}
			onModalClose={onModalClose}
			onButtonClick={handleOnSubmit}
			className="modal--ticket"
		>
			<div className="padding--horizontal margin--vertical text-align--left">
				{renderProblemState()}
				{renderCustomRemarks()}
			</div>
		</Modal>
	)
}

export default TicketModal
