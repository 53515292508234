import useApi, { BankverbindungRequestBody, QueryKey } from 'hooks/useApi'
import { t } from 'i18next'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormFieldType, FormFields } from 'shared/components/Form'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import zfToast, { ZFToastProps } from 'shared/components/ZFToast'

type BankverbindungProps = {
	userId: string
}

export const Bankverbindung: React.FC<BankverbindungProps> = (props) => {
	const api = useApi()
	const { t } = useTranslation()
	const { userId } = props
	const {
		data: bankverbindung,
		status,
		refetch: refetchBankverbindung,
	} = useQuery([QueryKey.adminGepflegteBankverbindung, { userId }], api.getBankverbindung, {
		enabled: !!userId,
	})
	const modal = useRef<ModalRefActions>()

	const [buttonType, setButtonType] = useState<'update' | 'delete'>('update')
	const [validatedBankInfo, setValidatedBankInfo] = useState<BankverbindungRequestBody>()
	const [bankname, setBankname] = useState<string>('')
	const [updatedValues, setUpdatedValues] = useState<Record<string, string>>({
		IBAN: '',
		bankname: '',
	})

	const getFormFields: () => FormFields = () => {
		return {
			IBAN: {
				fieldType: FormFieldType.text,
				label: <Trans i18nKey="view.pensionerProfile.tabs.austrittsDaten.fields.bankverbindung.iban" />,
				alternativeStyle: true,
				value: bankverbindung?.IBAN,
				onKeyDown: () => {
					setBankname('')
				},
			},
		}
	}

	const [updatedFormFields, setUpdatedFormFields] = useState<FormFields>(getFormFields)

	useEffect(() => {
		if (bankverbindung) {
			setBankname(bankverbindung.bankname)
			setUpdatedValues({
				IBAN: bankverbindung.IBAN ?? '',
				bankname: bankverbindung.bankname ?? '',
			})
		}
	}, [bankverbindung])

	const bankverbindungForm: FormFields = useMemo(
		() => {
			return getFormFields()
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[bankverbindung]
	)

	const handleOpenModal = async (buttonType: 'update' | 'delete') => {
		setButtonType(buttonType)
		const IBAN = (updatedFormFields?.IBAN.value as string) ?? undefined
		const { isValid, bankname } = await api.validiereIban({ iban: IBAN })
		if (!isValid) {
			setValidatedBankInfo(undefined)
		} else {
			setValidatedBankInfo({
				IBAN,
				bankname,
			})
		}
		modal.current?.openModal()
	}
	const getModalHeaderText = () => {
		return t(`view.pensionerProfile.tabs.austrittsDaten.fields.bankverbindung.modal.title.${buttonType}`)
	}
	const getModalContentText = () => {
		if (buttonType === 'update') {
			if (validatedBankInfo) {
				return (
					<div>
						<p>{`${validatedBankInfo.IBAN}`}</p>
						<p>{`Bankname: ${validatedBankInfo.bankname}`}</p>
						{t(
							`view.pensionerProfile.tabs.austrittsDaten.fields.bankverbindung.modal.content.${buttonType}`
						)}
					</div>
				)
			}
			return t(`view.pensionerProfile.tabs.austrittsDaten.fields.bankverbindung.modal.content.error`)
		}
		return t(`view.pensionerProfile.tabs.austrittsDaten.fields.bankverbindung.modal.content.${buttonType}`)
	}
	const handleSubmit = async () => {
		switch (buttonType) {
			case 'delete':
				const responseDelete = await api.deleteBankverbindung({ userId })
				if (!!responseDelete.success) {
					const toastProps: ZFToastProps = {
						title: 'Bankverbindung',
						content: t(
							'view.pensionerProfile.tabs.austrittsDaten.fields.bankverbindung.toast.deleteSucceed'
						),
						type: 'success',
					}
					zfToast(toastProps)
				}
				refetchBankverbindung()
				setUpdatedValues({
					IBAN: '',
					bankname: '',
				})
				setBankname('')
				return
			case 'update':
				if (!validatedBankInfo) {
					refetchBankverbindung()
					setUpdatedValues({
						IBAN: bankverbindung?.IBAN ?? '',
						bankname: bankverbindung?.bankname ?? '',
					})
					if (bankverbindung) {
						setBankname(bankverbindung.bankname)
					}
					modal.current?.closeModal()
					return
				}
				const responsePost = await api.postBankverbindung({ userId, body: validatedBankInfo })
				if (!!responsePost.success) {
					const toastProps: ZFToastProps = {
						title: 'Bankverbindung',
						content: t('view.pensionerProfile.tabs.austrittsDaten.fields.bankverbindung.toast.postSucceed'),
						type: 'success',
					}
					zfToast(toastProps)
				}
				refetchBankverbindung()
				if (bankverbindung) {
					setBankname(bankverbindung.bankname)
				}
				return
		}
	}

	return (
		<div>
			<div className="bankverbindung">
				<div className="bankverbindung__headline">
					<h2 className="bankverbindung__headline__subheadline">
						<Trans i18nKey="view.pensionerProfile.tabs.austrittsDaten.fields.bankverbindung.title" />
					</h2>
				</div>
				{status === 'success' && (
					<div className="bankverbindung__content">
						<Form
							fields={bankverbindungForm}
							onSubmit={() => {}}
							hideSubmit={true}
							className="bankverbindung__content__bank-info"
							onFormChange={setUpdatedFormFields}
							updatedValues={updatedValues}
						></Form>
						{bankname && (
							<div className="bankverbindung__content__bankname">
								<div>{bankname}</div>
								{/* <Icon type={IconType.approved}></Icon> */}
							</div>
						)}

						<div className="bankverbindung__content__buttongroup">
							<Button
								type={[ButtonType.highlight]}
								className="bankverbindung__content__buttongroup__button"
								onClick={() => handleOpenModal('update')}
							>
								<Trans i18nKey="view.pensionerProfile.tabs.austrittsDaten.fields.bankverbindung.button.update" />
							</Button>
							<Button
								type={[ButtonType.highlight]}
								className="bankverbindung__content__buttongroup__button"
								onClick={() => handleOpenModal('delete')}
							>
								<Trans i18nKey="view.pensionerProfile.tabs.austrittsDaten.fields.bankverbindung.button.delete" />
							</Button>
						</div>
					</div>
				)}
			</div>
			<Modal
				ref={modal}
				forceAction={false}
				header={getModalHeaderText()}
				buttonLabel={buttonType === 'update' && !validatedBankInfo ? t(`generic.ok`) : t(`generic.save`)}
				onModalClose={() => {}}
				onButtonClick={handleSubmit}
				className="modal--start-austrittstask"
			>
				<div>{getModalContentText()}</div>
			</Modal>
		</div>
	)
}
