import classNames from 'classnames'
import { IconType } from 'components/Icons'
import { TConnectQueue, connectQueueSettings, useSoftphone } from 'hooks/useSoftphone'
import { useUserIdentificationNavigation } from 'hooks/useUserIdentifcationNavigation'
import { FC, HTMLAttributes, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Icon from 'shared/components/Icon'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'

export const ConnectSuggestedAction: FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
	const { contactData, contactQueue: { name: queueName } = {} } = useSoftphone()
	const { getMainPath, isRouteAllowed } = useRouteHelper()
	const { openRecords } = useUserIdentificationNavigation()

	const { t } = useTranslation()

	const queueSettings = useMemo(() => connectQueueSettings[queueName as TConnectQueue], [queueName])

	const handleOpenIdentification = () => {
		openRecords(contactData?.identNumber || '', queueSettings?.tenantKey || '')
	}

	if (queueSettings?.quicklink === undefined) {
		return null
	}

	return (
		<div className={classNames(className, 'connect-suggested-action')}>
			{contactData?.found === false && (
				<div className="flex">
					<Icon type={IconType.profile} color="var(--color-blue)" />
					<Trans
						i18nKey={`component.connect.suggestedAction.callerNotFound.${
							contactData?.identNumber ? 'idNotFound' : 'noId'
						}`}
					/>
				</div>
			)}

			{queueSettings?.quicklink === 'openIdentification' && isRouteAllowed(routesDictionary.callerActions) && (
				<Button
					type={[ButtonType.small, ButtonType.primary]}
					label={t('component.connect.suggestedAction.openIdentification')}
					onClick={handleOpenIdentification}
				/>
			)}

			{queueSettings?.quicklink === 'openUserRecordOrSearch' && isRouteAllowed(routesDictionary.pensioner) && (
				<Button
					type={[ButtonType.small, ButtonType.primary]}
					label={t(
						`component.connect.suggestedAction.${contactData?.found ? 'openUserRecord' : 'openSearch'}`
					)}
					route={
						contactData?.found === true
							? `${getMainPath(routesDictionary.pensioner)}/${contactData.identNumber}`
							: getMainPath(routesDictionary.pensionerSearch)
					}
				/>
			)}
		</div>
	)
}
