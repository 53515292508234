export enum CompensationType {
	salary = 'salary',
	bonus = 'bonus',
	earningsBonus = 'earningsBonus',
	christmasBonus = 'christmasBonus',
	holidayBonus = 'holidayBonus',
	variableBonus = 'variableBonus',
	tariffAdditionalBonus = 'tariffAdditionalBonus',
	gratuityBonus = 'gratuityBonus',
	avwl = 'avwl',
	boardYearlyBonus = 'boardYearlyBonus',
}

export enum ConfirmedCompensationType {
	/**
	 * Compensations which are transferred monthly.
	 */
	MONTHLY = '01',
	CHRISTMAS_BONUS = '02',
	HOLIDAY_BONUS = '03',
	/**
	 * "Tarifliches Zusatzgeld" in german.
	 */
	TARIFF_GRANT = '04',
	PROFIT_SHARING = '05',
	VARIABLE_COMPENSATION = '06',
	ONE_TIME_BONUS = '07',
	SEVERANCE_PAY = '08',
	/**
	 * Employer initiated compensations for corrections.
	 * Has only employer contribution.
	 */
	POST_GRANT = '09',
	ANNIVERSARY_CONTRIBUTION = '10',
	/**
	 *  "Altersvorsorgewirksame Leistung" (AWVL) is german for contributions to employee pension capital.
	 */
	AVWL_MONTHLY = '11',
	BOARD_YEARLY_OWN_CONTRIBUTION = '12',
	BOARD_YEARLY_EMPLOYER_CONTRIBUTION = '13',
}

export enum UserGroup {
	Admin = 'Admin',
	None = 'None',
	LeftWithEntitlement = 'LeftWithEntitlement',
	Advisor = 'Advisor',
	DeleteTickets = 'DeleteTickets',
	ResetUser = 'ResetUser',
	ConnectAgent = 'Connect-Agent',
	ConnectAdmin = 'Connect-Admin',
	SoftphoneOnly = 'Softphone-Only',
	Vo20 = 'FE-VO20',
	Vo19 = 'FE-VO19',
	Betrieb = 'Betrieb',
	LetterCreator = 'LetterCreator',
	Vorstand = 'Vorstand',
	ExecutiveManagement = 'EMG',
	GlobalExecutiveTeam = 'GET',
	ClientAll = 'Client-ALL',
	Austrittsbearbeitung = 'Austrittsbearbeitung',

	ZF_Sachbearbeiter = 'ZF_Sachbearbeiter',
	ZF_Sachbearbeiter_Telefonie_erweitert = 'ZF_Sachbearbeiter_Telefonie_erweitert',
	ZF_Sachbearbeiter_Telefonie = 'ZF_Sachbearbeiter_Telefonie',
	ZF_Sachbearbeiter_Betrieb = 'ZF_Sachbearbeiter_Betrieb',
	ZF_Sachbearbeiter_Austrittsbearbeitung = 'ZF_Sachbearbeiter_Austrittsbearbeitung',
	ZF_Alle_Rechte = 'ZF_Alle_Rechte',
	ZF_Tickets_Loeschen = 'ZF_Tickets_Loeschen',

	Westenergie_Sachbearbeiter_Standard = 'Westenergie_Sachbearbeiter_Standard',
	Westenergie_FLS_Sachbearbeiter_erweitert = 'Westenergie_FLS_Sachbearbeiter_erweitert',
	Westenergie_FLS_Sachbearbeiter = 'Westenergie_FLS_Sachbearbeiter',
}

export enum CompensationInputType {
	percentage = 'percentage',
	amount = 'amount',
}

export enum CompensationUnit {
	percent = 'percent',
	euro = 'euro',
}

export enum CompensationStatus {
	open = 'OFFEN',
	confirmed = 'CONFIRMED',
}

export enum CompensationDisclaimer {
	WIE_ERWARTET = 'WIE_ERWARTET',
	ABWEICHEND = 'ABWEICHEND',
	NEGATIV = 'NEGATIV',
	PREVIEW = 'COMPENSATION_PREVIEW',
	ABGELEHNT = 'ABGELEHNT',
}

export enum ApiErrorCode {
	EMAIL_NOT_PRIVATE = 'EMAIL_NOT_PRIVATE',
	EMAIL_NOT_VALID = 'EMAIL_NOT_VALID',
	EMAIL_NOT_NEW = 'EMAIL_NOT_NEW',
	PASSWORD_INCORRECT = 'PASSWORD_INCORRECT',
	PASSWORD_INCLUDES_EMAIL = 'PASSWORD_INCLUDES_EMAIL',
	PASSWORD_INCLUDES_ID = 'PASSWORD_INCLUDES_ID',
	PASSWORD_NOT_COMPLY_WITH_POLICY = 'PASSWORD_NOT_COMPLY_WITH_POLICY',
	PASSWORD_MATCHES_OLD_PWS = 'PASSWORD_MATCHES_OLD_PWS',
	MFA_INVALID = 'MFA_INVALID',
	UNKNOWN_ERROR = 'UNKNOWN_ERROR',
	INITIAL_PASSWORD_EXPIRED = 'INITIAL_PASSWORD_EXPIRED',
	PINS_DO_NOT_MATCH = 'PINS_DO_NOT_MATCH',
	PASSWORDS_DO_NOT_MATCH = 'PASSWORDS_DO_NOT_MATCH',
	EMAIL_ADDRESSES_DO_NOT_MATCH = 'EMAIL_ADDRESSES_DO_NOT_MATCH',
	USERNAME_OR_PASSWORD_INCORRECT = 'USERNAME_OR_PASSWORD_INCORRECT',
	INITIAL_USERNAME_OR_PASSWORD_INCORRECT = 'INITIAL_USERNAME_OR_PASSWORD_INCORRECT',
	ACCOUNT_TEMPORARY_LOCKED = 'ACCOUNT_TEMPORARY_LOCKED',
	SESSION_TIMEOUT = 'SESSION_TIMEOUT',
}

/**
 * Erwerbsminderung // disabilityAllowancePercentage
 */
export enum RiskOptions {
	disabilityBenefit = 'disabilityAllowance',
	partnerBenefit = 'partnerBenefit',
	designatePartner = 'partnerFormData',
}

/**
 * Erwerbsminderung // disabilityAllowancePercentage
 */
export enum OPTEOptionsOriginal {
	STANDARD = 'X',
	ONE_YEAR = '1',
	THREE_YEARS = '3',
}

/**
 * original OPTEOptions need to be transformed to this interface after each api request,
 * to split up the choice of the type and duration
 */
export enum OPTEOptions {
	STANDARD = 'X',
	UP = 'E',
}

/**
 * Hinterbliebenenleistung // survivorBenefitPercentage
 */
export enum OPTTOptionsOriginal {
	STANDARD = 'X',
	ONE_YEAR_UP = 'E1',
	THREE_YEAR_UP = 'E3',
	ONE_YEAR_DOWN = 'A1',
	THREE_YEAR_DOWN = 'A3',
}

/**
 * original OPTTOptions need to be transformed to this interface after each api request,
 * to split up the choice of the type and duration *
 */
export enum OPTTOptions {
	STANDARD = 'X',
	UP = 'E',
	DOWN = 'A',
}

export enum riskOptionDisclaimer {
	maxAge = 'MAX_AGE',
	noDeferredCompensation = 'NO_DEFERRED_COMPENSATION',
	changeNotPossibleYet = 'CHANGE_NOT_POSSIBLE_YET',
}
