import { DisclaimerMessage } from 'components/Disclaimer'
import { ViewHeader } from 'components/ViewHeader'
import useApi, { QueryKey } from 'hooks/useApi'
import { useQuery } from 'react-query'
import { useUserRecord } from 'hooks/useUserRecord'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'
import ResetPwAuth2UserModal from '../../components/ResetPwAResetuth2UserModal'
import { useUserPermission } from 'hooks/useUserPermission'

const PensionerPwReset: React.FC = () => {
	const { userId } = useUserRecord()
	const api = useApi()
	const [modalOpen, setModalOpen] = useState(false)
	const { canResetPassword } = useUserPermission()

	const { t } = useTranslation()

	const baseData = useQuery([QueryKey.pensionerBaseData, { userId }], api.getPensionerBaseData, {
		enabled: !!userId,
	})
	const authResponse = useQuery([QueryKey.checkUser, { userId }], api.checkUser, {
		enabled: !!userId,
	})
	const checkAuthChangeResponse = useQuery([QueryKey.getLastAuth2Change, { userId }], api.getLastAuthChange, {
		enabled: !!userId,
	})

	const refetchAllQueries = useCallback(() => {
		authResponse.refetch()
		checkAuthChangeResponse.refetch()
	}, [authResponse, checkAuthChangeResponse])

	const parseLastAuthDate = useMemo(() => {
		if (checkAuthChangeResponse?.data) {
			const checkAuthDate = new Date(checkAuthChangeResponse.data)
			return checkAuthDate.toLocaleDateString('de-DE', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
			})
		} else {
			return ''
		}
	}, [checkAuthChangeResponse?.data])

	const authV1Access = authResponse.data !== undefined
	const authAccess = checkAuthChangeResponse.data !== undefined && checkAuthChangeResponse.data !== 'user not found'
	const hoursFromLastReset =
		authAccess && checkAuthChangeResponse.data
			? Math.abs(new Date().getTime() - new Date(checkAuthChangeResponse.data).getTime()) / (1000 * 60 * 60)
			: undefined
	const lastResetWithinADay = !!(hoursFromLastReset && hoursFromLastReset <= 24)

	const wayOfParticipation = baseData.data?.pensionPlan.participationState.wayOfParticipation ?? ''
	const appAccess = ['digital', 'hybrid'].includes(wayOfParticipation)

	return (
		<div className={`pensioner-app visually-hidden visually-hidden--false`}>
			<ViewHeader headline={t('view.pensionerPwReset.headline')}>
				{'success' === baseData.status && !appAccess && (
					<DisclaimerMessage text={t('view.pensionerPwReset.noAppAccessDisclaimer')} />
				)}
				{'success' === baseData.status && appAccess && !authAccess && (
					<DisclaimerMessage text={t('view.pensionerPwReset.noAuthResponseDisclaimer')} />
				)}
			</ViewHeader>
			{'success' === baseData.status && appAccess && authAccess ? (
				<div>
					<table>
						<tbody>
							<tr>
								<td className={`initial-text`}>
									<p>{t('view.pensionerPwReset.accessAuthV1')}</p>
								</td>
								<td className={`second-col`}>
									<p>
										{authV1Access
											? t('view.pensionerPwReset.authV1Zugang')
											: t('view.pensionerPwReset.authV2Zugang')}
									</p>
								</td>
							</tr>
							<tr>
								<td className={`initial-text`}>
									<p>{t('view.pensionerPwReset.hasAccess')}</p>
								</td>
								<td className={`second-col`}>
									<p>{parseLastAuthDate}</p>
								</td>
							</tr>
						</tbody>
					</table>
					{lastResetWithinADay && (
						<DisclaimerMessage text={t('view.pensionerPwReset.resetWarnung')} className="attention-font" />
					)}
					<DisclaimerMessage text={t('view.pensionerPwReset.hinweisText01')} />
					<DisclaimerMessage text={t('view.pensionerPwReset.hinweisText02')} />
				</div>
			) : (
				<></>
			)}
			{canResetPassword && appAccess && authAccess ? (
				<Button
					type={ButtonType.primary}
					label={t('view.pensionerPwReset.pwrReset')}
					iconColor={'var(--color-black)'}
					onClick={() => {
						setModalOpen(!modalOpen)
					}}
				/>
			) : (
				<></>
			)}
			<ResetPwAuth2UserModal
				open={modalOpen}
				onModalClose={() => {
					setModalOpen(false)
				}}
				onSuccess={() => {
					setTimeout(() => {
						refetchAllQueries()
					}, 5000)
				}}
				userId={userId}
			/>
		</div>
	)
}

export default PensionerPwReset
