import { DisclaimerMessage } from 'components/Disclaimer'
import DocumentEntry from 'components/DocumentEntry'
import { ViewHeader } from 'components/ViewHeader'
import useApi, { QueryKey } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import { useState } from 'react';
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import SelectInput from 'shared/components/SelectInput'
import { t } from 'i18next';

const PensionerDocuments: React.FC = () => {
	const { userId } = useUserRecord()
	const api = useApi()

	const {data: documentTypes} = useQuery([QueryKey.documentTypes], api.getDocumentTypes)

	const defaultFilterType: string[] = ['ALL'];
	const filterTypes: string[] = documentTypes ? [...defaultFilterType, ...documentTypes?.map((type) => type.id as string), ] : defaultFilterType;

	const documentTypeOptions = [...filterTypes?.map((type) => ({
		value: type, label: t(`component.documentGroupsFilter.groups.${type}`) ?? t(`component.documentGroupsFilter.unknown`)
	}))]

	const {
		data,
		status,
		refetch: refetchDocuments,
	} = useQuery([QueryKey.pensionerDocuments, { userId }], api.getDocuments, {
		enabled: undefined !== userId,
	})

	const renderDocuments = () => {
		if ('success' !== status || (undefined !== data && true === [undefined, 0].includes(data?.length))) {
			return null
		}
		if (foundDokuments) {
			return (
				<>
					{foundDokuments &&
						status === 'success' &&
						foundDokuments.map((document, index) => {
							return (
								<DocumentEntry
									key={`document--${index}`}
									data={document}
									refetchDocuments={refetchDocuments}
								/>
							)
						})}
				</>
			)
		}
		return (
			<>
				{data &&
					status === 'success' &&
					data.map((document, index) => {
						return (
							<DocumentEntry
								key={`document--${index}`}
								data={document}
								refetchDocuments={refetchDocuments}
							/>
						)
					})}
			</>
		)
	}

	const documentList = data;

	const [foundDokuments, setFoundDokuments] = useState(documentList)

	const filterDocuments = (e: string) => {
		if(!documentList) {
			return
		}
		if(e === 'ALL') {
			setFoundDokuments(data)
			return
		}
		const filtered = documentList.filter((document) => filterGroup(document.type, e))
		setFoundDokuments(filtered)
	}

	const filterGroup = (document: string | undefined, filterType: string) => {
		if(document?.length === 0) {
			return true;
		}
		return getDocumentGroup(document!) === filterType;
	}

	const getDocumentGroup = (document: string) => {
		let group;
		documentTypes?.forEach((documentType) => {
			if(documentType.types.includes(document)) {
				group = documentType.id;
			}
		})
		return group ?? ''
	}

	return (
		<div className={`pensioner-documents visually-hidden visually-hidden--${String(status !== 'success')}`}>
			<ViewHeader headline={<Trans i18nKey="view.pensionerDocuments.headline" />}>
				{'success' === status && !data?.length && (
					<DisclaimerMessage text={<Trans i18nKey="view.pensionerDocuments.noDataDisclaimer" />} />
				)}
			</ViewHeader>
			{documentTypeOptions && (
				<SelectInput
						alternativeStyle={true}
						returnEvent={false}
						options={documentTypeOptions}
						label={t(`component.documentGroupsFilter.label`) + ':'}
						value={documentTypeOptions[0].label}
						onChange={filterDocuments}
					></SelectInput>
			)}
			{renderDocuments()}
		</div>
	)
}

export default PensionerDocuments
