import { IconType } from 'components/Icons'
import React, { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Accordion from 'shared/components/Accordion'
import Tooltip from 'shared/components/Tooltip'
import { CompensationStatus, ConfirmedCompensationType } from 'shared/enums'
import { currencyFormat } from 'shared/helper/numberFormats'
import Icon from './Icon'

export interface MonatlicheBeitraege {
	readonly arbeitgeberGrundbeitrag: number
	readonly eigenbeitrag: number
	readonly einzelBeitraege: ReadonlyArray<Einzelbeitrag>
	readonly jahr: number
	readonly matchingBeitrag: number
	readonly monat: number
	readonly status: 'BESTAETIGT' | 'OFFEN'
	readonly bemessungsgrundlage?: number
	readonly disclaimer?: 'ABGELEHNT' | 'ABWEICHEND' | 'NEGATIV' | 'WIE_ERWARTET'
}

export interface Einzelbeitrag {
	readonly wandlungsart: string
	readonly bestaetigteBeitraege?: BestaetigteBeitraege
	readonly wandlungsWunsch?: WandlungsWunsch
}

export interface BestaetigteBeitraege {
	readonly arbeitgeberGrundbeitrag: number
	readonly eigenbeitrag: number
	readonly matchingBeitrag: number
}

export interface WandlungsWunsch {
	readonly einheit: 'EURO' | 'PROZENT'
	readonly wert: number
}
export interface CompensationDataTableProps {
	data: MonatlicheBeitraege
	liesInThePast: boolean
}

const CompensationDataTable: FunctionComponent<CompensationDataTableProps> = (props) => {
	const { data } = props
	const { t } = useTranslation()

	const [accordionOpen, setAccordionOpen] = useState(false)
	const [accordionContentHeight, setAccordionContentHeight] = useState(0)

	const onAccordionClick = () => {
		setAccordionOpen(accordionOpen ? false : true)
	}

	const afterAccordionRender = (height: any) => {
		setAccordionContentHeight(height)
	}

	const getCSS = () => {
		return {
			'--accordion-content-height': `${accordionContentHeight.toString()}px`,
		} as React.CSSProperties
	}

	const renderOwnContribution = () => {
		return (
			<>
				<span className="text-color-cyan font-size-s compensation-data-table__currency">
					{currencyFormat(data.eigenbeitrag)}
				</span>
				<span className="text-color-cyan font-size-s compensation-data-table__currency-label">
					{t('component.compensationDataTable.ownContribution')}
					<Tooltip
						color={'var(--color-cyan)'}
						headline={t('component.compensationDataTable.ownContributionTooltip.headline')}
						content={t('component.compensationDataTable.ownContributionTooltip.content')}
					/>
				</span>
			</>
		)
	}

	const renderArbeitgeberGrundbeitrag = () => {
		return (
			<>
				<span className="font-size-s compensation-data-table__currency">
					{currencyFormat(data.arbeitgeberGrundbeitrag)}
				</span>

				<span className="font-size-s compensation-data-table__currency-label">
					{t('component.compensationDataTable.employerBaseAmount')}

					<Tooltip
						headline={t('component.compensationDataTable.employerBaseAmountTooltip.headline')}
						content={t('component.compensationDataTable.employerBaseAmountTooltip.content')}
					/>
				</span>
			</>
		)
	}

	const renderMatchingBeitrag = () => {
		return (
			<>
				<span className="font-size-s compensation-data-table__currency">
					{currencyFormat(data.matchingBeitrag)}
				</span>
				<span className="font-size-s compensation-data-table__currency-label">
					{t('component.compensationDataTable.matchingAmount')}

					<Tooltip
						headline={t('component.compensationDataTable.matchingAmountTooltip.headline')}
						content={t('component.compensationDataTable.matchingAmountTooltip.content')}
					/>
				</span>
			</>
		)
	}

	const renderSum = () => {
		const sum = (data?.eigenbeitrag || 0) + (data?.arbeitgeberGrundbeitrag || 0) + (data?.matchingBeitrag || 0)
		return (
			<>
				<div className="compensation-data-table__divider" />
				<b className="font-size-m compensation-data-table__currency">{currencyFormat(sum)}</b>
				<b className="font-size-s compensation-data-table__currency-label">
					{t('component.compensationDataTable.sum')}{' '}
				</b>
			</>
		)
	}

	const renderEinzelBeitraege = () => {
		return (
			<Accordion
				className="compensation-data-table__own-contribution"
				header={
					<>
						<span className="text-color-cyan font-size-s compensation-data-table__currency">
							{currencyFormat(data.eigenbeitrag)}
						</span>
						<span className="text-color-cyan font-size-s compensation-data-table__currency-label">
							{t('component.compensationDataTable.ownContribution')}
							<Tooltip
								color={'var(--color-cyan)'}
								headline={t('component.compensationDataTable.ownContributionTooltip.headline')}
								content={t('component.compensationDataTable.ownContributionTooltip.content')}
							/>
						</span>
					</>
				}
				open={accordionOpen}
				onOpen={() => {
					onAccordionClick()
				}}
				afterRender={(height: any) => {
					afterAccordionRender(height)
				}}
				iconColor="var(--color-cyan)"
			>
				{data.einzelBeitraege &&
					data.einzelBeitraege.filter((einzelBeitrag) => einzelBeitrag.wandlungsWunsch?.wert || einzelBeitrag.bestaetigteBeitraege?.eigenbeitrag)
						.map((einzelBeitrag, index) => {
						const bestaetigteBeitraege = einzelBeitrag.bestaetigteBeitraege
						const einheit = einzelBeitrag.wandlungsWunsch?.einheit
						const wandlungsWunschWert = einzelBeitrag.wandlungsWunsch?.wert
						const type = einzelBeitrag.wandlungsart
						return (
							<React.Fragment key={`bonus-${index}`}>
								<span className="text-color-cyan font-size-s compensation-data-table__currency">
									{bestaetigteBeitraege
										? currencyFormat(bestaetigteBeitraege.eigenbeitrag)
										: einheit === 'EURO'
										? currencyFormat(wandlungsWunschWert)
										: '-'}
								</span>

								<span className="text-color-cyan font-size-s compensation-data-table__currency-label">
									{t(`generic.confirmedCompensationTypes.${type}`)}
									{data.status === 'OFFEN' && einheit === 'PROZENT' && (
										<>
											<br />
											<span className="font-size-xs">
												{t(`component.compensationDataTable.percentageCompensation`)}
											</span>
										</>
									)}
								</span>
							</React.Fragment>
						)
					})}
			</Accordion>
		)
	}

	const renderDisclaimer = () => {
		const einzelBeitrag = data.einzelBeitraege.find(
			(einzelBeitrag) => einzelBeitrag.wandlungsart === ConfirmedCompensationType.MONTHLY
		)
		const einheit = einzelBeitrag?.wandlungsWunsch?.einheit
		let disclaimer = ''
		if (einheit === 'EURO') {
			disclaimer = t(`component.compensationDataTable.disclaimer.euro`)
		}
		if (einheit === 'PROZENT') {
			disclaimer = t(`component.compensationDataTable.disclaimer.percent`)
		}
		if (CompensationStatus.open === data.status && props.liesInThePast) {
			disclaimer = t('component.compensationDataTable.disclaimer.denied')
		}
		if (data.status === 'BESTAETIGT') {
			disclaimer = t(`component.compensationDataTable.disclaimer.confirmed`)
		}
		switch (data.disclaimer) {
			case 'WIE_ERWARTET':
				return null
			case 'ABGELEHNT':
				disclaimer = t('component.compensationDataTable.disclaimer.denied')
				break
			case 'ABWEICHEND':
				disclaimer = t('component.compensationDataTable.disclaimer.partial')
				break
			case 'NEGATIV':
				disclaimer = t('component.compensationDataTable.disclaimer.negative')
				break
		}
		if (disclaimer === '') return
		return (
			<p className="compensation-data-table__disclaimer flex flex--align-items-start">
				<Icon className="margin--horizontal no-margin--left" type={IconType.info} />
				{disclaimer}
			</p>
		)
	}

	return (
		<div className="compensation-data-table" style={getCSS()}>
			{undefined !== data.eigenbeitrag && renderOwnContribution()}

			{data.einzelBeitraege && data.einzelBeitraege.length > 0 && renderEinzelBeitraege()}

			{undefined !== data.arbeitgeberGrundbeitrag && renderArbeitgeberGrundbeitrag()}

			{undefined !== data.matchingBeitrag && renderMatchingBeitrag()}

			{renderSum()}

			{renderDisclaimer()}
		</div>
	)
}

export default CompensationDataTable
