import { FunctionComponent, HTMLAttributes } from 'react'

export interface GridListProps extends HTMLAttributes<HTMLDivElement> {
	columnCount?: number
}

const GridList: FunctionComponent<GridListProps> = (props) => {
	const getClasses = () => {
		const classes = ['grid-list']

		if (props.className) {
			classes.push(props.className)
		}
		return classes.join(' ')
	}

	const getColumnCount = (): React.CSSProperties | undefined => {
		if (!props.columnCount) {
			return
		}

		return { '--column-count': props.columnCount } as React.CSSProperties
	}

	return (
		<div style={getColumnCount()} className={`${getClasses()}`}>
			{props.children}
		</div>
	)
}

export default GridList
