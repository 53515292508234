import AustrittsbearbeitungPage from 'components/PageWithTable/AustrittsbearbeitungPage'
import useApi, { QueryKey } from 'hooks/useApi'
import { Trans } from 'react-i18next'

const Austrittsbearbeitungen = () => {
	const api = useApi()

	return (
		<AustrittsbearbeitungPage
			className="no-padding--bottom"
			headline={<Trans i18nKey="view.austrittsbearbeitung.headline" />}
			subheadline={<Trans i18nKey="view.austrittsbearbeitung.subtitle" />}
			type="leaving-info"
			queryKey={QueryKey.austrittsbearbeitungen}
			apiRequest={api.getAustrittsbearbeitungen}
		/>
	)
}

export default Austrittsbearbeitungen
