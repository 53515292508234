import GridList from 'components/GridList'
import GridListCell from 'components/GridListCell'
import GridListRow from 'components/GridListRow'
import { IDisablilityOption, IPartnerBenefitOption } from 'hooks/useApi'
import React, { FunctionComponent } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import dateFormat from 'shared/helper/dateFormat'

export interface RiskOptionTableProps {
  className?: string
  data: IDisablilityOption[] | IPartnerBenefitOption[] | undefined;
}

const RiskOptionTable: FunctionComponent<RiskOptionTableProps> = props => {
  const { t } = useTranslation()
  const { data } = props
  const tableHeaders = [
    'confirmedAt',
    'choice',
    'isPaperBased',
    'start',
    'end',
  ]

  const endDatumForCancellation = "9999-12-31"

  const renderTableHeader = () => {
    const headerCellClass = 'grid-list__cell--header'

    return (
      <GridListRow className={'grid-list__row--header'}>
        {tableHeaders.map((label, index) => (
          <GridListCell className={headerCellClass} key={`header-field-${index}`}>
            {t(`view.pensionerRiskOptions.tableHeaders.${label}`)}
          </GridListCell>
        ))}
      </GridListRow>
    )
  }

  const renderTableRows = () => {
    if (!data) {
      return
    }
    return (
      <>
        {data.map((entry, index: number) => (
          <GridListRow key={`entry-${index}`}>
            {tableHeaders.map((key, fieldIndex: number) => {
              let transformedValue

              let classes = ['user-select--all']
              switch (key) {
                case 'confirmedAt':
                case 'start':
                case 'end':
                  if (entry[key] !== undefined) {
                    transformedValue = dateFormat(new Date(Date.parse(entry[key]!)))
                  } else if(entry.storno){
                    transformedValue = dateFormat(new Date(endDatumForCancellation))
                  } 
                  else {
                    transformedValue = '-'
                  }
                  break
                case 'isPaperBased':
                  transformedValue = entry[key] ? 'Ja' : 'Nein'
                  break
                case 'choice':
                  if (entry.storno) {
                    transformedValue = <Trans i18nKey={`view.pensionerRiskOptions.${entry[key]}-storno`} />
                  }
                  else if (entry[key] === 'deselect' || 'increase' || 'standard') {
                    transformedValue = <Trans i18nKey={`view.pensionerRiskOptions.${entry[key]}`} />
                  } else {
                    transformedValue = entry[key]
                  }

                  break

              }

              const cellClasses = classes.join(" ")

              return (
                <GridListCell key={`entry-field-${index}-${fieldIndex}`} className={cellClasses}>
                  {transformedValue}
                </GridListCell>
              )
            })}
          </GridListRow>
        ))}
      </>
    )
  }


  return (
    <>
      {data?.length !== 0 && (
        <>

          <div
            className={`text-transform-uppercase text-color-gold margin--right 
						}`}
          >
            <Trans i18nKey="view.pensionerRiskOptions.history" />
          </div>
          <GridList className="delegate__results" columnCount={tableHeaders.length}>
            {renderTableHeader()}
            {renderTableRows()}
          </GridList>
        </>
      )}
    </>
  )
}

export default RiskOptionTable
