const customPropertyValue = (property: string, returnType?: 'string' | 'number'): string | number => {
	const customProperty = getComputedStyle(document.documentElement).getPropertyValue(property)

	switch (returnType) {
		case 'string':
			return String(customProperty)

		case 'number':
			return Number(customProperty)

		default:
			return customProperty
	}
}

export default customPropertyValue
