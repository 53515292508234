import useAsyncEffect from '@n1ru4l/use-async-effect'
import { DisclaimerMessage } from 'components/Disclaimer'
import { AustrittsbearbeitungFooter, IAustrittsbearbeitungFooter } from 'components/Footer/AustrittsbearbeitungFooter'
import { ColumnHeader, SortType } from 'components/SortableTable'
import AustrittsbearbeitungTable from 'components/Table/AustrittsbearbeitungTable'
import { ViewHeader } from 'components/ViewHeader'
import { AustrittsbearbeitungenRequestQueryParams, AustrittsbearbeitungenResponse, IPageData } from 'hooks/useApi'
import { ChangeEvent, FunctionComponent, HTMLAttributes, useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import delay from 'shared/helper/delay'
import { getSessionStorage, removeSessionStorage, setSessionStorage } from 'shared/helper/sessionStorage'
import { components } from 'types/api-interface'

type QueryStringParameters = { values: string[]; type: string }

const parseQueryStringParamsFromDateInput = (dateInput: string) => {
	const date = dateInput.split('.').filter(Boolean).reverse()
	const params = date.join('-')
	const values = params === '' ? [] : [params]
	return values
}
export const AustrittsbearbeitungPage: FunctionComponent<IPageData & HTMLAttributes<HTMLDivElement>> = ({
	type,
	queryKey,
	apiRequest,
	headline,
	subheadline,
	className,
	requestData,
}) => {
	const pageSize = 50

	const [queryParameters, setQueryParameters] = useState<AustrittsbearbeitungenRequestQueryParams>({
		fallart: getSessionStorage(`austrittsbearbeitung-${type}-fallart`),
		identity: getSessionStorage(`austrittsbearbeitung-${type}-identity`),
		hrSystem: getSessionStorage(`austrittsbearbeitung-${type}-hrSystem`),
		personalNummer: getSessionStorage(`austrittsbearbeitung-${type}-personalNummer`),
		geburtstag: getSessionStorage(`austrittsbearbeitung-${type}-geburtstag`),
		austrittsdatum: getSessionStorage(`austrittsbearbeitung-${type}-austrittsdatum`),
		austrittsgrund: getSessionStorage(`austrittsbearbeitung-${type}-austrittsgrund`),
		versorgungsordnung: getSessionStorage(`austrittsbearbeitung-${type}-versorgungsordnung`),
		status: getSessionStorage(`austrittsbearbeitung-${type}-status`),
		bearbeitendePerson: getSessionStorage(`austrittsbearbeitung-${type}-bearbeitendePerson`),
		versorgungsguthaben: getSessionStorage(`austrittsbearbeitung-${type}-versorgungsguthaben`),
		lastChangesAt: getSessionStorage(`austrittsbearbeitung-${type}-lastChangesAt`),
		'fallart-sort': getSessionStorage(`austrittsbearbeitung-${type}-fallart-sort`),
		'identity-sort': getSessionStorage(`austrittsbearbeitung-${type}-identity-sort`),
		'hrSystem-sort': getSessionStorage(`austrittsbearbeitung-${type}-hrSystem-sort`),
		'personalNummer-sort': getSessionStorage(`austrittsbearbeitung-${type}-personalNummer-sort`),
		'geburtstag-sort': getSessionStorage(`austrittsbearbeitung-${type}-geburtstag-sort`),
		'austrittsdatum-sort': getSessionStorage(`austrittsbearbeitung-${type}-austrittsdatum-sort`),
		'austrittsgrund-sort': getSessionStorage(`austrittsbearbeitung-${type}-austrittsgrund-sort`),
		'versorgungsordnung-sort': getSessionStorage(`austrittsbearbeitung-${type}-versorgungsordnung-sort`),
		'versorgungsguthaben-sort': getSessionStorage(`austrittsbearbeitung-${type}-versorgungsguthaben-sort`),
		'bearbeitende-sort': getSessionStorage(`austrittsbearbeitung-${type}-bearbeitende-sort`),
		'status-sort': getSessionStorage(`austrittsbearbeitung-${type}-status-sort`),
		'lastChangesAt-sort': getSessionStorage(`austrittsbearbeitung-${type}-lastChangesAt-sort`),
		offset: 0,
		pageSize,
	})

	useEffect(() => {
		if (!queryParameters) {
			return
		}
		Object.entries(queryParameters).map(([key, value]) => {
			if (undefined === value) {
				return removeSessionStorage(`austrittsbearbeitung-${type}-${key}`)
			}
			return setSessionStorage(`austrittsbearbeitung-${type}-${key}`, value)
		})
	}, [queryParameters, type])

	const { data, status } = useQuery<AustrittsbearbeitungenResponse>(
		[queryKey, queryParameters, requestData],
		apiRequest,
		{
			cacheTime: 0,
			staleTime: 0,
			refetchInterval: 10000,
		}
	)
	const [disableFirstPage, setDisableFirstPage] = useState<boolean>(true)
	const [disableLastPage, setDisableLastPage] = useState<boolean>(false)
	useEffect(() => {
		if (queryParameters?.offset === undefined || !data) {
			return
		}
		if (data.total < pageSize) {
			setDisableFirstPage(true)
			setDisableLastPage(true)
			return
		}
		if (queryParameters.offset + pageSize >= data.total) {
			setDisableLastPage(true)
			if (queryParameters.offset >= pageSize) {
				setDisableFirstPage(false)
			}
			return
		}
		if (queryParameters.offset < pageSize) {
			setDisableFirstPage(true)
			if (queryParameters.offset < data.total) {
				setDisableLastPage(false)
			}
			return
		}
		setDisableFirstPage(false)
		setDisableLastPage(false)
	}, [queryParameters, data])
	const handleOnNavigateBackwards = () => {
		const offset = queryParameters?.offset ? queryParameters.offset - pageSize : 0
		setQueryParameters((currentQueryParameters) => ({
			...currentQueryParameters,
			offset,
			pageSize,
		}))
	}

	const handleOnNavigateForwards = () => {
		const offset = queryParameters?.offset ? queryParameters.offset + pageSize : pageSize
		setQueryParameters((currentQueryParameters) => ({
			...currentQueryParameters,
			offset,
			pageSize,
		}))
	}
	const austrittsbearbeitungen = useMemo(
		() => data?.data,
		[data]
	) as components['schemas']['AustrittsbearbeitungSearchDocument'][]

	const [queryStringParameters, setQueryStringParameters] = useState<QueryStringParameters>()

	const handleOnFallartChange: IAustrittsbearbeitungFooter['onFallartChange'] = (selectedType) => {
		if (selectedType === '') {
			setQueryParameters({ ...queryParameters, fallart: undefined, offset: 0 })
			return
		}
		setQueryParameters((currentQueryParameters) => ({
			...currentQueryParameters,
			fallart: selectedType,
			offset: 0,
		}))
	}
	const handleOnVersorgungsempfaengerChange = (e: ChangeEvent<HTMLInputElement>) => {
		const values = e.target.value.split(' ').filter(Boolean)
		setQueryStringParameters({ values, type: 'identity' })
	}
	const handleOnHRSystemChange: IAustrittsbearbeitungFooter['onHRSysChange'] = (selectedType) => {
		if (selectedType === '') {
			setQueryParameters({ ...queryParameters, hrSystem: undefined, offset: 0 })
			return
		}
		setQueryParameters((currentQueryParameters) => ({
			...currentQueryParameters,
			hrSystem: selectedType,
			offset: 0,
		}))
	}
	const handleOnPersonalNrChange = (e: ChangeEvent<HTMLInputElement>) => {
		const values = e.target.value.split(' ').filter(Boolean)
		setQueryStringParameters({ values, type: 'personalNummer' })
	}
	const handleOnGeburtstagChange: IAustrittsbearbeitungFooter['onGeburtsdatumChange'] = (
		e: ChangeEvent<HTMLInputElement>
	) => {
		const values = parseQueryStringParamsFromDateInput(e.target.value)
		setQueryStringParameters({ values, type: 'geburtsdatum' })
	}
	const handleOnAustrittsdatumChange: IAustrittsbearbeitungFooter['onAustrittsdatumChange'] = (
		e: ChangeEvent<HTMLInputElement>
	) => {
		const values = parseQueryStringParamsFromDateInput(e.target.value)
		setQueryStringParameters({ values, type: 'austrittsdatum' })
	}
	const handleOnAustrittsgrundChange: IAustrittsbearbeitungFooter['onAustrittsgrundChange'] = (
		e: ChangeEvent<HTMLInputElement>
	) => {
		const values = e.target.value.split(' ').filter(Boolean)
		setQueryStringParameters({ values, type: 'austrittsgrund' })
	}
	const handleOnVOChange: IAustrittsbearbeitungFooter['onVoChange'] = (selectedType) => {
		if (selectedType === '') {
			setQueryParameters({ ...queryParameters, versorgungsordnung: undefined, offset: 0 })
			return
		}
		setQueryParameters((currentQueryParameters) => ({
			...currentQueryParameters,
			versorgungsordnung: selectedType || undefined,
			offset: 0,
		}))
	}
	const handleOnGuthabenChange: IAustrittsbearbeitungFooter['onGuthabenChange'] = (selectedType) => {
		if (selectedType === '') {
			setQueryParameters({ ...queryParameters, versorgungsguthaben: undefined })
			return
		}
		const versorgungsguthaben = selectedType === 'ja' ? true : selectedType === 'nein' ? false : undefined
		setQueryParameters((currentQueryParameters) => ({
			...currentQueryParameters,
			versorgungsguthaben,
		}))
	}

	const handleOnStatusChange: IAustrittsbearbeitungFooter['onStatusChange'] = (selectedType) => {
		if (selectedType === '') {
			setQueryParameters({ ...queryParameters, status: undefined, offset: 0 })
			return
		}
		setQueryParameters((currentQueryParameters) => ({
			...currentQueryParameters,
			status: selectedType,
			offset: 0,
		}))
	}

	const handleOnBearbeitendeChange: IAustrittsbearbeitungFooter['onBearbeitendeChange'] = (selectedType) => {
		if (selectedType === '') {
			setQueryParameters({ ...queryParameters, bearbeitendePerson: undefined, offset: 0 })
			return
		}
		setQueryParameters((currentQueryParameters) => ({
			...currentQueryParameters,
			bearbeitendePerson: selectedType,
			offset: 0,
		}))
	}

	const handleOnZuletztGeaendertChange: IAustrittsbearbeitungFooter['onZuletztGeaendertChange'] = (
		e: ChangeEvent<HTMLInputElement>
	) => {
		const values = parseQueryStringParamsFromDateInput(e.target.value)
		setQueryStringParameters({ values, type: 'zuletztGeaendert' })
	}

	useAsyncEffect(
		function* (setErrorHandler: any, c: any) {
			yield* c(delay(500))
			switch (queryStringParameters?.type) {
				case 'personalNummer':
					yield* c(
						setQueryParameters((currentQueryParameters) => ({
							...currentQueryParameters,
							personalNummer: queryStringParameters.values[0],
							offset: 0,
						}))
					)
					break
				case 'austrittsgrund':
					yield* c(
						setQueryParameters((currentQueryParameters) => ({
							...currentQueryParameters,
							austrittsgrund: queryStringParameters.values[0],
							offset: 0,
						}))
					)
					break
				case 'identity':
					yield* c(
						setQueryParameters((currentQueryParameters) => ({
							...currentQueryParameters,
							identity: queryStringParameters.values[0],
							offset: 0,
						}))
					)
					break
				case 'geburtsdatum':
					yield* c(
						setQueryParameters((currentQueryParameters) => ({
							...currentQueryParameters,
							geburtstag: queryStringParameters.values[0],
							offset: 0,
						}))
					)
					break
				case 'austrittsdatum':
					yield* c(
						setQueryParameters((currentQueryParameters) => ({
							...currentQueryParameters,
							austrittsdatum: queryStringParameters.values[0],
							offset: 0,
						}))
					)
					break
				case 'zuletztGeaendert':
					yield* c(
						setQueryParameters((currentQueryParameters) => ({
							...currentQueryParameters,
							lastChangesAt: queryStringParameters.values[0],
							offset: 0,
						}))
					)
					break
			}
		},
		[queryStringParameters]
	)

	const handleOnSortTable = (accessor: ColumnHeader['accessor'], sortType: SortType) => {
		const queryParamKey = accessor + '-sort'
		setQueryParameters((currentQueryParameters) => ({
			...currentQueryParameters,
			'fallart-sort': undefined,
			'identity-sort': undefined,
			'hrSystem-sort': undefined,
			'personalNummer-sort': undefined,
			'geburtstag-sort': undefined,
			'austrittsdatum-sort': undefined,
			'austrittsgrund-sort': undefined,
			'versorgungsordnung-sort': undefined,
			'versorgungsguthaben-sort': undefined,
			'status-sort': undefined,
			'bearbeitende-sort': undefined,
			'lastChangesAt-sort': undefined,
			[queryParamKey]: sortType,
		}))
	}

	const getClasses = () => {
		const classes = ['inbox']
		if (className) {
			classes.push(className)
		}
		return classes.join(' ')
	}

	return (
		<div className={getClasses()}>
			<ViewHeader headline={headline} subheadline={subheadline}>
				{'success' === status && !austrittsbearbeitungen?.length && queryParameters && (
					<DisclaimerMessage
						text={
							<Trans
								i18nKey={
									queryParameters && 0 === Object.values(queryParameters).filter(Boolean).length
										? 'component.inbox.noDataDisclaimer'
										: 'component.austrittsbearbeitungTable.noFilterMatches'
								}
							/>
						}
					/>
				)}
			</ViewHeader>

			{'success' === status && !austrittsbearbeitungen?.length ? (
				<div />
			) : (
				<AustrittsbearbeitungTable tableData={austrittsbearbeitungen} onSort={handleOnSortTable} />
			)}
			{
				<AustrittsbearbeitungFooter
					type={type}
					onFallartChange={handleOnFallartChange}
					onVersorgungsempfaengerChange={handleOnVersorgungsempfaengerChange}
					onHRSysChange={handleOnHRSystemChange}
					onPersonalNrChange={handleOnPersonalNrChange}
					onGeburtsdatumChange={handleOnGeburtstagChange}
					onAustrittsdatumChange={handleOnAustrittsdatumChange}
					onAustrittsgrundChange={handleOnAustrittsgrundChange}
					onVoChange={handleOnVOChange}
					onGuthabenChange={handleOnGuthabenChange}
					onStatusChange={handleOnStatusChange}
					onZuletztGeaendertChange={handleOnZuletztGeaendertChange}
					onBearbeitendeChange={handleOnBearbeitendeChange}
					onNavigateBackwards={handleOnNavigateBackwards}
					onNavigateForwards={handleOnNavigateForwards}
					firstPage={disableFirstPage}
					lastPage={disableLastPage}
				/>
			}
		</div>
	)
}

export default AustrittsbearbeitungPage
