/**
 * Returns the type of the provided data and is aware of type array
 * @param {Object} data Object to check
 */
const getTypeOf = (data: any) => {
	if (Array.isArray(data)) {
		return 'array'
	} else {
		return typeof data
	}
}

export default getTypeOf
