import useApi, { QueryKey } from 'hooks/useApi'
import React, { FunctionComponent, useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

interface PensionerDetailsProps {
	setIdentNumberFoundStatus(identNumberFoundStatus: boolean): void
	setMandateIsValid(value: boolean): void
	id?: string
	className?: string
}

const PensionerDetails: FunctionComponent<PensionerDetailsProps> = (props) => {
	const api = useApi()

	const { data, status } = useQuery(
		[QueryKey.pensionerProfilePreview, { userId: props.id }],
		api.getPensionerProfilePreview,
		{
			enabled: !!props.id,
		}
	)

	useEffect(() => {
		if (data) {
			props.setIdentNumberFoundStatus(data.found)
			props.setMandateIsValid(!data.endOfMandate)
		}
	}, [data, props])

	const getClassNames = (): string => {
		const classes: string[] = ['pensioner-letter-data']

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}

	return (
		<>
			{'success' === status && (
				<div className={getClassNames()}>
					{true === data?.found ? (
						<>
							<Trans i18nKey="component.pensionerDetails.name" />: {data.firstName} {data.lastName}
						</>
					) : (
						<Trans i18nKey="component.pensionerDetails.noEntryFound" />
					)}
				</div>
			)}
		</>
	)
}

export default PensionerDetails
