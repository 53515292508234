import classNames from 'classnames'
import { IconType } from 'components/Icons'
import { DefaultTFuncReturn } from 'i18next'
import React, {
	ChangeEvent,
	forwardRef,
	HTMLAttributes,
	lazy,
	ReactElement,
	Suspense,
	useEffect,
	useRef,
	useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { mergeRefs } from 'react-merge-refs'
import { FeatureProps } from 'react-phone-number-input'
import { dateWithinXDays } from 'shared/helper/dateDistance'
import { sanitizeDecimalNumber } from 'shared/helper/sanitizeMaskedValue'
import { TFieldError } from 'shared/hooks/useForm'
import convertToDashCase from '../helper/convertToDashCase'
import { useUniqueId } from '../hooks/useUniqueInputId'
import Button, { ButtonType } from './Button'
import Icon, { IconSize } from './Icon'
import { isEmBuAuszahlungenBefristetBisValueValid } from 'views/Pensioner/PensionerLeavingInformation'
import { IMaskInput } from 'react-imask'

export enum TextInputType {
	text = 'text',
	email = 'email',
	password = 'password',
	tel = 'tel',
	number = 'number',
	hidden = 'hidden',
	date = 'date',
	maskedCurrency = 'maskedCurrency',
}

export enum ValidityStateType {
	valueMissing = 'valueMissing',
	typeMismatch = 'typeMismatch',
	patternMismatch = 'patternMismatch',
	tooLong = 'tooLong',
	tooShort = 'tooShort',
	rangeUnderflow = 'rangeUnderflow',
	rangeOverflow = 'rangeOverflow',
	stepMismatch = 'stepMismatch',
	badInput = 'badInput',
	customError = 'customError',
}

export interface TextInputProps extends Omit<HTMLAttributes<HTMLInputElement>, 'placeholder' | 'errorMessage'> {
	type?: TextInputType
	name?: string
	value?: string | number | string[]
	label?: string | React.ReactElement | DefaultTFuncReturn
	required?: boolean
	showRequiredAsterisk?: boolean
	placeholder?: string | DefaultTFuncReturn
	className?: string
	errorMessage?: string | DefaultTFuncReturn
	error?: { type: string; message?: string | DefaultTFuncReturn }
	validityHints?: { [key in ValidityStateType]?: TFieldError<'hint'> }
	hidePasswordLabel?: string | DefaultTFuncReturn
	showPasswordLabel?: string | DefaultTFuncReturn
	autoComplete?: string
	highlight?: boolean
	disabled?: boolean
	readOnly?: boolean
	disableDelete?: boolean
	maxValue?: number
	minValue?: number
	maxLength?: number
	minLength?: number
	pattern?: string
	ref?: any
	icon?: IconType
	iconRotate?: number
	iconColor?: string
	iconSize?: IconSize
	valueFunction?: {
		name: (value: any, parameters?: any) => void
		parameters?: any
	}
	renderAsText?: boolean
	usedInForm?: boolean
	hidden?: boolean
	prepend?: React.ReactElement
	append?: React.ReactElement
	tagPrefix?: React.ReactElement
	tagSuffix?: React.ReactElement
	usePhoneInput?: boolean
	phoneInputProps?: FeatureProps<unknown>
}
type TDateError = 'none' | 'hint' | 'warning'

const PhoneInput = lazy(() => import('react-phone-number-input'))

const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
	const { t, i18n } = useTranslation()
	const inputRef = useRef<HTMLInputElement | null>(null)
	const [dateError, setDateError] = useState<TDateError>()
	const id = useUniqueId(props.type)
	const [phoneSelectLabels, setPhoneSelectLabels] = useState<Record<string, string>>()

	useEffect(() => {
		const loadLabels = async () => {
			const locale = i18n.language.slice(0, 2)

			try {
				const labels = await import(`./phone-number-input/locale/${locale}.json`)
				setPhoneSelectLabels(labels.default)
			} catch (error) {}
		}

		if (props.type === TextInputType.tel && props.usePhoneInput === true) {
			loadLabels()
		}
	}, [i18n.language, props.type, props.usePhoneInput])

	const initalAttributes = () => {
		const filteredAttributes: any = { ...props }
		const propsToRemove = [
			'value',
			'className',
			'label',
			'highlight',
			'disableDelete',
			'errorMessage',
			'hidePasswordLabel',
			'showPasswordLabel',
			'error',
			'minValue',
			'maxValue',
			'icon',
			'iconRotate',
			'iconColor',
			'iconSize',
			'valueFunction',
			'renderAsText',
			'onChange',
			'validityHints',
			'usedInForm',
			'hidden',
			'showRequiredAsterisk',
			'prepend',
			'append',
			'tagPrefix',
			'tagSuffix',
			'usePhoneInput',
			'phoneInputProps',
		]

		for (const prop of Object.keys(filteredAttributes)) {
			if (propsToRemove.includes(prop)) {
				delete filteredAttributes[prop]
			}
		}

		if (TextInputType.number === props.type) {
			filteredAttributes.type = TextInputType.text
			filteredAttributes.inputMode = 'decimal'
			filteredAttributes.pattern = props.pattern || '[0-9,.]*'
		}

		if (undefined === props.autoComplete) {
			filteredAttributes.autoComplete = 'off'
		}

		return filteredAttributes
	}

	const [attributes, setAttributes] = useState({
		...initalAttributes(),
		value: undefined,
		className: undefined,
		label: undefined,
		highlight: undefined,
		id: props.label ? id : undefined,
		autoCapitalize: 'none',
		autoCorrect: 'off',
	})

	useEffect(() => {
		const updatedAttributes = { ...attributes }

		switch (props.type) {
			case TextInputType.number:
				updatedAttributes.type = TextInputType.text
				updatedAttributes.inputMode = 'decimal'
				updatedAttributes.pattern = props.pattern || '[0-9,.]*'
				break

			case TextInputType.date:
				updatedAttributes.type = props.type
				updatedAttributes.max = '9999-12-31'
				break

			default:
				updatedAttributes.type = props.type
				break
		}

		setAttributes(updatedAttributes)
		if (props.name === 'locationAndDate' || props.name === 'IncomingDate') {
			setDateError(checkDate(String(props.value)))
		}
		if (props.name === 'emBuAuszahlungenBefristetBis') {
			setDateError(checkDateBefristet(String(props.value)))
		}
		// eslint-disable-next-line
	}, [props.type])

	const handleOnChange = (e: any) => {
		if (props.maxValue && sanitizeDecimalNumber(e.target.value) > props.maxValue) {
			e.target.value = props.maxValue
		}

		if (props.minValue && sanitizeDecimalNumber(e.target.value) < props.minValue) {
			e.target.value = props.minValue
		}

		if (props.onChange) {
			props.onChange(e)
		}

		if (props.name === 'locationAndDate' || props.name === 'IncomingDate') {
			setDateError(checkDate(e.target.value))
		}
		if (props.name === 'emBuAuszahlungenBefristetBis') {
			setDateError(checkDateBefristet(String(e.target.value)))
		}
	}

	const checkDate = (dateString: string): TDateError => {
		const delta = dateWithinXDays(dateString, 100)
		if (delta) {
			return 'none'
		}
		if (delta === undefined) {
			return 'warning'
		}
		return 'hint'
	}

	const checkDateBefristet = (dateString: string): TDateError => {
		const delta = isEmBuAuszahlungenBefristetBisValueValid(dateString)
		if (delta || dateString === '') {
			return 'none'
		}
		return 'warning'
	}

	const label = (): ReactElement | undefined => {
		if (!props.label) {
			return
		}

		return (
			<label className="input__label bold-small-heading" htmlFor={id}>
				{props.label}
				{props.showRequiredAsterisk && <>{props.required ? ' *' : ` ${t('generic.optionalFormField')}`}</>}
			</label>
		)
	}

	const showDelete = (): ReactElement | undefined => {
		if (props.type === TextInputType.password || true === props.disableDelete) {
			return
		}

		return (
			<div
				className="input__delete"
				onClick={() => {
					if (null !== inputRef.current) {
						inputRef.current.value = ''
						props.onChange && props.onChange({ target: inputRef.current } as ChangeEvent<HTMLInputElement>)
					}
				}}
			/>
		)
	}

	const showPassword = (): ReactElement | undefined => {
		if (props.type !== TextInputType.password) {
			return
		}

		return (
			<Button
				tabIndex={-1}
				className="input__show-password"
				type={[ButtonType.small, ButtonType.text]}
				onClick={() => {
					setAttributes({
						...attributes,
						type: TextInputType.password === attributes.type ? TextInputType.text : TextInputType.password,
					})
				}}
				label={
					TextInputType.password === attributes.type
						? props.showPasswordLabel || <Icon type={IconType.eyeCrossed} />
						: props.hidePasswordLabel || <Icon type={IconType.eye} />
				}
			/>
		)
	}

	const showError = (): ReactElement | undefined => {
		return (
			<div
				style={{ opacity: Number(undefined !== props.error) }}
				className={`input__error ${props.error ? `input__error--${props.error.type}` : ''}`}
			>
				{props.error?.message}
			</div>
		)
	}

	const showTextRepresentation = (): ReactElement | undefined => {
		if (true !== props.renderAsText) {
			return
		}

		return (
			<div className="input__text-representation">
				{props.valueFunction
					? String(props.valueFunction.name(props.value, props.valueFunction.parameters)).trim()
					: String(props.value).trim()}
			</div>
		)
	}

	const getClasses = (): string[] => {
		const classes = []

		if (props.name) {
			classes.push(`input--${convertToDashCase(props.name)}`)
		}

		if (props.className) {
			classes.push(props.className)
		}

		if (TextInputType.hidden === props.type || props.hidden) {
			classes.push('input--hidden')
		}

		if (props.highlight) {
			classes.push('input--highlighted')

			if (props.error && 'hint' === props.error.type) {
				classes.push('input--highlighted-hint')
			}
		}

		if (props.disabled) {
			classes.push('input--disabled')
		}

		if (props.readOnly) {
			classes.push('input--read-only')
		}

		if (props.value) {
			classes.push('input--dirty')
		}

		if (props.renderAsText) {
			classes.push('input--render-as-text')
		}

		return classes
	}

	const inputTag = () => (
		<input
			{...attributes}
			defaultValue={
				true !== props.usedInForm
					? props.valueFunction
						? props.valueFunction.name(props.value, props.valueFunction.parameters)
						: props.value
					: undefined
			}
			value={
				true === props.usedInForm
					? props.valueFunction
						? props.valueFunction.name(props.value, props.valueFunction.parameters)
						: props.value
					: undefined
			}
			onChange={handleOnChange}
			disabled={props.disabled}
			readOnly={props.readOnly}
			required={props.required}
			className={classNames([
				'input__tag',
				{
					[`input__tag--date-error-${dateError}`]: dateError,
				},
			])}
			ref={mergeRefs([inputRef, ref])}
			placeholder={props.placeholder}
			data-type={props.type}
			data-error-message={props.errorMessage}
		/>
	)

	const renderInputTag = () => {
		switch (props.type) {
			case TextInputType.tel:
				if (props.usePhoneInput === true) {
					return (
						<Suspense fallback={inputTag()}>
							<PhoneInput
								labels={phoneSelectLabels}
								{...attributes}
								{...props.phoneInputProps}
								defaultValue={
									true !== props.usedInForm
										? props.valueFunction
											? props.valueFunction.name(props.value, props.valueFunction.parameters)
											: props.value
										: undefined
								}
								value={
									true === props.usedInForm
										? props.valueFunction
											? props.valueFunction.name(props.value, props.valueFunction.parameters)
											: props.value
										: undefined
								}
								onChange={(value: string) => {
									const event = {
										target: {
											name: attributes.name,
											value,
										},
									} as unknown as ChangeEvent<HTMLInputElement>

									handleOnChange(event)
								}}
								disabled={props.disabled}
								readOnly={props.readOnly}
								required={props.required}
								ref={mergeRefs([inputRef, ref])}
								placeholder={props.placeholder}
								data-type={props.type}
								data-error-message={props.errorMessage}
								className="fragment"
								numberInputProps={{
									className: `input__tag input__tag--phone`,
								}}
								countrySelectProps={{
									arrowComponent: () => <Icon type={IconType.arrow} />,
								}}
							/>
						</Suspense>
					)
				}
				break
			case TextInputType.maskedCurrency:
				return (
					<IMaskInput
						mask={'num €'}
						blocks={{
							num: {
								mask: Number,
								scale: 2,
								thousandsSeparator: '.',
								radix: ',',
								mapToRadix: [','],
								padFractionalZeros: true,
							},
						}}
						{...attributes}
						defaultValue={
							true !== props.usedInForm
								? props.valueFunction
									? props.valueFunction.name(props.value, props.valueFunction.parameters)
									: props.value
								: undefined
						}
						value={
							true === props.usedInForm
								? props.valueFunction
									? props.valueFunction.name(props.value, props.valueFunction.parameters)
									: props.value
								: undefined
						}
						onAccept={(value: number) => {
							const event = {
								target: {
									name: attributes.name,
									value,
								},
							} as unknown as ChangeEvent<HTMLInputElement>

							handleOnChange(event)
						}}
						disabled={props.disabled}
						readOnly={props.readOnly}
						required={props.required}
						className={classNames(['input__tag'])}
						inputRef={mergeRefs([inputRef, ref])}
						placeholder={props.placeholder}
						data-type={props.type}
						data-error-message={props.errorMessage}
					/>
				)
			default:
				return inputTag()
		}
	}

	return (
		<div
			className={['input', 'input--text']
				.concat(getClasses())
				.filter((item) => undefined !== item)
				.join(' ')}
		>
			{props.prepend && <div className="input__prepend">{props.prepend}</div>}

			{label()}
			{props.icon && (
				<Icon
					className="input__icon"
					type={props.icon}
					rotate={props.iconRotate}
					color={props.iconColor}
					size={props.iconSize}
				/>
			)}
			{showTextRepresentation()}

			{props.tagPrefix && <span className="input__prefix">{props.tagPrefix}</span>}

			{renderInputTag()}

			{props.tagSuffix && <span className="input__suffix">{props.tagSuffix}</span>}

			{showDelete()}
			{showPassword()}
			{showError()}

			{props.append && <div className="input__append">{props.append}</div>}
		</div>
	)
})

TextInput.defaultProps = {
	type: TextInputType.text,
}

export default TextInput
