import inboxIllustration from 'assets/images/illu-inbox.svg'
import { IconType } from 'components/Icons'
import MagicLetterConfirmButtons from 'components/MagicLetter/MagicLetterConfirmButtons'
import PensionerDetails from 'components/PensionerDetails'
import TicketCloseWithoutAction from 'components/TicketCloseWithoutAction'
import TicketDelete from 'components/TicketDelete'
import TicketOptions from 'components/TicketOptions'
import useApi, { QueryKey, Ticket, TicketId, TicketModelId } from 'hooks/useApi'
import {
	DOCUMENT_TYPE_FIELD_NAME,
	IDENT_NUMBER_FIELD_NAME,
	RECEIVED_AT_FIELD_NAME,
	useMagicLetter,
} from 'hooks/useMagicLetter'
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { useLatest } from 'react-use'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormFields, FormRefActions } from 'shared/components/Form'
import Icon from 'shared/components/Icon'
import SwitchSelect, { SwitchSelectOption } from 'shared/components/SwitchSelect'
import useGlobalModalState from 'shared/hooks/useGlobalModalState'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import parseDate from '../../shared/helper/parseDate'
import { AnalyzedMagicField, isMagicFieldGroup } from './magic-letter-metadata.model'
import { useUserPermission } from 'hooks/useUserPermission'

const followupRoutes = ['globalInbox', 'openTicket', 'reviewTicket'] as const

type TFollowupRoute = (typeof followupRoutes)[number]

interface IFollowupRouteProperties {
	icon: IconType
	ticketStatus?: Ticket['statusInfo']['status']
}

const followupRouteProperties: { [key in TFollowupRoute]: IFollowupRouteProperties } = {
	globalInbox: {
		icon: IconType.inbox,
	},
	openTicket: {
		icon: IconType.ticketNext,
		ticketStatus: 'open',
	},
	reviewTicket: {
		icon: IconType.ticketApprove,
		ticketStatus: 'inReview',
	},
}

export interface MagicLetterFormProps {
	data: Ticket
	ticketModelIds: TicketModelId[]
	formLocked: boolean
	ticketLocked: boolean
	onDocumentTypeChange: (ticketModelId: TicketModelId) => void
}

const MagicLetterForm: FunctionComponent<MagicLetterFormProps> = ({
	data,
	ticketModelIds,
	formLocked,
	ticketLocked,
	onDocumentTypeChange,
}) => {
	const { t } = useTranslation()
	const [, modalActions] = useGlobalModalState()
	const { getMainPath, navigateTo } = useRouteHelper()

	const { formFields, formFieldConditions } = useMagicLetter(data, ticketModelIds)
	const formRef = useRef<FormRefActions>(null)
	const [followupRoute, setFollowupRoute] = useState<TFollowupRoute>(
		(sessionStorage.getItem('ticket-followup-route') as TFollowupRoute) ?? 'globalInbox'
	)
	const latestFollowupRoute = useLatest(followupRoute)

	const api = useApi()
	const nextTicketId = useRef<TicketId | undefined>()
	const queryClient = useQueryClient()
	const ticketUpdated = useRef<boolean>(false)
	const documentTypeUpdated = useRef<boolean>(false)
	const { data: documentTypes } = useQuery([QueryKey.documentTypes], api.getDocumentTypes)
	const { canDeleteMagicLetterTickets } = useUserPermission()

	// lock ticket for other users
	useQuery([QueryKey.lockTicket, { ticketId: data.id }], api.lockTicket, {
		enabled: ticketLocked,
		staleTime: 0,
		cacheTime: 0,
		refetchInterval: 60000,
		refetchIntervalInBackground: true,
		notifyOnChangeProps: [],
	})

	const handleFieldConditions = (fields: FormFields): FormFields => {
		Object.entries(formFieldConditions).map(([fieldName, fieldConditions]) => {
			const fieldAttributes: { [key: string]: boolean } = {}

			/**
			 * a field can have multiple conditions if it is deepy nested inside other fields.
			 * every condition must match for an attribute. if one condition does not match,
			 * the other conditions for this attribute do not matter anymore.
			 *
			 * check each condition and set the attribute accordingly.
			 * if the current attribute already missed a condition, do no update the attribute anymore
			 */

			fieldConditions.forEach((fieldCondition) => {
				Object.entries(fieldCondition).forEach(([attributeName, conditionProperties]) => {
					const { conditionField, conditionValue, conditionResult } = conditionProperties

					const conditionMatches = fields[conditionField].value === conditionValue

					if (attributeName in fieldAttributes) {
						if (!conditionMatches) {
							fieldAttributes[attributeName] = !conditionResult
						}

						return
					}

					fieldAttributes[attributeName] = conditionMatches ? conditionResult : !conditionResult
				})
			})

			return (fields[fieldName] = {
				...fields[fieldName],
				...fieldAttributes,
			})
		})
		return fields
	}

	const [inputFields, setInputFields] = useState<FormFields>({ ...handleFieldConditions(formFields) })
	const [allFieldsConfirmed, setAllFieldsConfirmed] = useState<boolean>(false)
	const [problemTicketState, setProblemTicketState] = useState<boolean>(false)

	/**
	 * if updated data (e.g. by adding a remark) is coming in, the current state of the inputFields
	 * must be handled by handleFormChange function
	 */
	useEffect(() => {
		if (data) {
			setProblemTicketState(isProblemTicket(data))
		}
		if (inputFields) {
			handleFormChange(inputFields)
		}

		// eslint-disable-next-line
	}, [data])

	const handleFormChange = (fields: FormFields) => {
		const value = fields[DOCUMENT_TYPE_FIELD_NAME].value as string | undefined
		const { type, version } = value ? JSON.parse(value) : { type: 'UNDEFINED', version: '1' }
		if (data.documentContent.content.type !== type || data.documentContent.content.version !== version) {
			onDocumentTypeChange({ type, version })
			documentTypeUpdated.current = true
		}
		const updatedInputFields = { ...handleFieldConditions(fields) }
		setInputFields(updatedInputFields)
	}

	const isProblemTicket = (ticket: Ticket): boolean => {
		if (ticket.problemState) {
			return '' !== ticket.problemState
		}
		return false
	}

	const setFieldValues = (fieldValues: { [key: string]: string }) => {
		const setFieldValue = (field: AnalyzedMagicField<string>): AnalyzedMagicField<string> => {
			if (isMagicFieldGroup(field)) {
				field.fields.map((subField) => setFieldValue(subField as AnalyzedMagicField<string>))
			}

			const { name } = field

			if (name && fieldValues.hasOwnProperty(name)) {
				const shouldReturnUndefined = '' === fieldValues[name]

				field.value = shouldReturnUndefined ? undefined : fieldValues[name]
			}

			return field
		}

		const updatedTicketPages = data.documentContent.content.pages.map((page) => {
			const updatedPageFields = page.fields.map((field) => setFieldValue(field as AnalyzedMagicField<string>))

			page.fields = updatedPageFields

			return page
		})

		if (IDENT_NUMBER_FIELD_NAME in fieldValues) {
			data.documentContent.content.identNumber = fieldValues[IDENT_NUMBER_FIELD_NAME]
		}

		if (RECEIVED_AT_FIELD_NAME in fieldValues) {
			data.receivedAt = new Date(parseDate(fieldValues[RECEIVED_AT_FIELD_NAME])).toISOString()
		}

		data.documentContent.content.pages = updatedTicketPages
	}

	const handleSubmit = async (submittedFields: { [key: string]: string }) => {
		ticketUpdated.current = true
		setFieldValues(submittedFields)

		const getNextTicketResponse = followupRouteProperties[latestFollowupRoute.current].ticketStatus
			? await api.getNextTicket({
					ticketId: data.id,
					ticketStatus: followupRouteProperties[latestFollowupRoute.current].ticketStatus!,
			  })
			: undefined

		nextTicketId.current = getNextTicketResponse?.ticketId

		/**
		 * do not wait for response of updateTicket request to speed up
		 * going to the next ticket
		 */
		api.updateTicket({ ticketId: data.id, body: data }).then(() => {
			if (ticketLocked) {
				api.unlockTicket(data.id).then(() => {
					queryClient.refetchQueries([QueryKey.tickets])
				})
			}
		})
		return true
	}

	useEffect(() => {
		return () => {
			if (ticketLocked && false === ticketUpdated.current && false === documentTypeUpdated.current) {
				api.unlockTicket(data.id).then(() => {
					queryClient.refetchQueries([QueryKey.tickets])
				})
			}
		}
		// eslint-disable-next-line
	}, [ticketLocked])

	const handleOnSuccess = () => {
		if (nextTicketId.current) {
			sessionStorage.setItem('ticket', nextTicketId.current)
			navigateTo(`${getMainPath(routesDictionary.ticket)}/${nextTicketId.current}`, false)
			return
		}

		if ('globalInbox' === latestFollowupRoute.current) {
			navigateTo(getMainPath(routesDictionary.globalInbox))
			return
		}

		/**
		 * show a modal if the followupAction is not available and set the followupAction to "globalInbox"
		 */
		sessionStorage.setItem('ticket-followup-route', 'globalInbox')
		modalActions.setClass('modal--success')
		modalActions.setContent(
			<>
				<img src={inboxIllustration} alt="" />
				<h2>
					<Trans i18nKey={`component.magicLetterForm.followupAction.globalInbox.modalHeadline`} />
				</h2>
				<p>
					<Trans
						i18nKey={`component.magicLetterForm.followupAction.${latestFollowupRoute.current}.modalRouteNotAvailableText`}
					/>
				</p>
				<Button
					type={ButtonType.primary}
					label={t('component.magicLetterForm.followupAction.globalInbox.modalButtonLabel')}
					onClick={() => modalActions.closeModal()}
				/>
			</>
		)

		modalActions.openModal()
		modalActions.setHideCloseButtons()
		modalActions.setAutoCloseTimeout(3000)
		modalActions.setOnCloseAction(() => navigateTo(getMainPath(routesDictionary.globalInbox)))
	}

	const followupActionOptions: SwitchSelectOption[] = useMemo(() => {
		return followupRoutes.map((value) => {
			const optionLabel = (
				<span
					className={`flex flex--justify-content-center ticket__followup-action-option ${
						value === followupRoute ? 'ticket__followup-action-option--active' : ''
					}`}
				>
					<Icon type={IconType.doubleArrow} />
					<Icon type={followupRouteProperties[value].icon} />
				</span>
			)

			return { value, label: optionLabel }
		})
	}, [followupRoute])

	useEffect(() => {
		sessionStorage.setItem('ticket-followup-route', followupRoute)
	}, [followupRoute])

	const [identNumberFoundStatus, setIdentNumberFoundStatus] = useState(false)
	const [mandateIsValid, setMandateIsValid] = useState(false)

	return (
		<>
			{false === formLocked && mandateIsValid && (
				<MagicLetterConfirmButtons formFields={inputFields} onAllConfirmed={setAllFieldsConfirmed} />
			)}

			{!mandateIsValid && (
				<div className="flex flex--align-items-start">
					<Icon
						className="margin margin--small no-margin--left"
						type={IconType.info}
						color="var(--color-red)"
						rotate={180}
					/>
					<div>
						<p className="margin--vertical margin--small no-margin--bottom">
							{t('component.magicLetterForm.mandateIsNotValid')}
						</p>
					</div>
				</div>
			)}

			<Form
				ref={formRef}
				hideSubmit={true}
				className="results"
				fields={inputFields}
				onSubmit={handleSubmit}
				disabled={!allFieldsConfirmed}
				onFormChange={handleFormChange}
				onSuccess={handleOnSuccess}
				submitLabel={t('component.magicLetterForm.submit')}
				formLocked={formLocked}
			/>

			<PensionerDetails
				className="personal-info"
				id={inputFields.identNumber.error ? '' : String(inputFields.identNumber.value)}
				setIdentNumberFoundStatus={setIdentNumberFoundStatus}
				setMandateIsValid={setMandateIsValid}
			/>

			<div className="ticket__footer">
				{!formLocked && mandateIsValid && documentTypes && (
					<TicketCloseWithoutAction
						className="ticket__close-without-action"
						data={data}
						documentTypes={documentTypes}
						disabled={!identNumberFoundStatus || problemTicketState}
						identNumberField={inputFields.identNumber}
					/>
				)}

				{!mandateIsValid && (
					<div className="mandate-ended">{t('component.magicLetterForm.mandateIsNotValid')}</div>
				)}

				{canDeleteMagicLetterTickets && <TicketDelete className="ticket__delete" data={data} />}
				<TicketOptions className="ticket__options" data={data} />
				{!formLocked && mandateIsValid && (
					<>
						<span className="ticket__followup-heading bold-small-heading text-align--center font-size-xs">
							<Trans i18nKey="component.magicLetterForm.followupAction.headline" />
						</span>
						<SwitchSelect
							className="ticket__followup-actions"
							value={followupRoute}
							options={followupActionOptions}
							onChange={(e) => {
								setFollowupRoute(e.target.value as TFollowupRoute)
							}}
						/>
						<Button
							className="ticket__submit"
							onClick={formRef.current?.submitForm}
							label={
								<span className="flex flex--direction-column flex--align-items-center">
									<b>{t('component.magicLetterForm.submit')}</b>
									<b className="ticket__submit-suffix font-size-xs">
										{t(`component.magicLetterForm.followupAction.${followupRoute}.submitSuffix`)}
									</b>
								</span>
							}
							type={ButtonType.primary}
							// disabled={FormErrorType.none !== formError || !allFieldsConfirmed}
							disabled={!allFieldsConfirmed || problemTicketState}
						/>
						<div className="ticket__followup-actions-background" />
					</>
				)}
			</div>
		</>
	)
}

export default MagicLetterForm
