import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import Button, { ButtonType } from 'shared/components/Button'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import useApi, { QueryKey } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import { t } from 'i18next'
import _ from 'lodash'
interface IFinalisierungModalProps {
	open: boolean
	onClick: () => void
	setOpenFinalisierungModal: (arg: boolean) => void
}
const ANZAHL_HINWEIS = 1

const FinalisierungsModal: FunctionComponent<IFinalisierungModalProps> = ({
	open,
	onClick,
	setOpenFinalisierungModal,
}) => {
	const modalRef = useRef<ModalRefActions>()

	useEffect(() => {
		open ? modalRef.current?.openModal() : modalRef.current?.closeModal()
	}, [open])

	return (
		<Modal
			ref={modalRef}
			forceAction={false}
			header={t('view.pensionerProfile.tabs.finalizeWithoutAssets.modal.header')}
			buttonLabel={t(`generic.confirm`)}
			onModalClose={() => setOpenFinalisierungModal(false)}
			onButtonClick={onClick}
			className="modal--start-austrittstask modal--width-big"
			onCloseClick={() => setOpenFinalisierungModal(false)}
		>
			<div>
				<h3>{t('view.pensionerProfile.tabs.finalizeWithoutAssets.modal.content')}</h3>
				<ul>
					{_.range(ANZAHL_HINWEIS).map((i) => {
						return (
							<li className="text-align--left">
								{t(`view.pensionerProfile.tabs.finalizeWithoutAssets.modal.info-${i}`)}
							</li>
						)
					})}
				</ul>
			</div>
		</Modal>
	)
}

const Finalisierung: React.FC<{ refetchHistorie: any }> = ({ refetchHistorie }) => {
	const { userId } = useUserRecord()
	const { t } = useTranslation()
	const api = useApi()

	const [openFinalisierungModal, setOpenFinalisierungModal] = useState<boolean>(false)

	const [disableFinalisierung, setDisableFinalisierung] = useState<boolean>(false)

	const { data: austrittstask } = useQuery([QueryKey.austrittstask, { userId }], api.getAustrittstaskByIdentNumber, {
		enabled: !!userId,
	})

	const handleBeendeTeilnahme = async () => {
		if (!userId) return
		const response = await api.postAustrittsbearbeitungenAbschlussOhneVersorgungsguthaben({ userId })

		if (!response.success) {
			// TODO DA Toast
			console.log('response: lets Toast!', response.success)
		}
		await refetchHistorie()
		setDisableFinalisierung(response.success)
	}

	useEffect(() => {
		austrittstask?.response?.zusageGetaetigt && setDisableFinalisierung(true)
	}, [austrittstask])

	return (
		<div className="austrittsbearbeitungen-stammdaten">
			<h3>{t('view.pensionerProfile.tabs.finalizeWithoutAssets.title')}</h3>
			<Button
				type={ButtonType.secondary}
				onClick={() => setOpenFinalisierungModal(true)}
				disabled={disableFinalisierung}
			>
				{t('view.pensionerProfile.tabs.finalizeWithoutAssets.terminateParticipation')}
			</Button>
			<FinalisierungsModal
				open={openFinalisierungModal}
				onClick={handleBeendeTeilnahme}
				setOpenFinalisierungModal={setOpenFinalisierungModal}
			/>
		</div>
	)
}

export default Finalisierung
