import { TMonth, TMonthSchema, getMonthNameFromNumber } from './types/month'

interface YearMonth {
	year: number
	month: TMonthSchema
}

function generateYearMonthArray(startYear: number, startMonth: TMonth, endYear: number, endMonth: TMonth): YearMonth[] {
	const result: YearMonth[] = []

	let currentYear = startYear
	let currentMonth = startMonth

	while (currentYear < endYear || (currentYear === endYear && currentMonth <= endMonth)) {
		result.push({
			year: currentYear,
			month: { monthNumber: currentMonth, monthName: getMonthNameFromNumber(currentMonth) },
		})

		if (currentMonth === 12) {
			currentMonth = 1
			currentYear++
		} else {
			currentMonth++
		}
	}

	return result.reverse()
}

export default generateYearMonthArray
