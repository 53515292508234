import { useRef } from 'react'

let currentId: number = 0

export const useUniqueId = (name: any): string => {
	const ref = useRef(0)
	if (ref.current === 0) {
		ref.current = ++currentId
	}

	return `${name}-${ref.current}`
}
