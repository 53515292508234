import 'amazon-connect-streams'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useBetween } from 'use-between'
import useConnectApi, {
	IConnectAgentDetails,
	IConnectAuthDetails,
	IConnectCallerDetailsResponse,
	IConnectRoutingProfile,
} from './useConnectApi'

const CONNECT_CCP_VERSION = process.env.REACT_APP_CONNECT_CCP_VERSION
const CONNECT_DOMAIN = process.env.REACT_APP_CONNECT_DOMAIN
const CONNECT_REGION = process.env.REACT_APP_CONNECT_REGION

const connectQueues = [
	'RWE',
	'Amprion',
	'Metzler',
	'Agger/Thyssengas',
	'Wintershall Dea Vorsorge',
	'Wintershall Dea Rente',
	'ZF Vorsorge',
	'ZF Rente',
	'Westenergie',
	'0800er',
] as const

export type TConnectQueue = (typeof connectQueues)[number]

export const connectQueueSettings: {
	[key in TConnectQueue]?: {
		quicklink?: 'openUserRecordOrSearch' | 'openIdentification'
		tenantKey?: string
	}
} = {
	'Wintershall Dea Rente': {
		quicklink: 'openIdentification',
		tenantKey: 'WD',
	},
	'ZF Vorsorge': {
		quicklink: 'openUserRecordOrSearch',
	},
	Westenergie: {
		quicklink: 'openIdentification',
		tenantKey: 'WE',
	},
	RWE: {
		quicklink: 'openIdentification',
		tenantKey: 'RWE',
	},
}

export enum LocalizedAgentState {
	Login = 'Login',
	Init = 'Init',
	Offline = 'Offline',
	Verfügbar = 'Verfügbar',
	Nacharbeit = 'Nacharbeit',
	Available = 'Available',
	PendingBusy = 'PendingBusy',
	Busy = 'Busy',
	AfterCallWork = 'AfterCallWork',
	MissedCallAgent = 'MissedCallAgent',
	CallingCustomer = 'CallingCustomer',
}

export type AgentState =
	| 'login'
	| 'init'
	| 'offline'
	| 'available'
	| 'pending-busy'
	| 'busy'
	| 'after-call-work'
	| 'after-call-work-manual'
	| 'missed-call'
	| 'calling-customer'

export type FChangeAgentState = (newAgentState: AgentState) => void

export const mappedAgentState: { [key in LocalizedAgentState]: AgentState } = {
	[LocalizedAgentState.Login]: 'login',
	[LocalizedAgentState.Init]: 'init',
	[LocalizedAgentState.Offline]: 'offline',
	[LocalizedAgentState.Available]: 'available',
	[LocalizedAgentState.Verfügbar]: 'available',
	[LocalizedAgentState.MissedCallAgent]: 'missed-call',
	[LocalizedAgentState.PendingBusy]: 'pending-busy',
	[LocalizedAgentState.Busy]: 'busy',
	[LocalizedAgentState.Nacharbeit]: 'after-call-work-manual',
	[LocalizedAgentState.AfterCallWork]: 'after-call-work',
	[LocalizedAgentState.CallingCustomer]: 'calling-customer',
}

export type ContactState = 'connecting' | 'accepted' | 'ended' | 'connected'

interface IConnectContactAttribute {
	name: string
	value: string
}

export enum ConnectContactAttribute {
	Birthdate = 'birthdate',
	IdentNumber = 'identNumber',
	PhoneNumber = 'phoneNumber',
	ZipCode = 'zipCode',
}

type TConnectContactAttributes = { [key in ConnectContactAttribute]: IConnectContactAttribute }

const useSoftphoneHook = () => {
	const connectApi = useConnectApi()
	const [containerDiv, setContainerDiv] = useState<HTMLDivElement>()
	const [showSoftphone, setShowSoftphone] = useState<boolean>(false)
	const connectContact = useRef<connect.Contact>()
	const currentAgent = useRef<connect.Agent>()
	const agentId = useRef<IConnectAuthDetails['agentId']>()
	const agentDetails = useRef<IConnectAgentDetails>()
	const [agentState, setAgentState] = useState<AgentState>('login')
	const [contactState, setContactState] = useState<ContactState>('ended')
	const [contactAttributes, setContactAttributes] = useState<TConnectContactAttributes>()
	const [contactData, setContactData] = useState<IConnectCallerDetailsResponse>()
	const [contactQueue, setContactQueue] = useState<connect.Queue>()
	const [availableRoutingProfiles, setAvailableRoutingProfiles] = useState<IConnectRoutingProfile[]>()
	const [currentRoutingProfile, setCurrentRoutingProfile] = useState<IConnectRoutingProfile['name']>()
	const [acwTime, setAcwTime] = useState<IConnectAgentDetails['acwTime']>()
	const [outgoingCallNumber, setOutgoingCallNumber] = useState<string>('')

	// const latestContactState = useLatest(contactState)
	// const latestContactAttributes = useLatest(contactAttributes)

	const [availableAgentStatesRaw, setAvailableAgentStatesRaw] = useState<connect.AgentStateDefinition[]>()
	const [availableAgentStates, setAvailableAgentStates] = useState<AgentState[]>()

	useEffect(() => {
		if (!containerDiv) {
			return
		}

		const wrapperContainer = containerDiv

		connect.core.initCCP(wrapperContainer, {
			ccpUrl: `${CONNECT_DOMAIN}/connect/${CONNECT_CCP_VERSION}`,
			loginPopup: false,
			region: CONNECT_REGION,
			softphone: {
				// optional
				allowFramedSoftphone: true, // optional
				disableRingtone: false, // optional
				// ringtoneUrl: `${window.location.origin}${ringtone}`, // optional
			},
			pageOptions: {
				enableAudioDeviceSettings: true,
			},
		})

		connect.agent((agent) => {
			;(async () => {
				// const agentId = auth.userData?.connectAgentId
				const agentStates = await agent.getAgentStates()
				const mappedAgentStates = agentStates.map(
					(state) => mappedAgentState[state.name as unknown as LocalizedAgentState]
				)
				currentAgent.current = agent
				setAvailableAgentStatesRaw(agentStates)
				setAvailableAgentStates(mappedAgentStates)

				const connectAuthDetails = await connectApi.getAuthDetails()
				agentId.current = connectAuthDetails?.agentId

				if (agentId.current) {
					const getRoutingProfiles = connectApi.getRoutingProfiles()
					const getAgentDetails = connectApi.getAgentDetails(agentId.current)

					getRoutingProfiles.then(setAvailableRoutingProfiles)
					getAgentDetails.then((data) => {
						agentDetails.current = data
						setCurrentRoutingProfile(data?.routingProfileId)
						setAcwTime(data?.acwTime)
					})
				}
			})()

			agent.onStateChange(handleOnAgentStateChange)
		})

		connect.contact((contact) => {
			connectContact.current = contact

			// contact.onPending((contact) => handleEvent(contact, 'onPending'))
			// // contact.onConnecting((contact) => handleEvent(contact, 'onConnecting')) // call incoming
			contact.onAccepted(handleOnAccepted)
			contact.onMissed(handleOnMissed)
			// contact.onDestroy((contact) => handleEvent(contact, 'onDestroy')) // close contact action
			// contact.onACW((contact) => handleEvent(contact, 'onACW'))
			// contact.onMissed((contact) => handleEvent(contact, 'onMissed'))
			contact.onConnected(handleOnConnected)

			// contact.onRefresh(handleOnRefresh)
			contact.onConnecting(handleOnConnecting) // call incoming
			contact.onEnded(handleOnEnded) // call ended
			contact.onError(handleOnMissed)
		})
		// eslint-disable-next-line
	}, [containerDiv])

	useEffect(() => {
		if (
			undefined === currentRoutingProfile ||
			undefined === agentDetails.current ||
			agentDetails.current.routingProfileId === currentRoutingProfile
		) {
			return
		}

		connectApi
			.setRoutingProfile(agentDetails.current.agentId, { routingProfileId: currentRoutingProfile })
			.then((data) => {
				if (data?.routingProfileId) {
					agentDetails.current!.routingProfileId = data?.routingProfileId
				}
			})
		// eslint-disable-next-line
	}, [currentRoutingProfile])

	useEffect(() => {
		if (undefined === contactAttributes) {
			// console.log('@dev reset ContactData')
			setContactData(undefined)
			return
		}

		connectApi
			.getCallerDetails({
				phone: contactAttributes.phoneNumber.value,
				idOrPnr: contactAttributes?.identNumber?.value,
				zipCode: contactAttributes?.zipCode?.value,
				dateOfBirth: contactAttributes?.birthdate?.value,
				queue: contactQueue?.name || '',
			})
			.then((data) => {
				// console.log('@dev setting contact data', { data })
				setContactData(data)
			})
		// eslint-disable-next-line
	}, [contactAttributes])

	const startOutboundCall = useCallback((number: string) => {
		setOutgoingCallNumber(number)
		const endpoint = connect.Endpoint.byPhoneNumber(number)

		connect.agent((agent) => {
			agent.connect(endpoint, {
				success: function () {
					console.log('outbound call connected')
				},
				failure: function (err) {
					console.log('outbound call connection failed')
					console.log(err)
				},
			})
		})
	}, [])

	// useEffect(() => {
	// 	console.log('@dev', { contactState })
	// }, [contactState])

	// const handleEvent = (contact: connect.Contact, event: string) => {
	// 	console.log('handle', { event })
	// }

	/**
	 * handleOnAgentStateChange
	 * invoked whenever the contact is updated.
	 *
	 */
	const handleOnAgentStateChange: connect.AgentStateChangeCallback = (agentStateChange) => {
		// console.log('@dev', { agentStateChange })
		// console.log('@dev agentState', mappedAgentState[agentStateChange.newState as unknown as LocalizedAgentState])
		console.log('AGENTSTATE', agentStateChange)
		setAgentState(mappedAgentState[agentStateChange.newState as unknown as LocalizedAgentState])
	}

	// /**
	//  * handleOnRefresh
	//  * invoked whenever the contact is updated.
	//  *
	//  */
	// const handleOnRefresh = () => {
	// 	if (!connectContact.current) {
	// 		return
	// 	}

	// 	const { type } = connectContact.current.getState()
	// 	setContactState(type)
	// }

	/**
	 * handleOnConnected
	 * actions when a call is connected
	 *
	 */
	const handleOnConnected = () => {
		// console.log('@dev', 'handleOnConnected')

		setContactState('connected')

		// console.log('@dev handleOnConnected', connectContact.current?.getAttributes())
	}

	/**
	 * handleOnConnecting
	 * actions when a call is incoming
	 *
	 */
	const handleOnConnecting = () => {
		// console.log('@dev', 'handleOnConnecting')
		// console.log('@dev contactState', { latestContactState: latestContactState.current })
		// console.log('@dev contactState2', { contactState })
		// if ('connecting' !== latestContactState.current) {
		// if (JSON.stringify(latestContactAttributes.current) !== JSON.stringify(contactAttributes2)) {
		// console.log('@dev', JSON.stringify(latestContactAttributes), JSON.stringify(contactAttributes2))
		setContactQueue(connectContact.current?.getQueue())
		setContactAttributes(connectContact.current?.getAttributes() as unknown as TConnectContactAttributes)
		// }
		// }

		setContactState('connecting')

		// console.log('@dev handleOnConnecting', connectContact.current?.getAttributes())
	}

	/**
	 * handleOnEnded
	 * actions when call has ended
	 *
	 * reset connectContact when call ended
	 */
	const handleOnEnded = () => {
		// if ('ended' === connectContact.current?.getState()?.type) {
		// console.log('@dev', 'handleOnEnded')
		// console.log(connectContact.current?.getState())

		setContactState('ended')
		setContactAttributes(undefined)
		connectContact.current = undefined
		// }
	}

	const handleOnAccepted = () => {
		// console.log('@dev', 'handleOnAccepted')

		setContactState('accepted')

		// console.log('@dev handleOnAccepted', connectContact.current?.getAttributes())
	}

	// const handleOnPending = (contact: connect.Contact) => {
	// 	console.log('handleOnPending')
	// 	console.log(contact)
	// }

	// const handleOnConnected = (contact: connect.Contact) => {
	// 	console.log('handleOnConnected')
	// 	console.log(contact)
	// }

	// const handleOnIncoming = (contact: connect.Contact) => {
	// 	console.log('handleOnIncoming')
	// 	console.log(contact)
	// }

	const handleOnCallAction = () => {
		// console.log('@dev', 'handleOnCallAction')
		if (!connectContact.current) {
			return
		}

		const { type } = connectContact.current.getStatus()

		switch (type) {
			// accept incoming call
			case connect.ContactStateType.CONNECTING:
				return connectContact.current.accept()

			/**
			 * end current call
			 *
			 * // TODO: not clearing the contact atm. should the contact be cleared?
			 */
			case connect.ContactStateType.CONNECTED:
				const initialConnection = connectContact.current.getInitialConnection()
				const thirdParty = connectContact.current.getSingleActiveThirdPartyConnection()
				if (initialConnection) {
					initialConnection.destroy()
				}
				if (thirdParty) {
					thirdParty.destroy()
				}
		}
	}

	const handleOnDenyCallAction = () => {
		// console.log('@dev', 'handleOnDenyCallAction')

		if (!connectContact.current) {
			return
		}

		const { type } = connectContact.current.getStatus()

		switch (type) {
			case connect.ContactStateType.INIT:
			case connect.ContactStateType.CONNECTING:
				connectContact.current.reject()
				return
		}
	}

	const handleOnMissed = () => {
		// console.log('@dev handleOnMissed')

		if (!connectContact.current) {
			return
		}

		connectContact.current.clear({})
	}

	const changeAgentState: FChangeAgentState = (newAgentState) => {
		// console.log('@dev', { newAgentState })
		const state = availableAgentStatesRaw?.find(
			(rawState) => newAgentState === mappedAgentState[rawState.name as unknown as LocalizedAgentState]
		)

		if (state) {
			currentAgent.current?.setState(state, {
				success() {
					// console.log(`state changed to ${newAgentState}`)
				},
				failure() {
					// console.log(err)
				},
			})
		}
	}

	const handleLogout = () => {
		fetch(`${CONNECT_DOMAIN}/connect/logout`, { credentials: 'include', mode: 'no-cors' }).then(() => {
			connect.core.terminate()
		})

		if (containerDiv) {
			const iframe = containerDiv.firstElementChild

			if (iframe) {
				containerDiv.removeChild(iframe)
			}
		}
	}

	return {
		connectContact: connectContact.current,
		setContainerDiv,
		showSoftphone,
		setShowSoftphone,
		startOutboundCall,
		agentState,
		contactState,
		contactAttributes,
		availableAgentStates,
		handleOnCallAction,
		handleOnDenyCallAction,
		changeAgentState,
		availableRoutingProfiles,
		currentRoutingProfile,
		setCurrentRoutingProfile,
		acwTime,
		contactData,
		contactQueue,
		handleLogout,
		outgoingCallNumber,
	}
}

export const useSoftphone = () => useBetween(useSoftphoneHook)
