export const clearLocalStorage = () => {
	window.localStorage.clear()
}

export const getLocalStorage = (key: string, initialValue?: any) => {
	const item = window.localStorage.getItem(key)

	if (null === item && initialValue) {
		window.localStorage.setItem(key, JSON.stringify(initialValue))
	}

	return item ? JSON.parse(item) : initialValue
}

export const setLocalStorage = (key: string, value: any) => {
	window.localStorage.setItem(key, JSON.stringify(value))

	return value
}

export const removeLocalStorage = (key: string) => {
	window.localStorage.removeItem(key)
}
