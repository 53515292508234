import { IconType } from 'components/Icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Icon from 'shared/components/Icon'
import SidebarBetriebNavigation from "./SidebarBetriebNavigation";

const SidebarBetrieb: React.FC = () => {
	const { t } = useTranslation()

	return (
		<aside className="sidebar-betrieb">
			<div className="sidebar-betrieb__outer-wrapper">
				<div className="sidebar-betrieb__inner-wrapper">
					<div className="sidebar-betrieb__user flex flex--align-items-center padding">
						<Icon
							className="margin--horizontal margin--small no-margin--left"
							color="var(--color-white)"
							type={IconType.admin}
						/>
						<div className="flex flex--direction-column">
							<span className="text-color-gold text-transform-uppercase font-size-m">
								{t('component.sidebarBetriebNavigation.title')}
							</span>
						</div>
					</div>

					<hr className="hr sidebar-betrieb__spacer margin--horizontal no-margin--top no-margin--bottom" />
					<SidebarBetriebNavigation className="sidebar-betrieb__navigation" />
				</div>
			</div>
		</aside>
	)
}

export default SidebarBetrieb
