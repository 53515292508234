import classNames from 'classnames'
import { useSoftphone } from 'hooks/useSoftphone'
import { FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import Button, { ButtonType } from '../../shared/components/Button'
import { IconType } from '../Icons'

interface IConnectCallButtons extends HTMLAttributes<HTMLDivElement> {
	onClickActiveCallButton: (e: any) => void
}

export const ConnectCallButtons: FC<IConnectCallButtons> = ({ className, onClickActiveCallButton }) => {
	const { agentState, contactState, handleOnCallAction, handleOnDenyCallAction } = useSoftphone()
	const { t } = useTranslation()

	return (
		<div
			className={classNames(
				className,
				'connect-call-buttons',
				`connect-call-buttons--${agentState}
				 connect-call-buttons--${contactState}`,
				'flex'
			)}
		>
			<div className="connect-call-buttons__call-action-wrapper">
				{'connecting' === contactState && (
					<div className="rrring-animation">
						<div className="rrring" />
						<div className="rrring" />
						<div className="rrring" />
						<div className="rrring" />
					</div>
				)}

				<Button
					type={ButtonType.primary}
					className={`connect-call-buttons__call-action 
					connect-call-buttons__call-action--connect connect-call-buttons__call-action--${contactState}`}
					onClick={
						'connected' === contactState ? onClickActiveCallButton?.bind(null, true) : handleOnCallAction
					}
					label={
						['accepted', 'connected'].includes(contactState)
							? t(`component.connect.activeCall.connected`)
							: undefined
					}
					icon={IconType.callOn}
					iconColor="var(--color-blue--medium)"
				/>
			</div>

			<Button
				type={ButtonType.primary}
				className="connect-call-buttons__deny-action"
				onClick={'connected' === contactState ? handleOnCallAction : handleOnDenyCallAction}
				icon={IconType.callOff}
			/>
		</div>
	)
}
