import React, { FunctionComponent, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { ButtonType } from 'shared/components/Button'
import Button from './Button'
import Icon, { IconSize } from './Icon'
import { IconType } from 'components/Icons'
import { openLinkInNewWindow } from 'shared/helper/openLinkInNewWindow'
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`

export interface PdfViewerProps {
	document?: string
	className?: string
	onDownloadButtonClick?: () => void // if not provided, document will be opened in new window, otherwise this function will be called
}

const PdfViewer: FunctionComponent<PdfViewerProps> = (props) => {
	const [totalPages, setTotalPages] = useState<number>(1)
	const [file] = useState({
		url: props.document,
	})
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [aspectRatio, setAspectRatio] = useState<number>(1.41)
	// const [scale, setScale] = useState<number>(1)
	const pageRef = useRef<HTMLCanvasElement>(null)

	const getClasses = (): string => {
		const classes = ['pdf-viewer']

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}

	const previousPage = () => {
		setCurrentPage(Math.max(0, currentPage - 1))
	}

	const nextPage = () => {
		setCurrentPage(Math.min(totalPages || 1, currentPage + 1))
	}

	const handleOnClick = async () => {
		if (props.onDownloadButtonClick) {
			props.onDownloadButtonClick()
			return
		}

		if (!props.document) {
			return
		}

		openLinkInNewWindow(props.document, true)
	}

	return (
		<div
			className={getClasses()}
			style={{ '--document-aspect-ration': `1 / ${aspectRatio}` } as React.CSSProperties}
		>
			<Document
				file={file}
				onLoadSuccess={({ numPages }) => setTotalPages(numPages)}
				onLoadError={(e: any) => console.log('@dev', e)}
			>
				{/* <Page width={width} pageNumber={currentPage} scale={scale} /> */}
				<Page
					canvasRef={pageRef}
					onLoadSuccess={(pdf) => {
						// console.dir(pdf)
						if (!pageRef.current) {
							return
						}

						setAspectRatio(pageRef.current.height / pageRef.current.width)
					}}
					pageNumber={currentPage}
				/>
			</Document>

			<div className="pdf-viewer__sticky-bar">
				{totalPages > 1 && (
					<div className="pdf-viewer__buttons">
						<Button
							type={[ButtonType.primary, ButtonType.round]}
							label="-"
							onClick={previousPage}
							disabled={currentPage === 1}
						/>
						<div className="page">
							{currentPage} / {totalPages}
						</div>
						<Button
							type={[ButtonType.primary, ButtonType.round]}
							label="+"
							onClick={nextPage}
							disabled={currentPage === totalPages}
						/>
					</div>
				)}

				<div className="pdf-viewer__buttons pdf-viewer__buttons--download">
					<Button
						type={[ButtonType.primary, ButtonType.round]}
						label={<Icon type={IconType.arrow} size={IconSize.extraSmall} rotate={90}></Icon>}
						onClick={handleOnClick}
					/>
				</div>
			</div>

			{/* <RangeSlider value={scale} min={1} max={2} step={0.01} onChange={(e) => setScale(Number(e.target.value))} /> */}
		</div>
	)
}

export default PdfViewer
