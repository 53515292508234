import { DefaultTFuncReturn } from 'i18next'
import React from 'react'
import globalHook, { Store } from 'use-global-hook'

export interface GlobalModalState {
	open: boolean
	content?: React.ReactElement | DefaultTFuncReturn | string
	header?: React.ReactElement | DefaultTFuncReturn | string
	buttonLabel?: React.ReactElement | DefaultTFuncReturn | string
	className?: string | undefined
	autoCloseTimeout?: number
	/**
	 * @deprecated
	 * use hideCloseButton and hideButton
	 */
	hideCloseButtons?: boolean
	hideCloseButton?: boolean
	hideButton?: boolean
	onCloseAction?: () => void
	onCloseButtonAction?: () => void
}

export interface GlobalModalActions {
	openModal: () => void
	closeModal: () => void
	toggleModal: () => void
	setContent: (content: GlobalModalState['content']) => void
	setHeader: (header: GlobalModalState['header']) => void
	setClass: (className: string | undefined) => void
	setAutoCloseTimeout: (autoCloseTimeout: number) => void
	/**
	 * @deprecated
	 * use setHideCloseButton and setHideCloseX
	 */
	setHideCloseButtons: () => void
	setHideCloseButton: () => void
	setHideButton: () => void
	resetState: () => void
	updateState: () => void
	setButtonLabel: (buttonLabel: GlobalModalState['buttonLabel']) => void
	setOnCloseAction: (cb: () => void) => void
	setOnCloseButtonAction: (cb: () => void) => void
}

const setButtonLabel = (
	store: Store<GlobalModalState, GlobalModalActions>,
	buttonLabel: GlobalModalState['buttonLabel']
) => {
	store.setState({ ...store.state, buttonLabel })
}

const setClass = (store: Store<GlobalModalState, GlobalModalActions>, className: string | undefined) => {
	store.setState({ ...store.state, className })
}

const openModal = (store: Store<GlobalModalState, GlobalModalActions>) => {
	store.setState({ ...store.state, open: true })
}

const closeModal = (store: Store<GlobalModalState, GlobalModalActions>) => {
	store.setState({ ...store.state, open: false })
}

const toggleModal = (store: Store<GlobalModalState, GlobalModalActions>) => {
	if (store.state.open) {
		store.actions.closeModal()
	} else {
		store.actions.openModal()
	}
}

const setContent = (store: Store<GlobalModalState, GlobalModalActions>, content: GlobalModalState['content']) => {
	store.setState({ ...store.state, content })
}

const setHeader = (store: Store<GlobalModalState, GlobalModalActions>, header: GlobalModalState['header']) => {
	store.setState({ ...store.state, header })
}

const setAutoCloseTimeout = (
	store: Store<GlobalModalState, GlobalModalActions>,
	autoCloseTimeout: GlobalModalState['autoCloseTimeout']
) => {
	store.setState({ ...store.state, autoCloseTimeout })
}

const setHideCloseButtons = (store: Store<GlobalModalState, GlobalModalActions>) => {
	store.setState({ ...store.state, hideCloseButton: true, hideButton: true })
}

const setHideCloseButton = (store: Store<GlobalModalState, GlobalModalActions>) => {
	store.setState({ ...store.state, hideCloseButton: true })
}

const setHideButton = (store: Store<GlobalModalState, GlobalModalActions>) => {
	store.setState({ ...store.state, hideButton: true })
}

const resetState = (store: Store<GlobalModalState, GlobalModalActions>) => {
	store.setState(initialState)
}

const updateState = (store: Store<GlobalModalState, GlobalModalActions>) => {
	store.setState({ ...store.state })
}

const setOnCloseAction = (
	store: Store<GlobalModalState, GlobalModalActions>,
	onCloseAction: GlobalModalState['onCloseAction']
) => {
	store.setState({ ...store.state, onCloseAction })
}

const setOnCloseButtonAction = (
	store: Store<GlobalModalState, GlobalModalActions>,
	onCloseButtonAction: GlobalModalState['onCloseAction']
) => {
	store.setState({ ...store.state, onCloseButtonAction })
}

const initialState: GlobalModalState = {
	open: false,
	header: undefined,
	content: undefined,
	buttonLabel: undefined,
	className: undefined,
	autoCloseTimeout: undefined,
	hideCloseButtons: undefined,
	hideCloseButton: undefined,
	hideButton: undefined,
	onCloseAction: undefined,
	onCloseButtonAction: undefined,
}

const actions = {
	openModal,
	closeModal,
	setClass,
	toggleModal,
	setContent,
	setHeader,
	resetState,
	updateState,
	setButtonLabel,
	setAutoCloseTimeout,
	setHideCloseButtons,
	setHideCloseButton,
	setHideButton,
	setOnCloseAction,
	setOnCloseButtonAction,
}

const useGlobalModalState = globalHook<GlobalModalState, GlobalModalActions>(React, initialState, actions)

export default useGlobalModalState
