import useApi, { QueryKey } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import BarChart, { BarChartDataType } from 'shared/components/BarChart'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useViewTitle } from 'state/useNavigationState'

const PensionerConversionsSingleYear: React.FC<{ year: number }> = ({ year }) => {
	const { navigateTo, getChildPath, getParentPath } = useRouteHelper()
	const { t } = useTranslation()
	useViewTitle(
		t('view.pensionerConversions.conversions', {
			year,
		})
	)
	useBackButtonPath(getParentPath())

	const { userId } = useUserRecord()

	const api = useApi()
	const { data, status } = useQuery(
		[
			QueryKey.compensationYear,
			{
				userId,
				year,
			},
		],
		api.getCompensation,
		{
			enabled: !!userId,
		}
	)

	const onClick = (month?: any) => {
		// Math.round is needed, as somehow the cached values are sometimes converted to a float
		navigateTo(
			`${getChildPath(routesDictionary.pensioner, 'conversions', {
				main: [userId!],
			})}/${year}/${Math.round(month)}`
		)
	}

	return (
		<div className={`visually-hidden visually-hidden--${String(status !== 'success')}`}>
			{data && (
				<BarChart
					className="conversions__chart"
					data={data}
					yDataType={BarChartDataType.currency}
					xDataType={BarChartDataType.string}
					panToEnd={true}
					stackBars={true}
					clickHandler={onClick}
					legend={[
						t('view.pensionerConversions.legend.ownContribution'),
						t('view.pensionerConversions.legend.totalInclMatching'),
					]}
				/>
			)}
		</div>
	)
}

export default PensionerConversionsSingleYear
