import { ReactComponent as AnimatedCheckmarkIcon } from 'assets/animated-checkmark.svg'
import { ReactComponent as AnimatedRadiobuttonIcon } from 'assets/animated-radiobutton.svg'
import { ReactComponent as IconAblageOhneVerarbeitung } from 'assets/icons/ablage-ohne-verarbeitung.svg'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg'
import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg'
import { ReactComponent as IconCallInbound } from 'assets/icons/call-incoming.svg'
import { ReactComponent as IconCallOff } from 'assets/icons/call-off.svg'
import { ReactComponent as IconCallOn } from 'assets/icons/call-on.svg'
import { ReactComponent as IconCallOutbound } from 'assets/icons/call-outgoing.svg'
import { ReactComponent as CheckmarkIconFilled } from 'assets/icons/checkmark-filled.svg'
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as IconDelegate } from 'assets/icons/delegate.svg'
import { ReactComponent as IconDelete } from 'assets/icons/delete.svg'
import { ReactComponent as DoubleArrowIcon } from 'assets/icons/double-arrow.svg'
import { ReactComponent as IconEdit } from 'assets/icons/edit.svg'
import { ReactComponent as IconEyeCrossed } from 'assets/icons/eye-crossed.svg'
import { ReactComponent as IconEye } from 'assets/icons/eye.svg'
import { ReactComponent as InvalidIcon } from 'assets/icons/invalid.svg'
import { ReactComponent as IconKommentarHinzufuegen } from 'assets/icons/kommentar-hinzufuegen.svg'
import { ReactComponent as ListMarkerCheck } from 'assets/icons/list-marker-check.svg'
import { ReactComponent as ListMarkerCross } from 'assets/icons/list-marker-cross.svg'
import { ReactComponent as ListMarkerNeutral } from 'assets/icons/list-marker-neutral.svg'
import { ReactComponent as HelpIcon } from 'assets/icons/nanoicon-help.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/nanoicon-info.svg'
import { ReactComponent as NotSelectableIcon } from 'assets/icons/not-selectable.svg'
import { ReactComponent as IconOpen } from 'assets/icons/open.svg'
import { ReactComponent as IconPageUp } from 'assets/icons/page-up.svg'
import { ReactComponent as IconPhoneCrossed } from 'assets/icons/phone-crossed.svg'
import { ReactComponent as IconPhoneNumberpad } from 'assets/icons/phone-numberpad.svg'
import { ReactComponent as IconPhone } from 'assets/icons/phone.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg'
import { ReactComponent as AdminIcon } from 'assets/menuIcons/icon-admin.svg'
import { ReactComponent as IconProfile } from 'assets/menuIcons/icon-adresse.svg'
import { ReactComponent as IconLogout } from 'assets/menuIcons/icon-ausloggen.svg'
import { ReactComponent as IconAustrittsbearbeitung } from 'assets/menuIcons/icon-austrittsbearbeitung.svg'
import { ReactComponent as IconPayout } from 'assets/menuIcons/icon-auszahlung.svg'
import { ReactComponent as IconComplaints } from 'assets/menuIcons/icon-beschwerden.svg'
import { ReactComponent as MenuIconBlocksAccount } from 'assets/menuIcons/icon-blocksaccount.svg'
import { ReactComponent as IconLetter } from 'assets/menuIcons/icon-briefe.svg'
import { ReactComponent as IconCall } from 'assets/menuIcons/icon-call.svg'
import { ReactComponent as IconChart } from 'assets/menuIcons/icon-chart.svg'
import { ReactComponent as IconChat } from 'assets/menuIcons/icon-chat.svg'
import { ReactComponent as IconDocuments } from 'assets/menuIcons/icon-dokumente.svg'
import { ReactComponent as IconDeferredCompensation } from 'assets/menuIcons/icon-entgeltumwandlung.svg'
import { ReactComponent as IconFundComposition } from 'assets/menuIcons/icon-fondszusammensetzung.svg'
import { ReactComponent as IconCallHistory } from 'assets/menuIcons/icon-gold-icon-call-history.svg'
import { ReactComponent as IconInbox } from 'assets/menuIcons/icon-incomingletter.svg'
import { ReactComponent as IconEmail } from 'assets/menuIcons/icon-kommunikation.svg'
import { ReactComponent as IconCard } from 'assets/menuIcons/icon-konto.svg'
import { ReactComponent as IconMoney } from 'assets/menuIcons/icon-money.svg'
import { ReactComponent as IconNotizen } from 'assets/menuIcons/icon-notizen.svg'
import { ReactComponent as IconPasswordReset } from 'assets/menuIcons/icon-passwordreset.svg'
import { ReactComponent as IconPending } from 'assets/menuIcons/icon-pending.svg'
import { ReactComponent as IconPensionerDashboard } from 'assets/menuIcons/icon-pensioner-dashboard.svg'
import { ReactComponent as IconRisikoleistungen } from 'assets/menuIcons/icon-risikoleistungen.svg'
import { ReactComponent as IconDashboard } from 'assets/menuIcons/icon-sachbearbeiter-dashboard.svg'
import { ReactComponent as IconSortAsc } from 'assets/menuIcons/icon-sort-asc.svg'
import { ReactComponent as IconSortDefault } from 'assets/menuIcons/icon-sort-default.svg'
import { ReactComponent as IconSortDesc } from 'assets/menuIcons/icon-sort-desc.svg'
import { ReactComponent as IconBaseData } from 'assets/menuIcons/icon-stammdaten.svg'
import { ReactComponent as IconSearch } from 'assets/menuIcons/icon-suche.svg'
import { ReactComponent as IconTicketApprove } from 'assets/menuIcons/icon-ticket-approve.svg'
import { ReactComponent as IconTicketHistory } from 'assets/menuIcons/icon-ticket-history.svg'
import { ReactComponent as IconTicketNext } from 'assets/menuIcons/icon-ticket-next.svg'
import { ReactComponent as IconUploads } from 'assets/menuIcons/icon-useruploads.svg'
import { ReactComponent as IconPension } from 'assets/menuIcons/icon-versorgungsguthaben.svg'
import { ReactComponent as IconContract } from 'assets/menuIcons/icon-vertrag.svg'
import { ReactComponent as IconConversions } from 'assets/menuIcons/icon-wandlungen.svg'
import { ReactComponent as IconWandlungsanträge } from 'assets/menuIcons/icon-wandlungsanträge.svg'
import { ReactComponent as IconZero } from 'assets/menuIcons/icon-zero.svg'
import { ReactComponent as IconApproved } from 'assets/icons/approved.svg'
import { ReactComponent as IconAlert } from 'assets/icons/alert.svg'
import { ReactComponent as IconSimulate } from 'assets/icons/simulate.svg'
import { ReactComponent as IconSolved } from 'assets/icons/solved.svg'
import { ReactComponent as IconFail } from 'assets/icons/fail.svg'
import { ReactComponent as IconWandlungEuro } from 'assets/menuIcons/icon-wandlungen-euro.svg'

export enum IconType {
	wandlungEuro = 'wandlung-euro',
	fail = 'fail',
	solved = 'solved',
	approved = 'approved',
	ablageOhneVerarbeitung = 'ablageOhneVerarbeitung',
	animatedCheckmark = 'animatedCheckmark',
	animatedRadiobutton = 'animatedRadiobutton',
	arrow = 'arrow',
	austrittsbearbeitung = 'austrittsbearbeitung',
	baseData = 'baseData',
	calendar = 'calendar',
	call = 'call',
	callOn = 'callOn',
	callOff = 'callOff',
	callInbound = 'callInbound',
	callOutbound = 'callOutbound',
	card = 'card',
	chart = 'chart',
	chat = 'chat',
	checkmark = 'checkmark',
	checkmarkFilled = 'checkmarkFilled',
	clock = 'clock',
	close = 'close',
	complaints = 'complaints',
	contract = 'contract',
	conversions = 'conversions',
	dashboard = 'dashboard',
	deferredCompensation = 'deferredCompensation',
	delegate = 'delegate',
	delete = 'delete',
	documents = 'documents',
	doubleArrow = 'doubleArrow',
	edit = 'edit',
	email = 'email',
	fundComposition = 'fundComposition',
	help = 'help',
	inbox = 'inbox',
	info = 'info',
	invalid = 'invalid',
	letter = 'letter',
	logout = 'logout',
	kommentarHinzufuegen = 'kommentarHinzufuegen',
	menuBlocksAccount = 'menuBlocksAccount',
	money = 'money',
	notizen = 'notizen',
	notSelectable = 'notSelectable',
	open = 'open',
	pageUp = 'pageUp',
	passwordReset = 'passwordReset',
	payout = 'payout',
	pending = 'pending',
	pension = 'pension',
	pensionerDashboard = 'pensionerDashboard',
	phone = 'phone',
	phoneNumberpad = 'phoneNumberpad',
	phoneCrossed = 'phoneCrossed',
	profile = 'profile',
	risikoleistungen = 'risikoleistungen',
	search = 'search',
	settings = 'settings',
	ticketApprove = 'ticketApprove',
	ticketHistory = 'ticketHistory',
	ticketNext = 'ticketNext',
	admin = 'admin',
	uploads = 'uploads',
	wandlungsanträge = 'wandlungsanträge',
	zero = 'zero',
	sortDefault = 'sortDefault',
	sortAsc = 'sortAsc',
	sortDesc = 'sortDesc',
	callHistory = 'callHistory',
	eye = 'eye',
	eyeCrossed = 'eyeCrossed',
	listMarkerNeutral = 'listMarkerNeutral',
	listMarkerCheck = 'listMarkerCheck',
	listMarkerCross = 'listMarkerCross',
	alert = 'alert',
	simulate = 'simulate',
}

export const IconAssignment: { [key in IconType]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
	[IconType.alert]: IconAlert,
	[IconType.approved]: IconApproved,
	[IconType.ablageOhneVerarbeitung]: IconAblageOhneVerarbeitung,
	[IconType.austrittsbearbeitung]: IconAustrittsbearbeitung,
	[IconType.animatedCheckmark]: AnimatedCheckmarkIcon,
	[IconType.animatedRadiobutton]: AnimatedRadiobuttonIcon,
	[IconType.arrow]: ArrowIcon,
	[IconType.baseData]: IconBaseData,
	[IconType.calendar]: IconCalendar,
	[IconType.call]: IconCall,
	[IconType.callOn]: IconCallOn,
	[IconType.callOff]: IconCallOff,
	[IconType.callInbound]: IconCallInbound,
	[IconType.callOutbound]: IconCallOutbound,
	[IconType.card]: IconCard,
	[IconType.chart]: IconChart,
	[IconType.chat]: IconChat,
	[IconType.checkmark]: CheckmarkIcon,
	[IconType.wandlungEuro]: IconWandlungEuro,
	[IconType.checkmarkFilled]: CheckmarkIconFilled,
	[IconType.clock]: IconPending,
	[IconType.clock]: IconPending,
	[IconType.close]: CloseIcon,
	[IconType.close]: CloseIcon,
	[IconType.complaints]: IconComplaints,
	[IconType.complaints]: IconComplaints,
	[IconType.contract]: IconContract,
	[IconType.contract]: IconContract,
	[IconType.conversions]: IconConversions,
	[IconType.conversions]: IconConversions,
	[IconType.dashboard]: IconDashboard,
	[IconType.deferredCompensation]: IconDeferredCompensation,
	[IconType.delegate]: IconDelegate,
	[IconType.delegate]: IconDelegate,
	[IconType.delete]: IconDelete,
	[IconType.documents]: IconDocuments,
	[IconType.doubleArrow]: DoubleArrowIcon,
	[IconType.edit]: IconEdit,
	[IconType.email]: IconEmail,
	[IconType.fundComposition]: IconFundComposition,
	[IconType.help]: HelpIcon,
	[IconType.help]: HelpIcon,
	[IconType.inbox]: IconInbox,
	[IconType.info]: InfoIcon,
	[IconType.info]: InfoIcon,
	[IconType.invalid]: InvalidIcon,
	[IconType.kommentarHinzufuegen]: IconKommentarHinzufuegen,
	[IconType.letter]: IconLetter,
	[IconType.letter]: IconLetter,
	[IconType.logout]: IconLogout,
	[IconType.logout]: IconLogout,
	[IconType.menuBlocksAccount]: MenuIconBlocksAccount,
	[IconType.money]: IconMoney,
	[IconType.notizen]: IconNotizen,
	[IconType.notSelectable]: NotSelectableIcon,
	[IconType.open]: IconOpen,
	[IconType.open]: IconOpen,
	[IconType.pageUp]: IconPageUp,
	[IconType.passwordReset]: IconPasswordReset,
	[IconType.payout]: IconPayout,
	[IconType.payout]: IconPayout,
	[IconType.pending]: IconPending,
	[IconType.pension]: IconPension,
	[IconType.pensionerDashboard]: IconPensionerDashboard,
	[IconType.phone]: IconPhone,
	[IconType.phoneNumberpad]: IconPhoneNumberpad,
	[IconType.phoneCrossed]: IconPhoneCrossed,
	[IconType.profile]: IconProfile,
	[IconType.profile]: IconProfile,
	[IconType.search]: IconSearch,
	[IconType.risikoleistungen]: IconRisikoleistungen,
	[IconType.settings]: SettingsIcon,
	[IconType.ticketApprove]: IconTicketApprove,
	[IconType.ticketHistory]: IconTicketHistory,
	[IconType.ticketNext]: IconTicketNext,
	[IconType.notSelectable]: NotSelectableIcon,
	[IconType.invalid]: InvalidIcon,
	[IconType.admin]: AdminIcon,
	[IconType.uploads]: IconUploads,
	[IconType.wandlungsanträge]: IconWandlungsanträge,
	[IconType.zero]: IconZero,
	[IconType.sortDefault]: IconSortDefault,
	[IconType.sortAsc]: IconSortAsc,
	[IconType.sortDesc]: IconSortDesc,
	[IconType.callHistory]: IconCallHistory,
	[IconType.eye]: IconEye,
	[IconType.eyeCrossed]: IconEyeCrossed,
	[IconType.listMarkerCheck]: ListMarkerCheck,
	[IconType.listMarkerNeutral]: ListMarkerNeutral,
	[IconType.listMarkerCross]: ListMarkerCross,
	[IconType.simulate]: IconSimulate,
	[IconType.solved]: IconSolved,
	[IconType.fail]: IconFail,
}
