import i18next from 'i18next'

const dateFormat = (
	value?: number | Date,
	options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	}
) => {
	if (undefined === value) {
		return ''
	}

	try {
		return new Intl.DateTimeFormat(i18next.language, options).format(value)
	} catch (error) {
		return ''
	}
}

export default dateFormat
