import { Loader } from '@googlemaps/js-api-loader'
import { ChangeEvent, forwardRef, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'
import TextInput, { TextInputProps } from 'shared/components/TextInput'
import { useDetectClickOutside } from 'shared/hooks/useDetectClickOutside'
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'

export type FOnSelectEntry = (entry: string | google.maps.places.PlaceResult) => void

export interface IGooglePlacesAutocomplete extends TextInputProps {
	onSelectEntry: FOnSelectEntry
	ref?: any
}

export const GooglePlacesAutocomplete = forwardRef<HTMLInputElement, IGooglePlacesAutocomplete>(
	({ onSelectEntry, ...textInputProps }, ref) => {
		const {
			ready,
			value,
			suggestions: { status, data },
			setValue,
			clearSuggestions,
			init,
		} = usePlacesAutocomplete({
			initOnMount: false,
			requestOptions: {
				types: ['address'],
				/* Define search scope here */
			},
			debounce: 300,
		})
		const wrapperRef = useRef<HTMLDivElement>(null)
		const inputRef = useRef<HTMLInputElement>(null)
		const loader = new Loader({
			apiKey: String(process.env.REACT_APP_GOOGLE_MAPS_API),
			language: 'de',
			region: 'de',
			libraries: ['places'],
		})

		loader.loadCallback((e) => {
			if (e) {
				console.log(e)
			} else {
				init()
			}
		})

		// When user clicks outside of the component, we can dismiss
		// the searched suggestions by calling this method
		useDetectClickOutside(wrapperRef, clearSuggestions)

		const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
			// Update the keyword of the input element
			setValue(e.target.value)

			textInputProps.onChange && textInputProps.onChange(e)
			if (null === inputRef.current) {
				return
			}
		}

		const handleSelect =
			({ description, place_id: placeId }: { description: string; place_id: string }) =>
			() => {
				// When user selects a place, we can replace the keyword without request data from API
				// by setting the second parameter to "false"
				setValue(description, false)
				clearSuggestions()

				const parameter = {
					// Use the "place_id" of suggestion from the dropdown (object), here just taking first suggestion for brevity
					placeId,
					// Specify the return data that you want (optional)
					fields: ['geometry', 'address_components', 'formatted_address'],
				}

				getDetails(parameter)
					.then((details) => {
						onSelectEntry(details)
					})
					.catch((error) => {
						console.log('Error: ', error)
					})
			}

		const renderSuggestions = () =>
			data.map((suggestion) => {
				const {
					place_id,
					structured_formatting: { main_text, main_text_matched_substrings, secondary_text },
				} = suggestion

				const highlightedMainText = main_text.substring(0, main_text_matched_substrings[0].length)
				const restMainText = main_text.substring(main_text_matched_substrings[0].length)

				return (
					<li
						className="google-places-autocomplete__list-item"
						key={place_id}
						onClick={handleSelect(suggestion)}
					>
						<strong>{highlightedMainText}</strong>
						{restMainText}, {secondary_text}
					</li>
				)
			})

		return (
			<div className="google-places-autocomplete" ref={wrapperRef}>
				<TextInput
					{...textInputProps}
					ref={mergeRefs([inputRef, ref])}
					value={textInputProps.value || value}
					onChange={handleInput}
					disabled={!ready || textInputProps.disabled}
				/>

				{status === 'OK' && <ul className="google-places-autocomplete__list">{renderSuggestions()}</ul>}
			</div>
		)
	}
)
