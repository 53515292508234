import decadiacardIllustration from 'assets/images/illu-decadiacard.svg'
import classNames from 'classnames'
import { IconType } from 'components/Icons'
import { ViewHeader } from 'components/ViewHeader'
import useCallerActionsApi, { TCallerActionsUserDetailsRequestParams } from 'hooks/useCallerActionsApi'
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormFieldType, FormFields, FormRefActions } from 'shared/components/Form'
import Icon from 'shared/components/Icon'
import { SelectOption } from 'shared/components/SelectInput'
import { TextInputType } from 'shared/components/TextInput'
import isDev from 'shared/helper/isDev'
import numbersOnlyString from '../../shared/helper/numbersOnlyString'
import { useCallerActionsContext } from './CallerActions.context'
import { trackPromise } from 'react-promise-tracker'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import { useQuery } from 'react-query'
import { useUserIdentificationNavigation } from 'hooks/useUserIdentifcationNavigation'

const TENANTS_LIST = process.env.REACT_APP_CALLER_AUTHENTICATION_TENANTS
	? process.env.REACT_APP_CALLER_AUTHENTICATION_TENANTS?.split(',')
	: []

const CallerActionsIdentify: FunctionComponent = () => {
	const { t } = useTranslation()
	const [isDecadiaId, setIsDecadiaId] = useState<boolean>(true)
	const [updatedFormFields, setUpdatedFormFields] = useState<FormFields>()
	const [updatedValues, setUpdatedValues] = useState<Record<string, unknown>>()
	const { setUserDetails, setUserAuthData, resetData } = useCallerActionsContext()
	const [promiseInProgress, setPromiseInProgress] = useState<boolean>(false)
	const { resetUserId } = useUserIdentificationNavigation()

	const formRef = useRef<FormRefActions>()

	const { data } = useQuery('callerActionsIdentify', () => {
		return { tenantFromQuery: '', decadiaIdFromQuery: '' }
	})

	const [callTriggeredByQueryString, setCallTriggeredByQueryStrings] = useState(false)
	const { getUserDatails } = useCallerActionsApi()

	const tenantSelectOptions = useMemo(() => {
		const options: SelectOption[] = TENANTS_LIST.map((value) => ({
			label: t(`view.callerActionsIdentify.form.fields.tenant.options.${value}`) || '',
			value,
		})).sort((a, b) => a.label?.localeCompare(b.label) || 0)

		options.unshift({
			label: t('view.callerActionsIdentify.form.fields.tenant.placeholder'),
			value: '',
		})

		return options
		// eslint-disable-next-line
	}, [])

	const inputFields: FormFields = {
		tenant: {
			fieldType: FormFieldType.select,
			value: TENANTS_LIST[0],
			options: tenantSelectOptions,
			label: t('view.callerActionsIdentify.form.fields.tenant.label'),
			required: true,
			alternativeStyle: true,
			className: 'margin--top margin--large caller-actions__grid-item--center-column',
		},
		authInfo: {
			fieldType: FormFieldType.switchSelect,
			value: 'decadiaId',
			options: [
				{
					label: t('view.callerActionsIdentify.form.fields.authInfo.options.decadiaId'),
					value: 'decadiaId',
				},
				{
					label: t('view.callerActionsIdentify.form.fields.authInfo.options.foreignId'),
					value: 'foreignId',
				},
			],
			onChange: (e) => {
				setIsDecadiaId(e.target.value === 'decadiaId')
			},
			required: true,
			className: 'margin--vertical margin--large caller-actions__grid-item--center-column',
		},
		decadiaId: {
			value: isDev() ? process.env.REACT_APP_CALLER_ID : undefined,
			type: isDecadiaId ? TextInputType.text : TextInputType.hidden,
			fieldType: FormFieldType.text,
			label: t(`view.callerActionsIdentify.form.fields.decadiaId.label`),
			placeholder: t(`view.callerActionsIdentify.form.fields.decadiaId.placeholder`),
			required: isDecadiaId ? true : undefined,
			disabled: isDecadiaId ? undefined : true,
			onBlur: (e) => {
				const decadiaId = `${e.target.value.replace(/\D/g, '').padStart(9, '0')}`

				setUpdatedValues({ decadiaId })
			},
			valueFunction: {
				name: numbersOnlyString,
			},
			tagPrefix: <b>D</b>,
			append: (
				<p className="margin--bottom margin--large">
					{t(`view.callerActionsIdentify.form.fields.decadiaId.description`)}
				</p>
			),
			className: classNames([
				'caller-actions__grid-item--left-column',
				{
					'hidden-within-flow': !isDecadiaId,
				},
			]),
		},
		foreignId: {
			type: isDecadiaId ? TextInputType.hidden : TextInputType.text,
			fieldType: FormFieldType.text,
			label: t(`view.callerActionsIdentify.form.fields.foreignId.label`, {
				tenant: tenantSelectOptions.find(
					(item) => item.value !== '' && item.value === updatedFormFields?.tenant.value
				)?.label,
			}),
			placeholder: t(`view.callerActionsIdentify.form.fields.foreignId.placeholder`),
			required: isDecadiaId ? undefined : true,
			disabled: isDecadiaId ? true : undefined,
			onBlur: (e) => {
				const foreignId = `${e.target.value.replace(/\D/g, '').padStart(8, '0')}`

				setUpdatedValues({ foreignId })
			},
			valueFunction: {
				name: numbersOnlyString,
			},
			className: classNames([
				'caller-actions__grid-item--left-column',
				{
					'hidden-within-flow': isDecadiaId,
				},
			]),
		},
	}

	const handleOnSubmit = async (submittedFields: TCallerActionsUserDetailsRequestParams) => {
		let { tenant, decadiaId, foreignId } = submittedFields

		const sanitizedData = Object.entries({
			tenant,
			decadiaId: decadiaId ? `D${decadiaId.replace(/\D/g, '').padStart(9, '0')}` : undefined,
			foreignId,
		} as TCallerActionsUserDetailsRequestParams).reduce(
			(data: Partial<TCallerActionsUserDetailsRequestParams>, [key, value]: [string, string | undefined]) => {
				if (value !== undefined) {
					data[key as keyof TCallerActionsUserDetailsRequestParams] = value
				}
				return data
			},
			{}
		)

		const response = await trackPromise(
			getUserDatails(sanitizedData as TCallerActionsUserDetailsRequestParams),
			'identify'
		)

		const userDataValid = typeof response === 'object' && Object.keys(response).length > 0

		if (userDataValid) {
			setUserDetails?.(response)
			const { tenant, ...authInfo } = sanitizedData as TCallerActionsUserDetailsRequestParams

			setUserAuthData?.({
				tenant,
				authInfo,
			})
		} else {
			resetUserId()
		}

		return userDataValid
	}

	useEffect(() => {
		if (!data) {
			return
		}

		/**
		 * prefill form values if query string is set
		 * submit after waiting for the next event loop
		 */
		if (data.tenantFromQuery && data.decadiaIdFromQuery) {
			const tenant = data.tenantFromQuery
			const id = data.decadiaIdFromQuery

			setUpdatedValues({
				tenant: tenant,
				decadiaId: String(id),
			})

			setTimeout(() => {
				setCallTriggeredByQueryStrings(true)
				formRef.current?.submitForm()
			})

			return
		}
	}, [resetData, data])

	return (
		<div className="caller-actions">
			{callTriggeredByQueryString && (
				<div className={`promise-overlay promise-overlay--${promiseInProgress ? 'visible' : 'hidden'}`}>
					<div className="promise-overlay__spinner">
						(
						<LoadingSpinner
							delay={0}
							area={'identify'}
							onPromiseInProgressChange={setPromiseInProgress}
							className="submit__loading"
						/>
					</div>
					<p>
						<Trans i18nKey="view.callerActionsIdentify.loading" />
					</p>
				</div>
			)}

			<ViewHeader
				headline={t('view.callerActionsIdentify.headline')}
				subheadline={t('view.callerActionsIdentify.subtitle')}
			/>

			<div className="caller-actions__grid max-content-width--narrow">
				<p className="font-family-bold caller-actions__grid-item--center-column margin--top">
					<Trans i18nKey="view.callerActionsIdentify.copytext" />
				</p>

				<Form
					ref={formRef}
					className="caller-actions"
					fields={inputFields}
					onSubmit={handleOnSubmit}
					onFormChange={setUpdatedFormFields}
					alwaysAllowSubmit={true}
					updatedValues={updatedValues}
					errorMessages={[t('view.callerActionsIdentify.form.error')]}
					hideSubmit={true}
				>
					<Icon
						className="caller-actions__aside-icon caller-actions__aside-icon--identity margin--top"
						type={IconType.profile}
					/>

					{isDecadiaId && (
						<img
							className="caller-actions__id-card margin--top"
							src={decadiacardIllustration}
							width="183"
							alt=""
						/>
					)}
				</Form>
			</div>

			<Button
				className="horizontal-center send"
				label={t('view.callerActionsIdentify.form.submit')}
				type={ButtonType.highlight}
				onClick={() => formRef.current?.submitForm()}
				promiseTracker={{ area: 'identify' }}
			/>
		</div>
	)
}

export default CallerActionsIdentify
