import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Note, ITimelineNoteContent } from './Note/Note'
import { IconType } from 'components/Icons'
import Icon from 'shared/components/Icon'
import dateFormat from 'shared/helper/dateFormat'

export enum TimelineItemType {
	NOTE = 'note',
}

export interface ITimelineBaseItem {
	type: TimelineItemType
	createdAt: string
	itemData?: any
}

export interface ITimelineNote extends ITimelineBaseItem {
	type: TimelineItemType.NOTE
	itemData: ITimelineNoteContent
}

export interface ITimelineItem extends ITimelineNote {}

export interface ITimelineItemProps extends ITimelineItem {
	refreshHook(): void
}

export function noteContentToTimeLineItem(note: ITimelineNoteContent): ITimelineItem {
	return {
		type: TimelineItemType.NOTE,
		createdAt: note.createdAt,
		itemData: note,
	}
}

export const TimelineItem: FunctionComponent<ITimelineItemProps> = ({ type, createdAt, itemData, refreshHook }) => {
	const { t } = useTranslation()

	const itemDate = dateFormat(new Date(createdAt), {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	})

	const itemTime = dateFormat(new Date(createdAt), {
		hour: '2-digit',
		minute: '2-digit',
	})

	const getContent = () => {
		switch (type) {
			case TimelineItemType.NOTE:
				const { noteId, createdBy, createdAt, editedBy, editedAt, content } = itemData
				return (
					<Note
						noteId={noteId}
						createdBy={createdBy}
						createdAt={createdAt}
						editedBy={editedBy}
						editedAt={editedAt}
						content={content}
						refreshHook={refreshHook}
					/>
				)
		}
	}

	return (
		<div className="timeline-entry">
			<div className="timeline-entry__header">
				<div className="timeline-entry__type">{t(`view.pensionerTimeline.types.${type}.label`)}</div>
				<div className="timeline-entry__date">{itemDate}</div>
				<div className="timeline-entry__time">
					{itemTime} {t('generic.oClock')}
				</div>
			</div>
			<div className="timeline-entry__separator">
				<Icon type={IconType.notizen} className="margin--horizontal margin--default no-margin--left" />
				<div className="verticalLine"></div>
			</div>
			<div className="timeline-entry__content">{getContent()}</div>
		</div>
	)
}
