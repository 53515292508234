import { IconType } from 'components/Icons'
import { DefaultTFuncReturn } from 'i18next'
import React, { FunctionComponent, ReactElement, useMemo, useRef, useState } from 'react'
import DatePicker from 'react-date-picker'
import { useTranslation } from 'react-i18next'
import convertToDashCase from '../helper/convertToDashCase'
import Icon from './Icon'

export enum ValidityStateType {
	valueMissing = 'valueMissing',
	typeMismatch = 'typeMismatch',
	patternMismatch = 'patternMismatch',
	tooLong = 'tooLong',
	tooShort = 'tooShort',
	rangeUnderflow = 'rangeUnderflow',
	rangeOverflow = 'rangeOverflow',
	stepMismatch = 'stepMismatch',
	badInput = 'badInput',
	customError = 'customError',
}

type DatePickerProps = Parameters<typeof DatePicker>[0]
export interface DateInputProps extends DatePickerProps {
	label?: string | React.ReactElement | DefaultTFuncReturn
	highlight?: boolean
	error?: { type: string; message?: string | DefaultTFuncReturn }
	ref?: any
	showRequiredAsterisk?: boolean
}

export const DateInput: FunctionComponent<DateInputProps> = React.forwardRef((props: DateInputProps, ref: any) => {
	const { t } = useTranslation()

	const { onChange, className, error, label, name, value, showRequiredAsterisk, highlight, ...attributes } = props

	const [selectedDate, setSelectedDate] = useState<DateInputProps['value']>(value)
	const [startDate, setStartDate] = useState<Date | undefined>()

	const handleOnChange: typeof onChange = (value) => {
		let updatedValue = value

		if (value !== null) {
			if (attributes.minDate && value < attributes.minDate) {
				updatedValue = attributes.minDate
			}

			if (attributes.maxDate && value > attributes.maxDate) {
				updatedValue = attributes.maxDate
			}
		}

		setSelectedDate(updatedValue)

		if (onChange) {
			onChange(updatedValue)
		}
	}

	const inputLabel = useMemo((): ReactElement | undefined => {
		if (!label) {
			return
		}

		return (
			<label className="date-input__label bold-small-heading">
				{label}
				{showRequiredAsterisk && <>{props.required ? ' *' : ` ${t('generic.optionalFormField')}`}</>}
			</label>
		)
	}, [label, props.required, showRequiredAsterisk, t])

	const showError = (): ReactElement | undefined => {
		return (
			<div
				style={{ opacity: Number(undefined !== error) }}
				className={`date-input__error ${error ? `date-input__error--${error.type}` : ''}`}
			>
				{error?.message}
			</div>
		)
	}

	const classes = useMemo((): string[] => {
		const classes = []

		if (name) {
			classes.push(`date-input--${convertToDashCase(name)}`)
		}

		if (className) {
			classes.push([className].flat().filter((i) => typeof i === 'string') as string[])
		}

		if (highlight) {
			classes.push('input--highlighted')

			if (error && 'hint' === error.type) {
				classes.push('input--highlighted-hint')
			}
		}

		if (selectedDate) {
			classes.push('date-input--dirty')
		}

		return classes.flat()
	}, [className, name, highlight, error, selectedDate])

	const parentRef = useRef<HTMLDivElement>(null)

	return (
		<div
			className={['date-input']
				.concat(classes)
				.filter((item) => undefined !== item)
				.join(' ')}
			ref={parentRef}
			/**
			 * The DatePicker does fire an onChange event if one of the
			 * day, month or year fields are invalid, due to a user entering
			 * invalid data directly in the input fields.
			 *
			 * That means, that the default error handling using the onChange event is not fired.
			 *
			 * Therefore the native html5 validity check is fired to inform
			 * the user about the invalid data.
			 */
			onBlur={() => {
				parentRef.current?.querySelectorAll('input').forEach((input) => {
					if (input.value && false === input.checkValidity?.()) {
						input.reportValidity()
					}
				})
			}}
		>
			{inputLabel}

			<DatePicker
				inputRef={ref}
				className="date-input__tag"
				showLeadingZeros={true}
				onChange={handleOnChange}
				value={selectedDate}
				calendarIcon={<Icon type={IconType.calendar} color="var(--color-gold)" />}
				clearIcon={<></>}
				minDetail="month"
				openCalendarOnFocus={false}
				activeStartDate={startDate}
				onActiveStartDateChange={({ activeStartDate }) => {
					if (activeStartDate) {
						setStartDate(activeStartDate)
					}
				}}
				onCalendarOpen={() => {
					if (null === selectedDate) {
						setStartDate(new Date())
					}
				}}
				{...attributes}
			/>

			{showError()}
		</div>
	)
})
