import { useUserRecord } from 'hooks/useUserRecord'
import { FunctionComponent, HTMLAttributes } from 'react'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'

const CloseUserRecord: FunctionComponent<HTMLAttributes<HTMLDivElement>> = (props) => {
	const { getMainPath, navigateTo } = useRouteHelper()
	const user = useUserRecord()

	const handleOnClick = () => {
		if (undefined !== user.userId) {
			user.closeRecord()
			navigateTo(getMainPath(routesDictionary.dashboard))
		}
	}

	return (
		<div className={`close-user-record ${props.className}`} onClick={handleOnClick}>
			{props.children}
		</div>
	)
}

export default CloseUserRecord
