import classNames from 'classnames'
import { IconType } from 'components/Icons'
import { AgentState, useSoftphone } from 'hooks/useSoftphone'
import React, { FC, HTMLAttributes, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useKey } from 'react-use'
import Icon, { IconSize } from 'shared/components/Icon'
import { useDetectClickOutside } from 'shared/hooks/useDetectClickOutside'

export const ConnectStateSelector: FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
	const { availableAgentStates, agentState, changeAgentState } = useSoftphone()

	const [open, setOpen] = useState(false)

	const { t } = useTranslation()
	const buttonRef = useRef<HTMLDivElement>(null)
	const selectOptions = useMemo(() => {
		return availableAgentStates?.map((state) => ({
			value: state,
			label: t(`component.connect.agentState.${state}`),
		}))
	}, [availableAgentStates, t])

	useDetectClickOutside(buttonRef, () => setOpen(false))
	useKey('Escape', () => setOpen(false))

	const toggleButtonVisibility = () => (open ? setOpen(false) : setOpen(true))

	const handleOptionClick = (value: AgentState) => {
		if (!open) {
			return
		}

		changeAgentState(value)
	}

	return (
		<div
			className={classNames(
				className,
				'connect-state-selector',
				`connect-state-selector--agent-state-${agentState}`
			)}
		>
			<div
				style={
					{
						'--css-options-number': selectOptions?.length,
					} as React.CSSProperties
				}
				className={`connect-state-selector__input-button ${
					open && 'connect-state-selector__input-button--open'
				}`}
				onClick={toggleButtonVisibility}
				ref={buttonRef}
			>
				<div className="connect-state-selector__options-wrapper">
					{selectOptions?.map((v, i) => {
						// use startWith to map set after-call-work active on state after-call-work-manual
						const active = v.value.startsWith(agentState)

						return (
							<button
								key={`connect-state-option-${i}`}
								onClick={() => {
									handleOptionClick(v.value)
								}}
								style={
									{
										'--css-button-index': i,
									} as React.CSSProperties
								}
								className={`connect-wrapper--agent-state-${v.value}
						connect-state-selector__input-option
						${active ? 'connect-state-selector__input-option--active' : ''} 
						`}
							>
								<span className="connect-state-selector__state-indicator" />
								<span className="connect-state-selector__label"> {v.label} </span>
								{active && !open && (
									<Icon
										type={IconType.arrow}
										size={IconSize.extraSmall}
										rotate={-90}
										color="var(--color-blue--medium)"
									/>
								)}
							</button>
						)
					})}
				</div>
			</div>
		</div>
	)
}
