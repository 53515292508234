import { ProvideUserRecord } from 'hooks/useUserRecord'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Toast } from 'shared/components/ZFToast'
import { ProvideAuth } from '../hooks/useAuth'
import RouteWrapper from './RouteWrapper'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			staleTime: 10000,
		},
	},
})

/**
 * HINT:
 * it is required to set window.process in order to make
 * the app work with create-react-app v5
 *
 * see: https://stackoverflow.com/questions/70368760/react-uncaught-referenceerror-process-is-not-defined
 **/
window.process = {
	...window.process,
}

const App: React.FC = () => {
	return (
		<ProvideAuth>
			<QueryClientProvider client={queryClient}>
				<ProvideUserRecord>
					<RouteWrapper />
					<Toast />
				</ProvideUserRecord>
			</QueryClientProvider>
		</ProvideAuth>
	)
}

export default App
