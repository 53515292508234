import GridListCell from 'components/GridListCell'
import GridListRow from 'components/GridListRow'
import { IPartnerDetailTransaction } from 'hooks/useApi'
import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import dateFormat from 'shared/helper/dateFormat'
import GridList from 'components/GridList'

export interface PartnerDetailProps {
  className?: string
  data: IPartnerDetailTransaction[] | undefined;
}

const PartnerDetailTable: FunctionComponent<PartnerDetailProps> = props => {
  const { t } = useTranslation()
  const { data } = props
  const tableHeaders = [
    'confirmedAt',
    'firstName',
    'lastName',
    'street',
    'dateOfBirth',
    'placeOfBirth'
  ]

  const renderTableHeader = () => {
    const headerCellClass = 'grid-list__cell--header'

    return (
      <GridListRow className={'grid-list__row--header'}>
        {tableHeaders.map((label, index) => (
          <GridListCell className={headerCellClass} key={`header-field-${index}`}>
            {t(`view.pensionerRiskOptions.tableHeaders.${label}`)}
          </GridListCell>
        ))}
      </GridListRow>
    )
  }

  const renderTableRows = () => {
    if (!data) {
      return
    }
    return (
      <>
        {data.map((entry, index: number) => (
          <GridListRow key={`entry-${index}`}>
            {tableHeaders.map((key, fieldIndex: number) => {
              let transformedValue

              let classes = ['user-select--all']
              switch (key) {
                case 'confirmedAt':
                case 'dateOfBirth':
                  if (entry[key] !== undefined) {
                    transformedValue = dateFormat(new Date(Date.parse(entry[key]!)))
                  } else {
                    transformedValue = '-'
                  }
                  break
                case 'lastName':
                case 'firstName':
                case 'placeOfBirth':
                  transformedValue = entry[key]
                  break
                case 'street':
                  transformedValue = `${entry[key]} ${entry['zip']} ${entry['city']} ${entry['country']}`
                  break
              }

              const cellClasses = classes.join(" ")

              return (
                <GridListCell key={`entry-field-${index}-${fieldIndex}`} className={cellClasses}>
                  {transformedValue}
                </GridListCell>
              )
            })}
          </GridListRow>
        ))}
      </>
    )
  }


  return (
    <>
      {data?.length !== 0 && (
        <GridList className="delegate__results" columnCount={tableHeaders.length}>
          {renderTableHeader()}
          {renderTableRows()}
        </GridList>
      )}
    </>

  )
}

export default PartnerDetailTable
