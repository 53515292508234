import { DisclaimerMessage } from 'components/Disclaimer'
import CurrentRiskOption from 'components/RiskOption/CurrentRiskOption'
import PartnerDetailTable from 'components/RiskOption/PartnerDetailTable'
import RiskOptionTable from 'components/RiskOption/RiskOptionTable'
import { ViewHeader } from 'components/ViewHeader'
import useApi, { QueryKey } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import { LoadingSpinnerArea } from 'shared/components/LoadingSpinner'

const PensionerRiskOptions: React.FC = () => {
  const { userId, firstnamesGenitive: name } = useUserRecord()
  const api = useApi()

  const { data, status } = useQuery(
    [
      QueryKey.risikoleistungen,
      {
        userId,
        area: LoadingSpinnerArea.header,
      },
    ],
    api.getRisikoLeistungen,
    {
      enabled: !!userId,
    }
  )




  return (
    <div className={`visually-hidden visually-hidden--${String(status !== 'success')}`}>
      <ViewHeader
        headline={<Trans i18nKey="view.pensionerRiskOptions.headline" values={{ name }} />}
        subheadline={<Trans i18nKey="view.pensionerRiskOptions.subheadline" />}

      >
        {'success' === status && !data && (
          <DisclaimerMessage
            text={<Trans i18nKey="view.pensionerRiskOptions.noDataDisclaimer" />}
          />
        )}
      </ViewHeader>
      {data &&
        (<><h2 className="flex flex--align-items-center margin--vertical margin--large">
          <Trans i18nKey="view.pensionerRiskOptions.disabilityOptionList" />
        </h2>
          <CurrentRiskOption data={data?.currentDisabilityOption} />
          <RiskOptionTable data={data?.disabilityTransactions} />
          <h2 className="flex flex--align-items-center margin--vertical margin--large">
            <Trans i18nKey="view.pensionerRiskOptions.partnerBenefit" />
          </h2>
          <CurrentRiskOption data={data?.currentPartnerBenefitOption} />
          <RiskOptionTable data={data?.partnerBenefitTransactions} />
          <h2 className="flex flex--align-items-center margin--vertical margin--large">
            <Trans i18nKey="view.pensionerRiskOptions.partner" />
          </h2>
          <PartnerDetailTable data={data?.partnerDetailTransactions} /></>)}

    </div>
  )
}

export default PensionerRiskOptions
