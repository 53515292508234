import useApi, { QueryKey } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

const Stammdaten: React.FC = () => {
	const { userId } = useUserRecord()
	const api = useApi()
	const { data: stammdaten } = useQuery(
		[QueryKey.austrittsbearbeitungen, { userId }],
		api.getAustrittsbearbeitungenStammdatenByIdentNumber,
		{
			enabled: !!userId,
		}
	)

	const fields = useMemo(() => {
		const data = [
			[
				{
					headline: 'firstName',
					value: stammdaten?.vorname,
				},

				{
					headline: 'lastName',
					value: stammdaten?.nachname,
				},
				{ headline: 'address', value: stammdaten?.strasse },
				{ headline: 'postalCode', value: stammdaten?.plz },
				{ headline: 'city', value: stammdaten?.stadt },
			],
			[
				{
					headline: 'identNumber',
					value: stammdaten?.identNumber,
				},
				{
					headline: 'hr-id',
					value: stammdaten?.personalnummer,
				},

				{
					headline: 'birthday',
					value: stammdaten?.geborenAm,
				},
			],
			[
				{
					headline: 'phoneNumber',
					value: stammdaten?.telefonnummer,
				},
				{
					headline: 'additionalPhoneNumber',
					value: stammdaten?.weitereTelefonnummern,
				},

				{
					headline: 'email',
					value: stammdaten?.emailAdresse,
				},
				{
					headline: 'additionalEmail',
					value: stammdaten?.weitereEmailAdressen,
				},
			],
		]

		return data.map((v, i) => {
			return (
				<div className="austrittsbearbeitungen__sub-section " key={`div-stammdaten-${i}`}>
					{v.map((field, i2) => (
						<dl key={`stammdaten-field-${i}-${i2}`}>
							<dt className="bold-small-heading">
								<Trans i18nKey={`view.pensionerProfile.tabs.person.fields.${field.headline}`}></Trans>
							</dt>
							<dd>{field.value || '-'}</dd>
						</dl>
					))}
				</div>
			)
		})
	}, [stammdaten])

	return <div className="austrittsbearbeitungen-stammdaten">{fields}</div>
}

export default Stammdaten
