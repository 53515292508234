import useApi, { QueryKey } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { trackPromise } from 'react-promise-tracker'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { ButtonType } from 'shared/components/Button'
import Form, { FormFieldType, FormFields } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import zfToast, { ZFToastProps } from 'shared/components/ZFToast'

export const Notizen = () => {
	const [requestInProgress, setRequestInProgress] = useState(false)
	const [lastChangeSaved, setLastChangeSaved] = useState(false)
	const eTag = useRef<string>()

	const [formValues, setFormValues] = useState<{
		KPGNummer: string
		HPRAnspruch: string
		HPRDatum: string
		HPRErledigt: string
	}>({
		KPGNummer: '',
		HPRAnspruch: '',
		HPRDatum: '',
		HPRErledigt: '',
	})

	const api = useApi()
	const { userId } = useUserRecord()
	const { data, isLoading } = useQuery(
		[QueryKey.austrittsbearbeitungNotizen, { userId }],
		api.getAustrittsbearbeitungNotizen,
		{
			enabled: !!userId,
		}
	)

	useEffect(() => {
		data && setFormValues(data)
		eTag.current = data?.eTag
	}, [data])

	const i18nBasePath = 'view.pensionerProfile.tabs.assets.notes'

	const onFormChange = (fields: any) => {
		setLastChangeSaved(false)
		setFormValues({
			KPGNummer: fields?.KPGNummer?.value || '',
			HPRAnspruch: fields?.HPRAnspruch?.value || '',
			HPRDatum: fields?.HPRDatum?.value || '',
			HPRErledigt: fields?.HPRErledigt?.value || '',
		})
	}

	const onSubmit = useCallback(
		async (fields: any) => {
			const payload = {
				KPGNummer: fields.KPGNummer || '',
				HPRAnspruch: fields.HPRAnspruch || '',
				HPRDatum: fields.HPRDatum || '',
				HPRErledigt: fields.HPRErledigt || '',
				eTag: eTag.current || data?.eTag || '',
			}

			if (userId) {
				setRequestInProgress(true)
				const response = await trackPromise(
					api.postAustrittsbearbeitungNotizen(userId, payload),
					'austrittsbearbeitungNotizen'
				)
				setRequestInProgress(false)

				if (response && response.eTag) {
					eTag.current = response.eTag
					setLastChangeSaved(true)
					const toastProps: ZFToastProps = {
						title: t('view.pensionerProfile.tabs.assets.notes.toast.title'),
						content: t('view.pensionerProfile.tabs.assets.notes.toast.postSucceed'),
						type: 'success',
					}
					zfToast(toastProps)
					return { successful: true }
				}

				if (response === 409) {
					return { successful: false, errorMessages: [t(`${i18nBasePath}.errors.409`)] }
				}
				const toastProps: ZFToastProps = {
					title: t('view.pensionerProfile.tabs.assets.notes.toast.title'),
					content: t('view.pensionerProfile.tabs.assets.notes.toast.error'),
					type: 'error',
				}
				zfToast(toastProps)
				return { successful: false }
			}
		},
		[data?.eTag, userId, api]
	)

	const fields: FormFields | undefined = useMemo(() => {
		if (!formValues && isLoading) {
			return undefined
		}

		const showAll = formValues?.HPRAnspruch === 'ja'

		return {
			KPGNummer: {
				fieldType: FormFieldType.text,
				label: <Trans i18nKey={`${i18nBasePath}.KPGNummer`} />,
				value: data?.KPGNummer || '',
			},
			HPRAnspruch: {
				fieldType: FormFieldType.select,
				label: <Trans i18nKey={`${i18nBasePath}.HPRAnspruch.label`} />,
				alternativeStyle: true,
				options: [
					{
						value: 'keine Auswahl',
						label: <Trans i18nKey={`${i18nBasePath}.HPRAnspruch.options.default`} />,
					},
					{ value: 'ja', label: <Trans i18nKey={`${i18nBasePath}.HPRAnspruch.options.yes`} /> },
					{ value: 'nein', label: <Trans i18nKey={`${i18nBasePath}.HPRAnspruch.options.no`} /> },
				],
				value: data?.HPRAnspruch || '',
			},
			HPRDatum: {
				fieldType: FormFieldType.text,
				type: TextInputType.date,
				label: <Trans i18nKey={`${i18nBasePath}.HPRDatum`} />,
				hidden: !showAll,
				value: data?.HPRDatum || '',
				className: 'no-margin--top',
			},
			HPRErledigt: {
				fieldType: FormFieldType.select,
				label: 'HPR erledigt',
				alternativeStyle: true,
				hidden: !showAll,
				options: [
					{
						value: 'keine Auswahl',
						label: <Trans i18nKey={`${i18nBasePath}.HPRErledigt.options.default`} />,
					},
					{
						value: 'liegt vor',
						label: <Trans i18nKey={`${i18nBasePath}.HPRErledigt.options.present`} />,
					},
					{ value: 'ja', label: <Trans i18nKey={`${i18nBasePath}.HPRErledigt.options.yes`} /> },
				],
				value: data?.HPRErledigt || '',
			},
		}
	}, [formValues, data, isLoading])

	return (
		<div className="austrittsbearbeitung-notizen">
			<h3 className="text-transform-uppercase">
				<Trans i18nKey={`${i18nBasePath}.headline`} />
			</h3>

			{fields && !isLoading && (
				<Form
					className="austrittsbearbeitung-notizen__form"
					disabled={requestInProgress || lastChangeSaved}
					fields={fields}
					onSubmit={onSubmit}
					promiseTracker={{ area: 'austrittsbearbeitungNotizen' }}
					submitButtonType={ButtonType.secondary}
					submitLabel={lastChangeSaved ? t(`${i18nBasePath}.saved`) : t(`${i18nBasePath}.submit`)}
					onFormChange={onFormChange}
				></Form>
			)}
		</div>
	)
}
