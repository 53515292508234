import WawuExportModal from 'components/AdminWawuModal'
import useApi from 'hooks/useApi'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectInput from '../../../shared/components/SelectInput'

function ControlWawuExport() {
	const today = useRef<Date>(new Date())

	const [selectedWawuYear, setSelectedWawuYear] = useState<string>(today.current.getFullYear().toString())
	const [selectedWawuMonth, setSelectedWawuMonth] = useState<string>((today.current.getMonth() + 1).toString())
	const [wawuProdModalOpen, setWawuProdModalOpen] = useState(false)

	const { t } = useTranslation()
	const api = useApi()

	const getSelectMonthOptions = () => {
		return Array.from(Array(12), (_, i) => i + 1).map((month) => {
			return { value: month.toString(), label: t('generic.month.' + month) }
		})
	}

	const getSelectYearOptions = () => {
		return Array.from(Array(3), (_, i) => i - 1).map((yearDif) => {
			return {
				value: (today.current.getFullYear() + yearDif).toString(),
				label: (today.current.getFullYear() + yearDif).toString(),
			}
		})
	}

	const handleExecuteWawuDryRunExport = async () => {
		await api
			.getWawuExport({ month: selectedWawuMonth, year: selectedWawuYear, run: 'dry-run' })
			.then((exportUrl: string) => {
				const a = document.createElement('a')
				a.href = exportUrl
				a.target = '_blank'
				a.rel = 'noopener'
				a.click()
			})
	}

	return (
		<div className="admin-dashboard__item admin-dashboard__item__2">
			<div className="flex flex--gap flex--justify-content-center">
				<SelectInput
					onChange={setSelectedWawuMonth}
					className="admin-dashboard__select"
					alternativeStyle={true}
					label={'Monat'}
					options={getSelectMonthOptions()}
					value={selectedWawuMonth}
				/>
				<SelectInput
					onChange={setSelectedWawuYear}
					className="admin-dashboard__select"
					alternativeStyle={true}
					label={'Jahr'}
					options={getSelectYearOptions()}
					value={selectedWawuYear}
				/>
			</div>
			<div className="flex flex--gap flex--justify-content-center">
				<button
					className="button button--width-medium admin-dashboard__button"
					onClick={handleExecuteWawuDryRunExport}
				>
					{t('view.adminDashboard.wawuExport.buttons.dryRun')}
				</button>
				<button
					className="button button--width-medium admin-dashboard__button"
					onClick={() => {
						setWawuProdModalOpen(!wawuProdModalOpen)
					}}
				>
					{t('view.adminDashboard.wawuExport.buttons.prodRun')}
				</button>
			</div>
			<WawuExportModal
				open={wawuProdModalOpen}
				payload={{ year: selectedWawuYear, month: selectedWawuMonth }}
				onModalClose={() => {
					setWawuProdModalOpen(false)
				}}
			/>

			<h1 className="no-margin--bottom">{t('view.adminDashboard.wawuExport.headline')}</h1>

			<div className="flex flex-s-align-items-center ">
				<div className="text-transform-uppercase text-color-gold margin--right">
					{t('view.adminDashboard.wawuExport.subheadline')}
				</div>
				<hr className="hr hr--gold flex--grow" />
			</div>
		</div>
	)
}

export default ControlWawuExport
