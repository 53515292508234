import { FunctionComponent, HTMLAttributes } from 'react'

const GridListCell: FunctionComponent<HTMLAttributes<HTMLDivElement>> = (props) => {
	const getClasses = () => {
		const classes = ['grid-list__cell']

		if (props.className) {
			classes.push(props.className)
		}
		return classes.join(' ')
	}

	return <div className={`${getClasses()}`}>{props.children}</div>
}

export default GridListCell
