import { IconType } from 'components/Icons'
import { FunctionComponent, HTMLAttributes, useState } from 'react'
import Button, { ButtonType } from 'shared/components/Button'
import NoteEditModal from './NoteEditModal'

export interface INoteEdit {
	noteId: string
	createdAt: string
	content: string
	refreshHook(): void
}

const TicketCloseWithoutAction: FunctionComponent<INoteEdit & HTMLAttributes<HTMLDivElement>> = ({
	noteId,
	createdAt,
	content,
	refreshHook,
	...attributes
}) => {
	const [modalOpen, setModalOpen] = useState(false)

	return (
		<div {...attributes}>
			<NoteEditModal
				open={modalOpen}
				onModalClose={() => {
					setModalOpen(false)
				}}
				noteId={noteId}
				createdAt={createdAt}
				content={content}
				refreshHook={refreshHook}
			/>
			<Button
				type={ButtonType.round}
				icon={IconType.edit}
				iconColor={'var(--color-white)'}
				onClick={() => {
					setModalOpen(modalOpen ? false : true)
				}}
				className="timeline-note__button"
			/>
		</div>
	)
}

export default TicketCloseWithoutAction
