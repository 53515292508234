import { IconType } from 'components/Icons'
import React, { FunctionComponent } from 'react'
import Icon from './Icon'

export interface ChevronButtonProps {
	color?: string
	forward?: boolean
	disabled?: boolean
	className?: string
	onClick?: any
}

const ChevronButton: FunctionComponent<ChevronButtonProps> = props => {
	const getClasses = () => {
		const classes: string[] = ['chevron-button']

		props.className ? classes.push(props.className) : classes.push('')
		props.forward ? classes.push('chevron-button--forward') : classes.push('chevron-button--previous')

		return classes.join(' ')
	}

	const onClick = () => {
		if (props.onClick) {
			props.onClick()
		}
	}

	return (
		<button onClick={onClick} disabled={props.disabled ? true : false} className={getClasses()}>
			<Icon
				width={5}
				type={IconType.arrow}
				rotate={props.forward ? 0 : 180}
				color={props.color ? props.color : '#ffffff'}
			/>
		</button>
	)
}

export default ChevronButton
