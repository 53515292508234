const hourFormat = (value?: number | Date) => {
	if (undefined === value) {
		return ''
	}

	try {
		const date = new Date(value)
		const hour = addLeadingZero(date.getHours())
		const minute = addLeadingZero(date.getMinutes())
		const seconds = addLeadingZero(date.getSeconds())
		return hour + ':' + minute + ':' + seconds
	} catch (error) {
		return ''
	}
}

const addLeadingZero = (num: number) => {
	return num > 9 ? num : '0' + num
}

export default hourFormat
