import { useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormFieldType, FormFields } from 'shared/components/Form'
import useApi, { AuszahlungsdatumGETResponse } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import getLocalizedYears from 'shared/helper/getLocalizedYears'
import { toast } from 'react-toastify'
import { TMonthSchema, TMonth } from 'shared/helper/types/month'
import zfToast, { ZFToastProps } from 'shared/components/ZFToast'
type AuszahlungsdatumProps = {
	auszahlungsdatum: AuszahlungsdatumGETResponse | undefined
	refetchAuszahlungsdatum: any
}

export const Auszahlungsdatum: React.FC<AuszahlungsdatumProps> = (props) => {
	const { userId } = useUserRecord()
	const { auszahlungsdatum, refetchAuszahlungsdatum } = props
	const [updatedValues, setUpdatedValues] = useState<Record<string, string>>()
	const api = useApi()
	const modal = useRef<ModalRefActions>()
	const { t } = useTranslation()

	const getFormFields: () => FormFields = () => {
		const { years, months } = getSettings()
		return {
			Month: {
				fieldType: FormFieldType.select,
				label: <Trans i18nKey={'view.zusageschreiben.content.entgeltnachweis.monat'} />,
				alternativeStyle: true,
				value: auszahlungsdatum?.month ? String(auszahlungsdatum.month) : undefined,
				className: 'auszahlungsdatum__content__date-form__fields__left',
				options: months.map((month: TMonthSchema) => ({
					value: String(month.monthNumber),
					label: month.monthName,
				})),
			},
			Year: {
				fieldType: FormFieldType.select,
				label: <Trans i18nKey={'view.zusageschreiben.content.entgeltnachweis.jahr'} />,
				alternativeStyle: true,
				value: auszahlungsdatum?.year ? String(auszahlungsdatum.year) : undefined,
				className: 'auszahlungsdatum__content__date-form__fields__right',
				options: years.map((year) => ({
					value: String(year),
					label: year === 0 ? '---' : String(year),
				})),
			},
		}
	}
	const [updatedFormFields, setUpdatedFormFields] = useState<FormFields>(getFormFields)

	const [buttonType, setButtonType] = useState<'update' | 'delete'>('update')

	const getUpdatedValues = () => {
		const month = auszahlungsdatum?.month ? String(auszahlungsdatum.month) : '0'
		const year = auszahlungsdatum?.year ? String(auszahlungsdatum.year) : '0'
		const updatedValues: Record<string, string> = {}
		updatedValues['Month'] = month
		updatedValues['Year'] = year
		return updatedValues
	}
	useEffect(
		() => {
			setUpdatedValues(getUpdatedValues())
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[auszahlungsdatum]
	)

	const auszahlungsdatumForm: FormFields = useMemo(
		() => {
			return getFormFields()
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[auszahlungsdatum]
	)

	const handleOpenModal = (buttonType: 'update' | 'delete') => {
		setButtonType(buttonType)
		modal.current?.openModal()
	}
	const getText = (position: string) => {
		return t(`component.confirmedAuszahlungOption.modell.auszahlungsdatum.${position}.${buttonType}`)
	}
	const handleSubmit = async () => {
		console.log(':::SUBMIT')
		if (!userId) {
			return
		}
		if (buttonType === 'delete') {
			const responseDelete = await api.deleteMasterDataAuszahlungsdatum({ userId })
			if (!!responseDelete.success) {
				const toastProps: ZFToastProps = {
					title: 'Auszahlungsdatum',
					content: t('component.confirmedAuszahlungOption.modell.auszahlungsdatum.toast.deleteSucceed'),
					type: 'success',
				}
				zfToast(toastProps)
			}
			refetchAuszahlungsdatum()
			return
		}
		const month = Number(updatedFormFields?.Month.value) as TMonth | 0
		const year = Number(updatedFormFields?.Year.value)
		if (month === 0 || year === 0) {
			refetchAuszahlungsdatum()
			setUpdatedValues(getUpdatedValues())
			return
		}
		const responsePost = await api.postMasterDataAuszahlungsdatum({ userId, body: { month: month, year } })
		if (!!responsePost.success) {
			const toastProps: ZFToastProps = {
				title: 'Auszahlungsdatum',
				content: t('component.confirmedAuszahlungOption.modell.auszahlungsdatum.toast.postSucceed'),
				type: 'success',
			}
			zfToast(toastProps)
		} else {
			refetchAuszahlungsdatum()
			setUpdatedValues(getUpdatedValues())
		}
	}

	return (
		<div className="auszahlungsdatum">
			<div className="auszahlungsdatum__headline">
				<h2 className="auszahlungsdatum__headline__subheadline">
					<Trans i18nKey="component.confirmedAuszahlungOption.modell.auszahlungsdatum.title" />
				</h2>
			</div>
			<div className="auszahlungsdatum__content">
				<Form
					fields={auszahlungsdatumForm}
					hideSubmit={true}
					className="auszahlungsdatum__content__date-form"
					onFormChange={setUpdatedFormFields}
					onSubmit={() => {}}
					updatedValues={updatedValues}
				></Form>
				<div className="auszahlungsdatum__content__buttongroup">
					<Button
						type={[ButtonType.highlight]}
						className="auszahlungsdatum__content__buttongroup__button"
						onClick={() => handleOpenModal('update')}
					>
						<Trans i18nKey="component.confirmedAuszahlungOption.modell.auszahlungsdatum.button.update" />
					</Button>
					<Button
						type={[ButtonType.highlight]}
						className="auszahlungsdatum__content__buttongroup__button"
						onClick={() => handleOpenModal('delete')}
					>
						<Trans i18nKey="component.confirmedAuszahlungOption.modell.auszahlungsdatum.button.delete" />
					</Button>
				</div>
			</div>
			<Modal
				ref={modal}
				forceAction={false}
				header={getText('modalTitle')}
				buttonLabel={t(`generic.save`)}
				onModalClose={() => {}}
				onButtonClick={handleSubmit}
				className="modal--start-austrittstask"
			>
				<div>{getText('modalContent')}</div>
			</Modal>
		</div>
	)
}

const getSettings = () => {
	const thisYear = new Date().getFullYear()
	const years = [0].concat(getLocalizedYears(thisYear + 2, thisYear))
	const months: TMonthSchema[] = [
		{
			monthNumber: 0,
			monthName: '---',
		},
	].concat(
		Array.from({ length: 12 }, (_, i) => {
			const monthNumber = i + 1
			const monthName = new Date(0, monthNumber, 0).toLocaleString('de-DE', { month: 'long' })
			return { monthNumber, monthName }
		})
	)
	return { years, months }
}
