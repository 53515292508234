import logo from 'assets/decadia-logo-white@2x.png'
import { Link } from 'raviger'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

const Logo: FunctionComponent<{ className?: string }> = (props) => {
	const { t } = useTranslation()

	return (
		<Link href="/" className={`logo ${props.className}`}>
			<img src={logo} width="99" height="70" alt={t<string>('partial.logo.altTag')} />
		</Link>
	)
}

export default Logo
