import useApi from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import { FunctionComponent, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Modal, { ModalRefActions } from 'shared/components/Modal'

export interface INoteDeleteModal {
	open: boolean
	onModalClose: () => void
	noteId: string
	createdAt: string
	refreshHook(): void
}

const NoteDeleteModal: FunctionComponent<INoteDeleteModal> = (props) => {
	const { open, onModalClose, noteId, createdAt, refreshHook } = props

	const modal = useRef<ModalRefActions>()
	const api = useApi()
	const { t } = useTranslation()
	const { userId: pensionerId } = useUserRecord()

	useEffect(() => {
		if (open) {
			modal.current?.openModal()
		}
	}, [open])

	const handleOnSubmit = async () => {
		if (!pensionerId) {
			return
		}
		await api.deleteNote(pensionerId, noteId, createdAt).then(refreshHook)
	}

	const renderCloseWithoutActionMessage = () => {
		return (
			<div className="note-delete-disclaimer">
				<h3>{t('view.pensionerTimeline.types.note.deleteModal.text')}</h3>
			</div>
		)
	}

	return (
		<Modal
			ref={modal}
			forceAction={false}
			header={t('view.pensionerTimeline.types.note.deleteModal.header')}
			buttonLabel={t('generic.confirm')}
			onModalClose={onModalClose}
			onButtonClick={handleOnSubmit}
			className="modal--close-without-action"
		>
			<div className="padding--horizontal margin--vertical text-align--left">
				{renderCloseWithoutActionMessage()}
			</div>
		</Modal>
	)
}

export default NoteDeleteModal
