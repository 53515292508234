import { DisclaimerMessage } from 'components/Disclaimer'
import { IconType } from 'components/Icons'

import PensionerTransactionTable from 'components/PensionerTransactionTable'
import { ViewHeader } from 'components/ViewHeader'
import useApi, { ITransaction, QueryKey } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import Button, { ButtonType } from 'shared/components/Button'
import { LoadingSpinnerArea } from 'shared/components/LoadingSpinner'

const PensionerTransactions: React.FC = () => {
	const { userId, firstnamesGenitive: name } = useUserRecord()
	const api = useApi()
	const [transactions, setTransactions] = useState<ITransaction[] | undefined>()

	const { data, status } = useQuery(
		[
			QueryKey.transactions,
			{
				userId,
				area: LoadingSpinnerArea.header,
			},
		],
		api.getAllTransactions,
		{
			enabled: !!userId,
		}
	)

	const filteredData = data?.filter((transaction) => transaction.isRelevant)
	const [checked, setChecked] = React.useState(true)
	const handleChange = () => {
		setChecked(!checked)
	}

	useEffect(() => {
		if (checked === true) {
			setTransactions(filteredData)
		} else {
			setTransactions(data)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checked])

	useEffect(() => {
		setTransactions(filteredData)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	return (
		<div className={`visually-hidden visually-hidden--${String(status !== 'success')}`}>
			<ViewHeader
				headline={<Trans i18nKey="view.pensionerTransactions.headline" values={{ name }} />}
				subheadline={<Trans i18nKey="view.pensionerTransactions.subheadline" />}
			>
				{'success' === status && !data && (
					<DisclaimerMessage text={<Trans i18nKey="view.pensionerTransactions.noDataDisclaimer" />} />
				)}
			</ViewHeader>
			<div className="pensioner-transactions__div">
				<Button
					className={'button pensioner-transactions__confirmation'}
					key={`confirmbutton-0`}
					type={ButtonType.round}
					icon={checked ? IconType.checkmarkFilled : IconType.checkmark}
					onClick={handleChange}
				/>
				<label className="pensioner-transactions__text">
					<Trans i18nKey="view.pensionerTransactions.filter" />
				</label>
			</div>

			{data && (
				<PensionerTransactionTable
					className="pensioner-transactions__tabelle"
					data={transactions}
				></PensionerTransactionTable>
			)}
		</div>
	)
}

export default PensionerTransactions
