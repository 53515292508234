import { useUserRecord } from 'hooks/useUserRecord'
import { useRoutes } from 'raviger'
import React, { useEffect } from 'react'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import useNavigationState from 'state/useNavigationState'

const Pensioner: React.FC<{ id: string }> = ({ id }) => {
	const { navigateTo, getChildPath, getMainPath, routes } = useRouteHelper(routesDictionary.pensioner)
	const { userId, closeRecord, openRecord } = useUserRecord()
	const routeResult = useRoutes(routes, { basePath: `${getMainPath(routesDictionary.pensioner)}/${id}` })
	const [navigationState, navigationActions] = useNavigationState()

	useEffect(() => {
		if (false === navigationState.visible && undefined !== userId) {
			navigationActions.setVisible(true)
		}
	}, [userId, navigationState, navigationActions])

	const defaultRoute = () => {
		navigateTo(getChildPath(routesDictionary.pensioner, 'profile', { main: [id] }), true)
	}

	const renderRoute = () => {
		if (userId !== id) {
			closeRecord().then(async () => {
				sessionStorage.setItem('pensioner', id)

				await openRecord(id)

				return routeResult || defaultRoute()
			})

			return
		}

		return routeResult || defaultRoute()
	}

	return <>{renderRoute()}</>
}

export default Pensioner
