import {
	AnalysisIdentNumberDocument,
	AnalyzedMagicField,
	FieldConstraints,
	isMagicFieldGroup,
	MagicField,
	MagicLetterDocumentMetaData,
	MagicLetterFields,
	OptionalString,
} from 'components/MagicLetter/magic-letter-metadata.model'
import { TFunction } from 'i18next'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FormField, FormFields, FormFieldType } from 'shared/components/Form'
import { SelectOption } from 'shared/components/SelectInput'
import { IDENT_NUMBER_STRING_VALIDATION } from '../shared/helper/numberFormats'
import { Ticket, TicketModelId } from './useApi'

// import useApi, { PensionerSearchQueryParams, PensionerSearchResult, QueryKey } from 'hooks/useApi'
// import React, { ChangeEvent, FunctionComponent, useState } from 'react'

export const RECEIVED_AT_FIELD_NAME: keyof Pick<Ticket, 'receivedAt'> = 'receivedAt'
export const IDENT_NUMBER_FIELD_NAME: keyof Pick<AnalysisIdentNumberDocument, 'identNumber'> = 'identNumber'
export const DOCUMENT_TYPE_FIELD_NAME: keyof Pick<MagicLetterDocumentMetaData, 'type'> = 'type'
export const NAME_FIELD_NAME: keyof Pick<AnalysisIdentNumberDocument, 'name'> = 'name'

interface IFormFieldsCondition {
	conditionField: string
	conditionValue?: string | number
	conditionResult: boolean
}

interface IFormFieldsConditions {
	disabled?: IFormFieldsCondition
	hidden?: IFormFieldsCondition
}
interface IFormFieldsConfiguration {
	formFields: FormFields
	formFieldMetaData: { [key: string]: Partial<AnalyzedMagicField<string>> }
	formFieldConditions: { [key: string]: IFormFieldsConditions[] }
}

export const useFormFieldsConfiguration = () => {
	const { t } = useTranslation()

	const getFieldConfiguration = (
		field: AnalyzedMagicField<OptionalString>,
		subfields?: AnalyzedMagicField<OptionalString>[]
	): FormField | void => {
		const type = field.ui?.type || field.fieldType

		switch (type) {
			case 'trueFalse': {
				return {
					fieldType: FormFieldType.select,
					alternativeStyle: true,
					value: field.value,
					options: [
						{
							label: t('generic.pleaseSelect'),
							value: '',
						},
						{
							value: 'true',
							label: t('generic.yes'),
						},
						{
							value: 'false',
							label: t('generic.no'),
						},
					],
				}
			}

			case 'selectOne': {
				const selectOptions =
					subfields?.map((field) => ({
						value: field.optionValue || field.name,
						label: t(`component.ticket.optionLabels.${field.ui?.label || field.name}`),
					})) || []

				const selectedSubfield = subfields?.find((field) => 'selected' === field.selectionStatus)
				const value = selectedSubfield ? selectedSubfield.optionValue || selectedSubfield.name : undefined

				return {
					fieldType: FormFieldType.select,
					alternativeStyle: true,
					value: field.value || (value as string),
					options: [
						{
							label: field.ui?.emptyOptionLabel
								? t(`component.ticket.optionLabels.${field.ui.emptyOptionLabel}`)
								: t('generic.pleaseSelect'),
							value: '',
						},
						...selectOptions,
					],
				}
			}

			case 'selectOption': {
				break
			}

			default:
				return {
					fieldType: FormFieldType.text,
					value: field.value,
					autoComplete: 'off',
					// FIXME: should be returned by the backend
					pattern: 'number' === type ? '[0-9]*' : undefined,
					type,
				}
		}
	}

	// TODO: fix any
	const getFieldConstraints = (constraints: FieldConstraints): any => {
		return Object.entries(constraints).reduce((fieldContraints: any, [key, value]: [string, any]) => {
			fieldContraints[key] = value[0]

			fieldContraints['errorMessage'] = value[1]

			return fieldContraints
		}, {})
	}

	/**
	 * recursively go through a single field to set the provided condition
	 * for this field and all of its subfields
	 */
	const getRecursiveFieldConditions = ({
		field,
		conditionPresets,
		currentConditions,
	}: {
		field: any
		conditionPresets: any
		currentConditions: any
	}) => {
		let conditions = currentConditions

		if (field.fieldType !== 'selectOption') {
			currentConditions[field.name] = [...(currentConditions[field.name] || []), conditionPresets]
		}

		if (isMagicFieldGroup(field)) {
			field.fields.forEach((subfield) => {
				conditions = getRecursiveFieldConditions({
					field: subfield,
					conditionPresets,
					currentConditions: conditions,
				})
			})
		}

		return conditions
	}

	const getFieldConditions = (
		field: AnalyzedMagicField<OptionalString>,
		subfields?: AnalyzedMagicField<OptionalString>[]
	): { [key: string]: IFormFieldsConditions[] } => {
		let conditions: { [key: string]: IFormFieldsConditions[] } = {}

		switch (field.fieldType) {
			/**
			 * disable and hide subfields of selectOnes' if their name is
			 * not equal the fields' value
			 */
			case 'selectOne': {
				/**
				 * first split subfields of selectOnes' into selectOptions and other fields
				 */
				const [conditionalFields, conditionalOptions] = (subfields || []).reduce(
					(
						currentTuple: [AnalyzedMagicField<OptionalString>[], AnalyzedMagicField<OptionalString>[]],
						currentField
					) => {
						let [currentSubFields, currentSubOptions] = currentTuple

						if (currentField.fieldType === 'selectOption') {
							currentSubOptions = [...currentSubOptions, currentField]
						} else {
							currentSubFields = [...currentSubFields, currentField]
						}

						return [currentSubFields, currentSubOptions]
					},
					[[], []]
				)

				/**
				 * for all fields that are not a selectOption, set the condition value based on their own field name
				 */
				conditionalFields.forEach((conditionalField) => {
					conditions[conditionalField.name] = [
						{
							disabled: {
								conditionField: field.name,
								conditionValue: conditionalField.name,
								conditionResult: false,
							},
							hidden: {
								conditionField: field.name,
								conditionValue: conditionalField.name,
								conditionResult: false,
							},
						},
					]
				})

				/**
				 * for all fields that are selectOptions, first check if the field has subfields.
				 * then go through all subfields of the select option and set the condition value to the optionValue (if set) or name of the option field.
				 * This way the subfields will be visible if the selectOption is selected in the parent field
				 */
				conditionalOptions.forEach((conditionalOption) => {
					if (isMagicFieldGroup(conditionalOption)) {
						conditionalOption.fields.forEach((subfield) => {
							const conditionPresets = {
								disabled: {
									conditionField: field.name,
									conditionValue: conditionalOption.optionValue || conditionalOption.name,
									conditionResult: false,
								},
								hidden: {
									conditionField: field.name,
									conditionValue: conditionalOption.optionValue || conditionalOption.name,
									conditionResult: false,
								},
							}

							conditions = getRecursiveFieldConditions({
								field: subfield,
								conditionPresets,
								currentConditions: conditions,
							})
						})
					}
				})
				break
			}
		}

		return conditions
	}

	const extractFieldConfiguration = (
		formFields: MagicLetterFields<OptionalString, AnalyzedMagicField<OptionalString>>
	): IFormFieldsConfiguration => {
		const fieldConfiguration: IFormFieldsConfiguration = {
			formFields: {},
			formFieldMetaData: {},
			formFieldConditions: {},
		}

		const configurationReducer = (
			fields: MagicLetterFields<OptionalString, AnalyzedMagicField<OptionalString>>
		): IFormFieldsConfiguration =>
			fields.reduce((configuration: IFormFieldsConfiguration, field) => {
				const { name, label, constraints, description, value, ui, fieldType, ...metaData } = field

				let fieldConfiguration = getFieldConfiguration(
					field as AnalyzedMagicField<OptionalString>,
					// TODO: proper typing
					isMagicFieldGroup(field as unknown as MagicField<any>) ? (field as any).fields : undefined
				)

				let fieldConstraints = constraints ? getFieldConstraints(constraints) : null

				let fieldConditions = getFieldConditions(
					field as AnalyzedMagicField<OptionalString>,
					// TODO: proper typing
					isMagicFieldGroup(field as unknown as MagicField<any>) ? (field as any).fields : undefined
				)

				for (const [fieldKey, fieldCondition] of Object.entries(fieldConditions)) {
					const currentFieldConditions = configuration.formFieldConditions[fieldKey] || []

					configuration.formFieldConditions = {
						...configuration.formFieldConditions,
						[fieldKey]: [...currentFieldConditions, ...fieldCondition],
					}
				}

				fieldConfiguration = {
					...fieldConfiguration,
					...fieldConstraints,
				}

				/**
				 * only add field to formFields if it has a fieldConfiguration.
				 * e.g: a field might not have any configuration if it is a selectoption
				 */
				if (fieldConfiguration && 0 !== Object.keys(fieldConfiguration).length) {
					const formField: Partial<FormField> = {
						name,
						label: t(`component.ticket.fieldLabels.${ui?.label || name}`),
						...fieldConfiguration,
					}

					configuration.formFields[name] = formField as FormField
					configuration.formFieldMetaData[name] = metaData
				}

				/**
				 * run extractFieldConfiguration recursively for all sub fields
				 */
				if (isMagicFieldGroup(field)) {
					configurationReducer(field.fields)
				}

				return configuration
			}, fieldConfiguration)

		return configurationReducer(formFields)
	}

	return {
		extractFieldConfiguration,
	}
}

const renderIdentNumberField = (data: Ticket, t: TFunction): FormField => {
	// updateIdName: any
	return {
		pattern: IDENT_NUMBER_STRING_VALIDATION,
		required: true,
		label: t(`component.ticket.fieldLabels.identNumber`),
		errorMessage: t(`generic.formErrors.invalidIdentNumber`),
		fieldType: FormFieldType.text,
		// onChange: () => updateIdName(data),
		value: data.documentContent.content.identNumber,
	}
}

const stringifyTicketModelId = (ticketModelId: TicketModelId) => {
	return JSON.stringify({
		type: ticketModelId.type,
		version: ticketModelId.version,
		required: true,
	})
}

const renderDocumentTypeField = (ticketModelIds: TicketModelId[], data: Ticket, t: TFunction): FormField => {
	const options: SelectOption[] = ticketModelIds.map((tmid) => {
		const typeLabel = t(`component.documentEntry.type.${tmid.type}`)
		let versionLabel: string = ''

		// add versionAnnotation if possible
		if (tmid.versionAnnotation) {
			versionLabel =
				tmid.version !== '1'
					? ` (v${tmid.version} - ${tmid.versionAnnotation})`
					: ` (${tmid.versionAnnotation})`
		} else {
			versionLabel = tmid.version !== '1' ? ` (v${tmid.version})` : ''
		}
		return {
			value: stringifyTicketModelId(tmid),
			label: `${typeLabel}${versionLabel}`,
			disabled: tmid.type === 'UNDEFINED' ? true : undefined,
		}
	})

	return {
		fieldType: FormFieldType.select,
		alternativeStyle: true,
		label: t(`component.ticket.fieldLabels.documentType`),
		value: stringifyTicketModelId(data.documentContent.content),
		options: options,
		required: true,
	}
}

const getBaseConfiguration = (data: Ticket, ticketModelIds: TicketModelId[], t: TFunction) => {
	const identNumberField = renderIdentNumberField(data, t)
	const documentTypeField = renderDocumentTypeField(ticketModelIds, data, t)
	const configuration = {
		formFields: {
			[DOCUMENT_TYPE_FIELD_NAME]: documentTypeField,
			[IDENT_NUMBER_FIELD_NAME]: identNumberField,
		},
		formFieldMetaData: {},
		formFieldConditions: {},
	}
	return configuration
}

export const useMagicLetter = (data: Ticket, ticketModelIds: TicketModelId[]) => {
	const { t } = useTranslation()
	const configuration = useRef<IFormFieldsConfiguration>(getBaseConfiguration(data, ticketModelIds, t))
	const { extractFieldConfiguration } = useFormFieldsConfiguration()

	data.documentContent.content.pages.map((page) => {
		const { formFields, formFieldMetaData, formFieldConditions } = extractFieldConfiguration(page.fields)
		configuration.current = {
			...configuration.current,
			formFields: {
				...configuration.current.formFields,
			},
		}

		return (configuration.current = {
			formFields: {
				...configuration.current.formFields,
				...formFields,
			},
			formFieldMetaData: {
				...configuration.current.formFieldMetaData,
				...formFieldMetaData,
			},
			formFieldConditions: {
				...configuration.current.formFieldConditions,
				...formFieldConditions,
			},
		})
	})

	return configuration.current
}
