import GridListCell from 'components/GridListCell'
import { IconType } from 'components/Icons'
import SortableTable, { ColumnHeader, SortFunction, SortType } from 'components/SortableTable'
import { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Icon from 'shared/components/Icon'
import dateFormat from 'shared/helper/dateFormat'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { components } from 'types/api-interface'
import { getSessionStorage, removeSessionStorage, setSessionStorage } from 'shared/helper/sessionStorage'

type TableProps = {
	tableData: components['schemas']['AustrittsbearbeitungSearchDocument'][]
	onSort: SortFunction
}

export const AustrittsbearbeitungTable: FunctionComponent<TableProps> = ({ tableData, onSort }) => {
	const { t } = useTranslation()
	const { navigateTo, getMainPath } = useRouteHelper()
	const translationPath = 'component.austrittsbearbeitungTable'

	const sessionStorageSortTypePrefix = 'austrittsbearbeitung-leaving-info'

	const [columnData, setColumnData] = useState<ColumnHeader[]>([
		{
			accessor: 'fallart',
			sortable: true,
			sortType: getSessionStorage(`${sessionStorageSortTypePrefix}-fallart-sortType`, undefined),
		},
		{
			accessor: 'identity',
			sortable: true,
			sortType: getSessionStorage(`${sessionStorageSortTypePrefix}-identity-sortType`, undefined),
		},
		{
			accessor: 'hrSystem',
			sortable: true,
			sortType: getSessionStorage(`${sessionStorageSortTypePrefix}-hrSystem-sortType`, undefined),
		},
		{
			accessor: 'personalNummer',
			sortable: true,
			sortType: getSessionStorage(`${sessionStorageSortTypePrefix}-personalNummer-sortType`, undefined),
		},
		{
			accessor: 'geburtstag',
			sortable: true,
			sortType: getSessionStorage(`${sessionStorageSortTypePrefix}-geburtstag-sortType`, undefined),
		},
		{
			accessor: 'austrittsdatum',
			sortable: true,
			sortType: getSessionStorage(`${sessionStorageSortTypePrefix}-austrittsdatum-sortType`, undefined),
		},
		{
			accessor: 'austrittsgrund',
			sortable: true,
			sortType: getSessionStorage(`${sessionStorageSortTypePrefix}-austrittsgrund-sortType`, undefined),
		},
		{
			accessor: 'versorgungsordnung',
			sortable: true,
			sortType: getSessionStorage(`${sessionStorageSortTypePrefix}-versorgungsordnung-sortType`, undefined),
		},
		{
			accessor: 'versorgungsguthaben',
			sortable: true,
			sortType: getSessionStorage(`${sessionStorageSortTypePrefix}-versorgungsguthaben-sortType`, undefined),
		},
		{
			accessor: 'bearbeitende',
			sortable: true,
			sortType: getSessionStorage(`${sessionStorageSortTypePrefix}-bearbeitende-sortType`, undefined),
		},
		{
			accessor: 'status',
			sortable: true,
			sortType: getSessionStorage(`${sessionStorageSortTypePrefix}-status-sortType`, undefined),
		},
		{
			accessor: 'lastChangesAt',
			sortable: true,
			sortType: getSessionStorage(`${sessionStorageSortTypePrefix}-lastChangesAt-sortType`, undefined),
		},
	])

	useEffect(() => {
		columnData.map((columnHeader) => {
			if (columnHeader.sortType === undefined) {
				return removeSessionStorage(`${sessionStorageSortTypePrefix}-${columnHeader.accessor}-sortType`)
			}
			return setSessionStorage(
				`${sessionStorageSortTypePrefix}-${columnHeader.accessor}-sortType`,
				columnHeader.sortType
			)
		})
	}, [columnData, tableData])

	const getOtherSortType = (sortType: SortType): SortType => {
		if (sortType === 'asc') {
			return 'desc'
		}
		if (sortType === 'desc') {
			return undefined
		}
		return 'asc'
	}

	const sortAfter = (accessor: string, sortType: SortType) => {
		columnData.map((item) => (item.sortType = undefined))
		const columnHeaderToSwitch: ColumnHeader = {
			accessor,
			sortable: true,
			sortType: getOtherSortType(sortType),
		}
		const indexHeader = columnData.findIndex((item) => item.accessor === accessor)
		columnData.splice(indexHeader, 1, columnHeaderToSwitch)
		setColumnData(columnData)
		onSort(accessor, columnData[indexHeader].sortType)
	}

	const getValue = (accessor: string, entry: components['schemas']['AustrittsbearbeitungSearchDocument']) => {
		let transformedValue
		switch (accessor) {
			case 'fallart': {
				const data = entry.employee?.fallart
				transformedValue = (
					<div className="fallart">{data ? t(`${translationPath}.${accessor}.${data}`) : '-'}</div>
				)
				break
			}
			case 'identity': {
				const userId = entry.identNumber
				const firstname = entry.employee?.name ? entry?.employee?.name.vorname : '-'
				const lastname = entry.employee?.name ? entry.employee?.name.nachname : '-'
				const username = firstname && lastname ? `${firstname} ${lastname}` : undefined
				transformedValue = (
					<div className="flex flex--direction-column">
						<span>{userId}</span>
						{username && <span className="font-size-s margin--top margin--small">{username}</span>}
					</div>
				)
				break
			}
			case 'hrSystem': {
				const data = entry.employee?.hrSystem
				transformedValue = <div className="hr-sys">{data ? data : '-'}</div>
				break
			}
			case 'personalNummer': {
				const data = entry.employee?.bavPersonalNummer
				transformedValue = <div className="personalnummer">{data ? data : '-'}</div>
				break
			}
			case 'geburtstag': {
				const stringDate = entry.employee?.geburtstag
				const date = stringDate ? new Date(stringDate) : undefined
				transformedValue = <div className="date">{date ? dateFormat(date) : '-'}</div>
				break
			}
			case 'austrittsdatum': {
				const stringDate = entry.employee?.austritt?.austrittsdatum
				const date = stringDate ? new Date(stringDate) : undefined
				transformedValue = <div className="date">{date ? dateFormat(date) : '-'}</div>
				break
			}
			case 'austrittsgrund': {
				const data = entry.employee?.austritt?.austrittsgrund
				transformedValue = <div className="leaving-reason">{data ? data : '-'}</div>
				break
			}
			case 'versorgungsordnung': {
				transformedValue = <div className="vo">{entry.employee?.versorgungsordnung ?? '-'}</div>
				break
			}
			case 'versorgungsguthaben': {
				transformedValue = entry.versorgungsguthaben ? (
					<Icon type={IconType.money} />
				) : (
					<Icon type={IconType.zero} />
				)
				break
			}
			case 'bearbeitende': {
				const value = entry.bearbeitung?.bearbeitendePerson
				transformedValue = (
					<div
						className="button-select__tag"
						// TODO DA adding color for bearbeitende if the info avail.
						style={
							{
								background: value?.tag ? value.tag === '' : 'grey',
								fontSize: 'medium',
							} as React.CSSProperties
						}
					>
						{value?.tag ? value.tag : '-'}
					</div>
				)
				break
			}
			case 'status': {
				const value = entry.bearbeitung?.status
				if (!value) {
					return '-'
				}
				transformedValue = (
					<Button
						type={ButtonType.small}
						label={t(`component.austrittsbearbeitungTable.status.${value}`)}
						className={`button--status--${entry.bearbeitung.status}`}
					></Button>
				)
				break
			}
			case 'lastChangesAt': {
				const stringDate = entry.lastChangesAt
				const date = stringDate ? new Date(stringDate) : undefined
				transformedValue = <div className="date">{date ? dateFormat(date) : '-'}</div>
				break
			}
		}
		return transformedValue
	}

	const renderButtonColumn = (identNumber: string) => {
		return (
			<GridListCell>
				<Button
					onClick={() => navigateTo(`${getMainPath(routesDictionary.austritte)}/${identNumber}`, false)}
					type={ButtonType.round}
					icon={IconType.open}
				/>
			</GridListCell>
		)
	}

	return (
		<SortableTable
			columnHeaderData={columnData}
			data={tableData}
			translationPath={translationPath}
			sortFunction={sortAfter}
			getValue={getValue}
			getButtonColumn={renderButtonColumn}
			gridListClassName="inbox-list-austritt"
		></SortableTable>
	)
}

export default AustrittsbearbeitungTable
