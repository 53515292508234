import { useUserRecord } from 'hooks/useUserRecord'
import { useRoutes } from 'raviger'
import React, { useEffect } from 'react'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import useNavigationState from 'state/useNavigationState'

const SyncConflict: React.FC<{ id: string, uuid: string, timeStamp: string }> = ({ id, timeStamp, uuid }) => {
    const { navigateTo, getChildPath, getMainPath, routes } = useRouteHelper(routesDictionary.syncConflict)
    const { userId, closeRecord, openRecord } = useUserRecord()
    const routeResult = useRoutes(routes, { basePath: `${getMainPath(routesDictionary.syncConflict)}/${id}/${timeStamp}/${uuid}` })
    const [navigationState, navigationActions] = useNavigationState()

    useEffect(() => {
        if (false === navigationState.visible && undefined !== userId) {
            navigationActions.setVisible(true)
        }
    }, [userId, navigationActions, navigationState.visible])

    const defaultRoute = () => {
        navigateTo(getChildPath(routesDictionary.syncConflict, 'solution', { main: [`${id}/${timeStamp}/${uuid}`] }), true)
    }

    const renderRoute = () => {
        if (userId !== id) {
            closeRecord().then(async () => {

                await openRecord(id)

                return routeResult || defaultRoute()
            })
            return
        }

        return routeResult || defaultRoute()
    }

    return <>{renderRoute()}</>
}

export default SyncConflict
