/**
 * @param {string} date input date in YYYY-MM-DD
 * @returns {string} first day of the next month from the input date in YYYY-MM-DD
 */

const getFirstDayOfNextMonth = (date: string) => {
    const dt = new Date(date)
    const resultDate = new Date(dt.getFullYear(), dt.getMonth() + 1, 1)
    const offset = resultDate.getTimezoneOffset()
    const firstDayOfNextMonth = new Date(resultDate.getTime() - (offset * 60 * 1000))
    const result = firstDayOfNextMonth.toISOString().split('T')[0]
    return result
}

export default getFirstDayOfNextMonth