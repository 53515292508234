export type TMonth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export type TMonthSchema = { monthNumber: number; monthName: string }

export const isTMonth = (value: unknown): value is TMonth => {
	return typeof value === 'number' && value >= 1 && value <= 12
}

export const months: TMonthSchema[] = Array.from({ length: 12 }, (_, i) => {
	const monthNumber = i + 1
	const monthName = new Date(0, monthNumber, 0).toLocaleString('de-DE', { month: 'long' })
	return { monthNumber, monthName }
})

export function getMonthNameFromNumber(monthNumber: TMonth) {
	const month = months.find((item) => item.monthNumber === monthNumber)
	if (!month) {
		return ''
	}
	return month.monthName
}
