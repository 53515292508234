import freigegeben from 'assets/images/illu-document-accepted.svg'
import freigabeAusstehend from 'assets/images/illu-document-pending.svg'
import nichtFreigegeben from 'assets/images/illu-document-rejected.svg'
import formularVersandt from 'assets/images/illu-document-sent.svg'
import nochNichtAngefragt from 'assets/images/illu-document-unavailable.svg'
import classNames from 'classnames'
import { IconType } from 'components/Icons'
import useApi, { GETAustrittDokumenteAnfrageSchreiben, POSTAustrittDokumente } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormFieldType, FormFields } from 'shared/components/Form'
import Icon, { IconSize } from 'shared/components/Icon'
import dateFormat from 'shared/helper/dateFormat'
import {
	IDocumentCardProps,
	TAblehnungsgruende,
	TAblehnungsgrund,
	TDocumentStatus,
	TDocumentType,
	TDokumentDokumente,
	TTimelineData,
	TTimelineEntryProps,
} from './document-view-types'
import Modal, { ModalRefActions } from 'shared/components/Modal'

//----------------------------------------------------

//TYPES

type TButtonGroupProps = {
	type?: TDocumentType
	status?: string
	label?: React.ReactNode
	className?: string
	config: {
		[key: string]: {
			buttonStatus: string
			label: React.ReactNode
			onClick: () => void
		}
	}
	isAbfindungsFall?: boolean
}

type TButtonGroupStatus = POSTAustrittDokumente['status'] | 'initial' | 'erneut'

//----------------------------------------------------

//* BUTTONS-COMPONENT

export const ButtonGroup: FunctionComponent<TButtonGroupProps> = ({ config, className, status, label, type, isAbfindungsFall }) => {
	const hideButtons = type !== 'PERSONALAUSWEIS' && (status === 'formularVersandt' || status === 'nochNichtAngefragt')
	const documementCheckedStatus =
		status === 'freigegeben' || status === 'nichtFreigegeben' || status === 'initial' || status === 'erneut'

	const getClasses = () =>
		classNames(
			'document-card-button-group',
			{
				'document-card-button-group__hidden': hideButtons,
			},
			className
		)

	const getButtonClasses = (customButtonStatus: TButtonGroupStatus, isAbfindungsFall?: boolean) => {
		if (isAbfindungsFall) {
			return classNames('document-card-button-group__button-abfindung', {
				[`document-card-button-group__button-abfindung--${customButtonStatus}`]: status === customButtonStatus,
			})
		}
		return classNames('document-card-button-group__button', {
			[`document-card-button-group__button--${customButtonStatus}`]: status === customButtonStatus,
		})
	}
	const spacerClass = hideButtons || documementCheckedStatus ? 'spacer--hidden' : 'spacer'

	return (
		<div>
			{!hideButtons && <p className="bold-small-heading">{label}</p>}
			{isAbfindungsFall ?
				<div className={getClasses()}><Button
					className={getButtonClasses(config && (config.right.buttonStatus as TButtonGroupStatus), isAbfindungsFall)}
					type={[ButtonType.small, ButtonType.secondary]}
					onClick={() => { }}
				>
					<Trans i18nKey="view.dokumente.options.labels.erneut" />
				</Button></div> :
				<div className={getClasses()}>
					<Button
						type={ButtonType.small}
						className={getButtonClasses(config && (config.left.buttonStatus as TButtonGroupStatus))}
						onClick={config && config.left.onClick}
						promiseTracker={{ area: 'nichtFreigegeben' }}
					>
						{config && config.left.label}
					</Button>
					<span className={spacerClass} />
					<Button
						type={ButtonType.small}
						className={getButtonClasses(config && (config.right.buttonStatus as TButtonGroupStatus))}
						onClick={config && config.right.onClick}
						promiseTracker={{ area: 'freigegeben' }}
					>
						{config && config.right.label}
					</Button>
					{hideButtons && (
						<p>
							<Trans
								i18nKey="view.dokumente.documentCard.noDocuments"
								className="document-card-button-group__noDocuments"
							/>
						</p>
					)}
				</div>}
		</div>
	)
}

//* TIMELINE ENTRY-COMPONENT

const TimelineEntry = ({ date, message }: TTimelineEntryProps) => {
	return (
		<div className="document-card-timeline-entry">
			<span>{date}</span>
			<Trans
				i18nKey={`view.dokumente.timeline.${message}`}
				components={{ highlight: <span className="document-card-timeline-entry__highlight"></span> }}
			/>
		</div>
	)
}

//* TIMELINE-COMPONENT

const Timeline = ({ data }: { data: TTimelineData[] }) => {
	return (
		<div className="document-card-timeline">
			{data.map(({ date, message }, index) => (
				<TimelineEntry key={index} date={date} message={message} />
			))}
		</div>
	)
}

//*STATUS-FLAG-COMPONENT

export const StatusFlag = ({ status }: { status: string }) => {
	const getStatusFlagContent = (status: string): { type?: IconType; size?: IconSize } => {
		switch (status) {
			case 'freigegeben':
				return { type: IconType.solved, size: IconSize.small }
			case 'formularVersandt':
				return { type: IconType.solved, size: IconSize.small }
			case 'freigabeAusstehend':
				return { type: IconType.pending, size: IconSize.medium }
			case 'nichtFreigegeben':
				return { type: IconType.fail, size: IconSize.small }
			default:
				return {}
		}
	}

	const { type, size } = getStatusFlagContent(status)

	const statusFlagClass = classNames('document-card-status-flag', {
		[`document-card-status-flag--${status}`]: status,
	})

	return (
		<div className={statusFlagClass}>
			<p className="document-card-status-flag__text">
				<Trans i18nKey={`view.dokumente.status.${status}`} />
			</p>
			{type && size && <Icon size={size} type={type} />}
		</div>
	)
}

//----------------------------------------------------

//*DOCUMENT CARD COMPONENT

const DocumentCard: FunctionComponent<IDocumentCardProps> = (props) => {
	const { t } = useTranslation()
	const { userId } = useUserRecord()
	const modal = useRef<ModalRefActions>()
	const api = useApi()
	const {
		fallart,
		status,
		type,
		statusChangedAt,
		dokumente,
		ablehnungsgruende,
		ablehnungsgrund,
		schreibenProperties,
		nochAnzufragen,
		handleDocumentReleaseStatus,
		onDocumentChange,
	} = props
	const hasDocuments = dokumente.length > 0
	const documentId = [...dokumente].sort(
		(a, b) => new Date(a.receivedAt ?? '').getTime() - new Date(b.receivedAt ?? '').getTime()
	)[dokumente.length - 1]?.id

	const [updatedFormFields, setUpdatedFormFields] = useState<FormFields>({})
	const [schreibenPropertiesState] = useState<GETAustrittDokumenteAnfrageSchreiben | undefined>(schreibenProperties)
	const [timelineData, setTimelineData] = useState<TTimelineData[]>([])

	const getDocumentInfo = () => {
		let imageSource
		switch (status) {
			case 'freigegeben':
				imageSource = freigegeben
				break
			case 'freigabeAusstehend':
				imageSource = freigabeAusstehend
				break
			case 'nichtFreigegeben':
				imageSource = nichtFreigegeben
				break
			case 'nochNichtAngefragt':
				imageSource = nochNichtAngefragt
				break
			case 'formularVersandt':
				imageSource = formularVersandt
				break
		}

		return { imageSource }
	}

	const reasonOfRejectionFields: FormFields = {
		reasonOfRejection: {
			label: 'Grund für die Ablehnung',
			fieldType: FormFieldType.select,
			alternativeStyle: true,
			required: true,
			value: ablehnungsgrund?.type
				? ablehnungsgrund?.type === 'vordefinierterGrund'
					? ablehnungsgrund?.grund
					: 'eigenerGrund'
				: '',
			onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
				const ablehnungsgrundTypeValue = e.target.value
				let ablehnungsgrundNeu: TAblehnungsgrund

				switch (ablehnungsgrundTypeValue) {
					case '':
						ablehnungsgrundNeu = undefined
						break
					case 'eigenerGrund':
						const eigenerHinweisGrund =
							typeof updatedFormFields.customText?.value === 'string'
								? updatedFormFields.customText?.value
								: ''
						ablehnungsgrundNeu = {
							type: 'eigenerGrund',
							grund: eigenerHinweisGrund,
						}
						break
					default:
						ablehnungsgrundNeu = {
							type: 'vordefinierterGrund',
							grund: ablehnungsgrundTypeValue as TAblehnungsgruende[number],
						}
				}

				onDocumentChange(ablehnungsgrundNeu)

				api.postAustrittDokumente({
					body: {
						ablehnungsgrund: ablehnungsgrundNeu,
						type: type,
						nochAnzufragen: true,
						status: 'nichtFreigegeben' as TDocumentStatus,
					},
					userId,
					fallart: fallart || 'LEISTUNGSFALL',
					area: type,
				})
			},
			options: [
				{
					value: '',
					label: 'Bitte wählen...',
				},
				...ablehnungsgruende.map((reason: string) => ({
					value: reason,
					label: t(`view.dokumente.options.declineOptions.${reason}`),
				})),
				{
					value: `eigenerGrund`,
					label: 'Eigenen Hinweis hinzufügen...',
				},
			],
		},
		customText: {
			hidden:
				updatedFormFields?.reasonOfRejection?.value !== `eigenerGrund` &&
				ablehnungsgrund?.type !== 'eigenerGrund',
			label: 'Ablehnungsgrund - Eigener Hinweis',
			fieldType: FormFieldType.textArea,
			className: 'document-card__content-container--custom-text',
			value: ablehnungsgrund?.type === 'eigenerGrund' ? ablehnungsgrund?.grund : '',
			onBlur: (e: React.FocusEvent<HTMLTextAreaElement>) => {
				const customReason = e.target.value
				const ablehnungsgrundNeu: TAblehnungsgrund = {
					type: 'eigenerGrund',
					grund: customReason,
				}
				onDocumentChange(ablehnungsgrundNeu)
				api.postAustrittDokumente({
					body: {
						ablehnungsgrund: ablehnungsgrundNeu,
						type: type,
						nochAnzufragen: true,
						status: 'nichtFreigegeben' as TDocumentStatus,
					},
					userId,
					fallart: fallart || 'LEISTUNGSFALL',
					area: type,
				})
			},
		},
	}

	const { imageSource } = getDocumentInfo()

	const BUTTON_GROUP_CONFIG = {
		right: {
			buttonStatus: 'freigegeben',
			label: 'Okay',
			onClick: () => {
				handleDocumentReleaseStatus(type, nochAnzufragen, 'freigegeben', 'freigegeben')
			},
		},
		left: {
			buttonStatus: 'nichtFreigegeben',
			label: 'Nicht okay',
			onClick: () => {
				handleDocumentReleaseStatus(type, nochAnzufragen, 'nichtFreigegeben', 'nichtFreigegeben')
			},
		},
	}

	const createTimelineData = useCallback(
		(
			status: TDocumentStatus,
			dokumente: TDokumentDokumente,
			schreibenProperties: GETAustrittDokumenteAnfrageSchreiben | undefined
		) => {
			const timelineData: TTimelineData[] = []
			const { sentAt, createdAt } = schreibenProperties || {}

			if (statusChangedAt && status !== 'nochNichtAngefragt' && status !== 'formularVersandt') {
				timelineData.push({
					date: dateFormat(new Date(statusChangedAt)),
					message: status,
				})
			}
			if (dokumente && dokumente?.length > 0) {
				dokumente.forEach((document) => {
					const documentDate = document && document.receivedAt
					timelineData.push({
						date: documentDate ? dateFormat(new Date(documentDate)) : '',
						message: 'documentReceived',
					})
				})
			}
			if (createdAt) {
				timelineData.push({
					date: dateFormat(new Date(createdAt)),
					message: 'created',
				})
			}

			if (sentAt) {
				timelineData.push({
					date: dateFormat(new Date(sentAt)),
					message: 'formularVersandt',
				})
			}

			timelineData.sort((a, b) => {
				const datePartsA = a.date.split('.')
				const datePartsB = b.date.split('.')

				const dateA = new Date(parseInt(datePartsA[2]), parseInt(datePartsA[1]) - 1, parseInt(datePartsA[0]))
				const dateB = new Date(+datePartsB[2], parseInt(datePartsB[1]) - 1, +datePartsB[0])

				return dateA.getTime() - dateB.getTime()
			})

			return timelineData
		},
		[statusChangedAt]
	)

	const handleOpenDocument = async () => {
		if (!userId) return

		const documentUrl = await api.getDocumentLink({ userId, id: documentId, area: `document-${documentId}` })

		if (documentUrl) {
			setTimeout(() => {
				const win = window.open(documentUrl, '_blank', 'noopener,noreferrer')

				if (null !== win) {
					win.focus()
				}
			})
		}
	}

	useEffect(() => {
		setTimelineData(createTimelineData(status, dokumente, schreibenPropertiesState))
	}, [status, dokumente, schreibenPropertiesState, createTimelineData])

	const applyResetStatus = async () => {
		const body = {
			type,
			nochAnzufragen,
			status: 'freigabeAusstehend' as TDocumentStatus,
		}
		const payload = {
			body,
			userId,
			fallart: fallart ?? 'LEISTUNGSFALL',
			area: 'postAustrittDokumenteAnfrage',
		}
		await api.postAustrittDokumente(payload)
		await props.refetchAustrittDokumente()
	}
	return (
		<div className="document-card">
			<img src={imageSource} alt="test" />
			<span className="document-card__spacer" />
			<div className="document-card__content-container">
				<div className="document-card__content-container--content">
					<h3 className="margin--none font-family-bold">{t(`view.dokumente.dokumentetypes.${type}`)}</h3>
					<Timeline data={timelineData} />
					{hasDocuments && (
						<Button
							className="document-card__content-container--document-button"
							type={[ButtonType.medium, ButtonType.secondary]}
							onClick={handleOpenDocument}
							promiseTracker={{ area: `document-${documentId}` }}
						>
							Dokument ansehen
						</Button>
					)}

					<p className="bold-small-heading">{`Freigabe: `}</p>
					<div className="document-card__button-group-container">
						<ButtonGroup type={type} config={BUTTON_GROUP_CONFIG} status={status} />
						{type === 'PERSONALAUSWEIS' && status !== 'freigabeAusstehend' && (
							<Button
								icon={IconType.close}
								iconSize={IconSize.mediumLarge}
								className="cancel-button"
								onClick={() => modal.current?.openModal()}
							></Button>
						)}
					</div>
				</div>
				<div className="document-card__content-container--status">
					<StatusFlag status={status} />
				</div>
				{status === 'nichtFreigegeben' && (
					<Form
						hideSubmit
						fields={reasonOfRejectionFields}
						onSubmit={() => { }}
						onFormChange={setUpdatedFormFields}
					/>
				)}
			</div>
			<Modal
				ref={modal}
				forceAction={false}
				header={<Trans i18nKey="view.dokumente.documentCard.modalResetStatus.title" />}
				buttonLabel={t(`generic.save`)}
				onModalClose={() => { }}
				onButtonClick={applyResetStatus}
				className="modal--start-austrittstask"
			>
				<div>
					<Trans i18nKey="view.dokumente.documentCard.modalResetStatus.content" />
				</div>
			</Modal>
		</div>
	)
}

export default DocumentCard
