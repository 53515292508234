import { ViewHeader } from 'components/ViewHeader'
import ViewTitle from 'components/ViewTitle'
import { useUserRecord } from 'hooks/useUserRecord'
import BackButton from 'partials/BackButton'
import { useRoutes } from 'raviger'
import React from 'react'
import { Trans } from 'react-i18next'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'

const PensionerConversions: React.FC = () => {
	const { getChildPath, navigateTo, routes } = useRouteHelper(routesDictionary.pensioner.children?.conversions)
	const { userId, firstnamesGenitive: name } = useUserRecord()
	const routeResult = useRoutes(routes, {
		basePath: getChildPath(routesDictionary.pensioner, 'conversions', { main: [userId || ''] }),
	})

	const defaultRoute = () => {
		navigateTo(
			getChildPath(routesDictionary.pensioner, 'conversions', {
				main: [userId!],
				child: [routesDictionary.pensioner.children!.conversions.children!.allYears.path.substring(1)],
			}),
			true
		)
	}

	return (
		<div className="pensioner-conversions">
			<>
				<ViewHeader
					headline={<Trans i18nKey="view.pensionerConversions.headline" values={{ name }} />}
					subheadline={<ViewTitle />}
					button={<BackButton />}
				/>

				{routeResult || defaultRoute()}
			</>
		</div>
	)
}

export default PensionerConversions
