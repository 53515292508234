import GridList from 'components/GridList'
import GridListCell from 'components/GridListCell'
import GridListRow from 'components/GridListRow'
import { IconType } from 'components/Icons'
import { ViewHeader } from 'components/ViewHeader'
import { QueryKey } from 'hooks/useApi'
import useConnectApi, { CallerHistoryParams } from 'hooks/useConnectApi'
import { useSoftphone } from 'hooks/useSoftphone'
import { useCallback, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { useQuery } from 'react-query'
import Button, { ButtonType } from 'shared/components/Button'
import Icon from 'shared/components/Icon'
import SelectInput from 'shared/components/SelectInput'
import { components } from 'types/api-interface'

const CallerHistory = () => {
	const { getCallerHistory } = useConnectApi()

	const [queryParams, setQueryParams] = useState<CallerHistoryParams>({ maxItems: 10 })

	const { data, isLoading } = useQuery([QueryKey.callerHistory, queryParams], {
		queryFn: getCallerHistory,
		cacheTime: 0,
		staleTime: 0,
		refetchInterval: 60000,
	})
	const { startOutboundCall } = useSoftphone()

	function toMinutes(totalSeconds: number) {
		if (!totalSeconds) {
			return '-'
		}

		const result = new Date(totalSeconds * 1000).toISOString().slice(11, 19)
		return result
	}

	const handleMaxIemsChanged = (value: string) => {
		setQueryParams((queryParams) => {
			const payload = { ...queryParams, maxItems: Number(value) }
			delete payload.firstToken
			delete payload.lastToken

			return payload
		})
	}

	const handlePagination = useCallback(
		(forward = true) => {
			const payload: {
				firstToken: string | undefined
				lastToken: string | undefined
			} = {
				firstToken: undefined,
				lastToken: undefined,
			}

			if (forward) {
				payload.lastToken = data?.pageInfo.lastToken
			} else {
				payload.firstToken = data?.pageInfo.firstToken
			}

			setQueryParams((params) => {
				return { ...params, ...payload }
			})
		},
		[data?.pageInfo.firstToken, data?.pageInfo.lastToken]
	)

	// const handleSortChanged = (sortParameter: 'asc' | 'desc') => {
	// 	setQueryParams((params) => {
	// 		return { ...params, sortParameter }
	// 	})
	// }

	const allowNext = data?.pageInfo?.lastToken && !isLoading
	const allowPrev = data?.pageInfo?.firstToken && !isLoading

	const headersCells = useMemo(
		() => [
			<Trans i18nKey={'view.callerHistory.tableHeaders.incomingOutgoing'} />,

			<Trans i18nKey={'view.callerHistory.tableHeaders.caller'} />,
			<Trans i18nKey={'view.callerHistory.tableHeaders.IDNumber'} />,
			<Trans i18nKey={'view.callerHistory.tableHeaders.number'} />,
			<Trans i18nKey={'view.callerHistory.tableHeaders.duration'} />,
			<Trans i18nKey={'view.callerHistory.tableHeaders.agentDuration'} />,
			<Trans i18nKey={'view.callerHistory.tableHeaders.customer'} />,
			<Trans i18nKey={'view.callerHistory.tableHeaders.call'} />,
		],
		[]
	)

	const getName = (firstName: string | null | undefined, lastName: string | null | undefined) => {
		if (!firstName || !lastName) {
			return <Trans i18nKey={'view.callerHistory.unknown'} />
		}

		return [firstName, lastName].filter((v) => v).join(' ')
	}

	const getIdNumbers = (personellNumber: string | undefined, callerId: string | undefined) => {
		if (!personellNumber && !callerId) {
			return '—'
		}

		const output = [personellNumber, callerId].filter((v) => v)

		if (output.length === 2) {
			return (
				<>
					{personellNumber}
					<br />
					{callerId}
				</>
			)
		}

		return output
	}

	const bodyRows = useMemo(() => {
		return data?.callEntries.map((v: components['schemas']['CallHistoryEntry'], i: number) => (
			<GridListRow key={'history-body-' + i}>
				<GridListCell>
					<Icon
						type={
							IconType[
								`${
									v.initiationMethod === 'INBOUND' ? 'callInbound' : 'callOutbound'
								}` as keyof typeof IconType
							]
						}
					></Icon>{' '}
					<Trans
						i18nKey={'view.callerHistory.date'}
						values={{ date: new Date(v.callDatetime).toLocaleString() }}
					></Trans>
				</GridListCell>
				<GridListCell>{getName(v.caller?.firstName, v.caller?.lastName)}</GridListCell>
				<GridListCell>{getIdNumbers(v.caller?.personnelNumber, v.caller?.callerId)}</GridListCell>
				<GridListCell>{v.phone && formatPhoneNumberIntl(v.phone)}</GridListCell>
				<GridListCell>{toMinutes(Number(v.callDuration))} </GridListCell>
				<GridListCell>{toMinutes(Number(v.agentDuration))} </GridListCell>
				<GridListCell>{v.queue} </GridListCell>

				<GridListCell>
					<Button
						onClick={() => v.phone && startOutboundCall(v.phone)}
						icon={IconType.callOutbound}
						type={[ButtonType.primary, ButtonType.round]}
						className="margin--left margin--extra-large  call-outbound-button"
					></Button>
				</GridListCell>
			</GridListRow>
		))
	}, [data?.callEntries, startOutboundCall])

	const footer = useMemo(
		() => (
			<GridList columnCount={8} className="caller-history__footer">
				<GridListRow>
					<GridListCell>
						<SelectInput
							className="margin--top"
							alternativeStyle={true}
							label={'Einträge pro Seite'}
							options={[
								{ label: '10', value: '10' },
								{ label: '25', value: '25' },
								{ label: '50', value: '50' },
								{ label: '100', value: '100' },
							]}
							onChange={handleMaxIemsChanged}
						></SelectInput>
					</GridListCell>
					<GridListCell></GridListCell>
					<GridListCell></GridListCell>
					<GridListCell> </GridListCell>
					<GridListCell />
					<GridListCell />
					<GridListCell />
					<GridListCell>
						{' '}
						<Button
							onClick={() => handlePagination(false)}
							disabled={!allowPrev}
							type={ButtonType.round}
							icon={IconType.pageUp}
							className=" margin--large"
						/>
						<Button
							onClick={() => handlePagination(true)}
							disabled={!allowNext}
							type={ButtonType.round}
							icon={IconType.pageUp}
							iconRotate={180}
							className="margin--left"
						/>
					</GridListCell>
				</GridListRow>
			</GridList>
		),
		[allowNext, allowPrev, handlePagination]
	)

	return (
		<div className="caller-history">
			<ViewHeader
				headline={<Trans i18nKey={'view.callerHistory.navigationName'}></Trans>}
				subheadline={<Trans i18nKey={'view.callerHistory.subheadline'}></Trans>}
			></ViewHeader>{' '}
			<GridList columnCount={8} className="caller-history-table">
				<GridListRow>
					{headersCells.map((v, i) => (
						<GridListCell key={`caller-history-header-${i}`} className={'grid-list__cell--header'}>
							{v}
						</GridListCell>
					))}
				</GridListRow>
				{!isLoading && (!data || data.callEntries.length === 0) ? (
					<Trans i18nKey={'view.callerHistory.noData'} />
				) : (
					<></>
				)}
				{bodyRows}
			</GridList>
			{footer}
		</div>
	)
}

export default CallerHistory
