import { useLayoutEffect, useState } from 'react'

export const useScreenOrientation = () => {
	const [orientation, setOrientation] = useState<OrientationType>()

	useLayoutEffect(() => {
		const handleOrientationChange = () => {
			const updatedOrientation =
				(window.screen.orientation || {}).type ||
				// @ts-ignore
				window.screen.mozOrientation ||
				// @ts-ignore
				window.screen.msOrientation ||
				window.orientation

			setOrientation(updatedOrientation)
		}
		window.addEventListener('orientationchange', handleOrientationChange)
		return () => window.removeEventListener('orientationchange', handleOrientationChange)
	}, [])

	return orientation
}
