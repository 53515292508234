import { IconType } from 'components/Icons'
import { FC, useEffect, useRef, useState } from 'react'
import { uid } from 'react-uid'
import { useKey } from 'react-use'
import useWindowSize from 'react-use/lib/useWindowSize'
import Icon, { IconSize } from 'shared/components/Icon'
import { useDetectClickOutside } from 'shared/hooks/useDetectClickOutside'

export const ConnectSelect: FC<{
	initialSelected?: string
	options: { value: string; label: string }[]
	onChange?: (value: string) => void
}> = (props) => {
	const [expanded, setExpanded] = useState(false)
	const [selectedOption, setSelectedOption] = useState<{ value: string; label: string } | undefined>(
		props.options.find((option) => props.initialSelected === option.value)
	)
	const [maxHeight, setMaxHeight] = useState(0)
	const divRef = useRef<HTMLDivElement>(null)
	const windowSize = useWindowSize()
	useDetectClickOutside(divRef, () => setExpanded(false))
	useKey('Escape', () => setExpanded(false))

	const handleDrownDownClick = () => {
		setExpanded(expanded ? false : true)
	}

	const handleOptionClick = (option: { value: string; label: string }) => {
		setSelectedOption(option)
		setExpanded(false)

		props.onChange && props.onChange(option.value)
	}

	const calcMaxHeight = () => {
		const vH = window.innerHeight // viewportHeight
		const offsetTop = divRef.current?.getBoundingClientRect().top

		if (vH !== undefined && offsetTop !== undefined) {
			const maxHeight = vH - offsetTop
			setMaxHeight(maxHeight)
		}
	}

	useEffect(() => {
		expanded && calcMaxHeight()
	}, [expanded])

	useEffect(() => {
		expanded && handleWindowResize()
		// eslint-disable-next-line
	}, [windowSize])

	let timeout: ReturnType<typeof setTimeout> | undefined

	const handleWindowResize = (): void => {
		if (timeout) {
			clearTimeout(timeout)
		}

		timeout = setTimeout(() => {
			calcMaxHeight()
		}, 200)
	}

	return (
		<div
			style={{ '--max-height': `${maxHeight ? maxHeight + 'px' : 'auto'}` } as React.CSSProperties}
			className={`connect-select connect-select--${expanded ? 'expanded' : 'closed'}`}
		>
			<div ref={divRef} className="connect-select__button">
				<div onClick={handleDrownDownClick} className="connect-select__selected-option">
					<span>{selectedOption ? selectedOption.label : ''}</span>
					<Icon
						type={IconType.arrow}
						size={IconSize.extraSmall}
						rotate={expanded ? -90 : 90}
						color="var(--color-blue--medium)"
					/>
				</div>
				{expanded && (
					<div className="connect-select__options">
						{props.options.map((el) => {
							if (selectedOption?.value === el.value) {
								return null
							}

							return (
								<div
									key={uid(el)}
									onClick={() => {
										handleOptionClick(el)
									}}
									className="connect-select__option"
								>
									{el.label}
								</div>
							)
						})}
					</div>
				)}
			</div>
		</div>
	)
}
