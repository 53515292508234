import useApi, { QueryKey } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import { useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'

export const AustritttaskButton: React.FC = () => {
	const { getChildPath, navigateTo } = useRouteHelper()
	const { userId } = useUserRecord()
	const { t } = useTranslation()
	const api = useApi()
	const modal = useRef<ModalRefActions>()
	const [modalOptionen, setModalOptionen] = useState<{
		zusageGetaetigt?: boolean
		action: 'start' | 'delete'
	}>({ action: 'start' })
	const { data: austrittstask, refetch: refetchAustritttask } = useQuery(
		[QueryKey.austrittstask, { userId }],
		api.getAustrittstaskByIdentNumber,
		{
			enabled: !!userId,
		}
	)
	const hasAustrittstask = !!austrittstask?.response

	const handleStartAustrittstask = async () => {
		if (!userId) {
			return
		}
		if (hasAustrittstask) {
			modal.current?.openModal()
			setModalOptionen({ action: 'start', zusageGetaetigt: austrittstask.response?.zusageGetaetigt })
			return
		}
		const response = await api.startAustrittstask(userId)
		if (response?.success === true && userId) {
			await refetchAustritttask()
			setModalOptionen({ action: 'start' })
			modal.current?.openModal()
		}
	}

	const handleDeleteAustrittstask = async () => {
		modal.current?.openModal()
		if (!userId) {
			return
		}
		setModalOptionen({ action: 'delete' })
	}

	const handleModalAction = async () => {
		if (!userId) {
			return
		}
		if (modalOptionen.action === 'delete') {
			if (hasAustrittstask) {
				await api.deleteAustrittstask(userId)
				await refetchAustritttask()
				return
			}
			return
		}
		navigateTo(getChildPath(routesDictionary.austritte, 'austritt', { main: [userId] }), true)
	}

	const getText = (type: 'content' | 'header') => {
		const { action, zusageGetaetigt } = modalOptionen
		let text = ''
		switch (action) {
			case 'delete':
				text = !hasAustrittstask ? 'noAustritttask' : 'deleteAustritttask'
				break
			case 'start':
				text =
					zusageGetaetigt === true
						? 'austrittstaskAlreadyClosed'
						: zusageGetaetigt === false
						? 'austrittstaskAlreadyAvailable'
						: 'austrittstaskStarted'
				break
		}
		return t(`view.pensionerLeavingInformation.buttons.austrittstaskStartenModal.${type}.${text}`)
	}
	const getButtonText = () => {
		const { action } = modalOptionen
		if (action === 'start') {
			return 'navi'
		}
		if (hasAustrittstask) {
			return 'deleteAustritttask'
		}
		return 'noAustritttask'
	}

	return (
		<div className="austritttask-button">
			<Button
				type={ButtonType.primary}
				label={<Trans i18nKey="view.pensionerLeavingInformation.buttons.austrittstaskStarten" />}
				iconColor={'var(--color-black)'}
				className="button--width--fit-content"
				onClick={handleStartAustrittstask}
			/>
			<Button
				type={ButtonType.primary}
				label={<Trans i18nKey="view.pensionerLeavingInformation.buttons.austrittstaskLoeschen" />}
				iconColor={'var(--color-black)'}
				className="button--width--fit-content"
				onClick={handleDeleteAustrittstask}
			/>
			<Modal
				ref={modal}
				forceAction={false}
				header={getText('header')}
				buttonLabel={t(
					`view.pensionerLeavingInformation.buttons.austrittstaskStartenModal.button.${getButtonText()}`
				)}
				onModalClose={() => {}}
				onButtonClick={handleModalAction}
				className="modal--start-austrittstask"
			>
				<div>{getText('content')}</div>
			</Modal>
		</div>
	)
}
