import useApi from 'hooks/useApi'
import React from 'react'
import { useTranslation } from 'react-i18next'

function AllErstanschreibenCandidatesExport() {
	const { t } = useTranslation()
	const api = useApi()

	async function handleLoadAllErstanschreibenCandidates() {
		await api.getAllErstanschreibenCandidatesExport().then((exportUrl: string) => {
			const a = document.createElement('a')
			a.href = exportUrl
			a.rel = 'noopener'
			a.click()
		})
	}

	return (
		<div
			className="admin-dashboard__item admin-dashboard__item__2 admin-dashboard__item__pointer  flex flex--direction-column flex--justify-content-end "
			onClick={handleLoadAllErstanschreibenCandidates}
		>
			<h1 className="delegate_headline no-margin--bottom">
				{t('view.adminDashboard.allErstanschreibenCandidatesExport.headline')}
			</h1>
			<div className="flex flex-s-align-items-left">
				<div className="text-transform-uppercase text-color-gold margin--right">
					{t('view.adminDashboard.allErstanschreibenCandidatesExport.subheadline')}
				</div>
				<hr className="hr hr--gold flex--grow" />
			</div>
		</div>
	)
}

export default AllErstanschreibenCandidatesExport
