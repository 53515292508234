import useApi, { DocumentData } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import { FunctionComponent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dateFormat from 'shared/helper/dateFormat'
import Button, { ButtonType } from '../shared/components/Button'
import LoadingSpinner from '../shared/components/LoadingSpinner'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import routesDictionary from 'routes'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import { wait } from 'shared/helper/wait'
import { NOT_RESENDABLE_POSTAUSGANG_DOCUMENTTYPE, POSTAUSGANG_DOCUMENTTYPE } from './DocumentType/DocumentType'

export interface DocumentEntryProps {
	data: DocumentData
	refetchDocuments: any
	className?: string
	onOpenDocument?: (data: DocumentData) => void
}

const DocumentEntry: FunctionComponent<DocumentEntryProps> = ({
	data,
	className,
	refetchDocuments,
	onOpenDocument,
}) => {
	const { id, type, createdAt, isRead, ticketId, parentDocumentId } = data
	const { t } = useTranslation()
	const api = useApi()
	const { userId } = useUserRecord()
	const { navigateTo, getMainPath } = useRouteHelper()
	const [documentRequested, setDocumentRequested] = useState<boolean>(false)
	const modal = useRef<ModalRefActions>()
	const area = `document-${id}`
	const isResendableAusgangsdokument =
		!!type && POSTAUSGANG_DOCUMENTTYPE.includes(type) && !NOT_RESENDABLE_POSTAUSGANG_DOCUMENTTYPE.includes(type)

	const getClasses = () => {
		const classes = ['document']

		if (className) {
			classes.push(`document--${className}`)
		}

		classes.push(`document--${isRead ? 'read' : 'unread'}`)

		return classes.join(' ')
	}

	const handleOnClick = async () => {
		if (!userId) {
			return
		}

		setDocumentRequested(true)

		const documentUrl = await api.getDocumentLink({ userId, id, area })

		if (documentUrl) {
			setTimeout(() => {
				const win = window.open(documentUrl, '_blank', 'noopener,noreferrer')

				if (null !== win) {
					win.focus()
				}
			})
		}

		setDocumentRequested(false)

		if (onOpenDocument) {
			onOpenDocument(data)
		}
	}

	const handleResend = async () => {
		if (!userId || !data.id) {
			return
		}
		await api.resendDocument(userId, data.id)
		await wait(5000)
		await refetchDocuments()
	}

	const handleOpenResendModal = () => {
		modal.current?.openModal()
	}

	return (
		<div>
			<div className={getClasses()}>
				<div className="document__title">
					{t(`component.documentEntry.type.${type}`)}
					{}
					{parentDocumentId && ` (${t(`component.documentEntry.resent`)})`}
				</div>
				<div className="document__date">
					{dateFormat(createdAt, {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit',
					})}
				</div>

				<LoadingSpinner area={area} delay={0} />
				<Button
					className="document__link"
					label={t('component.documentEntry.buttonLabel')}
					type={[ButtonType.small, ButtonType.secondary]}
					onClick={handleOnClick}
					disabled={documentRequested}
				/>

				{isResendableAusgangsdokument && (
					<Button
						className="document__resendlink"
						label={t('component.documentEntry.resend')}
						type={[ButtonType.small, ButtonType.primary]}
						onClick={handleOpenResendModal}
						disabled={!!parentDocumentId}
					></Button>
				)}

				{ticketId && (
					<Button
						className="document__ticketlink"
						label={t('component.documentEntry.ticketButtonLabel')}
						type={[ButtonType.small, ButtonType.primary]}
						onClick={() => {
							sessionStorage.setItem('ticket', ticketId)
							navigateTo(`${getMainPath(routesDictionary.ticket)}/${ticketId}`)
						}}
						disabled={documentRequested}
					/>
				)}
			</div>
			<Modal
				ref={modal}
				forceAction={false}
				header={t('component.documentEntry.resendModal.header')}
				buttonLabel={t(`generic.yes`)}
				onModalClose={() => {}}
				onButtonClick={handleResend}
				className="modal--start-austrittstask"
			>
				<div>
					{t('component.documentEntry.resendModal.content', {
						documentType: `${t(`component.documentEntry.type.${type}`)}`,
					})}
				</div>
			</Modal>
		</div>
	)
}

export default DocumentEntry
