import classNames from "classnames";
import GridList from "components/GridList";
import GridListCell from "components/GridListCell";
import GridListRow from "components/GridListRow";
import { ViewHeader } from "components/ViewHeader";
import useApi, { GETSingleSyncConflictResponse, QueryKey } from "hooks/useApi";
import { useUserRecord } from "hooks/useUserRecord";
import { useBasePath } from "raviger";
import { useState, useEffect, FunctionComponent } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import Button, { ButtonType } from "shared/components/Button";
import Checkbox from "shared/components/Checkbox";
import Form, { FormFieldType, FormFields } from "shared/components/Form";
import { TextInputType } from "shared/components/TextInput";
import zfToast from "shared/components/ZFToast";
import { wait } from "shared/helper/wait";
import { components } from "types/api-interface";



type DecisionDataEntry = { key: string; vorher: string | number | readonly string[] | null | undefined; nachher: string | number | readonly string[] | null | undefined }
type DecisionButtons = { v: string; n: string }



const SyncConflictsSolution: React.FC = () => {

    const api = useApi()
    const { t } = useTranslation()
    const { userId } = useUserRecord()
    const [, , userIdPath, timeStamp, uuid] = useBasePath().split('/')
    const syncConflictInfo = { identNumber: userId ?? userIdPath, timeStamp, uuid }
    const [updatedState, setUpdatedState] = useState<Record<string, GETSingleSyncConflictResponse['status']>>()

    const {
        data: syncConflict,
        status,
        refetch: refetchBaseData,
        error
    } = useQuery([QueryKey.syncConflict, { ...syncConflictInfo }], api.getSingleSyncConflict, {
        enabled: !!(syncConflictInfo.timeStamp && syncConflictInfo.identNumber && syncConflictInfo.uuid),
        retry: 3,
    })

    const [decisionData, setDecisionData] = useState<DecisionDataEntry[]>([])
    const [decision, setDecision] = useState<Record<string, boolean>>({})

    const [baseDataFields, setBaseDataFields] = useState<FormFields>()

    const [disabled, setDisabled] = useState(true)

    const nonVisibleFields: (keyof GETSingleSyncConflictResponse)[] = ['newImageData', 'oldImageData']


    const decisionHeaders: (keyof DecisionDataEntry | keyof DecisionButtons)[] = [
        'key',
        'vorher',
        'v',
        'nachher',
        'n',
    ]

    useEffect(() => {

    })

    useEffect(() => {
        if (syncConflict !== undefined) {
            setUpdatedState({ "status": t(`view.syncConflict.status.${syncConflict.status}`) })
            console.log(syncConflict.status, updatedState)

            const updatedBaseDataFields = Object.keys(syncConflict).reduce(
                (fields: FormFields, fieldKey: string) => {
                    if (nonVisibleFields.indexOf(fieldKey as keyof GETSingleSyncConflictResponse) > -1) {
                        return fields
                    }
                    let value = syncConflict[fieldKey as Exclude<keyof GETSingleSyncConflictResponse, 'oldImageData' | 'newImageData'>]
                    switch (fieldKey as keyof GETSingleSyncConflictResponse) {
                        case "status":
                            value = t(`view.syncConflict.status.${value}`)
                            break
                        case "conflictType":
                            value = t(`view.syncConflict.conflictType.${value}`)
                            break
                        case "identNumber":
                        case "uuid":
                        case "timeStamp":
                            break

                    }
                    const valueIsNotSet = undefined === value || '' === value

                    fields[fieldKey] = {
                        value: valueIsNotSet ? '–' : typeof value !== 'object' ? String(value) : (value as any),
                        type: TextInputType.text,
                        label: t(
                            `view.syncConflict.labels.${fieldKey}`,
                            t(`view.syncConflict.labels.${fieldKey}`)
                        ),
                        fieldType: FormFieldType.text,
                        disabled: true,
                        className: "large__field",
                        pattern: "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}"

                    }
                    return fields
                },
                {}
            )

            setBaseDataFields(updatedBaseDataFields)
            setDecisionData(decisionDataFromSyncConflict())

        }
    }, [syncConflict])

    useEffect(() => {
        if (status === 'success') {
            if (syncConflict === undefined) {
                zfToast({ title: "Meldung", content: "Falsche Daten in der URL", type: 'error' })
            }
        }
    }, [status])

    useEffect(() => {
        if (syncConflict) {
            if (syncConflict.newImageData.every((data) => { return decision[data.key] !== undefined }) && syncConflict.status === 'UNRESOLVED') {
                setDisabled(false)
            }
            else {
                setDisabled(true)
            }
        }
    }, [decision, updatedState])



    const renderHeader = () => {
        const headerCellClass = 'grid-list__cell--header'

        return (
            <>
                <GridListRow>
                    {decisionHeaders.map((label, index) => (
                        <GridListCell className={headerCellClass} key={`header-field-${index}`}>
                            {t(`view.syncConflict.labels.${label}`)}
                        </GridListCell>
                    ))}
                    <GridListCell className={headerCellClass} />
                </GridListRow>
            </>
        )
    }

    const renderResults = () => {
        if (!syncConflict) {
            return
        }

        return (
            <>
                {decisionData.map((result, index: number) => (
                    <GridListRow key={`decision-${index}`}>
                        {decisionHeaders.map((key, fieldIndex: number) => {
                            if (key !== 'n' && key !== 'v') {
                                let value = result[key]!
                                switch (key) {
                                    case 'key':
                                        break
                                    case 'nachher':
                                    case 'vorher':
                                        value = changedDataToString(value)
                                        break
                                }

                                return (

                                    <GridListCell key={`decision-field-${index}-${fieldIndex}`} className="user-select--all">
                                        {String(value === '' ? '-' : value)}
                                    </GridListCell>
                                )
                            }
                            else
                                return (
                                    <GridListCell>
                                        {getCheckBox(result['key'], key === 'n')}
                                    </GridListCell>
                                )

                        })}

                    </GridListRow>
                ))}
            </>
        )
    }

    const getCheckBox = (key: string, isNachher: boolean) => {
        return <>
            <DecisionCheckBox
                checkKey={`${key}`}
                isNachher={isNachher}
                isChecked={isNachher === decision[key]}
                clickEvent={clickEvent}
            />
        </>
    }

    const clickEvent = (key: string, isNachher: boolean, event?: React.ChangeEvent<HTMLInputElement>) => {
        if (event) {
            event.stopPropagation()
        }
        setDecision({ ...decision, [key]: isNachher })
        decision[key] = isNachher
    }

    const buildRequest = async () => {
        let requestBody: { key: string; value: string | number | readonly string[] | null | undefined }[] = []
        decisionData.forEach(({ key, vorher, nachher }) => {
            if (decision[key] === undefined) {
                return
            } else {
                requestBody.push({ key, value: decision[key] ? nachher : vorher })
            }
        })

        api.postSingleSyncConflict({ syncConflictInfo, body: { decisions: requestBody } })

        await wait(300)

        refetchBaseData()
    }

    const DecisionCheckBox: FunctionComponent<{ checkKey: string, isNachher: boolean, isChecked: boolean, clickEvent: (checkKey: string, isNachher: boolean, event: React.ChangeEvent<HTMLInputElement>) => void }> = ({ checkKey, isNachher, isChecked, clickEvent }) => {
        return (
            <Checkbox
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => clickEvent(checkKey, isNachher, event)}
                size="large"
                checked={isChecked}
                label={' '}
                className={classNames('sync-conflict__checkbox', {})}
            />)

    }

    const decisionDataFromSyncConflict = () => {
        if (syncConflict) {
            const { oldImageData, newImageData } = syncConflict
            return combineArrays(oldImageData, newImageData);
        }
        return []
    }

    return (
        <div className="sync-conflict">


            <ViewHeader
                headline={<Trans i18nKey="view.syncConflict.headlineSingle" />}
                subheadline={<Trans i18nKey="view.syncConflict.subheadline" />}
            >
            </ViewHeader>

            {(status === 'success' && syncConflict === undefined) && (<>Daten überprüfen</>)}
            {(status === 'success' && syncConflict !== undefined) && (<>{<GridList columnCount={decisionHeaders.length + 1}>
                {renderHeader()}
                {renderResults()}
            </GridList>}
                {(baseDataFields && (
                    <>
                        <div className=" no-margin--right">
                            <Form
                                className={'base-data'}
                                fields={baseDataFields}
                                updatedValues={updatedState}
                                hideSubmit={true}
                                onSubmit={() => { }}
                            />
                        </div>
                        <div className="button__container">
                            <div className="scaled__button"><Button
                                type={[ButtonType.secondary, ButtonType.medium]}
                                disabled={disabled}
                                onClick={() => buildRequest()}
                            >
                                <Trans i18nKey="view.syncConflict.buttons.send" />
                            </Button >
                            </div>
                        </div>
                    </>
                ))}</>)}
        </div>
    )
}


function combineArrays(oldImageData: readonly components['schemas']['ChangedData'][], newImageData: readonly components['schemas']['ChangedData'][]) {
    const map = new Map<string, { vorher?: any; nachher?: any }>();

    oldImageData.forEach(item => {
        if (!map.has(item.key)) {
            map.set(item.key, { vorher: item.value });
        } else {
            map.get(item.key)!.vorher = item.value;
        }
    });

    newImageData.forEach(item => {
        if (!map.has(item.key)) {
            map.set(item.key, { nachher: item.value });
        } else {
            map.get(item.key)!.nachher = item.value;
        }
    });

    const result: { key: string; vorher: string | number | readonly string[]; nachher: string | number | readonly string[] }[] = [];
    map.forEach((value, key) => {
        result.push({ key, vorher: value.vorher, nachher: value.nachher });
    });

    return result;
}

const changedDataToString = (value: string | number | readonly string[] | null | undefined): string => {
    let valueString = "";

    if (Array.isArray(value)) {
        (value as Array<string>).forEach((value) => {
            valueString += `${value}; `
        })
        valueString = valueString.substring(0, valueString.length - 2)
    } else {
        valueString += `${value === null ? 'NULL' : value === undefined ? 'UNDEFINED' : value}`
    }

    return valueString
}

export default SyncConflictsSolution