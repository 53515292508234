import useApi, { GETSingleSyncConflictResponse, QueryKey } from 'hooks/useApi'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import dateFormat from 'shared/helper/dateFormat'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import GridList from './GridList'
import GridListCell from './GridListCell'
import GridListRow from './GridListRow'
import { IconType } from './Icons'
import { useQuery } from 'react-query'

const UnresolvedSyncConflictsResults: FunctionComponent = () => {
    const { getMainPath } = useRouteHelper()

    const api = useApi()
    const { t } = useTranslation()

    const {
        data: syncConflictResults,
        status,
        refetch: refetchBaseData,
    } = useQuery([QueryKey.unresolvedSyncConflict], api.getUnresolvedSyncConflicts)

    const resultHeaders: (keyof GETSingleSyncConflictResponse)[] = [
        'identNumber',
        'conflictType',
        'timeStamp',
    ]

    const renderHeader = () => {
        const headerCellClass = 'grid-list__cell--header'

        return (
            <>
                <GridListRow>
                    {resultHeaders.map((label, index) => (
                        <GridListCell className={headerCellClass} key={`header-field-${index}`}>
                            {t(`view.syncConflict.labels.${label}`)}
                        </GridListCell>
                    ))}
                    <GridListCell className={headerCellClass} />
                </GridListRow>
            </>
        )
    }

    const renderResults = () => {
        if (!syncConflictResults) {
            return
        }

        return (
            <>
                {syncConflictResults.map((result: GETSingleSyncConflictResponse, index: number) => (
                    <GridListRow key={`result-${index}`}>
                        {resultHeaders.map((key, fieldIndex: number) => {
                            let value = result[key]

                            switch (key) {
                                case 'timeStamp':
                                    value = `${dateFormat(new Date(value as string))}\n${(value as string).substring(11, value.length - 1)}`

                                    break
                                case 'conflictType':
                                    value = t(`view.syncConflict.conflictType.${value as string}`) ?? "Unknown"
                                    break
                            }

                            return (
                                <GridListCell key={`result-field-${index}-${fieldIndex}`} className="user-select--all">
                                    {String(value === '' ? '-' : value)}
                                </GridListCell>
                            )
                        })}
                        <GridListCell>
                            <Button
                                type={ButtonType.round}
                                icon={IconType.open}
                                route={`${getMainPath(routesDictionary.syncConflict)}/${result.identNumber}/${result.timeStamp}/${result.uuid}`}
                            />
                        </GridListCell>
                    </GridListRow>
                ))}
            </>
        )
    }

    return (
        <GridList columnCount={resultHeaders.length + 1}>
            {renderHeader()}
            {renderResults()}
        </GridList>
    )
}


export default UnresolvedSyncConflictsResults
