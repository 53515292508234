import { pensionAssetsChartStyle } from 'components/ChartsTheme'
import { DisclaimerMessage } from 'components/Disclaimer'
import { ViewHeader } from 'components/ViewHeader'
import useApi, { PensionAssetsStringParameters, QueryKey, vo20PensionRulSetCode } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import React, { ChangeEvent, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import Accordion from 'shared/components/Accordion'
import LoadingSpinner, { LoadingSpinnerArea } from 'shared/components/LoadingSpinner'
import PensionAssetsChart from 'shared/components/PensionAssetsChart'
import SelectInput, { SelectOption } from 'shared/components/SelectInput'
import dateFormat from 'shared/helper/dateFormat'
import { getSessionStorage, setSessionStorage } from 'shared/helper/sessionStorage'
import { currencyFormat } from '../../shared/helper/numberFormats'
import { SimpleChartData } from 'shared/interfaces'

const PensionAssets: React.FC = () => {
	const { t } = useTranslation()
	const { userId } = useUserRecord()
	const api = useApi()
	const queryClient = useQueryClient()

	const [pensionAssetsStringParameters, setPensionAssetsStringParameters] = useState(
		userId === String(getSessionStorage('pensioner'))
			? getSessionStorage('pensionAssetsStringParameters', { year: 'all' })
			: {}
	)

	const { data, status } = useQuery(
		[
			QueryKey.pensionerPensionAssets,
			{
				userId,
				queryStringParameters: pensionAssetsStringParameters,
				area: LoadingSpinnerArea.pensionAssetsDashboard,
			},
		],
		api.getPensionAssets,
		{
			enabled: !!userId,
		}
	)
	const pensionerDataQuery = useQuery([QueryKey.pensionerBaseData, { userId }], api.getPensionerBaseData, {
		enabled: !!userId,
	})

	// const { data: viewIntervals } = useQuery(api.getQuery(QueryKey.pensionAssetsViewIntervals))
	// const translatedViewIntervals = useMemo((): TimeRangeData[] | undefined => {
	// 	if (undefined === viewIntervals) {
	// 		return undefined
	// 	}

	// 	return viewIntervals.map((entry: TimeRangeData) => {
	// 		return {
	// 			...entry,
	// 			label: t(`view.pensionerPensionAssets.viewIntervals.${entry.label}`),
	// 		}
	// 	})
	// }, [viewIntervals, t])

	const [accordionOpen] = useState(false)
	const [accordionState, setAccordionState] = useState<{ height?: string; open?: boolean }>({
		height: '0',
		open: false,
	})

	const onAccordionClick = () => {
		setAccordionState((state) => ({ ...state, open: !accordionState.open }))
	}

	const afterAccordionRender = (height: any) => {
		if (height !== accordionState.height) {
			setAccordionState((state) => ({ ...state, height }))
		}
	}

	const handleOnChange = async (queryParameters: PensionAssetsStringParameters) => {
		const updatedPensionAssetsStringParameters = { ...pensionAssetsStringParameters, ...queryParameters }

		setSessionStorage('pensionAssetsStringParameters', updatedPensionAssetsStringParameters)

		await queryClient.prefetchQuery(
			[
				QueryKey.pensionerPensionAssets,
				{
					userId,
					queryStringParameters: updatedPensionAssetsStringParameters,
					area: LoadingSpinnerArea.pensionAssetsDashboard,
				},
			],
			api.getPensionAssets
		)

		setPensionAssetsStringParameters(updatedPensionAssetsStringParameters)
	}

	const getSelectJsx = () => {
		if (!data) {
			return
		}

		if (data.filterOptions.availableYearFilters.length <= 1) {
			return
		}

		const options = data.filterOptions.availableYearFilters.reduce(
			(currentOptions: SelectOption[], year: number) => {
				if (0 === currentOptions.length) {
					currentOptions.push({
						label: t('view.pensionerPensionAssets.referenceYear.options.allTransforms'),
						value: 'all',
					})
				}

				currentOptions.push({
					label: t('view.pensionerPensionAssets.referenceYear.options.onlyTransformsFrom', {
						year,
					}),
					value: String(year),
				})

				return currentOptions
			},
			[]
		)

		return (
			<>
				<SelectInput
					className="pension-assets__reference-years"
					alternativeStyle={true}
					returnEvent={true}
					options={options}
					label={t('view.pensionerPensionAssets.referenceYear.label')}
					value={String(data.filterOptions.appliedYearFilter)}
					onChange={(event: ChangeEvent<HTMLInputElement>) => {
						const value = (event.target as HTMLInputElement).value

						handleOnChange({
							year: value as PensionAssetsStringParameters['year'],
						})
					}}
				/>
			</>
		)
	}

	const getOwnContributions = () => {
		if (!data || !data.pensionAssetsSummary) {
			return
		}

		const {
			ownAvwlContribution: ownAwlContribution,
			ownTotalContribution,
			ownContribution,
		} = data.pensionAssetsSummary

		if (ownAwlContribution && ownAwlContribution > 0) {
			return (
				<Accordion
					className="pension-assets__own-contributions"
					header={
						<div
							className="flex flex--direction-column"
							style={{ color: pensionAssetsChartStyle.barColors[2] }}
						>
							<span className="font-size-s">
								<Trans i18nKey="view.pensionerPensionAssets.ownContributionsSum" />
							</span>

							<span className="font-size-xxl font-family-regular">
								{currencyFormat(ownTotalContribution)}
							</span>
						</div>
					}
					open={accordionOpen}
					onOpen={() => {
						onAccordionClick()
					}}
					afterRender={(height: any) => {
						afterAccordionRender(height)
					}}
					iconColor={pensionAssetsChartStyle.barColors[2]}
				>
					<div className="sum" style={{ color: pensionAssetsChartStyle.barColors[2] }}>
						{currencyFormat(ownContribution)}
					</div>
					<div className="label" style={{ color: pensionAssetsChartStyle.barColors[2] }}>
						<Trans i18nKey="view.pensionerPensionAssets.ownContributions" />
					</div>
					<div className="sum" style={{ color: pensionAssetsChartStyle.barColors[2] }}>
						{currencyFormat(ownAwlContribution)}
					</div>
					<div className="label" style={{ color: pensionAssetsChartStyle.barColors[2] }}>
						<Trans i18nKey="view.pensionerPensionAssets.ownContributionsAvwl" />
					</div>
				</Accordion>
			)
		} else {
			return (
				<div className="flex flex--direction-column" style={{ color: pensionAssetsChartStyle.barColors[2] }}>
					<span className="font-size-s">
						<Trans i18nKey="view.pensionerPensionAssets.ownContributionsSum" />
					</span>

					<span className="font-size-xxl font-family-regular">{currencyFormat(ownTotalContribution)}</span>
				</div>
			)
		}
	}

	const showBausteinKonto =
		pensionerDataQuery?.data?.pensionPlan.ruleSets === vo20PensionRulSetCode &&
		data?.pensionAssetsSummary?.nichtAusfinanzierteBausteine !== undefined &&
		data?.pensionAssetsSummary?.nichtAusfinanzierteBausteine > 0

	const getFiguresJsx = () => {
		if (!data || !data.pensionAssetsSummary) {
			return
		}

		const { total, guaranteed, relevant, nichtAusfinanzierteBausteine } = data.pensionAssetsSummary

		return (
			<>
				<div className="pension-assets__figures">
					<div className="flex flex--direction-column" style={{ color: 'var(--color-grey)' }}>
						<span className="font-size-s">
							<Trans i18nKey="view.pensionerPensionAssets.total" />
						</span>

						<span className="font-size-xxl font-family-regular">{currencyFormat(total)}</span>
					</div>

					<div
						className="flex flex--direction-column"
						style={{ color: pensionAssetsChartStyle.barColors[0] }}
					>
						<span className="font-size-s">
							<Trans i18nKey="view.pensionerPensionAssets.relevantesVersorgungsguthaben" />
						</span>

						<span className="font-size-xxl font-family-regular">{currencyFormat(relevant)}</span>
					</div>

					<div
						className="flex flex--direction-column"
						style={{ color: pensionAssetsChartStyle.barColors[1] }}
					>
						<span className="font-size-s">
							<Trans i18nKey="view.pensionerPensionAssets.guaranteedPensionAssets" />
						</span>

						<span className="font-size-xxl font-family-regular">{currencyFormat(guaranteed)}</span>
					</div>

					{getOwnContributions()}
					{showBausteinKonto}
					{showBausteinKonto && (
						<div className="flex flex--direction-column" style={{ color: 'var(--color-grey)' }}>
							<span className="font-size-s">
								<Trans i18nKey="view.pensionerPensionAssets.blocksAccountTodayAssets" />
							</span>

							<span className="font-size-xl font-family-regular">
								{data.pensionAssetsSummary.nichtAusfinanzierteBausteine
									? currencyFormat(nichtAusfinanzierteBausteine)
									: 'loading...'}
							</span>
						</div>
					)}
				</div>
			</>
		)
	}

	const getCSS = () => {
		return {
			'--accordion-content-height': `${accordionState.height?.toString()}px`,
		} as React.CSSProperties
	}

	const getClasses = () => {
		const classes = ['pension-assets']

		if (accordionState?.open) {
			classes.push('accordion-open')
		}

		return classes.join(' ')
	}

	const chartData = useMemo(() => {
		if (!data || !data.pensionAssetsDataSeries) return []
		const { ownContribution, guaranteed, relevant } = data.pensionAssetsDataSeries
		return [ownContribution, guaranteed, relevant] as SimpleChartData[][]
	}, [data])

	return (
		<div
			className={`${getClasses()} visually-hidden visually-hidden--${String(status !== 'success')}`}
			style={getCSS()}
		>
			<ViewHeader
				className="pension-assets__header"
				headline={<Trans i18nKey="view.pensionerPensionAssets.headline" />}
				subheadline={
					data &&
					data.pensionAssetsSummary && (
						<Trans
							i18nKey="generic.updatedAt"
							values={{
								date: dateFormat(new Date(data.pensionAssetsSummary.date), {
									weekday: 'long',
									year: 'numeric',
									month: '2-digit',
									day: '2-digit',
								}),
							}}
						/>
					)
				}
			>
				{'success' === status && !data && (
					<DisclaimerMessage text={<Trans i18nKey="view.pensionerPensionAssets.noDataDisclaimer" />} />
				)}
			</ViewHeader>

			{getSelectJsx()}

			{data && <PensionAssetsChart className="pension-assets__graph" data={chartData} />}
			<LoadingSpinner area={LoadingSpinnerArea.pensionAssetsDashboard} delay={0} />

			{/* {getTimeRanges()} */}

			{/* figures must be placed as last element so the accordion won't move the other elements */}
			{getFiguresJsx()}
		</div>
	)
}

export default PensionAssets
