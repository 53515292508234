import { TextInputType } from 'shared/components/TextInput'
import { BoundingBox, ConfidenceValue } from './magic-letter-textract.model'

export type MagicLetterTenant = 'ZF'

export type DocumentLabel = string
export type UILabel = string
export type UIFieldHint = string

export interface Name {
	readonly firstName: string
	readonly lastName: string
	readonly title: string
	readonly salutation: string
	readonly prefix: string
	readonly suffix: string
}

export type OptionalString = string | undefined
export type FieldSelectionStatus = 'selected' | 'notSelected'
export interface UIField {
	label: UILabel
	type: FieldType
	emptyOptionLabel?: string
	hints?: UIFieldHint
}

export interface MagicField<O extends any = string | undefined> {
	name: string
	fieldType: FieldType
	label?: DocumentLabel
	ui?: UIField
	constraints?: FieldConstraints
	optionValue?: O
	description?: string
}
export interface MagicFieldGroup<V = OptionalString, T extends MagicField<V> = MagicField<V>> extends MagicField<V> {
	value?: V | V[]
	fields: T[]
}

export type MagicLetterFields<V, T extends MagicField<V>> = (MagicFieldGroup<V, T> | T)[]
export type MagicLetterPages<T extends MagicField, V> = MagicLetterPage<T, V>[]

export type MagicLetterLocale = 'de-DE'

export interface AnalysisIdentNumberDocument {
	identNumber?: string
	name?: Name
}

export interface MagicLetterDocumentMetaData<T extends MagicField<any> = MagicField, V = undefined>
	extends AnalysisIdentNumberDocument {
	type: string
	version: string
	pageCount: number
	language: MagicLetterLocale
	tenant: MagicLetterTenant
	pages: MagicLetterPages<T, V>
}

export type FieldGroupType = 'selectOne' | 'selectMany' | 'trueFalse' | string

export interface MagicLetterPage<T extends MagicField<any> = MagicField, V = undefined> {
	pageNumber: number
	fields: MagicLetterFields<V, T>
}

type TextInput = TextInputType
export type FieldType = TextInput | 'trueFalse' | 'selectOne' | 'selectOption'

type UIFieldConstraints = 'required' | 'minLength' | 'maxLength'

export type FieldConstraints = { [key in UIFieldConstraints]?: any[] }

export type FieldValidationStatus = 'valid' | 'invalid'
export type MagicFieldValidationStatus = ValidMagicFieldStatus | InvalidFieldStatus

export interface ValidMagicFieldStatus {
	state: 'valid'
}

export interface InvalidFieldStatus {
	state: 'invalid'
	messages: string[]
}

export interface FieldValue<T> {
	value?: T
}
export interface AnalyzedMagicField<T = OptionalString> extends MagicField, FieldValue<T> {
	confidence: ConfidenceValue
	boundingBox?: BoundingBox
	status: MagicFieldValidationStatus
	selectionStatus?: FieldSelectionStatus
}

export function isMagicFieldGroup<V, T extends MagicField<V>>(
	field: MagicFieldGroup<V, T> | T
): field is MagicFieldGroup<V, T> {
	const fields = (field as any).fields
	return fields !== undefined && Array.isArray(fields)
}

export function isFieldValue<T>(input: unknown): input is FieldValue<T> {
	const fields = input as FieldValue<T>
	return fields && fields.value !== undefined
}

export type MagicLetterDocumentAnalyzed = MagicLetterDocumentMetaData<
	AnalyzedMagicField<OptionalString>,
	OptionalString
>
export type MagicLetterDocumentFields = MagicLetterDocumentMetaData<MagicField, OptionalString>
