import classNames from 'classnames'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import Checkbox from 'shared/components/Checkbox'
import { StatusFlag } from './DocumentCard'
import { TDocumentRequestEntryProps, TDocumentType } from './document-view-types'

const DocumentRequestEntry: FunctionComponent<TDocumentRequestEntryProps> = ({
	title,
	type,
	status,
	onChange,
	isChecked,
}) => {
	const { t } = useTranslation()
	const hasDisabledStatus =
		status === 'freigegeben' || (status === 'freigabeAusstehend' && type !== 'PERSONALAUSWEIS')

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, type: TDocumentType) => {
		event.stopPropagation()
		const { checked } = event.target
		onChange(checked, type)
	}
	return (
		<Checkbox
			size="large"
			value={type}
			label={title || t(`view.dokumente.dokumentetypes.${type}`)}
			append={<StatusFlag status={status} />}
			className={classNames('document-request-entry', {
				'document-request-entry--disabled': hasDisabledStatus,
			})}
			onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnChange(event, type)}
			checked={!hasDisabledStatus && isChecked}
		/>
	)
}

export default DocumentRequestEntry
