import classNames from 'classnames'
import { DefaultTFuncReturn } from 'i18next'
import React, { forwardRef, HTMLAttributes, ReactElement, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { mergeRefs } from 'react-merge-refs'
import convertToDashCase from 'shared/helper/convertToDashCase'
import { useUniqueId } from 'shared/hooks/useUniqueInputId'

export interface TextAreaProps extends Omit<HTMLAttributes<HTMLTextAreaElement>, 'placeholder'> {
	className?: string
	value?: string | number | undefined
	onChange?: any
	label?: string | React.ReactElement | DefaultTFuncReturn
	name?: string
	disabled?: boolean
	required?: boolean
	showRequiredAsterisk?: boolean
	placeholder?: string | DefaultTFuncReturn
	errorMessage?: string | DefaultTFuncReturn
	error?: { type: string; message?: string | DefaultTFuncReturn }
	readOnly?: boolean
	ref?: any
	usedInForm?: boolean
	hidden?: boolean
	highlight?: boolean
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
	const {
		className,
		label,
		usedInForm,
		errorMessage,
		error,
		showRequiredAsterisk,
		value,
		hidden,
		highlight,
		...attributes
	} = props
	const inputRef = useRef<HTMLTextAreaElement | null>(null)
	const id = useUniqueId('textarea')
	const { t } = useTranslation()

	const renderLabel = (): ReactElement | undefined => {
		if (!label) {
			return
		}

		return (
			<label className="textarea__label bold-small-heading" htmlFor={id}>
				{label}
				{showRequiredAsterisk && <>{attributes.required ? ' *' : ` ${t('generic.optionalFormField')}`}</>}
			</label>
		)
	}

	const renderError = (): ReactElement | undefined => {
		return (
			<div
				style={{ opacity: Number(undefined !== error) }}
				className={classNames([
					'textarea__error',
					`textarea__error--${convertToDashCase(attributes.name || '')}`,
					{
						[`textarea__error--${error?.type}`]: error,
					},
				])}
			>
				{error?.message}
			</div>
		)
	}

	return (
		<div
			className={classNames([
				className,
				'textarea',
				{
					[`textarea--highlighted`]: highlight,
					[`textarea--disabled`]: attributes.disabled,
					[`textarea--readOnly`]: attributes.readOnly,
					[`textarea--dirty`]: value,
					[`textarea--hidden`]: hidden,
				},
			])}
		>
			{renderLabel()}

			<textarea
				{...attributes}
				className="textarea__tag"
				defaultValue={true !== usedInForm ? value : undefined}
				value={true === usedInForm ? value : undefined}
				disabled={attributes.disabled}
				readOnly={attributes.readOnly}
				required={attributes.required}
				placeholder={attributes.placeholder || undefined}
				ref={mergeRefs([inputRef, ref])}
			/>

			{renderError()}
		</div>
	)
})

export default TextArea
