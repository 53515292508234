import GridList from 'components/GridList'
import GridListCell from 'components/GridListCell'
import GridListRow from 'components/GridListRow'
import { IconType } from 'components/Icons'
import { t } from 'i18next'
import { FunctionComponent } from 'react'
import Icon, { IconSize } from 'shared/components/Icon'
import { components } from 'types/api-interface'

export type SortType = 'asc' | 'desc' | undefined
export type ColumnHeader = {
	accessor: string
	sortable: boolean
	sortType?: SortType
}

export type SortFunction = (accessor: string, sortType: SortType) => void
interface ISortableTable {
	columnHeaderData: ColumnHeader[]
	data: components['schemas']['AustrittsbearbeitungSearchDocument'][] | undefined
	translationPath: string
	sortFunction: SortFunction
	getValue: (accessor: string, entry: components['schemas']['AustrittsbearbeitungSearchDocument']) => any
	getButtonColumn: (identNumber: string) => any
	gridListClassName: string
}

export const SortableTable: FunctionComponent<ISortableTable> = ({
	columnHeaderData,
	data,
	translationPath,
	sortFunction,
	getValue,
	getButtonColumn,
	gridListClassName,
}) => {
	const renderTableHeader = () => {
		let headerCellClass = 'grid-list__cell--header'

		const getIcon = (columnHeaderData: ColumnHeader) => {
			if (!columnHeaderData.sortType) {
				return IconType.sortDefault
			}
			if (columnHeaderData.sortType === 'asc') {
				return IconType.sortAsc
			}
			if (columnHeaderData.sortType === 'desc') {
				return IconType.sortDesc
			}
			return IconType.info
		}

		return (
			<GridListRow>
				{columnHeaderData.map((key, index) => (
					<GridListCell className={headerCellClass} key={`header-field-${index}`}>
						<>
							{t(`${translationPath}.tableHeader.${key.accessor}`)}
							<div
								className={`${headerCellClass}--sortable`}
								style={key.sortable ? { display: 'flex' } : { display: 'none' }}
							>
								<Icon
									type={getIcon(key)}
									size={IconSize.medium}
									onClick={() => {
										sortFunction(key.accessor, key.sortType)
									}}
								></Icon>
							</div>
						</>
					</GridListCell>
				))}
				<GridListCell className={headerCellClass} />
			</GridListRow>
		)
	}

	const renderTableRows = () => {
		return (
			<>
				{data?.map((entry, index: number) => (
					<GridListRow key={`entry-${index}`}>
						{columnHeaderData.map((key, fieldIndex: number) => {
							const transformedValue = getValue(key.accessor, entry)
							return (
								<GridListCell key={`entry-field-${index}-${fieldIndex}`}>
									{transformedValue}
								</GridListCell>
							)
						})}
						{getButtonColumn(entry.identNumber)}
					</GridListRow>
				))}
			</>
		)
	}

	return (
		<GridList columnCount={columnHeaderData.length + 1} className={gridListClassName}>
			{renderTableHeader()}
			{renderTableRows()}
		</GridList>
	)
}

export default SortableTable
