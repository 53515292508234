import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import {
	TAblehnungsgrund,
	TDocumentStatus,
	TDocumentType,
	TFallart,
	TVersandart,
} from 'components/DocumentView/document-view-types'
import useApi, { GETAustrittDokumente } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import DocumentCard from 'components/DocumentView/DocumentCard'
import DocumentRequest from 'components/DocumentView/DocumentRequest'
import { DokumentNaviButton } from './SubComponents/DokumentNaviButton'

export type TDokumente = GETAustrittDokumente['dokumente']
export type TOptionen = GETAustrittDokumente['options']
export type TSchreibenState =
	| {
			isCreated?: boolean
			createdDate?: string
			isSent?: boolean
			sentDate?: string
	  }
	| undefined
const Dokumente = ({ fallart, refetchHistorie, isAbfindungsFall }: { fallart: TFallart | undefined; refetchHistorie: any; isAbfindungsFall?: boolean; }) => {
	const api = useApi()
	const { userId } = useUserRecord()
	const [toggleNewDocument, setToggleNewDocument] = useState<boolean>(false)
	const [dokumente, setDokumente] = useState<TDokumente>([])
	const [sonstigeDokumente, setSonstigeDokumente] = useState<{ title: string }[]>([])
	const [options, setOptions] = useState<TOptionen | undefined>({} as TOptionen)
	const [initial, setInitial] = useState<boolean>()
	const [sonstigeChecked, setSonstigeChecked] = useState<{ checked: boolean; title: string }[]>([])

	const [state, setState] = useState<TSchreibenState>({
		isCreated: false,
		createdDate: '',
		isSent: false,
		sentDate: '',
	})
	const [isInitialLoading, setIsInitialLoading] = useState(true)
	const [nochAnzufragenChecked, setNochAnzufragenChecked] = useState<{ checked: boolean; type: TDocumentType }[]>([])

	// Initialer API-Aufruf für Dokumente
	const {
		data: austrittDokumente,
		isLoading,
		refetch: refetchAustrittDokumente,
	} = useQuery(['austrittDokumenteGet', { userId, fallart: fallart || 'LEISTUNGSFALL' }], api.getAustrittDokumente, {
		enabled: !!userId,
	})

	const handleDocumentChange = (type: TDocumentType, ablehnungsgrund: TAblehnungsgrund) => {
		const neueDokumente: TDokumente = dokumente?.map((eachDokument) => {
			if (eachDokument.type === type) {
				return { ...eachDokument, ablehnungsgrund: ablehnungsgrund }
			} else {
				return eachDokument
			}
		})
		setDokumente(neueDokumente)
	}

	useEffect(() => {
		if (!austrittDokumente || !dokumente) {
			return
		}
		const newDokumente = austrittDokumente.dokumente
		setDokumente(newDokumente)
		setOptions(austrittDokumente.options)
		setInitial(austrittDokumente.options?.isInitial)
		if (austrittDokumente.options && austrittDokumente.options.weitereDokumente) {
			const updatedSonstigeDokumente = austrittDokumente.options.weitereDokumente.map((item) => {
				return { ...item }
			})
			setSonstigeDokumente(updatedSonstigeDokumente)
			const updatedSonstige = austrittDokumente.options.weitereDokumente.map((item) => {
				return { checked: true, title: item.title }
			})
			setSonstigeChecked(updatedSonstige)
		}
	}, [austrittDokumente])

	useEffect(() => {
		if (!dokumente) {
			return
		}
		const updatedNochAnzufragenChecked = dokumente.map((item) => {
			return { checked: item.nochAnzufragen, type: item.type }
		})
		setNochAnzufragenChecked(updatedNochAnzufragenChecked)
	}, [dokumente])

	useEffect(() => {
		if (!sonstigeDokumente) {
			return
		}
		const updatedSonstigeChecked = sonstigeChecked.map((item) => {
			const it = sonstigeDokumente.find((doc) => doc.title === item.title)
			if (it) {
				return { checked: item.checked, title: item.title }
			} else {
				return item
			}
		})
		setSonstigeChecked(updatedSonstigeChecked)
	}, [sonstigeDokumente])

	const { data: schreibenProperties, isLoading: loading } = useQuery(
		['austrittDokumenteSchreibenProperties', { userId }],
		() => api.getAustrittDokumenteSchreibenProperties(userId),
		{
			enabled: !!userId,
			onSuccess: (schreibenProperties) => {
				if (!schreibenProperties) {
					return
				}
				setState({
					isCreated: true,
					createdDate: schreibenProperties.createdAt,
					isSent: !!schreibenProperties.sentAt,
					sentDate: schreibenProperties.sentAt ?? '',
				})
			},
		}
	)

	const { mutateAsync: sendData } = useMutation({
		mutationKey: 'austrittDokumentePost',
		mutationFn: api.postAustrittDokumente,
		onSuccess: () => {
			refetchAustrittDokumente()
		},
	})

	const { mutateAsync: sendDocumentRequest } = useMutation({
		mutationKey: 'austrittDokumenteAnfragePost',
		mutationFn: api.postAustrittDokumenteAnfrage,
		onSuccess: () => {
			refetchAustrittDokumente()
		},
	})

	async function handleDocumentReleaseStatus(
		type: TDocumentType,
		nochAnzufragen: boolean,
		customArea: string,
		status: TDocumentStatus
	) {
		const body = {
			type: type,
			nochAnzufragen: nochAnzufragen,
			status,
		}
		const payload = {
			body,
			userId,
			fallart: fallart || 'LEISTUNGSFALL',
			area: customArea,
		}
		sendData(payload)
	}

	// Aktualisierung der States, sobald die Daten verfügbar sind oder sich ändern

	if (isLoading && loading) return <h1>Loading...</h1>

	const updateDocuments = async () => {
		if (!austrittDokumente) return
		const documentsToRequest = austrittDokumente?.dokumente?.filter((document) => document.nochAnzufragen)
		if (!documentsToRequest) return
		const promises = documentsToRequest.map(async (document) => {
			return sendData({
				body: {
					type: document.type,
					nochAnzufragen: false,
					status: document.status,
					ablehnungsgrund: document.ablehnungsgrund,
				},
				userId,
				fallart: fallart || 'LEISTUNGSFALL',
				area: document.type,
			})
		})
		await Promise.all(promises)
		setIsInitialLoading(false)
	}

	if (isInitialLoading) {
		updateDocuments()
	}

	const finalizeDocumentRequest = async () => {
		const finalDocuments = dokumente?.filter((document) => document.nochAnzufragen)
		if (finalDocuments) {
			finalDocuments.map((document) => {
				return handleDocumentReleaseStatus(document.type, false, document.type, 'formularVersandt')
			})
		}
	}

	const handleNochAnzufragenChange = (updatedChecked: boolean, typeToUpdate: TDocumentType, title?: string) => {
		if (!title) {
			const updatedNochAnzufragenChecked = nochAnzufragenChecked?.map(({ checked, type }) =>
				type === typeToUpdate ? { type, checked: updatedChecked } : { type, checked }
			)
			setNochAnzufragenChecked(updatedNochAnzufragenChecked)
			return
		}
		if (title) {
			if (updatedChecked) {
				if (!sonstigeChecked) {
					setSonstigeChecked([{ checked: true, title }])
					return
				}
				const index = sonstigeChecked.findIndex((item) => item.title === title)
				if (index === -1) {
					const updatedSonstige = [...sonstigeChecked, { checked: true, title }]
					setSonstigeChecked(updatedSonstige)
					return
				} else {
					const updatedSonstige = sonstigeChecked.map((item) => {
						return item.title === title ? { title, checked: true } : item
					})
					setSonstigeChecked(updatedSonstige)
					return
				}
			} else {
				if (sonstigeChecked) {
					const index = sonstigeChecked.findIndex((item) => item.title === title)
					if (index !== -1) {
						const updatedSonstige = sonstigeChecked.map((item) => {
							return item.title === title ? { title, checked: false } : item
						})
						setSonstigeChecked(updatedSonstige)
						return
					}
					return
				}
				return
			}
		}
	}

	const sendSchreibenData = async (versand: TVersandart, optionaleNachricht?: string) => {
		await updateDocuments()
		const typesNochAnzufragen = nochAnzufragenChecked
			.filter((item) => item.checked && item.type !== 'SONSTIGE_NACHWEISE')
			.map((item) => item.type)
		const weitereDokumente = sonstigeChecked
			.filter((item) => item.checked)
			.map((item) => {
				return { title: item.title }
			})
		if (weitereDokumente.length > 0) {
			typesNochAnzufragen.push('SONSTIGE_NACHWEISE')
		}
		if (!dokumente) {
			return
		}
		const dokumenteToUpdate = dokumente.filter(
			(item) => item.status !== 'freigegeben' && typesNochAnzufragen.includes(item.type)
		)

		const promises = dokumenteToUpdate.map(async (item) => {
			const { type, status, ablehnungsgrund } = item
			const payload = {
				body: {
					ablehnungsgrund,
					type,
					nochAnzufragen: true,
					status,
				},
				userId,
				fallart: fallart || 'LEISTUNGSFALL',
				area: '',
			}
			return sendData(payload)
		})

		await Promise.all(promises)
		await sendDocumentRequest({
			body: {
				optionaleNachricht: optionaleNachricht,
				versandart: versand,
				isInitial: initial || false,
				weitereDokumente,
			},
			userId,
		})
	}

	const handleInitialStateChange = (state: boolean) => {
		setInitial(state)
		sendDocumentRequest({
			body: {
				isInitial: state,
				versandart: 'postalisch',
			},
			userId,
		})
	}

	const handleAddSonstigeDocument = (checked: boolean, type: TDocumentType, title: string) => {
		if (title.trim() === '') {
			setToggleNewDocument(false)
			return
		}
		const newDocument = { title, checked: true }
		const updatedSonstige = [...sonstigeChecked, newDocument]
		const updatedSonstigeDokumente = [...sonstigeDokumente, { title }]
		setSonstigeChecked(updatedSonstige)
		setSonstigeDokumente(updatedSonstigeDokumente)
		setToggleNewDocument(false)
	}

	return (
		<div>
			<h1>Nachweise & weitere Dokumente</h1>
			{userId && <DokumentNaviButton userId={userId}></DokumentNaviButton>}
			<div className="austritt-dokumente flex flex--direction-column flex--gap"></div>
			<div className="flex flex--direction-column flex--gap">
				{dokumente?.map((dokument, index) => (
					<DocumentCard
						key={index}
						status={dokument.status}
						type={dokument.type}
						statusChangedAt={dokument.statusChangedAt}
						dokumente={dokument.dokumente}
						ablehnungsgruende={austrittDokumente?.options?.moeglicheAblehnungsgruende ?? []}
						fallart={fallart}
						ablehnungsgrund={dokument.ablehnungsgrund}
						schreibenProperties={schreibenProperties}
						handleDocumentReleaseStatus={handleDocumentReleaseStatus}
						nochAnzufragen={dokument.nochAnzufragen}
						refetchAustrittDokumente={refetchAustrittDokumente}
						onDocumentChange={(ablehnungsgrund: TAblehnungsgrund) =>
							handleDocumentChange(dokument.type, ablehnungsgrund)
						}
					/>
				))}
			</div>

			{nochAnzufragenChecked && (
				<DocumentRequest
					dokumente={dokumente}
					sonstigeDokumente={sonstigeDokumente}
					optionen={options}
					setState={setState}
					state={state}
					finalizeDocumentRequest={finalizeDocumentRequest}
					initial={initial}
					handleInitialStateChange={handleInitialStateChange}
					sendSchreibenData={sendSchreibenData}
					refetchHistorie={refetchHistorie}
					onCheckedChange={handleNochAnzufragenChange}
					nochAnzufragenChecked={nochAnzufragenChecked}
					addDocument={handleAddSonstigeDocument}
					handleToggleNewDocument={() => setToggleNewDocument(!toggleNewDocument)}
					toggleNewDocument={toggleNewDocument}
					sonstigeChecked={sonstigeChecked}
					isAbfindungsFall={isAbfindungsFall}
				/>
			)}
		</div>
	)
}

export default Dokumente
