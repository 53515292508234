import { useTranslation } from 'react-i18next'
import { Editor } from 'hooks/useApi'
import { FunctionComponent } from 'react'
import dateFormat from 'shared/helper/dateFormat'
import NoteDelete from './NoteDelete'
import NoteEdit from './NoteEdit'

export interface ITimelineNoteContent {
	noteId: string
	createdBy: Editor
	createdAt: string
	editedBy?: Editor
	editedAt?: string
	content: string
}

export interface ITimelineNoteProps extends ITimelineNoteContent {
	refreshHook(): void
}

export interface PensionerNotesQueryResult {
	notes: ITimelineNoteContent[]
}

export const Note: FunctionComponent<ITimelineNoteProps> = ({
	noteId,
	createdAt,
	createdBy,
	editedBy,
	editedAt,
	content,
	refreshHook,
}) => {
	const { t } = useTranslation()

	const getAuthorLine = () => {
		if (editedBy && editedAt) {
			const itemTime = dateFormat(new Date(editedAt), {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
			})
			const atWord = t('view.pensionerTimeline.types.note.at')
			const state = t('view.pensionerTimeline.types.note.state.edited')
			const { firstName, lastName } = editedBy.salutation
			return `${atWord} ${itemTime} ${state} ${firstName} ${lastName}`
		} else {
			const state = t('view.pensionerTimeline.types.note.state.created')
			const { firstName, lastName } = createdBy.salutation
			return `${state} ${firstName} ${lastName}`
		}
	}

	return (
		<div className="timeline-note">
			<div className="timeline-note__content">
				<div className="timeline-note__content--header">{getAuthorLine()}</div>
				<div className="timeline-note__content--text">{content}</div>
			</div>
			<div className="timeline-note__button">
				<NoteEdit
					className="timeline-note__button"
					noteId={noteId}
					createdAt={createdAt}
					content={content}
					refreshHook={refreshHook}
				/>
				<NoteDelete
					className="timeline-note__button"
					noteId={noteId}
					createdAt={createdAt}
					refreshHook={refreshHook}
				/>
			</div>
		</div>
	)
}
