import { VersorgungsausgleichStatusInfoAdminApi } from '../../hooks/useUserRecord'
import { Trans } from 'react-i18next'
import dateFormat from '../../shared/helper/dateFormat'
import React from 'react'
import { IVersorgungsausgleich } from 'hooks/useApi'

const optionalDateFormat = (date: string | undefined) => (date ? dateFormat(new Date(date)) : '-')

const renderRoleLabel = ({ role }: Pick<VersorgungsausgleichStatusInfoAdminApi, 'role'>) => {
	switch (role) {
		case 'Ausgleichsberechtigt':
			return <Trans i18nKey="view.pensionerProfile.versorgungsausgleich.ausgleichspflichtige_person" />
		case 'Ausgleichspflichtig':
			return <Trans i18nKey="view.pensionerProfile.versorgungsausgleich.ausgleichsberechtige_person" />
		case undefined:
		case null:
			return <Trans i18nKey="view.pensionerProfile.versorgungsausgleich.connectedRole" />
	}
}

const VersorgungsausgleichGroup: React.FC<{
	ehenummer: string
	versorgungsausgleichHistory: ReadonlyArray<VersorgungsausgleichStatusInfoAdminApi>
	onRedirectToEmployee: (identNumber: string) => void
}> = ({ versorgungsausgleichHistory, ehenummer, onRedirectToEmployee }) => {
	const relationInfo = versorgungsausgleichHistory.find(({ connectedPerson, role }) => {
		return connectedPerson && role
	})
	return (
		<div>
			<h3>
				<Trans i18nKey="view.pensionerProfile.versorgungsausgleich.marriage_title" values={{ ehenummer }} />
			</h3>
			<div>
				{relationInfo ? (
					<div>
						<span className="versorgungsausgleich_label">{renderRoleLabel(relationInfo)}:</span>
						<u>
							<span
								className="employee-link"
								onClick={() => {
									if (relationInfo.connectedPerson?.identNumber) {
										onRedirectToEmployee(relationInfo.connectedPerson?.identNumber)
									}
								}}
							>
								{relationInfo.connectedPerson?.identNumber}
							</span>
						</u>
						{relationInfo?.connectedPerson?.name ? ` ${relationInfo.connectedPerson.name}` : ''}
					</div>
				) : null}
			</div>
			<div>
				{versorgungsausgleichHistory
					.slice()
					.sort((item1, item2) => new Date(item1.changedAt).getTime() - new Date(item2.changedAt).getTime())
					.map((versorgungsausgleich) => (
						<div key={versorgungsausgleich.statusCode} className="margin--vertical margin--large">
							<div className="flex-warp margin--vertical margin--small">
								<div>
									<span className="versorgungsausgleich_label">
										<Trans i18nKey="view.pensionerProfile.versorgungsausgleich.status" />:
									</span>
									<span>{versorgungsausgleich.statusText}</span>
								</div>
								<div>
									<span className="versorgungsausgleich_label">
										<Trans i18nKey="view.pensionerProfile.versorgungsausgleich.date" />:
									</span>
									<span>{dateFormat(new Date(versorgungsausgleich.changedAt))}</span>
								</div>
								<div>
									<span className="versorgungsausgleich_label">
										<Trans i18nKey="view.pensionerProfile.versorgungsausgleich.marriage_time_range" />
										:
									</span>
									<span>{optionalDateFormat(versorgungsausgleich.ehe.von)}</span>
									<Trans i18nKey="view.pensionerProfile.versorgungsausgleich.until" />
									<span>{optionalDateFormat(versorgungsausgleich.ehe.bis)}</span>
								</div>
							</div>
							<div className="flex-warp margin--vertical margin--small">
								<div>
									<span className="versorgungsausgleich_label">
										<Trans i18nKey="view.pensionerProfile.versorgungsausgleich.court_name" />:
									</span>
									<span>{versorgungsausgleich.gericht.gerichtsname || '-'}</span>
								</div>
								<div>
									<span className="versorgungsausgleich_label">
										<Trans i18nKey="view.pensionerProfile.versorgungsausgleich.court_inquiry_date" />
										:
									</span>
									<span>{optionalDateFormat(versorgungsausgleich.gericht.anfragedatum)}</span>
								</div>
								<div>
									<span className="versorgungsausgleich_label">
										<Trans i18nKey="view.pensionerProfile.versorgungsausgleich.court_file_reference" />
										:
									</span>
									<span>{versorgungsausgleich.gericht.aktenzeichen ?? ''}</span>
								</div>
								<div>
									<span className="versorgungsausgleich_label">
										<Trans i18nKey="view.pensionerProfile.versorgungsausgleich.court_judgement_from" />
										:
									</span>
									<span>{optionalDateFormat(versorgungsausgleich.gericht.urteilVom)}</span>
								</div>
								<div>
									<span className="versorgungsausgleich_label">
										<Trans i18nKey="view.pensionerProfile.versorgungsausgleich.court_legally_binding_since" />
										:
									</span>
									<span>{optionalDateFormat(versorgungsausgleich.gericht.rechtskraeftigAb)}</span>
								</div>
								<div>
									<span className="versorgungsausgleich_label">
										<Trans i18nKey="view.pensionerProfile.versorgungsausgleich.court_legally_binding_acknowledgement_since" />
										:
									</span>
									<span>
										{optionalDateFormat(versorgungsausgleich.gericht.kenntnisnahmeRechtskraeftigAb)}
									</span>
								</div>
							</div>
							<div className="flex flex--align-items-center margin--vertical margin--small">
								<div>
									<span className="versorgungsausgleich_label">
										<Trans i18nKey="view.pensionerProfile.versorgungsausgleich.note" />:
									</span>
									<span>{versorgungsausgleich.ehe.note || '-'}</span>
								</div>
							</div>
						</div>
					))}
			</div>
		</div>
	)
}

const Versorgungsausgleich: React.FC<{
	data: IVersorgungsausgleich | undefined
	onRedirectToEmployee: (identNumber: string) => void
}> = ({ data, onRedirectToEmployee }) => {
	if (!data?.versorgungsausgleich.length) {
		return <Trans i18nKey="generic.noDataAvailable" />
	}
	const versorgungsausgleichGroups = data.versorgungsausgleich.reduce((groups, versorgungsausgleich) => {
		const group = groups[versorgungsausgleich.ehe.nummer]
		if (group) {
			group.push(versorgungsausgleich)
		} else {
			groups[versorgungsausgleich.ehe.nummer] = [versorgungsausgleich]
		}
		return groups
	}, {} as Record<string, VersorgungsausgleichStatusInfoAdminApi[]>)
	return (
		<>
			{Object.entries(versorgungsausgleichGroups)
				.sort(([ehenummer1], [ehenummer2]) => Number(ehenummer1) - Number(ehenummer2))
				.map(([ehenummer, versorgungsausgleichHistory]) => (
					<VersorgungsausgleichGroup
						key={ehenummer}
						ehenummer={ehenummer}
						versorgungsausgleichHistory={versorgungsausgleichHistory}
						onRedirectToEmployee={onRedirectToEmployee}
					/>
				))}
		</>
	)
}

export default Versorgungsausgleich
