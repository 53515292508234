import useApi from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import { FunctionComponent, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import TextArea from 'shared/components/TextArea'

export interface INoteEditModal {
	open: boolean
	onModalClose: () => void
	noteId: string
	createdAt: string
	content: string
	refreshHook(): void
}

const NoteEditModal: FunctionComponent<INoteEditModal> = (props) => {
	const { open, onModalClose, noteId, createdAt, content, refreshHook } = props

	const modal = useRef<ModalRefActions>()
	const api = useApi()
	const { t } = useTranslation()
	const { userId: pensionerId } = useUserRecord()

	useEffect(() => {
		if (open) {
			modal.current?.openModal()
		}
	}, [open])

	const contentInputField = useRef<HTMLTextAreaElement>(null)

	const handleOnSubmit = async () => {
		if (!contentInputField.current || !pensionerId) {
			return
		}

		await api.updateNote(pensionerId, noteId, createdAt, contentInputField.current?.value).then(refreshHook)
	}

	return (
		<Modal
			ref={modal}
			forceAction={false}
			header={t('view.pensionerTimeline.types.note.editModal.header')}
			buttonLabel={t('generic.confirm')}
			onModalClose={onModalClose}
			onButtonClick={handleOnSubmit}
			className="modal--close-without-action"
		>
			<TextArea
				ref={contentInputField}
				name={'edit-note-content-input'}
				disabled={false}
				className={'timeline-note-edit-modal'}
				value={content}
			/>
		</Modal>
	)
}

export default NoteEditModal
