import { DisclaimerMessage } from 'components/Disclaimer'
import GridList from 'components/GridList'
import GridListCell from 'components/GridListCell'
import GridListRow from 'components/GridListRow'
import { ViewHeader } from 'components/ViewHeader'
import useApi, { ICompensationTableData, QueryKey } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import dateFormat from 'shared/helper/dateFormat'
import { currencyFormat } from 'shared/helper/numberFormats'

export const ConfirmedCompensations: React.FC = () => {
	const { t } = useTranslation()
	const { userId, firstnamesGenitive: name } = useUserRecord()
	const api = useApi()

	const { data, status } = useQuery(
		[
			QueryKey.pensionerConfirmedCompensationsTable,
			{
				userId,
			},
		],
		api.getConfirmedCompensationsTable,
		{
			enabled: !!userId,
		}
	)

	const sums = useMemo(() => {
		let ownContributionSum = 0.0
		let employerBaseAmountSum = 0.0
		let matchingAmountSum = 0.0

		data?.forEach((row) => {
			ownContributionSum += row['ownContribution']
			employerBaseAmountSum += row['employerBaseAmount']
			matchingAmountSum += row['matchingAmount']
		})
		return {
			year: '',
			month: '',
			ownContribution: ownContributionSum,
			employerBaseAmount: employerBaseAmountSum,
			matchingAmount: matchingAmountSum,
			compensationType: '',
			reportingDate: '',
			fundId: '',
		}
	}, [data])

	const tableHeaders: (keyof ICompensationTableData)[] = [
		'year',
		'month',
		'ownContribution',
		'employerBaseAmount',
		'matchingAmount',
		'compensationType',
		'reportingDate',
	]

	const getClasses = () => {
		const classes = ['confirmed-compensations']

		return classes.join(' ')
	}

	const renderTableHeader = () => {
		if (!data) {
			return
		}
		const headerCellClass = 'grid-list__cell--header'

		return (
			<GridListRow className={'grid-list__row--header'}>
				{tableHeaders.map((label, index) => (
					<GridListCell className={headerCellClass} key={`header-field-${index}`}>
						{t(`view.pensionerConfirmedCompensations.labels.${label}`)}
					</GridListCell>
				))}
			</GridListRow>
		)
	}

	const renderTableSumRow = () => {
		if (!data) {
			return
		}
		return (
			<GridListRow className="grid-list__row grid-list__row--sum">
				{Object.entries(sums).map(([key, value], index) => {
					let transformedValue = ''
					let classes = ['grid-list__cell--sum']
					switch (key) {
						case 'employerBaseAmount':
						case 'matchingAmount':
						case 'ownContribution':
							transformedValue = currencyFormat(value, { fixedFractionDigits: 2 })
							classes.push('grid-list__cell--number')
							break
					}
					const classNames = classes.join(' ')
					return (
						<GridListCell className={classNames} key={`sum-field-${index}`}>
							{transformedValue}
						</GridListCell>
					)
				})}
			</GridListRow>
		)
	}

	const renderTableRows = () => {
		if (!data) {
			return
		}

		return (
			<>
				{data.map((entry, index: number) => (
					<GridListRow key={`entry-${index}`}>
						{tableHeaders.map((key, fieldIndex: number) => {
							let transformedValue

							let classes = ['user-select--all']

							switch (key) {
								case 'year':
									transformedValue = entry[key]
									classes.push('grid-list__cell--number')
									break

								case 'month':
									transformedValue = dateFormat(new Date(Date.UTC(0, entry[key] - 1)), {
										month: 'long',
									})
									break

								case 'employerBaseAmount':
								case 'matchingAmount':
								case 'ownContribution':
									transformedValue = currencyFormat(entry[key], { fixedFractionDigits: 2 })
									classes.push('grid-list__cell--number')
									break

								case 'compensationType':
									transformedValue = `${t(`generic.confirmedCompensationTypes.${entry[key]}`)} ${entry[`fundId`] ? t(`generic.fundId.${entry[`fundId`]}`) : ''}`
									break
								case 'fundId':
									break
								default:
									transformedValue = entry[key]
							}

							const cellClasses = classes.join(' ')

							return (
								<GridListCell key={`entry-field-${index}-${fieldIndex}`} className={cellClasses}>
									{transformedValue}
								</GridListCell>
							)
						})}
					</GridListRow>
				))}
			</>
		)
	}

	return (
		<div className={`${getClasses()} visually-hidden visually-hidden--${String(status !== 'success')}`}>
			<ViewHeader
				headline={<Trans i18nKey="view.pensionerConfirmedCompensations.headline" values={{ name }} />}
				subheadline={<Trans i18nKey="view.pensionerConfirmedCompensations.conversionOverview" />}
			>
				{'success' === status && !data?.length && (
					<DisclaimerMessage
						text={<Trans i18nKey="view.pensionerConfirmedCompensations.noDataDisclaimer" />}
					/>
				)}
			</ViewHeader>

			{0 !== data?.length && (
				<GridList className="delegate__results" columnCount={tableHeaders.length}>
					{renderTableHeader()}
					{renderTableSumRow()}
					{renderTableRows()}
				</GridList>
			)}
		</div>
	)
}
