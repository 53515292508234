import bankAccountIllustration from 'assets/images/illu-bankaccount.svg'
import { ViewHeader } from 'components/ViewHeader'
import useCallerActionsApi, { TCallerActionsPrintFormRequestParams } from 'hooks/useCallerActionsApi'
import BackButton from 'partials/BackButton'
import { FunctionComponent, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormFields, FormFieldType, FormRefActions } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import useGlobalModalState from 'shared/hooks/useGlobalModalState'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath } from 'state/useNavigationState'
import { useCallerActionsContext } from './CallerActions.context'
import PdfViewer from 'shared/components/PdfViewer'

const CallerActionsSendBankAccountForm: FunctionComponent = () => {
	const { t } = useTranslation()
	const [, modalActions] = useGlobalModalState()
	const { getChildPath, navigateTo } = useRouteHelper(routesDictionary.callerActions)
	useBackButtonPath(getChildPath([routesDictionary.callerActions], 'dashboard'))
	const { userDetails, userAuthData } = useCallerActionsContext()
	const { postPrintedForm } = useCallerActionsApi()
	const formRef = useRef<FormRefActions>()
	const tenant = userAuthData?.tenant.toLowerCase()
	const selectedPDFForm = `/assets/tenant/${tenant}/banks-formular.pdf`

	const handleOnSuccess = () => {
		modalActions.setClass('modal--success')
		modalActions.setContent(
			<>
				<img src={bankAccountIllustration} alt="" />
				<h2>
					<Trans i18nKey={`view.callerActionsSendBankAccountForm.success.headline`} />
				</h2>
				<p>
					<Trans i18nKey={`view.callerActionsSendBankAccountForm.success.content`} />
				</p>
				<Button type={ButtonType.primary} label={t('generic.done')} onClick={() => modalActions.closeModal()} />
			</>
		)

		modalActions.setHideCloseButtons()
		modalActions.setOnCloseAction(() => navigateTo(getChildPath([routesDictionary.callerActions], 'dashboard')))
		modalActions.openModal()
	}

	const handleOnSubmit = async (submittedFields: Pick<TCallerActionsPrintFormRequestParams, 'formType'>) => {
		if (!userAuthData) {
			return false
		}

		return await postPrintedForm({
			...submittedFields,
			...userAuthData,
		})
	}

	const inputFields: FormFields = {
		formType: {
			type: TextInputType.hidden,
			fieldType: FormFieldType.text,
			value: 'BANK_DETAILS_CHANGE',
		},
	}

	return (
		<div className="caller-actions">
			<ViewHeader
				headline={t('view.callerActionsSendBankAccountForm.headline')}
				subheadline={t('view.callerActionsSendBankAccountForm.subtitle')}
				button={<BackButton />}
			/>
			<div className="flex margin--top">
				<div>
					<b className="margin--bottom">
						<Trans i18nKey={'generic.exampleLetter'} />
					</b>
					<PdfViewer className="margin--top" document={selectedPDFForm} />
				</div>
				<div className="max-content-width--narrow padding--horizontal">
					<div className="sticky">
						<div className="caller-actions__grid-item--full">
							<p className="font-family-bold margin--top">
								<Trans i18nKey="view.callerActionsSendBankAccountForm.copytext" />
							</p>

							<p className="font-family-bold text-color-teal margin--vertical">
								{userDetails?.firstName} {userDetails?.lastName}
								<br />
								{userDetails?.sendAddress?.street} {userDetails?.sendAddress?.houseNumber}
								<br />
								{userDetails?.sendAddress?.zip} {userDetails?.sendAddress?.city}
							</p>

							<Trans i18nKey="view.callerActionsSendBankAccountForm.disclaimer" />

							<Form
								ref={formRef}
								fields={inputFields}
								onSubmit={handleOnSubmit}
								onSuccess={handleOnSuccess}
								alwaysAllowSubmit={true}
								errorMessages={[t('view.callerActionsSendBankAccountForm.form.error')]}
								hideSubmit={true}
							/>
							<Button
								className="margin--top margin--large"
								label={t('view.callerActionsSendBankAccountForm.form.submit')}
								type={ButtonType.highlight}
								onClick={() => formRef.current?.submitForm()}
							/>
						</div>{' '}
					</div>
				</div>
			</div>
		</div>
	)
}

export default CallerActionsSendBankAccountForm
