import IdentNumberExportModal from 'components/AdminIdentNumberModal'
import useApi from 'hooks/useApi'
import React, { FunctionComponent, useState } from 'react'
import { Trans } from 'react-i18next'
import LoadingSpinner from '../../shared/components/LoadingSpinner'
import AdminHeader from './AdminHeader'
import ControlWawuExport from './Maintenance/ControlWawuExport'
import AllErstanschreibenCandidatesExport from './Maintenance/AllErstanschreibenCandidatesExport'
import { useUserPermission } from 'hooks/useUserPermission'
import { TMonth } from 'shared/helper/types/month'
import generateYearMonthArray from 'shared/helper/getListOfMonthAndYear'
import SelectInput, { SelectOption } from 'shared/components/SelectInput'
import { getToastMessage } from 'shared/helper/getToastMessage'
import zfToast, { ZFToastProps } from 'shared/components/ZFToast'

const AdminDashboard: FunctionComponent = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [modalOpen, setModalOpen] = useState(false)
	const [planvermoegenZeitraum, setPlanvermoegenZeitraum] = useState<string>()
	const [summenbildungZeitraum, setSummenbildungzeitraum] = useState<string>()

	const api = useApi()
	const { canUseAdminDashboard } = useUserPermission()

	const handleExecuteEmployessExport = async () => {
		await api.executeAllEmployeeExport()
	}

	const handleLoadEmployessExport = async () => {
		setIsLoading(true)
		await api.getAllEmployeeExport().then((exportUrl: string) => {
			window.open(exportUrl, '_blank', 'noopener')
		})
		setIsLoading(false)
	}

	const handleLoadTransactionExport = async () => {
		setIsLoading(true)
		await api.getAllTransactionsExport().then((exportUrl: string) => {
			window.open(exportUrl, '_blank', 'noopener')
		})
		setIsLoading(false)
	}

	const handleLoadRiskTransactionExport = async () => {
		setIsLoading(true)
		await api.getRiskTransactionExport().then((exportUrl) => {
			if (!exportUrl) {
				const toastProps: ZFToastProps = {
					title: 'Export von Risikotransaktionen',
					content: 'Das Exportieren ist fehlgeschlagen.',
					type: 'error',
				}
				zfToast(toastProps)
			} else {
				const resp = window.open(exportUrl, '_blank', 'noopener')
				if (resp === null) {
					const toastProps: ZFToastProps = {
						title: 'Export von Risikotransaktionen',
						content: 'Das Exportieren ist fehlgeschlagen.',
						type: 'error',
					}
					zfToast(toastProps)
				}
			}
		})
		setIsLoading(false)
	}

	const handleLoadPlanvermoegenReport = async () => {
		if (!planvermoegenZeitraum) {
			return
		}
		const [month, year] = planvermoegenZeitraum?.split('#')
		const monthNumber = Number(month)
		const yearNumber = Number(year)
		setIsLoading(true)
		await api
			.getPlanvermoegenReport(monthNumber, yearNumber)
			.then((exportUrl: string) => {
				const a = document.createElement('a')
				a.href = exportUrl
				a.target = '_blank'
				a.rel = 'noopener'
				a.click()
			})
			.catch((e: any) => {
				const toastProps: ZFToastProps = {
					title: 'Planvermögen Report',
					content: getToastMessage(e.response.status),
					type: 'error',
				}
				zfToast(toastProps)
			})

		setIsLoading(false)
	}

	const handleLoadSummenbildungReport = async () => {
		if(!summenbildungZeitraum) {
			return;
		}
		const [month, year] = summenbildungZeitraum.split('#');
		const monthNumber = Number(month)
		const yearNumber = Number(year)
		setIsLoading(true)
		await api
			.getSummenbildungReport(monthNumber, yearNumber)
			.then((exportUrl: string) => {
				const a = document.createElement('a')
					a.href = exportUrl
					a.target = '_blank'
					a.rel = 'noopener'
					a.click()
			})
			.catch((e: any) => {
				const toastProps: ZFToastProps = {
					title: 'Planvermögen Report',
					content: getToastMessage(e.response.status),
					type: 'error',
				}
				zfToast(toastProps)
			})
		setIsLoading(false)
	}

	return (
		<>
			<AdminHeader />
			<div className="admin-dashboard">
				{canUseAdminDashboard && (
					<div className="admin-dashboard__item admin-dashboard__item__2">
						<div className="flex flex--gap">
							<button className="button admin-dashboard__button" onClick={handleExecuteEmployessExport}>
								<Trans i18nKey={'view.adminDashboard.employeeExportItem.buttons.execution'} />
							</button>
							<button className="button admin-dashboard__button" onClick={handleLoadEmployessExport}>
								<Trans i18nKey={'view.adminDashboard.employeeExportItem.buttons.download'} />
							</button>
						</div>

						<h1 className="no-margin--bottom">
							<Trans i18nKey={'view.adminDashboard.employeeExportItem.headline'} />
						</h1>

						<div className="flex flex-s-align-items-center ">
							<div className="text-transform-uppercase text-color-gold margin--right">
								<Trans i18nKey={'view.adminDashboard.employeeExportItem.subheadline'} />
							</div>
							<hr className="hr hr--gold flex--grow" />
						</div>
					</div>
				)}
				{canUseAdminDashboard && (
					<div className="admin-dashboard__item admin-dashboard__item__2">
						<div className="flex flex--gap ">
							<SelectInput
								className="planvermoegen"
								alternativeStyle={true}
								returnEvent={false}
								options={getSelectOptions({})}
								label={<Trans i18nKey={'view.adminDashboard.planvermoegenReport.selectLabel'} />}
								value={planvermoegenZeitraum}
								onChange={setPlanvermoegenZeitraum}
							></SelectInput>
							<button className="button admin-dashboard__button" onClick={handleLoadPlanvermoegenReport}>
								<Trans i18nKey={'view.adminDashboard.planvermoegenReport.buttons.download'} />
							</button>
						</div>
						<h1 className="delegate_headline no-margin--bottom">
							<Trans i18nKey={'view.adminDashboard.planvermoegenReport.headline'} />
						</h1>
						<div className="flex flex-s-align-items-left">
							<div className="text-transform-uppercase text-color-gold margin--right">
								<Trans i18nKey={'view.adminDashboard.planvermoegenReport.subheadline'} />
							</div>
							<hr className="hr hr--gold flex--grow" />
						</div>
					</div>
				)}
				{canUseAdminDashboard && (
					<div className="admin-dashboard__item admin-dashboard__item__2">
						<div className="flex flex--gap ">
							<SelectInput
								className="summenbildungReport"
								alternativeStyle={true}
								returnEvent={false}
								options={getSelectOptions({includeCurrentMonth: true})}
								label={<Trans i18nKey={'view.adminDashboard.summenbildungReport.selectLabel'} />}
								value={summenbildungZeitraum}
								onChange={setSummenbildungzeitraum}
							></SelectInput>
							<button className="button admin-dashboard__button" onClick={handleLoadSummenbildungReport}>
								<Trans i18nKey={'view.adminDashboard.summenbildungReport.buttons.download'} />
							</button>
						</div>
						<h1 className="delegate_headline no-margin--bottom">
							<Trans i18nKey={'view.adminDashboard.summenbildungReport.headline'} />
						</h1>
						<div className="flex flex-s-align-items-left">
							<div className="text-transform-uppercase text-color-gold margin--right">
								<Trans i18nKey={'view.adminDashboard.summenbildungReport.subheadline'} />
							</div>
							<hr className="hr hr--gold flex--grow" />
						</div>
					</div>
				)}
				{canUseAdminDashboard && (
					<div
						onClick={handleLoadTransactionExport}
						className="admin-dashboard__item  admin-dashboard__item__pointer flex flex--direction-column flex--justify-content-end "
					>
						<h1 className="delegate_headline no-margin--bottom">
							<Trans i18nKey={'view.adminDashboard.transactionExport.headline'} />
						</h1>
						<div className="flex flex-s-align-items-left">
							<div className="text-transform-uppercase text-color-gold margin--right">
								<Trans i18nKey={'view.adminDashboard.transactionExport.subheadline'} />
							</div>
							<hr className="hr hr--gold flex--grow" />
						</div>
					</div>
				)}
				{canUseAdminDashboard && (
					<>
						<div
							onClick={() => {
								setModalOpen(!modalOpen)
							}}
							className="admin-dashboard__item admin-dashboard__item__pointer flex flex--direction-column flex--justify-content-end "
						>
							<h1 className="no-margin--bottom">
								<Trans i18nKey={'view.adminDashboard.identNumberExport.headline'} />
							</h1>

							<div className="flex flex-s-align-items-center ">
								<div className="text-transform-uppercase text-color-gold margin--right">
									<Trans i18nKey={'view.adminDashboard.identNumberExport.subheadline'} />
								</div>
								<hr className="hr hr--gold flex--grow" />
							</div>
						</div>
						<IdentNumberExportModal
							open={modalOpen}
							onModalClose={() => {
								setModalOpen(false)
							}}
						/>
					</>
				)}
				{canUseAdminDashboard && <ControlWawuExport />}
				{canUseAdminDashboard && (
					<div
						className="admin-dashboard__item admin-dashboard__item__2 admin-dashboard__item__pointer flex flex--direction-column flex--justify-content-end "
						onClick={handleLoadRiskTransactionExport}
					>
						<h1 className="delegate_headline no-margin--bottom">
							<Trans i18nKey={'view.adminDashboard.riskTransactionExport.headline'} />
						</h1>
						<div className="flex flex-s-align-items-left">
							<div className="text-transform-uppercase text-color-gold margin--right">
								<Trans i18nKey={'view.adminDashboard.riskTransactionExport.subheadline'} />
							</div>
							<hr className="hr hr--gold flex--grow" />
						</div>
					</div>
				)}

				{canUseAdminDashboard && <AllErstanschreibenCandidatesExport />}
			</div>
			{isLoading && <LoadingSpinner />}
		</>
	)
}


const getSelectOptions = ({includeCurrentMonth}: {includeCurrentMonth?: boolean}): SelectOption[] => {
	const thisYear = new Date().getFullYear()
	/**
	 * latest month für Planvermögen ist ein Monat vor dem aktuellen.
	 * latest month für Summenbildung ist der aktuelle Monat.
	 */
	const latestMonth = includeCurrentMonth ? (new Date().getMonth() + 1)  as TMonth : new Date().getMonth() as TMonth
	const monthAndYearListForPlanVermoegen = generateYearMonthArray(thisYear - 2, latestMonth, thisYear, latestMonth)
	const result: SelectOption[] = monthAndYearListForPlanVermoegen.map((item) => {
		return {
			value: `${item.month.monthNumber}#${item.year}`,
			label: `${item.month.monthName} ${item.year}`,
		}
	})
	return result
}

export default AdminDashboard
