import { IconType } from 'components/Icons'
import Table from 'components/Table'
import { convertStatusClosedWithoutAction } from 'helper/convertStatusClosedWithoutAction'
import { Ticket } from 'hooks/useApi'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import Icon from 'shared/components/Icon'
import dateFormat from 'shared/helper/dateFormat'

interface IInboxList {
	data?: Ticket[]
}

const TicketTable: FunctionComponent<IInboxList> = ({ data }) => {
	const { t } = useTranslation()

	const columnData: string[] = ['systemReceivedAt', 'type', 'user', 'remarks', 'status']

	const getValue = (accessor: string, entry: Ticket) => {
		let transformedValue
		switch (accessor) {
			case 'systemReceivedAt':
				transformedValue = `${dateFormat(new Date(entry.systemReceivedAt), {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit',
					hour: '2-digit',
					minute: '2-digit',
				})}
									${t('generic.oClock')}`
				break

			case 'type': {
				const type = entry.documentContent.content.type
				transformedValue = (
					<>
						<Icon className="margin--left margin--small-negative" type={entry.receivedVia} />
						{t(`component.documentEntry.type.${type}`)}
					</>
				)
				break
			}

			case 'user': {
				const userId = entry.documentContent.content.identNumber
				const { firstName, lastName } = entry.documentContent.content?.name || {}

				const userName = firstName && lastName ? `${firstName} ${lastName}` : undefined

				transformedValue = (
					<div className="flex flex--direction-column">
						<span>{userId}</span>
						{userName && <span className="font-size-s margin--top margin--small">{userName}</span>}
					</div>
				)
				break
			}

			case 'remarks':
				const remarks = entry.remarks?.map((remark, index: number) => (
					<span className="remark" key={`remark-${index}`}>
						${t(`component.ticket.remarks.${remark}`)}
					</span>
				))

				const predefinedRemarks = entry.predefinedRemarks?.map((remark, index: number) => (
					<span className="predefined-remark margin margin--small" key={`custom-remark-${index}`}>
						{t(`component.ticketModal.remarkLabel.${remark}`)}
					</span>
				))

				const customRemarks = entry.customRemarks?.map((remark, index: number) => (
					<span className="custom-remark margin margin--small" key={`custom-remark-${index}`}>
						{remark}
					</span>
				))

				transformedValue = (
					<div className="flex flex--wrap">
						{remarks}
						{predefinedRemarks}
						{customRemarks}
					</div>
				)

				break

			case 'status':
				const { problemState } = entry

				const status = convertStatusClosedWithoutAction(entry)

				const { firstName: editedByFirstName, lastName: editedByLastName } =
					entry.statusInfo.editedBy?.salutation || {}
				const { firstName: approvedByFirstName, lastName: approvedByLastName } =
					entry.statusInfo.approvedBy?.salutation || {}

				const editedBy =
					editedByFirstName && editedByLastName ? `${editedByFirstName} ${editedByLastName}` : undefined
				const approvedBy =
					approvedByFirstName && approvedByLastName
						? `${approvedByFirstName} ${approvedByLastName}`
						: undefined

				if (!entry.lockedBy) {
					transformedValue = (
						<div className="flex flex--align-items-center flex--grow">
							<div className="flex flex--direction-column flex--grow">
								<span>{t(`component.ticket.ticketStates.${status}`)}</span>
								{editedBy && (
									<span className="font-size-s margin--top margin--small">
										{t('component.inboxList.tableCell.editedBy', {
											name: editedBy,
										})}
									</span>
								)}
								{approvedBy && (
									<span className="font-size-s margin--top margin--small">
										{t('component.inboxList.tableCell.approvedBy', {
											name: approvedBy,
										})}
									</span>
								)}
							</div>
							{'default' === problemState && (
								<Icon
									className="margin--left margin--small"
									type={IconType.info}
									color="var(--color-red)"
									rotate={180}
								/>
							)}
						</div>
					)
				} else {
					transformedValue = t('component.ticket.ticketStates.lockedBy', {
						name: `${entry.lockedBy?.salutation.firstName} ${entry.lockedBy?.salutation.lastName}`,
					})
				}
				break
		}
		return transformedValue
	}

	return <Table columnData={columnData} data={data} translationPath="" getValue={getValue}></Table>
}

export default TicketTable
