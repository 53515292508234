import GridList from 'components/GridList'
import GridListCell from 'components/GridListCell'
import GridListRow from 'components/GridListRow'
import useApi, { PdfJob, PdfMergeQueryResponse, QueryKey } from 'hooks/useApi'
import React, { FunctionComponent, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import Button, { ButtonType } from 'shared/components/Button'
import dateFormat from 'shared/helper/dateFormat'
import LoadingSpinner from '../../shared/components/LoadingSpinner'
import { Buffer } from 'buffer'

const PdfFactory: FunctionComponent = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const searchHeaders: (keyof PdfJob)[] = [
		'entityId',
		'documentType',
		'compiled',
		'pdfReady',
		'merged',
		'transferredToEAkte',
		'transferredToSpo',
	]
	const api = useApi()
	const { data: pdfJobs } = useQuery([QueryKey.pdfJobs], api.pdfJobs, {
		refetchInterval: 5000,
	})

	const { t } = useTranslation()
	const inputFile = useRef(document.createElement('input'))

	async function handleMergePdf(entityId: string, documentType: string, fileExtension?: 'pdf' | 'zip') {
		console.log(`id: ${entityId}`)
		setIsLoading(true)
		if (fileExtension) {
			await api.pdfMerge({ entityId, documentType, fileExtension }).then((respose: PdfMergeQueryResponse) => {
				const a = document.createElement('a')
				a.href = respose.signedUrl
				a.target = '_blank'
				a.rel = 'noopener'
				a.click()
			})
		} else {
			await api.pdfRemoveMiniJobs({ entityId })
			await api.pdfMerge({ entityId, documentType }).then(() => { })
		}
		setIsLoading(false)
	}

	async function handleCopyPdf(entityId: string, documentType: string) {
		console.log(`id: ${entityId}`)
		setIsLoading(true)
		await api.pdfRemoveMiniJobs({ entityId })
		await api.pdfCopy({ entityId, documentType })
		setIsLoading(false)
	}

	async function handleSendToSPO(entityId: string, documentType: string) {
		console.log(`id: ${entityId}`)
		setIsLoading(true)
		await api.pdfSendToSPO({ entityId, documentType })
		setIsLoading(false)
	}

	const handleFileUpload = async (e: { target: { files: any } }) => {
		const { files } = e.target
		if (files && files.length) {
			const buffer = await new Response(files[0]).arrayBuffer()
			await api.pdfUploadCsv(Buffer.from(buffer).toString('base64'), files[0].name)
		}
		inputFile.current.value = ''
	}

	const onButtonClick = () => {
		inputFile.current.click()
	}

	const renderSearchHeader = () => {
		const headerCellClass = 'grid-list__cell--header'

		return (
			<>
				<GridListRow>
					{searchHeaders.map((label, index) => (
						<GridListCell className={headerCellClass} key={`header-field-${index}`}>
							{t(`view.pdfFactory.labels.${label}`)}
						</GridListCell>
					))}
				</GridListRow>
			</>
		)
	}

	const renderSearchResults = () => {
		if (!pdfJobs) {
			return
		}

		return (
			<>
				{Object.values(pdfJobs)
					.sort((a, b) => Number(b.entityId) - Number(a.entityId))
					.map((result: PdfJob, index: number) => (
						<GridListRow key={`result-${index}`}>
							{searchHeaders.map((key, fieldIndex: number) => {
								let value
								switch (key) {
									case 'entityId':
										value = dateFormat(new Date(parseInt(result[key] as string)), {
											year: 'numeric',
											month: '2-digit',
											day: '2-digit',
											hour: '2-digit',
											minute: '2-digit',
										})
										break
									case 'documentType':
										value = `${result[key]}`
										break
									case 'transferredToEAkte':
										return (
											<GridListCell
												key={`result-field-${index}-${fieldIndex}`}
												className="user-select--all"
											>
												{result['total'] === result['pdfReady'] &&
													result['transferredToEAkte'] === 0 ? (
													<Button
														type={ButtonType.secondary}
														onClick={() =>
															handleCopyPdf(result['entityId'], result['documentType'])
														}
														className={`generate-${result['entityId']} pdf-factory-button`}
														disabled={isLoading}
														label={t('component.pdfFactory.actions.uploadToS3')}
													/>
												) : (
													result['transferredToEAkte'] === -1 ? (
														<>{t('component.pdfFactory.labels.nichtErlaubt')}</>
													) : (
														<>{`${result[key]} von ${result['total']}`}</>
													)
												)}
											</GridListCell>
										)
									case 'merged':
										return (
											<GridListCell
												key={`result-field-${index}-${fieldIndex}`}
												className="user-select--all"
											>
												{result['total'] !== result['pdfReady'] ? (
													<>{t('view.pdfFactory.labels.notAllPdfReady')}</>
												) : result['merged'] === 'done' ? (
													<>
														<Button
															type={ButtonType.primary}
															onClick={() =>
																handleMergePdf(
																	result['entityId'],
																	result['documentType'],
																	'pdf'
																)
															}
															className={`generate-${result['entityId']} pdf-factory-button`}
															disabled={isLoading}
															label={t('component.pdfFactory.actions.downloadPdf')}
														/>
														<Button
															type={ButtonType.primary}
															onClick={() =>
																handleMergePdf(
																	result['entityId'],
																	result['documentType'],
																	'zip'
																)
															}
															className={`generate-${result['entityId']} pdf-factory-button`}
															disabled={isLoading}
															label={t('component.pdfFactory.actions.downloadZip')}
														/>
													</>
												) : result['merged'] === 'n/a' ? (
													<Button
														type={ButtonType.primary}
														onClick={() =>
															handleMergePdf(result['entityId'], result['documentType'])
														}
														className={`generate-${result['entityId']} pdf-factory-button`}
														disabled={isLoading}
														label={t('component.pdfFactory.actions.merge')}
													/>
												) : (
													<>{result[key]}</>
												)}
											</GridListCell>
										)
									case 'transferredToSpo':
										return (
											<GridListCell
												key={`result-field-${index}-${fieldIndex}`}
												className="user-select--all"
											>
												{result['total'] === result['pdfReady'] &&
													result['merged'] === 'done' &&
													result['transferredToSpo'] === 0 ? (
													<Button
														type={ButtonType.primary}
														onClick={() =>
															handleSendToSPO(result['entityId'], result['documentType'])
														}
														className={`generate-${result['entityId']} pdf-factory-button`}
														disabled={isLoading}
														label={t('component.pdfFactory.actions.uploadToSpo')}
													/>
												) : (
													result['transferredToSpo'] === -1 ? (
														<>{t('component.pdfFactory.labels.nichtErlaubt')}</>
													) : (
														<>{`${result[key]} von ${result['total']}`}</>
													)
												)}
											</GridListCell>
										)
									default:
										value = `${result[key]} von ${result['total']}`
										break
								}
								return (
									<GridListCell
										key={`result-field-${index}-${fieldIndex}`}
										className="user-select--all"
									>
										{value}
									</GridListCell>
								)
							})}
						</GridListRow>
					))}
			</>
		)
	}
	return (
		<div className="pdf-factory">
			<div className="pdf-factory-header" style={{ position: 'relative' } as React.CSSProperties}>
				<h1 className="pdf-factory__headline margin--bottom no-margin--bottom">
					<Trans i18nKey="view.pdfFactory.headline" />
				</h1>
				<input
					style={{ display: 'none' }}
					accept=".csv"
					ref={inputFile}
					onChange={handleFileUpload}
					type="file"
				/>
				<Button
					type={ButtonType.secondary}
					onClick={onButtonClick}
					disabled={isLoading}
					label={t('component.pdfFactory.actions.upload')}
					style={
						{ position: 'absolute', right: 24, top: -34, '--button-height': '2rem' } as React.CSSProperties
					}
				/>
			</div>
			<div className="flex flex--align-items-center font-family-bold ">
				<div className="text-transform-uppercase text-color-gold margin--right">
					<Trans i18nKey="view.pdfFactory.subheadline" />
				</div>
				<hr className="hr hr--gold flex--grow" />
			</div>
			<GridList columnCount={searchHeaders.length}>
				{renderSearchHeader()}
				{renderSearchResults()}
			</GridList>
			{isLoading && <LoadingSpinner />}
		</div>
	)
}

export default PdfFactory
