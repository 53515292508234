import classNames from 'classnames'
import { IconType } from 'components/Icons'
import { useSoftphone } from 'hooks/useSoftphone'
import { FC, HTMLAttributes, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Trans, useTranslation } from 'react-i18next'
import useWindowSize from 'react-use/lib/useWindowSize'
import Accordion from 'shared/components/Accordion'
import Button, { ButtonType } from 'shared/components/Button'
import Icon, { IconSize } from 'shared/components/Icon'
import { getLocalStorage, setLocalStorage } from 'shared/helper/localStorage'
import { ConnectActions } from './ConnectActions'
import { ConnectAfterCallWork } from './ConnectAfterCallWork'
import { ConnectAfterCallWorkButton } from './ConnectAfterCallWorkButton'
import { ConnectCallButtons } from './ConnectCallButtons'
import { ConnectCallHint } from './ConnectCallHint'
import { ConnectCallInformation } from './ConnectCallInformation'
import { ConnectStateSelector } from './ConnectStateSelector'

const SOFTPHONE_BREAKPOINT_PX = 1400

enum AccordionNames {
	CONNECT_ACTIONS,
	CALL_INFORMATION,
}

export const ConnectWrapper: FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
	const breakpointReached = window.innerWidth > SOFTPHONE_BREAKPOINT_PX

	// in offline state the background will only switch for a brief amount of time and the retun to the default state
	const ANIMATION_TIME_MS = 5000

	const { agentState, contactState, acwTime, outgoingCallNumber, handleOnDenyCallAction } = useSoftphone()
	const { t } = useTranslation()
	const [expanded, setExpanded] = useState<boolean>(breakpointReached ? true : false)
	// if window is bigger than certain viewport, render phone in sidebar; otherwise in footer
	const [expandedInSidebar, setExpandedInSidebar] = useState<boolean>(breakpointReached ? true : false)
	const [animationRunning, setAnimationRunning] = useState<boolean>(false)

	const [openAccordions, setOpenAccordions] = useState<{ [key in AccordionNames]: boolean }>(
		getLocalStorage('connectAccordeonStates', {
			[AccordionNames.CONNECT_ACTIONS]: true,
			[AccordionNames.CALL_INFORMATION]: true,
		})
	)

	const windowSize = useWindowSize()

	const timeout = useRef<NodeJS.Timeout>()

	useEffect(() => {
		const handleWindowResize = () => {
			if (timeout.current) {
				clearTimeout(timeout.current)
			}
			// use timeout for input debuffer
			timeout.current = setTimeout(() => {
				if (windowSize.width > SOFTPHONE_BREAKPOINT_PX) {
					setExpandedInSidebar(true)
				} else {
					setExpandedInSidebar(false)
				}
			}, 200)
		}

		handleWindowResize()
	}, [windowSize])

	useEffect(() => {
		if (agentState === 'offline') {
			setAnimationRunning(true)
			setTimeout(() => {
				setAnimationRunning(false)
			}, ANIMATION_TIME_MS)
		}
	}, [agentState])

	useEffect(() => {
		setLocalStorage('connectAccordeonStates', openAccordions)
	}, [openAccordions])

	const handleAccordionsClick = (name: AccordionNames) => {
		const open = openAccordions[name] ? false : true
		const newState = { ...openAccordions }
		newState[name] = open
		setOpenAccordions(newState)
	}

	const softPhoneJSX = (
		<div
			className={classNames(
				className,
				'connect-wrapper',
				`connect-wrapper--agent-state-${agentState}`,
				`connect-wrapper--contact-state-${contactState}`,
				`${
					agentState === 'offline' && animationRunning
						? 'connect-wrapper--agent-state-offline-transition-animation'
						: ''
				}`,
				{
					'connect-wrapper--expanded': expanded,
				}
			)}
		>
			<button className="connect-wrapper__expand" onClick={setExpanded.bind(null, !expanded)}>
				<Icon
					type={IconType.arrow}
					rotate={expanded ? 90 : -90}
					size={IconSize.small}
					color="var(--color-blue--medium)"
				/>
			</button>
			{expanded && (
				<>
					{false === ['pending-busy', 'busy', 'calling-customer'].includes(agentState) && (
						<div className="no-call-active-header flex flex--align-items-center padding padding--small no-padding--top">
							<Icon
								type={IconType.phoneCrossed}
								size={IconSize.large}
								color="var(--color-blue--medium)"
							/>
							<span className="font-size-l font-family-regular">
								<Trans i18nKey="component.connect.noActiveCall" />
							</span>
						</div>
					)}
					{agentState === 'calling-customer' && (
						<div className="no-call-active-header flex flex--align-items-center padding padding--small no-padding--top">
							<Icon
								type={IconType.callOutbound}
								size={IconSize.large}
								color="var(--color-blue--medium)"
							/>
							<span className="font-size-l font-family-regular">
								<Trans
									i18nKey="component.connect.outboundCall"
									values={{ phoneNumber: outgoingCallNumber }}
								/>
							</span>
						</div>
					)}
					 
					<Accordion
						open={openAccordions[AccordionNames.CONNECT_ACTIONS]}
						iconColor={'var(--color-blue--medium)'}
						header={t('component.connect.actions.header')}
						onClick={() => {
							handleAccordionsClick(AccordionNames.CONNECT_ACTIONS)
						}}
					>
						<ConnectActions />
					</Accordion>
					{['pending-busy', 'busy'].includes(agentState) && (
						<Accordion
							open={openAccordions[AccordionNames.CALL_INFORMATION]}
							onClick={() => {
								handleAccordionsClick(AccordionNames.CALL_INFORMATION)
							}}
							iconColor={'var(--color-blue--medium)'}
							header={t('component.connect.callInformation.header')}
						>
							<ConnectCallInformation />,
						</Accordion>
					)}
					{/* <AccordionGroup
				
						options={
							[
								{
									header:,
									content: (
										
									),
								},
								['pending-busy', 'busy'].includes(agentState)
									? {
											header: t('component.connect.callInformation.header'),
											content: <ConnectCallInformation />,
									  }
									: undefined,
							].filter(Boolean) as AccordionProps[]
						}
					/> */}
				</>
			)}
			<div className="connect-wrapper__flex-spacer"></div>
			{['pending-busy', 'busy'].includes(agentState) && (
				<>
					<ConnectCallButtons onClickActiveCallButton={setExpanded} />
					<ConnectCallHint className="call-hint" />
				</>
			)}
			{agentState === 'calling-customer' && (
				<>
					<Button
						type={ButtonType.primary}
						onClick={() => {
							handleOnDenyCallAction()
						}}
						className={'abort-outgoing-call'}
						icon={IconType.callOff}
					>
						<Trans i18nKey="component.connect.outboundCall" values={{ phoneNumber: outgoingCallNumber }} />
					</Button>
				</>
			)}
			{['offline', 'available'].includes(agentState) && <ConnectStateSelector />}
			{['after-call-work', 'after-call-work-manual'].includes(agentState) && (
				<>
					{expanded && <ConnectAfterCallWork />}
					<ConnectAfterCallWorkButton
						// className={!expanded ? 'margin margin--top' : ''}
						countDown={'after-call-work' === agentState ? acwTime : 0}
					/>
					{expanded && <ConnectStateSelector />}
				</>
			)}
		</div>
	)

	const portalEl = document.querySelector('#softphone-portal')

	if (portalEl && expandedInSidebar && expanded) {
		return softPhoneJSX
	} else {
		return portalEl && ReactDOM.createPortal(<div className="footer-wrapper">{softPhoneJSX}</div>, portalEl)
	}
}
