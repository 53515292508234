import useApi, { Ticket } from 'hooks/useApi'
import React, { FunctionComponent, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'

export interface IDeleteModal {
	open: boolean
	onModalClose: () => void
	data: Ticket
}

const DeleteModal: FunctionComponent<IDeleteModal> = (props) => {
	const { open, onModalClose, data } = props

	const modal = useRef<ModalRefActions>()
	const api = useApi()
	const { t } = useTranslation()
	const { navigateTo, getMainPath } = useRouteHelper()

	useEffect(() => {
		if (open) {
			modal.current?.openModal()
		}
	}, [open])

	const handleOnSubmit = async () => {
		api.deleteTicket(data.id).then(() => navigateTo(getMainPath(routesDictionary.globalInbox)))
	}

	const renderDeleteMessage = () => {
		return (
			<div className="deletion-disclaimer">
				<h3>{t('component.deleteModal.text')}</h3>
			</div>
		)
	}

	return (
		<Modal
			ref={modal}
			forceAction={false}
			header={t('component.deleteModal.header')}
			buttonLabel={t('generic.confirm')}
			onModalClose={onModalClose}
			onButtonClick={handleOnSubmit}
			className="modal--delete"
		>
			<div className="padding--horizontal margin--vertical text-align--left">
				{renderDeleteMessage()}
			</div>
		</Modal>
	)
}

export default DeleteModal
