import { usePath, useRoutes } from 'raviger'
import React, { useEffect } from 'react'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useCallerActionsContext } from './CallerActions.context'
import { useQuery } from 'react-query'

const CallerActions: React.FC = () => {
	const { navigateTo, getChildPath, getMainPath, routes } = useRouteHelper(routesDictionary.callerActions)
	const { userDetails, resetData } = useCallerActionsContext()
	const routeResult = useRoutes(routes, { basePath: getMainPath(routesDictionary.callerActions) })
	const currentPath = usePath(getMainPath(routesDictionary.callerActions))

	const { data } = useQuery('callerActionsIdentify', () => {
		return { tenantFromQuery: '', decadiaIdFromQuery: '' }
	})
	useEffect(() => {
		if (userDetails === undefined) {
			return
		}

		if (data?.tenantFromQuery !== '' && data?.decadiaIdFromQuery !== '') {
			return
		}

		resetData!()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	useEffect(() => {
		if (userDetails === undefined && currentPath !== getChildPath(routesDictionary.callerActions, 'identify')) {
			/**
			 * waiting for the next event loop is required to properly
			 * redirect to "identify" view on initial page load
			 */

			setTimeout(defaultRoute)
			return
		}

		if (userDetails !== undefined && currentPath === getChildPath(routesDictionary.callerActions, 'identify')) {
			dashboardRoute()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userDetails, currentPath])

	const defaultRoute = () => {
		navigateTo(getChildPath(routesDictionary.callerActions, 'identify'), true)
	}

	const dashboardRoute = () => {
		navigateTo(getChildPath(routesDictionary.callerActions, 'dashboard'), true)
	}

	return <>{routeResult || defaultRoute()}</>
}

export default CallerActions
