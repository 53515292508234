import React, { useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import Form, { FormFieldType, FormFields } from 'shared/components/Form'
import AustrittsSchreibenButtonGroup from 'components/AustrittSchreibenButtonGroup'
import { useUserRecord } from 'hooks/useUserRecord'
import useApi from 'hooks/useApi'
import { useQuery } from 'react-query'
import classNames from 'classnames'
import { currencyFormat } from 'shared/helper/numberFormats'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import getLocalizedYears from 'shared/helper/getLocalizedYears'
import { TMonthSchema, months } from 'shared/helper/types/month'

type THorizontalRowProps = {
	headlineKey?: string
	className?: string
}

const thisYear = new Date().getFullYear()
const years = getLocalizedYears(thisYear + 1, thisYear - 3)
//*------------------------------------------------------------------------------

// Components

const HorizontalLine: React.FC<THorizontalRowProps> = ({ headlineKey, className }) => (
	<div className={classNames('zusageschreiben__horizontal-line', className)}>
		{headlineKey && (
			<h3 className="bold-small-heading">
				<Trans i18nKey={headlineKey} />
			</h3>
		)}

		<hr className="hr hr--gold flex--grow" />
	</div>
)

//*------------------------------------------------------------------------------
export type LeistungsfallInfo = {
	bausteinkonto: boolean
	hasAuszahlungsplan: boolean
	underThreshold: boolean
}
type TFinalLeistungsfallProps = {
	refetchHistorie: any
	leistungsfallInfo?: LeistungsfallInfo
}

const FinalLeistungsfall: React.FC<TFinalLeistungsfallProps> = ({ refetchHistorie, leistungsfallInfo }) => {
	const { userId } = useUserRecord()
	const api = useApi()

	const { data, status } = useQuery(['getZusageschreibenOptionen', { userId }], api.getZusageschreibenOptionen, {
		enabled: !!userId,
	})

	const [updatedFormFields, setUpdatedFormFields] = useState<FormFields>()

	//*------------------------------------------------------------------------------

	//TODO: Textinput Component um eine Mask erweitern, ähnlich wie bei der BigNumberInput Komponente

	// FIELDS

	const hprfields: FormFields = useMemo(() => {
		return {
			hprStatus: {
				fieldType: FormFieldType.radioButtons,
				label: <Trans i18nKey={'view.zusageschreiben.content.hpr.vorhanden'} />,
				name: 'HPRVorhanden',
				prepend: <HorizontalLine headlineKey={'view.zusageschreiben.content.hpr.headline'} />,

				options: [
					{
						value: 'HPR_LIEGT_VOR',
						label: <Trans i18nKey={'view.zusageschreiben.content.hpr.liegtVor'} />,
					},
					{
						value: 'HPR_FEHLT',
						label: <Trans i18nKey={'view.zusageschreiben.content.hpr.fehlt'} />,
					},
					{
						value: 'OHNE_HPR',
						label: <Trans i18nKey={'view.zusageschreiben.content.hpr.nichtVorhanden'} />,
					},
				],
				value: data?.hprStatus,
			},
			r4: {
				fieldType: FormFieldType.text,
				hidden: updatedFormFields?.hprStatus?.value !== 'HPR_LIEGT_VOR',
				label: <Trans i18nKey={'view.zusageschreiben.content.hpr.r4'} />,
				value: currencyFormat(data?.r4),
			},
			r10: {
				fieldType: FormFieldType.text,
				hidden: updatedFormFields?.hprStatus?.value !== 'HPR_LIEGT_VOR',
				label: <Trans i18nKey={'view.zusageschreiben.content.hpr.r10'} />,
				value: currencyFormat(data?.r10),
			},
			avwl: {
				fieldType: FormFieldType.text,
				hidden: updatedFormFields?.hprStatus?.value !== 'HPR_LIEGT_VOR',
				label: <Trans i18nKey={'view.zusageschreiben.content.hpr.avwl'} />,
				value: currencyFormat(data?.avwl),
			},
			entgeltnachweisSenden: {
				fieldType: FormFieldType.radioButtons,
				label: <Trans i18nKey={'view.zusageschreiben.content.entgeltnachweis.mitschicken'} />,
				prepend: <HorizontalLine headlineKey={'view.zusageschreiben.content.entgeltnachweis.headline'} />,
				value: data?.entgeltnachweisZeitraum ? 'ja' : 'nein',
				options: [
					{
						value: 'ja',
						label: <Trans i18nKey={'view.zusageschreiben.content.entgeltnachweis.ja'} />,
					},
					{
						value: 'nein',
						label: <Trans i18nKey={'view.zusageschreiben.content.entgeltnachweis.nein'} />,
					},
				],
			},
			entgeltnachweisZeitraum: {
				fieldType: FormFieldType.radioButtons,
				hidden: updatedFormFields?.entgeltnachweisSenden?.value !== 'ja',
				label: <Trans i18nKey={'view.zusageschreiben.content.entgeltnachweis.wennJa'} />,
				value:
					data?.entgeltnachweisZeitraum && data?.entgeltnachweisZeitraum?.from
						? 'rueckwirkend'
						: 'zumAbrechnungsmonat',
				options: [
					{
						value: 'rueckwirkend',
						label: <Trans i18nKey={'view.zusageschreiben.content.entgeltnachweis.rückwirkend'} />,
					},
					{
						value: 'zumAbrechnungsmonat',
						label: <Trans i18nKey={'view.zusageschreiben.content.entgeltnachweis.abrechnungsmonat'} />,
					},
				],
			},

			rückwirkendForMonat: {
				fieldType: FormFieldType.select,
				label: <Trans i18nKey={'view.zusageschreiben.content.entgeltnachweis.monat'} />,
				alternativeStyle: true,
				hidden:
					updatedFormFields?.entgeltnachweisZeitraum?.value !== 'rueckwirkend' ||
					updatedFormFields?.entgeltnachweisSenden?.value !== 'ja',
				options: months.map((month: TMonthSchema) => ({
					value: String(month.monthNumber),
					label: month.monthName,
				})),
				value: data?.entgeltnachweisZeitraum?.from ? String(data.entgeltnachweisZeitraum.for.month) : undefined,
				prepend: (
					<h4>
						<Trans i18nKey={'view.zusageschreiben.content.entgeltnachweis.fuer'} />
					</h4>
				),
				className: 'zusageschreiben__optionen--form__left',
			},
			rückwirkendForJahr: {
				fieldType: FormFieldType.select,
				label: <Trans i18nKey={'view.zusageschreiben.content.entgeltnachweis.jahr'} />,
				alternativeStyle: true,
				hidden:
					updatedFormFields?.entgeltnachweisZeitraum?.value !== 'rueckwirkend' ||
					updatedFormFields?.entgeltnachweisSenden?.value !== 'ja',
				options: years.map((year) => ({
					value: String(year),
					label: String(year),
				})),
				value: data?.entgeltnachweisZeitraum?.from ? String(data.entgeltnachweisZeitraum.for.year) : undefined,
				className: 'zusageschreiben__optionen--form__right',
			},

			rückwirkendFromMonat: {
				fieldType: FormFieldType.select,
				label: <Trans i18nKey={'view.zusageschreiben.content.entgeltnachweis.monat'} />,
				alternativeStyle: true,
				hidden:
					updatedFormFields?.entgeltnachweisZeitraum?.value !== 'rueckwirkend' ||
					updatedFormFields?.entgeltnachweisSenden?.value !== 'ja',
				options: months.map((month: TMonthSchema) => ({
					value: String(month.monthNumber),
					label: month.monthName,
				})),
				value: String(data?.entgeltnachweisZeitraum?.from?.month),
				prepend: (
					<h4>
						<Trans i18nKey={'view.zusageschreiben.content.entgeltnachweis.vom'} />
					</h4>
				),
				className: 'zusageschreiben__optionen--form__left',
			},
			rückwirkendFromJahr: {
				fieldType: FormFieldType.select,
				hidden:
					updatedFormFields?.entgeltnachweisZeitraum?.value !== 'rueckwirkend' ||
					updatedFormFields?.entgeltnachweisSenden?.value !== 'ja',
				label: <Trans i18nKey={'view.zusageschreiben.content.entgeltnachweis.jahr'} />,
				alternativeStyle: true,
				options: years.map((year) => ({
					value: String(year),
					label: String(year),
				})),

				value: String(data?.entgeltnachweisZeitraum?.from?.year),
				className: 'zusageschreiben__optionen--form__right',
			},

			zumAbrechnungsmonatForMonat: {
				fieldType: FormFieldType.select,
				hidden:
					updatedFormFields?.entgeltnachweisZeitraum?.value !== 'zumAbrechnungsmonat' ||
					updatedFormFields?.entgeltnachweisSenden?.value !== 'ja',
				label: <Trans i18nKey={'view.zusageschreiben.content.entgeltnachweis.monat'} />,
				alternativeStyle: true,
				options: months.map((month: TMonthSchema) => ({
					value: String(month.monthNumber),
					label: month.monthName,
				})),

				value:
					data?.entgeltnachweisZeitraum?.from === undefined
						? String(data?.entgeltnachweisZeitraum?.for.month)
						: undefined,
				className: 'zusageschreiben__optionen--form__left',
			},
			zumAbrechnungsmonatForJahr: {
				fieldType: FormFieldType.select,
				hidden:
					updatedFormFields?.entgeltnachweisZeitraum?.value !== 'zumAbrechnungsmonat' ||
					updatedFormFields?.entgeltnachweisSenden?.value !== 'ja',
				label: <Trans i18nKey={'view.zusageschreiben.content.entgeltnachweis.jahr'} />,
				alternativeStyle: true,
				options: years.map((year: number) => ({
					value: String(year),
					label: String(year),
				})),
				value:
					data?.entgeltnachweisZeitraum?.from === undefined
						? String(data?.entgeltnachweisZeitraum?.for.year)
						: undefined,
				className: 'zusageschreiben__optionen--form__right',
			},
			auslandnachweis: {
				fieldType: FormFieldType.radioButtons,
				prepend: <HorizontalLine headlineKey={'view.zusageschreiben.content.ausland.headline'} />,
				label: <Trans i18nKey={'view.zusageschreiben.content.ausland.wennJa'} />,
				name: 'ausland',
				options: [
					{
						value: 'LEBENSNACHWEIS',
						label: <Trans i18nKey={'view.zusageschreiben.content.ausland.lebensnachweis'} />,
					},
					{
						value: 'LEBENSNACHWEIS_UND_ANSAESSIGKEITSBESCHEINIGUNG',
						label: (
							<Trans
								i18nKey={
									'view.zusageschreiben.content.ausland.lebensnachweisUndAnsaessigkeitsbescheinigung'
								}
							/>
						),
					},
					{
						value: 'KEINE',
						label: <Trans i18nKey={'view.zusageschreiben.content.ausland.nein'} />,
					},
				],
				value: data?.auslandnachweis,
			},
			ersteller: {
				prepend: (
					<HorizontalLine
						headlineKey={'view.zusageschreiben.content.erstellerPruefer.headline'}
						className="margin--bottom"
					/>
				),
				fieldType: FormFieldType.text,
				label: <Trans i18nKey={'view.zusageschreiben.content.erstellerPruefer.ersteller'} />,
				value: data?.ersteller,
				name: 'ersteller',
			},
			pruefer: {
				fieldType: FormFieldType.text,
				label: <Trans i18nKey={'view.zusageschreiben.content.erstellerPruefer.pruefer'} />,
				value: data?.pruefer,
				name: 'pruefer',
			},
		}
	}, [
		data,
		updatedFormFields?.entgeltnachweisSenden?.value,
		updatedFormFields?.entgeltnachweisZeitraum?.value,
		updatedFormFields?.hprStatus?.value,
		updatedFormFields?.auslandnachweis?.value,
	])

	return (
		<div className="zusageschreiben">
			{status === 'success' ? (
				<>
					<div className="zusageschreiben__optionen">
						<h2>
							<Trans i18nKey={'view.zusageschreiben.headlines.optionen'} />
						</h2>

						<Form
							fields={hprfields}
							onSubmit={() => {}}
							hideSubmit={true}
							onFormChange={setUpdatedFormFields}
							className="zusageschreiben__optionen--form"
						/>
					</div>
				</>
			) : (
				<LoadingSpinner area={'getZusageschreibenOptionen'} delay={0} />
			)}

			<AustrittsSchreibenButtonGroup
				createEndpoint={'ZusageSchreiben'}
				closeEndpoint={'Leistungsfall'}
				zusageschreibenoption={updatedFormFields}
				subheadline="view.zusageschreiben.headlines.zusageschreiben"
				headline="view.zusageschreiben.headlines.finalisieren"
				className="zusageschreiben__button-group"
				refetchHistorie={refetchHistorie}
				leistungsfallInfo={leistungsfallInfo}
			/>
		</div>
	)
}

export default FinalLeistungsfall
