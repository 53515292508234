import { IDisablilityOption, IPartnerBenefitOption } from 'hooks/useApi';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export interface CurrentRiskOptionProps {
  className?: string
  data: IDisablilityOption | IPartnerBenefitOption | undefined;
}

const CurrentRiskOption: FunctionComponent<CurrentRiskOptionProps> = props => {
  const { t } = useTranslation()
  const { data } = props
  const [mappedValues, setMappedValues] = useState<any>()

  useEffect(() => {
    if (data) {
      const startYear = new Date(data.start!).getFullYear()
      const endYear = new Date(data.end!).getFullYear()

      const values = {
        timerange: `${startYear} - ${endYear}`,
        choice: data?.choice === 'deselect' || 'increase' || 'standard' ? t(`view.pensionerRiskOptions.${data?.choice}`) : ''
      }

      setMappedValues(values)
    }
  }, [data, t])


  return (
    <>
      <div
        className={`text-transform-uppercase text-color-gold margin--right 
						}`}
      >
        <Trans i18nKey="view.pensionerRiskOptions.current" />
      </div>
      <div className="currentriskoption margin--vertical ">
        <div className="currentriskoption--grid">
          <label htmlFor="text-1" className="bold-small-heading currentriskoption--label">
            {t(`view.pensionerRiskOptions.tableHeaders.choice`)}
          </label>
          <div className="currentriskoption--value">{mappedValues?.choice}</div>
        </div>
        <div className="currentriskoption--grid">
          <label htmlFor="text-2" className="bold-small-heading currentriskoption--label">
            {t(`view.pensionerRiskOptions.timerange`)}
          </label>
          <div className="currentriskoption--value">{mappedValues?.timerange}</div>
        </div>
      </div>
    </>

  )
}

export default CurrentRiskOption
