import { Ticket } from 'hooks/useApi'
import React, { FunctionComponent, HTMLAttributes, useState } from 'react'
import Button, { ButtonType } from 'shared/components/Button'
import { IconType } from './Icons'
import TicketModal from './TicketModal'

export interface ITicketOptions {
	data: Ticket
}

const TicketOptions: FunctionComponent<ITicketOptions & HTMLAttributes<HTMLDivElement>> = ({ data, ...attributes }) => {
	const [modalOpen, setModalOpen] = useState(false)

	return (
		<div {...attributes}>
			<TicketModal
				open={modalOpen}
				onModalClose={() => {
					setModalOpen(false)
				}}
				data={data}
			/>
			<Button
				type={ButtonType.round}
				icon={IconType.kommentarHinzufuegen}
				iconColor={'var(--color-white)'}
				onClick={() => {
					setModalOpen(modalOpen ? false : true)
				}}
			/>
		</div>
	)
}

export default TicketOptions
