import addressIllustration from 'assets/images/illu-address.svg'
import bankAccountIllustration from 'assets/images/illu-bankaccount.svg'
import decadiaIdIllustration from 'assets/images/illu-decadia-id.svg'
import letterIllustration from 'assets/images/illu-letter.svg'
import illuLock from 'assets/images/illu-lock.svg'
import illuUnlock from 'assets/images/illu-unlock.svg'
import { DashboardTile } from 'components/DashboardTile'
import { IconType } from 'components/Icons'
import { ViewHeader } from 'components/ViewHeader'
import { TCallerActionsUserDetailsResponse, UserAccountStatus } from 'hooks/useCallerActionsApi'
import BackButton from 'partials/BackButton'
import { Fragment, FunctionComponent, ReactElement, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Icon from 'shared/components/Icon'
import dateFormat from 'shared/helper/dateFormat'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonClickHandler, useBackButtonPath } from 'state/useNavigationState'
import { useCallerActionsContext } from './CallerActions.context'

const dashboardTiles = {
	sendAddressForm: {
		name: 'sendAddressForm',
		imageSrc: addressIllustration,
	},
	sendBankAccountForm: {
		name: 'sendBankAccountForm',
		imageSrc: bankAccountIllustration,
	},
	sendInitialLetter: {
		name: 'sendInitialLetter',
		imageSrc: decadiaIdIllustration,
	},
	sendRegistrationLetter: {
		name: 'sendRegistrationLetter',
		imageSrc: letterIllustration,
	},
	lockAccess: {
		name: 'lockAccess',
		imageSrc: illuLock,
	},
	unlockAccess: {
		name: 'unlockAccess',
		imageSrc: illuUnlock,
	},
}

const getDashbordTilesForAccountStatus = (
	status: UserAccountStatus,
	registered?: boolean
): (keyof typeof dashboardTiles)[] => {
	const tiles: Record<UserAccountStatus, (keyof typeof dashboardTiles)[]> = {
		active: registered
			? ['sendInitialLetter', 'sendRegistrationLetter']
			: ['sendAddressForm', 'sendBankAccountForm', 'sendInitialLetter', 'sendRegistrationLetter'],
		deceased: [],
		expired: ['sendAddressForm', 'sendBankAccountForm', 'sendInitialLetter', 'sendRegistrationLetter'],
		left: ['sendAddressForm', 'sendBankAccountForm', 'sendInitialLetter', 'sendRegistrationLetter'],
		revoked: ['sendAddressForm', 'sendBankAccountForm', 'sendInitialLetter'],
	}
	return tiles[status]
}

const CallerActionsDashboard: FunctionComponent = () => {
	const { t } = useTranslation()
	const { getChildPath, navigateTo } = useRouteHelper(routesDictionary.callerActions)
	const { userDetails, resetData } = useCallerActionsContext()

	useBackButtonPath(getChildPath([routesDictionary.callerActions], 'identify'))
	useBackButtonClickHandler(
		resetData
			? {
					name: resetData,
			  }
			: undefined
	)

	const {
		current: {
			title,
			namePrefix,
			nameAffix,
			firstName,
			lastName,
			sendAddress,
			roles,
			email,
			phoneNr,
			decadiaId,
			foreignId,
			deathDate,
			leavingDate,
			userExpireDate,
			revocationDate,
			userAccountStatus,
		},
	} = useRef((userDetails as TCallerActionsUserDetailsResponse) || {})

	const isRegistered = roles?.includes('registered')

	const activeDashboardTiles = useMemo(() => {
		if (userAccountStatus === undefined) {
			return []
		}

		return (
			getDashbordTilesForAccountStatus(userAccountStatus, isRegistered)?.map((key) => {
				const { name, imageSrc } = dashboardTiles[key]

				return (
					<DashboardTile
						key={key}
						onClick={() => {
							navigateTo(getChildPath([routesDictionary.callerActions], name))
						}}
						image={{
							width: 200,
							src: imageSrc,
						}}
						headline={<Trans i18nKey={`view.callerActionsDashboard.actions.options.${name}`} />}
					/>
				)
			}) || []
		)
	}, [getChildPath, isRegistered, navigateTo, userAccountStatus])

	const activeAccountStatusTile = useMemo(() => {
		if (userAccountStatus === undefined) {
			return null
		}

		if (!['active', 'revoked'].includes(userAccountStatus)) {
			return null
		}

		const key = userAccountStatus === 'active' ? 'lockAccess' : 'unlockAccess'

		const { name, imageSrc } = dashboardTiles[key]

		return (
			<DashboardTile
				key={key}
				onClick={() => {
					navigateTo(getChildPath([routesDictionary.callerActions], name))
				}}
				image={{
					width: 200,
					src: imageSrc,
				}}
				headline={<Trans i18nKey={`view.callerActionsDashboard.actions.options.${name}`} />}
			/>
		)
	}, [getChildPath, navigateTo, userAccountStatus])

	if (userDetails === undefined) {
		return null
	}

	const userDetailFields: ReactElement[] = [
		...Object.entries({
			title,
			namePrefix,
			nameAffix,
			firstName,
			lastName,
			decadiaId,
			foreignId,
		})
			.filter(([_, value]) => value !== undefined)
			.reduce((currentFields: any, [key, value], index) => {
				currentFields.push(
					<div className={`caller-actions__grid-item--${index % 2 ? 'right' : 'left'}-column margin--bottom`}>
						<h5 className="bold-small-heading no-margin--top margin--bottom margin--small">
							{t(`view.callerActionsDashboard.data.fields.${key}`)}
						</h5>
						<span className="font-size-m">{value}</span>
					</div>
				)

				return currentFields
			}, []),
		<div className={`caller-actions__grid-item--left-column margin--bottom`}>
			<h5 className="bold-small-heading no-margin--top margin--bottom margin--small">
				{t(`view.callerActionsDashboard.data.fields.street`)}
			</h5>
			<span className="font-size-m">
				{sendAddress?.street} {sendAddress?.houseNumber}
			</span>
		</div>,

		...Object.entries({ zip: sendAddress?.zip, city: sendAddress?.city })
			.filter(([_, value]) => value !== undefined)
			.reduce((currentFields: any, [key, value], index) => {
				currentFields.push(
					<div className={`caller-actions__grid-item--${index % 2 ? 'right' : 'left'}-column margin--bottom`}>
						<h5 className="bold-small-heading no-margin--top margin--bottom margin--small">
							{t(`view.callerActionsDashboard.data.fields.${key}`)}
						</h5>
						<span className="font-size-m">{value}</span>
					</div>
				)

				return currentFields
			}, []),
		<div className={`caller-actions__grid-item--left-column margin--bottom`}>
			<h5 className="bold-small-heading no-margin--top margin--bottom margin--small">
				{t(`view.callerActionsDashboard.data.fields.email`)}
			</h5>
			<span className="font-size-m">{email}</span>
		</div>,
		<div className={`caller-actions__grid-item--right-column margin--bottom`}>
			<h5 className="bold-small-heading no-margin--top margin--bottom margin--small">
				{t(`view.callerActionsDashboard.data.fields.phoneNr`)}
			</h5>
			<span className="font-size-m">{phoneNr}</span>
		</div>,
		<div className={`caller-actions__grid-item--left-column margin--bottom`}>
			<h5 className="bold-small-heading no-margin--top margin--bottom margin--small">
				{t(`view.callerActionsDashboard.data.fields.registered`)}
			</h5>
			<span className="font-size-m">{isRegistered ? t(`generic.yes`) : t(`generic.no`)}</span>
		</div>,
		...Object.entries({
			deathDate,
			leavingDate,
			userExpireDate,
			revocationDate,
		})
			.filter(([_, value]) => value !== undefined && value !== null)
			.reduce((currentFields: any, [key, value], index) => {
				currentFields.push(
					<div className={`caller-actions__grid-item--${index % 2 ? 'right' : 'left'}-column margin--bottom`}>
						<h5 className="bold-small-heading no-margin--top margin--bottom margin--small">
							{t(`view.callerActionsDashboard.data.fields.${key}`)}
						</h5>
						<span className="font-size-m">{dateFormat(new Date(value!))}</span>
					</div>
				)

				return currentFields
			}, []),
		<div className={`caller-actions__grid-item--left-column margin--bottom`}>
			<h5 className="bold-small-heading no-margin--top margin--bottom margin--small">
				{t(`view.callerActionsDashboard.data.fields.userAccountStatus`)}
			</h5>
			<span className="font-size-m">
				{userAccountStatus
					? t(`view.callerActionsDashboard.data.values.userAccountStatus.${userAccountStatus}`)
					: '-'}
			</span>
		</div>,
	]

	return (
		<div className="caller-actions">
			<ViewHeader
				headline={t('view.callerActionsDashboard.headline')}
				subheadline={t('view.callerActionsDashboard.subtitle')}
				button={<BackButton />}
			/>

			<div className="caller-actions__grid">
				<p className="font-family-bold caller-actions__grid-item--full margin--top">
					<Trans i18nKey="view.callerActionsDashboard.copytext" />
				</p>
				<Icon
					className="caller-actions__aside-icon caller-actions__aside-icon--dashboard"
					type={IconType.profile}
				/>
				<h2 className="caller-actions__grid-item--center-column">
					<Trans i18nKey="view.callerActionsDashboard.data.headline" />
				</h2>
				{userDetailFields.map((child, index) => (
					<Fragment key={index}>{child}</Fragment>
				))}
				{activeDashboardTiles.length > 0 && (
					<>
						<p className="font-family-bold caller-actions__grid-item--full margin--vertical margin--extra-large margin--none-bottom">
							<Trans i18nKey="view.callerActionsDashboard.actions.headline" />
						</p>

						<div className="caller-actions__tile-grid caller-actions__grid-item--center-column margin--vertical">
							{activeDashboardTiles}
						</div>
					</>
				)}
				{activeAccountStatusTile && (
					<>
						<p className="caller-actions__grid-item--full font-family-bold  margin--vertical margin--extra-large margin--none-bottom">
							<Trans i18nKey="view.callerActionsDashboard.revokeHeadline" />
						</p>
						<div className="caller-actions__tile-grid caller-actions__grid-item--center-column margin--vertical  ">
							{activeAccountStatusTile}
						</div>
					</>
				)}
			</div>
		</div>
	)
}

export default CallerActionsDashboard
