import TicketPage from 'components/PageWithTable/TicketPage'
import useApi, { QueryKey } from 'hooks/useApi'
import { Trans } from 'react-i18next'

const Archive = () => {
	const api = useApi()

	return (
		<TicketPage
			className="no-padding--bottom"
			headline={<Trans i18nKey="view.archive.headline" />}
			subheadline={<Trans i18nKey="view.archive.subtitle" />}
			type="global-archive"
			queryKey={QueryKey.archivedTickets}
			apiRequest={api.getArchivedTickets}
		/>
	)
}

export default Archive
