import { Buffer } from 'buffer'
import { IconType } from 'components/Icons'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import isDev from 'shared/helper/isDev'
import { useClipboard } from 'use-clipboard-copy'

export const WebAuthMissing = () => {
	const { createWebAuthCredentials } = useAuth()
	const { t } = useTranslation()
	const [publicKey, setPublicKey] = useState<string>()
	const [copied, setCopied] = useState<boolean>(false)
	const clipboard = useClipboard({
		onSuccess() {
			setCopied(true)
		},
	})

	const inputElements: FormFields = {
		username: {
			value: isDev() ? 'tschweneker' : '',
			// value: isDev() ? 'fidotest' : '',
			required: true,
			type: TextInputType.text,
			label: t('generic.userId'),
			autoComplete: 'username',
			disableDelete: true,
			fieldType: FormFieldType.text,
		},
	}

	const handleCreatePublicKey = async (fields: { username: string }) => {
		try {
			const publicKeyCredentials = await createWebAuthCredentials(fields)

			setPublicKey(Buffer.from(publicKeyCredentials, 'utf8').toString('base64'))

			return true
		} catch (e) {
			console.log(e)

			return false
		}
	}

	return (
		<div className="auth">
			<h1 className="text-color-gold text-transform-uppercase">
				<Trans i18nKey="view.webAuthMissing.pageTitle" />
			</h1>

			<p>
				<Trans i18nKey="view.webAuthMissing.bodytext" />
			</p>

			{publicKey ? (
				<div className={`margin--vertical margin--center`}>
					<Button
						className={`auth__copy-string-button ${copied ? 'auth__copy-string-button--active' : ''}`}
						onClick={() => clipboard.copy(publicKey)}
						label={t(copied ? 'view.webAuthMissing.publicKeyCopied' : 'view.webAuthMissing.copyPublicKey')}
						type={[ButtonType.secondary, ButtonType.small]}
						icon={copied ? IconType.animatedCheckmark : undefined}
					/>
				</div>
			) : (
				<Form
					className="auth"
					fields={inputElements}
					onSubmit={handleCreatePublicKey}
					submitLabel={t('generic.letsGo')}
					alwaysAllowSubmit={true}
				/>
			)}
		</div>
	)
}
