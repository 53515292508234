import React from 'react'
import { Trans } from 'react-i18next'

const AdminHeader = () => {
	return (
		<div className="admin">
			<h1 className="admin__headline margin--bottom no-margin--bottom">
				<Trans i18nKey="view.admin.headline" />
			</h1>
			<div className="flex flex--align-items-center font-family-bold ">
				<div className="text-transform-uppercase text-color-gold margin--right">
					<Trans i18nKey="view.admin.subheadline" />
				</div>
				<hr className="hr hr--gold flex--grow" />
			</div>
		</div>
	)
}

export default AdminHeader
