const convertToDashCase = (v: string): string => {
	let ret = ''
	let prevLowercase = false

	for (const s of v) {
		const isUppercase = s.toUpperCase() === s
		if (isUppercase && prevLowercase) {
			ret += '-'
		}

		ret += s
		prevLowercase = !isUppercase
	}

	return ret.replace(/-+/g, '-').toLowerCase()
}

export default convertToDashCase
