import decadiaIdIllustration from 'assets/images/illu-lock.svg'
import { ViewHeader } from 'components/ViewHeader'
import useCallerActionsApi, { TCallerActionsPrintFormRequestParams, UserAccountStatus } from 'hooks/useCallerActionsApi'
import BackButton from 'partials/BackButton'
import { FunctionComponent, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormFields, FormFieldType, FormRefActions } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import useGlobalModalState from 'shared/hooks/useGlobalModalState'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath } from 'state/useNavigationState'
import { useCallerActionsContext } from './CallerActions.context'
const LockAccess: FunctionComponent = () => {
	const { t } = useTranslation()
	const [, modalActions] = useGlobalModalState()
	const { getChildPath, navigateTo } = useRouteHelper(routesDictionary.callerActions)
	useBackButtonPath(getChildPath([routesDictionary.callerActions], 'dashboard'))
	const { userDetails, userAuthData, setUserDetails } = useCallerActionsContext()
	const { lockAccess } = useCallerActionsApi()
	const formRef = useRef<FormRefActions>()

	const handleOnSuccess = () => {
		const updatedUserDetails = { ...userDetails }
		updatedUserDetails.userAccountStatus = UserAccountStatus.Revoked
		setUserDetails!(updatedUserDetails)

		modalActions.setClass('modal--success')
		modalActions.setContent(
			<>
				<img src={decadiaIdIllustration} alt="" />
				<h2>
					<Trans i18nKey={`view.lockAccess.success.headline`} />
				</h2>
				<p>
					<Trans i18nKey={`view.lockAccess.success.content`} />
				</p>
				<Button type={ButtonType.primary} label={t('generic.done')} onClick={() => modalActions.closeModal()} />
			</>
		)

		modalActions.setHideCloseButtons()
		modalActions.setOnCloseAction(() => navigateTo(getChildPath([routesDictionary.callerActions], 'dashboard')))
		modalActions.openModal()
	}

	const handleOnSubmit = async (submittedFields: Pick<TCallerActionsPrintFormRequestParams, 'formType'>) => {
		if (!userAuthData) {
			return false
		}

		return await lockAccess({
			...userAuthData,
		})
	}

	const inputFields: FormFields = {
		formType: {
			type: TextInputType.hidden,
			fieldType: FormFieldType.text,
			value: '',
		},
	}

	return (
		<div className="caller-actions">
			<ViewHeader
				headline={t('view.lockAccess.headline')}
				// subheadline={t('view.lockAccess.subtitle')}
				button={<BackButton />}
			/>

			<div className="flex margin--top">
				<div className="caller-actions__grid max-content-width--narrow">
					<div className="caller-actions__grid-item--full">
						<div className="sticky">
							<p className=" margin--top">
								<Trans
									i18nKey="view.lockAccess.copytext"
									values={{
										firstName: userDetails?.firstName,
										lastName: userDetails?.lastName,
									}}
								/>
							</p>

							<p className="font-family-bold caller-actions__grid-item--full text-color-teal margin--vertical">
								{userDetails?.firstName} {userDetails?.lastName}
								<br />
								{userDetails?.sendAddress?.street} {userDetails?.sendAddress?.houseNumber}
								<br />
								{userDetails?.sendAddress?.zip} {userDetails?.sendAddress?.city}
							</p>

							<Trans i18nKey="view.lockAccess.disclaimer" />

							<Form
								ref={formRef}
								fields={inputFields}
								onSubmit={handleOnSubmit}
								onSuccess={handleOnSuccess}
								alwaysAllowSubmit={true}
								errorMessages={[t('view.lockAccess.form.error')]}
								hideSubmit={true}
							/>
							<Button
								className="margin--top margin--large"
								label={t('view.lockAccess.form.submit')}
								type={ButtonType.highlight}
								onClick={() => formRef.current?.submitForm()}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LockAccess
