import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { currencyFormat } from 'shared/helper/numberFormats'

export interface CompensationSharesProps {
	ownContribution: number
	employerContribution: number
	sum: number
	className?: string
}

const CompensationShares: FunctionComponent<CompensationSharesProps> = props => {
	const { t } = useTranslation()

	const getClassNames = (): string => {
		const classes: string[] = ['compensation-shares']

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}

	const getShare = (amount: number, total: number) => {
		if (0 !== amount && 0 !== total) {
			const share = amount / total + 0.5
			const minimumShare = 0.7
			const maximumShare = 1.4

			if (maximumShare < share) {
				return maximumShare
			}

			if (minimumShare > share) {
				return minimumShare
			}

			return share
		}
	}

	return (
		<div className={getClassNames()}>
			<div
				className="circle circle--employee"
				style={
					{
						'--share': getShare(props.ownContribution, props.sum),
					} as React.CSSProperties
				}
			/>
			<div className="amount amount--employee">
				<b className="font-size-l font-family-alternative--medium">{currencyFormat(props.ownContribution)}</b>
				<span className="font-size-s">{t('component.compensationShares.ownContribution')}</span>
			</div>
			<div
				className={`circle circle--employer ${props.employerContribution < 0 ? 'circle--employer-dashed' : ''}`}
				style={
					{
						'--share': getShare(props.employerContribution, props.sum),
					} as React.CSSProperties
				}
			/>
			<div className="amount amount--employer">
				<b className="font-size-l font-family-alternative--medium">
					{currencyFormat(props.employerContribution, {
						addSignPrefix: true,
					})}
				</b>
				<span className="font-size-s">{t('component.compensationShares.employerContribution')}</span>
				{props.employerContribution < 0 && (
					<span className="font-size-s">
						{t('component.compensationShares.employerContributionIncludesCorrection')}
					</span>
				)}
			</div>
		</div>
	)
}

export default CompensationShares
