import { DisclaimerMessage } from 'components/Disclaimer'
import { IInboxListFooter, InboxListFooter } from 'components/Footer/InboxListFooter'
import TicketTable from 'components/Table/TicketTable'
import { ViewHeader } from 'components/ViewHeader'
import { IPageData, TicketRequestQueryParams } from 'hooks/useApi'
import { ChangeEvent, FunctionComponent, HTMLAttributes, useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import dateFormat from 'shared/helper/dateFormat'
import dateToUtcDate from 'shared/helper/dateToUtcDate'
import { isValidYear } from 'shared/helper/isValidYear'
import { getSessionStorage, removeSessionStorage, setSessionStorage } from 'shared/helper/sessionStorage'

export const TicketPage: FunctionComponent<IPageData & HTMLAttributes<HTMLDivElement>> = ({
	type,
	queryKey,
	apiRequest,
	headline,
	subheadline,
	className,
	requestData,
}) => {
	const [queryParameters, setQueryParameters] = useState<TicketRequestQueryParams>({
		lastToken: getSessionStorage(`inbox-${type}-lastToken`),
		firstToken: getSessionStorage(`inbox-${type}-firstToken`),
		dateFilter: getSessionStorage(`inbox-${type}-dateFilter`),
		documentTypeFilter: getSessionStorage(`inbox-${type}-documentTypeFilter`),
		problemStateFilter: getSessionStorage(`inbox-${type}-problemStateFilter`),
	})
	const { data, status } = useQuery<any>([queryKey, queryParameters, requestData], apiRequest, {
		cacheTime: 0,
		staleTime: 0,
		refetchInterval: 10000,
	})
	const tickets = useMemo(() => (data?.tickets ? data.tickets : data?.data), [data])

	useEffect(() => {
		Object.entries(queryParameters).map(([key, value]) => {
			if (undefined === value) {
				return removeSessionStorage(`inbox-${type}-${key}`)
			}

			return setSessionStorage(`inbox-${type}-${key}`, value)
		})
	}, [queryParameters, type])

	const displayedRange: IInboxListFooter['displayedRange'] = useMemo(() => {
		if (!tickets || 0 === tickets.length) {
			return undefined
		}

		return {
			from: dateFormat(new Date(tickets[0].receivedAt)),
			to: dateFormat(new Date(tickets[tickets.length - 1].receivedAt)),
		}
	}, [tickets])

	const handleOnDateChange: IInboxListFooter['onDateChange'] = (selectedDate) => {
		if (selectedDate === null) {
			setQueryParameters((currentQueryParameters) => ({
				...currentQueryParameters,
				dateFilter: undefined,
			}))
			return
		}
		const year = (selectedDate as Date).getFullYear()
		if (isValidYear(year)) {
			setQueryParameters((currentQueryParameters) => ({
				...currentQueryParameters,
				dateFilter: dateToUtcDate(selectedDate as Date),
			}))
		}
	}

	const handleOnTypeChange: IInboxListFooter['onTypeChange'] = (selectedType) => {
		setQueryParameters((currentQueryParameters) => ({
			...currentQueryParameters,
			documentTypeFilter: selectedType || undefined,
		}))
	}

	const handleOnProblemStateChange: IInboxListFooter['onProblemStateChange'] = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target

		setQueryParameters((currentQueryParameters) => ({
			...currentQueryParameters,
			problemStateFilter: value || undefined,
		}))
	}

	const handleOnNavigateBackwards = () => {
		setQueryParameters((currentQueryParameters) => ({
			...currentQueryParameters,
			lastToken: undefined,
			firstToken: data?.firstToken,
		}))
	}

	const handleOnNavigateForwards = () => {
		setQueryParameters((currentQueryParameters) => ({
			...currentQueryParameters,
			firstToken: undefined,
			lastToken: data?.lastToken,
		}))
	}

	const getClasses = () => {
		const classes = ['inbox']

		if (className) {
			classes.push(className)
		}

		return classes.join(' ')
	}

	return (
		<div className={getClasses()}>
			<ViewHeader headline={headline} subheadline={subheadline}>
				{'success' === status && !tickets?.length && (
					<DisclaimerMessage
						text={
							<Trans
								i18nKey={
									0 === Object.values(queryParameters).filter(Boolean).length
										? 'component.inbox.noDataDisclaimer'
										: 'component.inbox.noFilterMatches'
								}
							/>
						}
					/>
				)}
			</ViewHeader>

			{'success' === status && !tickets?.length ? <div /> : <TicketTable data={tickets} />}
			{data?.tickets ? (
				<InboxListFooter
					displayedRange={displayedRange}
					onDateChange={handleOnDateChange}
					onTypeChange={handleOnTypeChange}
					onProblemStateChange={'global-archive' !== type ? handleOnProblemStateChange : undefined}
					onNavigateBackwards={handleOnNavigateBackwards}
					onNavigateForwards={handleOnNavigateForwards}
					firstPage={data?.firstPage}
					lastPage={data?.lastPage}
					type={type}
				/>
			) : (
				''
			)}
		</div>
	)
}

export default TicketPage
