import { useEffect } from 'react'

export const useDetectClickOutside = (ref: any, onClick: () => any) => {
	function handleClickOutside(event: MouseEvent) {
		if (ref.current && !ref.current.contains(event.target)) {
			onClick()
		}
	}

	useEffect(() => {
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside)
		}
	})
}
