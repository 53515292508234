import useApi, { QueryKey } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import { Link, usePath } from 'raviger'
import React, { FunctionComponent, useCallback } from 'react'
import routesDictionary from 'routes'
import Icon from 'shared/components/Icon'
import { RouteItem, useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useQueries } from 'react-query'

interface SidebarNavigationProps {
	className?: string
}

const SidebarNavigation: FunctionComponent<SidebarNavigationProps> = (props) => {
	const { filteredRoutesDictionary } = useRouteHelper(routesDictionary.pensioner)
	const path = usePath()
	const api = useApi()
	const { userId } = useUserRecord()

	const [
		confirmedCompensation,
		blocksAccount,
		timeline,
		leistungsdaten,
		confirmedAuszahlungOption,
		relations,
		versorgungsausgleich,
		baseData,
	] = useQueries([
		{
			queryKey: [QueryKey.pensionerConfirmedCompensationsTable, { userId }],
			queryFn: api.getConfirmedCompensationsTable,
			enabled: !!userId,
		},
		{
			queryKey: [QueryKey.bausteinKonto, { userId }],
			queryFn: api.getBausteinKonto,
			enabled: !!userId,
		},
		{
			queryKey: [QueryKey.pensionerNotes, { pensionerId: userId }],
			queryFn: api.getPensionerNotes,
			enabled: !!userId,
		},
		{
			queryKey: [QueryKey.leistungsdaten, { userId }],
			queryFn: api.getLeistungsdaten,
			enabled: !!userId,
		},
		{
			queryKey: [QueryKey.pensionerConfirmedAuszahlungOption, { userId }],
			queryFn: api.getConfirmedAuszahlungOption,
			enabled: !!userId,
		},
		{
			queryKey: [QueryKey.relations, { userId }],
			queryFn: api.getRelations,
			enabled: !!userId,
		},
		{
			queryKey: [QueryKey.versorgungsausgleich, { userId }],
			queryFn: api.getVersorgungsausgleich,
			enabled: !!userId,
		},
		{
			queryKey: [QueryKey.pensionerBaseData, { userId }],
			queryFn: api.getPensionerBaseData,
			enabled: !!userId,
		},
	])

	const checkPensionerHasRelevantAustrittData = () => {
		const data = baseData.data
		if (
			data?.pensionPlan.leavingDate ||
			data?.pensionPlan.leavingReason ||
			data?.pensionPlan.participationState.anspruchUnverfallbar ||
			data?.pensionPlan.isInPartialRetirement
		) {
			return true
		}
		return false
	}
	const checkPathHasData: (path: string) => boolean = (path: string) => {
		switch (path) {
			case '/confirmed-compensations':
				return confirmedCompensation.data !== undefined
			case '/blocks-account':
				return blocksAccount.data !== undefined
			case '/timeline':
				return timeline.data !== undefined
			case '/leaving-info':
				return (
					[leistungsdaten, confirmedAuszahlungOption, relations, versorgungsausgleich].some(
						(query) => query.data !== undefined && query.data !== ''
					) || checkPensionerHasRelevantAustrittData()
				)
			default:
				return true
		}
	}
	const renderNavigationItem = (route: RouteItem, index: number) => {
		const navigationItemPath = path ? path.split('/').slice(0, 3).join('/') + route.path : route.path
		const activeIcon =
			null !== path &&
			(path === navigationItemPath || (navigationItemPath.length !== 0 && path.startsWith(navigationItemPath)))
		return (
			<div
				className={`sidebar-navigation__item sidebar-navigation__item--${index} ${
					activeIcon ? 'sidebar-navigation__item--active' : ''
				}`}
				key={`sidebar-navigation__item--${index}`}
			>
				<Link className="sidebar-navigation__link" href={navigationItemPath}>
					{route.icon && (
						<Icon
							type={route.icon}
							className={`sidebar-navigation__icon ${
								checkPathHasData(route.path) ? '' : 'sidebar-navigation__icon__grey'
							}`}
						/>
					)}
					<span
						className={`sidebar-navigation__link-text ${
							checkPathHasData(route.path) ? '' : 'sidebar-navigation__link-text__grey'
						}`}
					>
						{route.navigationName || route.path}
					</span>
				</Link>
			</div>
		)
	}
	const getClassNames = useCallback((): string => {
		const classes: string[] = ['sidebar-navigation']

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}, [props.className])

	return (
		<nav className={getClassNames()}>
			{filteredRoutesDictionary
				.filter((item: RouteItem) => {
					return item.showInNavigation
				})
				.map(renderNavigationItem)}
		</nav>
	)
}

export default SidebarNavigation
