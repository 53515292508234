import GridList from 'components/GridList'
import GridListCell from 'components/GridListCell'
import GridListRow from 'components/GridListRow'
import { IconType } from 'components/Icons'
import { Ticket } from 'hooks/useApi'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import Button, { ButtonType } from '../shared/components/Button'

interface ITable {
	columnData: string[]
	data: Ticket[] | undefined
	translationPath: string
	getValue: (accessor: string, entry: any) => any
}

export const Table: FunctionComponent<ITable> = ({ columnData, data, translationPath, getValue }) => {
	const { t } = useTranslation()
	const { navigateTo, getMainPath } = useRouteHelper()
	const renderTableHeader = () => {
		let headerCellClass = 'grid-list__cell--header'

		return (
			<GridListRow>
				{columnData.map((key, index) => (
					<GridListCell className={headerCellClass} key={`header-field-${index}`}>
						{t(`component.inboxList.tableHeader.${key}`)}
					</GridListCell>
				))}
				<GridListCell className={headerCellClass} />
			</GridListRow>
		)
	}

	const renderTableRows = () => {
		return (
			<>
				{data?.map((entry, index: number) => (
					<GridListRow key={`entry-${index}`}>
						{columnData.map((key, fieldIndex: number) => {
							const transformedValue = getValue(key, entry)
							return (
								<GridListCell key={`entry-field-${index}-${fieldIndex}`} className="user-select--all">
									{transformedValue}
								</GridListCell>
							)
						})}
						<GridListCell>
							<div className="inbox__actions">
								{/* <Delegate placement="left" /> */}
								<Button
									onClick={() => {
										sessionStorage.setItem('ticket', entry.id)
										navigateTo(`${getMainPath(routesDictionary.ticket)}/${entry.id}`, false)
									}}
									type={ButtonType.round}
									icon={IconType.open}
								/>
							</div>
						</GridListCell>
					</GridListRow>
				))}
			</>
		)
	}

	return (
		<GridList columnCount={columnData.length + 1} className="inbox-list">
			{renderTableHeader()}
			{renderTableRows()}
		</GridList>
	)
}

export default Table
