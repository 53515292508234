import { IconType } from 'components/Icons'
import { Trans } from 'react-i18next'
import { UserGroup } from 'shared/enums'
import { RouteItem } from 'shared/hooks/useRouteHelper'
import Admin from 'views/Admin/Admin'
import AdminDashboard from 'views/Admin/AdminDashboard'
import PdfFactory from 'views/Admin/PdfFactory'
import Archive from 'views/Archive'
import PensionerAustritte from 'views/Austrittsbearbeitung/PensionerAustritte'
import PensionerAustrittsbearbeitungen from 'views/Austrittsbearbeitung/PensionerAustrittsbearbeitungen'
import Austrittsbearbeitungen from 'views/Austrittsbearbeitungen'
import CallerActions from 'views/CallerActions/CallerActions'
import { ProvideCallerActions } from 'views/CallerActions/CallerActions.context'
import CallerActionsIdentify from 'views/CallerActions/Identify'
import CallerActionsSendAddressForm from 'views/CallerActions/SendAddressForm'
import CallerActionsSendBankAccountForm from 'views/CallerActions/SendBankAccountForm'
import CallerActionsSendRegistrationLetter from 'views/CallerActions/SendRegistrationLetter'
import GlobalInbox from 'views/GlobalInbox'
import Login from 'views/Login'
import Pensioner from 'views/Pensioner/Pensioner'
import { ConfirmedCompensations } from 'views/Pensioner/PensionerConfirmedCompensations'
import PensionerConversions from 'views/Pensioner/PensionerConversions/PensionerConversions'
import PensionerConversionsAllYears from 'views/Pensioner/PensionerConversions/PensionerConversionsAllYears'
import PensionerConversionsSingleMonth from 'views/Pensioner/PensionerConversions/PensionerConversionsSingleMonth'
import PensionerConversionsSingleYear from 'views/Pensioner/PensionerConversions/PensionerConversionsSingleYear'
import PensionerDocuments from 'views/Pensioner/PensionerDocuments'
import PensionerHistory from 'views/Pensioner/PensionerHistory'
import PensionerLeavingInformation from 'views/Pensioner/PensionerLeavingInformation'
import PensionAssets from 'views/Pensioner/PensionerPensionAssets'
import PensionerProfile from 'views/Pensioner/PensionerProfile'
import PensionerRiskOptions from 'views/Pensioner/PensionerRiskOptions'
import PensionerNotes from 'views/Pensioner/PensionerTimeline'
import PensionerTransactions from 'views/Pensioner/PensionerTransactions'
import PensionerSearch from 'views/PensionerSearch'
import Ticket from 'views/Ticket'
import { WebAuthMissing } from 'views/WebAuthMissing'
import { WebAuthSetup } from 'views/WebAuthSetup'
import CallerActionsDashboard from './views/CallerActions/Dashboard'
import CallerActionsSendInitialLetter from './views/CallerActions/SendInitialLetter'
import Dashboard from './views/Dashboard'
import Logout from './views/Logout'
import { Mfa } from './views/Mfa'
import { MfaSetup } from './views/MfaSetup'
import PensionerBlocksAccount from './views/Pensioner/PensionerBlocksAccount'
import PensionerPwReset from './views/Pensioner/PensionerPwReset'
import ResetPassword from './views/ResetPassword'
import { SetNewPassword } from './views/SetNewPassword'
import Settings from './views/Settings'
import CallerHistory from 'views/CallerHistory'
import UnlockAccess from 'views/CallerActions/UnlockAcces'
import LockAccess from 'views/CallerActions/LockAccess'
import UnresolvedSyncConflicts from 'views/Admin/UnresolvedSyncConflicts'
import SyncConflict from 'views/SyncConflicts/SyncConflict'
import SyncConflictsSolution from 'views/SyncConflicts/SyncConflictSolution'
import CallerInformation from 'views/CallerInformation'

const pensionerCondition = (): boolean => {
	const pensionerInStorage = null !== sessionStorage.getItem('pensioner')

	return pensionerInStorage
}

const pensionerParameters: RouteItem['parameterValues'] = () => ({
	id: sessionStorage.getItem('pensioner') ?? undefined,
})

const ticketCondition = (): boolean => {
	const ticketInStorage = null !== sessionStorage.getItem('ticket')

	return ticketInStorage
}

const ticketParameters: RouteItem['parameterValues'] = () => ({
	id: sessionStorage.getItem('ticket') ?? undefined,
})

const austrittsbearbeitungParameters: RouteItem['parameterValues'] = () => ({
	id: sessionStorage.getItem('austrittsbearbeitung') ?? undefined,
})

const routesDictionary: { [key: string]: RouteItem } = {
	dashboard: {
		path: '/',
		view: <Dashboard />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.dashboard.navigationName" />,
		hideIfLoggedOut: true,
		icon: IconType.dashboard,
	},
	globalInbox: {
		path: '/global-inbox',
		view: <GlobalInbox />,
		navigationName: <Trans i18nKey="view.globalInbox.navigationName" />,
		showInNavigation: true,
		icon: IconType.inbox,
		allowStatus: [
			UserGroup.ZF_Sachbearbeiter,
			UserGroup.ZF_Alle_Rechte,
			UserGroup.Westenergie_Sachbearbeiter_Standard,
		],
	},
	archive: {
		path: '/archive',
		view: <Archive />,
		navigationName: <Trans i18nKey="view.archive.navigationName" />,
		showInNavigation: true,
		icon: IconType.contract,
		allowStatus: [
			UserGroup.ZF_Sachbearbeiter,
			UserGroup.ZF_Alle_Rechte,
			UserGroup.Westenergie_Sachbearbeiter_Standard,
		],
	},
	ticket: {
		path: '/ticket',
		view: <Ticket id={''} />,
		parameters: ['id'],
		navigationName: <Trans i18nKey="view.ticket.navigationName" />,
		parameterValues: ticketParameters,
		showInNavigation: ticketCondition,
		icon: IconType.ticketApprove,
		allowStatus: [
			UserGroup.ZF_Sachbearbeiter,
			UserGroup.ZF_Alle_Rechte,
			UserGroup.Westenergie_Sachbearbeiter_Standard,
		],
	},

	callerActions: {
		path: '/caller-actions',
		view: (
			<ProvideCallerActions>
				<CallerActions />
			</ProvideCallerActions>
		),
		navigationName: <Trans i18nKey="view.callerActions.navigationName" />,
		showInNavigation: true,
		icon: IconType.phone,
		allowStatus: [
			UserGroup.ZF_Alle_Rechte,
			UserGroup.ZF_Sachbearbeiter_Telefonie_erweitert,
			UserGroup.Westenergie_FLS_Sachbearbeiter_erweitert,
		],

		children: {
			identify: {
				path: '/identify',
				view: <CallerActionsIdentify />,
				navigationName: <Trans i18nKey="view.callerActionsIdentify.navigationName" />,
			},
			dashboard: {
				path: '/dashboard',
				view: <CallerActionsDashboard />,
				navigationName: <Trans i18nKey="view.callerActionsIdentify.navigationName" />,
			},
			sendAddressForm: {
				path: '/send-address-form',
				view: <CallerActionsSendAddressForm />,
				navigationName: <Trans i18nKey="view.callerActionsSendAddressForm.navigationName" />,
			},
			sendBankAccountForm: {
				path: '/send-bank-account-form',
				view: <CallerActionsSendBankAccountForm />,
				navigationName: <Trans i18nKey="view.callerActionsSendBankAccountForm.navigationName" />,
			},
			sendInitialLetter: {
				path: '/send-initial-letter',
				view: <CallerActionsSendInitialLetter />,
				navigationName: <Trans i18nKey="view.callerActionsSendInitialLetter.navigationName" />,
			},
			sendRegistrationLetter: {
				path: '/send-registration-letter',
				view: <CallerActionsSendRegistrationLetter />,
				navigationName: <Trans i18nKey="view.callerActionsSendRegistrationLetter.navigationName" />,
			},
			unlockAccess: {
				path: '/unlockAccess',
				view: <UnlockAccess />,
				navigationName: <Trans i18nKey="view.unlockAccess.navigationName" />,
			},
			lockAccess: {
				path: '/lockAccess',
				view: <LockAccess />,
				navigationName: <Trans i18nKey="view.lockAccess.navigationName" />,
			},
		},
	},
	pensionerSearch: {
		path: '/search',
		view: <PensionerSearch />,
		navigationName: <Trans i18nKey="view.pensionerSearch.navigationName" />,
		showInNavigation: true,
		icon: IconType.search,
		allowStatus: [
			UserGroup.ZF_Sachbearbeiter,
			UserGroup.ZF_Alle_Rechte,
			UserGroup.Westenergie_Sachbearbeiter_Standard,
		],
	},
	admin: {
		path: '/admin',
		view: <Admin />,
		navigationName: <Trans i18nKey="view.admin.navigationName" />,
		showInNavigation: true,
		icon: IconType.admin,
		allowStatus: [UserGroup.ZF_Alle_Rechte, UserGroup.ZF_Sachbearbeiter_Betrieb],

		children: {
			dashboard: {
				path: '/dashboard',
				view: <AdminDashboard />,
				showInNavigation: true,
				navigationName: <Trans i18nKey="view.adminDashboard.navigationName" />,
				icon: IconType.dashboard,
			},
			pdfFactory: {
				path: '/pdfFactory',
				view: <PdfFactory />,
				showInNavigation: true,
				navigationName: <Trans i18nKey="view.pdfFactory.navigationName" />,
				icon: IconType.documents,
			},
			syncConflicts: {
				path: '/sync-conflicts',
				view: <UnresolvedSyncConflicts />,
				showInNavigation: true,
				navigationName: <Trans i18nKey="view.syncConflict.navigationsName" />,
				icon: IconType.documents,
			},
		},
	},
	syncConflict: {
		path: '/sync-conflict',
		view: <SyncConflict id={''} uuid={''} timeStamp={''} />,
		parameters: ['id', 'timeStamp', 'uuid'],
		icon: IconType.profile,
		children: {
			solution: {
				path: '/solution',
				view: <SyncConflictsSolution />,
				navigationName: <Trans i18nKey="view.syncConflict.navigationName" />,
				icon: IconType.search,
			},
		},
		hideIfLoggedIn: true,
	},
	pensioner: {
		path: '/pensioner',
		view: <Pensioner id={''} />,
		parameters: ['id'],
		parameterValues: pensionerParameters,
		icon: IconType.profile,
		showInNavigation: pensionerCondition,
		allowStatus: [
			UserGroup.ZF_Sachbearbeiter,
			UserGroup.ZF_Alle_Rechte,
			UserGroup.Westenergie_Sachbearbeiter_Standard,
		],
		children: {
			profile: {
				path: '/profile',
				view: <PensionerProfile />,
				showInNavigation: true,
				navigationName: <Trans i18nKey="view.pensionerProfile.navigationName" />,
				icon: IconType.baseData,
			},
			conversions: {
				path: '/conversions',
				view: <PensionerConversions />,
				showInNavigation: true,
				navigationName: <Trans i18nKey="view.pensionerConversions.navigationName" />,
				icon: IconType.conversions,
				children: {
					allYears: {
						path: '/overview',
						view: <PensionerConversionsAllYears />,
					},
					singleYear: {
						path: '',
						view: <PensionerConversionsSingleYear year={0} />,
						parameters: ['year'],
					},
					singleMonth: {
						path: '',
						view: <PensionerConversionsSingleMonth year={0} month={0} />,
						parameters: ['year', 'month'],
					},
				},
			},
			confirmedCompensations: {
				path: '/confirmed-compensations',
				view: <ConfirmedCompensations />,
				showInNavigation: true,
				navigationName: <Trans i18nKey="view.pensionerConfirmedCompensations.navigationName" />,
				icon: IconType.deferredCompensation,
			},
			pensionAssets: {
				path: '/pension-assets',
				view: <PensionAssets />,
				showInNavigation: true,
				navigationName: <Trans i18nKey="view.pensionerPensionAssets.navigationName" />,
				icon: IconType.pension,
			},
			blocksAccount: {
				path: '/blocks-account',
				view: <PensionerBlocksAccount />,
				showInNavigation: true,
				navigationName: <Trans i18nKey="view.accountBlocks.navigationName" />,
				icon: IconType.menuBlocksAccount,
			},
			pwreset: {
				path: '/pwreset',
				view: <PensionerPwReset />,
				showInNavigation: true,
				navigationName: <Trans i18nKey="view.pensionerPwReset.navigationName" />,
				icon: IconType.passwordReset,
			},
			history: {
				path: '/history',
				view: <PensionerHistory />,
				showInNavigation: true,
				navigationName: <Trans i18nKey="view.pensionerHistory.navigationName" />,
				icon: IconType.ticketHistory,
			},
			transactions: {
				path: '/transactions',
				view: <PensionerTransactions />,
				showInNavigation: true,
				navigationName: <Trans i18nKey="view.pensionerTransactions.navigationName" />,
				icon: IconType.wandlungsanträge,
			},
			risk: {
				path: '/riskoptions',
				view: <PensionerRiskOptions />,
				showInNavigation: true,
				navigationName: <Trans i18nKey="view.pensionerRiskOptions.navigationName" />,
				icon: IconType.risikoleistungen,
			},
			documents: {
				path: '/documents',
				view: <PensionerDocuments />,
				showInNavigation: true,
				navigationName: <Trans i18nKey="view.pensionerDocuments.navigationName" />,
				icon: IconType.documents,
			},
			timeline: {
				path: '/timeline',
				view: <PensionerNotes />,
				showInNavigation: true,
				navigationName: <Trans i18nKey="view.pensionerTimeline.navigationName" />,
				icon: IconType.notizen,
			},
			leavingInformation: {
				path: '/leaving-info',
				view: <PensionerLeavingInformation />,
				showInNavigation: true,
				navigationName: <Trans i18nKey="view.pensionerLeavingInformation.navigationName" />,
				icon: IconType.austrittsbearbeitung,
			},
		},
	},
	settings: {
		path: '/settings',
		view: <Settings />,
	},
	login: {
		path: '/login',
		view: <Login />,
		navigationName: <Trans i18nKey="view.login.navigationName" />,
		showInNavigation: true,
		hideIfLoggedIn: true,
		authRequired: false,
		icon: IconType.profile,
	},
	logout: {
		path: '/logout',
		view: <Logout />,
	},
	resetPassword: {
		path: '/reset-password',
		navigationName: <Trans i18nKey="view.resetPassword.navigationName" />,
		view: <ResetPassword />,
		showInNavigation: false,
		hideIfLoggedIn: true,
		authRequired: false,
	},
	setNewPassword: {
		path: '/set-new-password',
		navigationName: <Trans i18nKey="view.setNewPassword.navigationName" />,
		view: <SetNewPassword />,
		showInNavigation: false,
		hideIfLoggedIn: true,
		authRequired: false,
	},
	mfaSetup: {
		path: '/mfa-setup',
		navigationName: <Trans i18nKey="view.mfa.navigationName" />,
		view: <MfaSetup />,
		showInNavigation: false,
		hideIfLoggedIn: true,
		authRequired: false,
	},
	mfa: {
		path: '/mfa',
		navigationName: <Trans i18nKey="view.mfa.navigationName" />,
		view: <Mfa />,
		showInNavigation: false,
		hideIfLoggedIn: true,
		authRequired: false,
	},
	callerHistory: {
		path: '/caller-history',
		navigationName: <Trans i18nKey="view.callerHistory.navigationName" />,
		showInNavigation: true,
		icon: IconType.callHistory,
		view: <CallerHistory />,
		allowStatus: [
			UserGroup.ZF_Alle_Rechte,
			UserGroup.ZF_Sachbearbeiter_Telefonie,
			UserGroup.ZF_Sachbearbeiter_Telefonie_erweitert,
			UserGroup.Westenergie_FLS_Sachbearbeiter_erweitert,
			UserGroup.Westenergie_FLS_Sachbearbeiter,
		],
	},
	callerInformation: {
		path: '/caller-information',
		navigationName: <Trans i18nKey="view.callerInformation.navigationName" />,
		view: <CallerInformation />,
		allowStatus: [
			UserGroup.ZF_Alle_Rechte,
			UserGroup.ZF_Sachbearbeiter_Telefonie,
			UserGroup.ZF_Sachbearbeiter_Telefonie_erweitert,
			UserGroup.Westenergie_FLS_Sachbearbeiter_erweitert,
			UserGroup.Westenergie_FLS_Sachbearbeiter,
		],
	},
	webAuthSetup: {
		path: '/web-auth-setup',
		view: <WebAuthSetup />,
		showInNavigation: false,
		hideIfLoggedIn: true,
		authRequired: false,
	},
	webAuthSetupMissing: {
		path: '/web-auth-missing',
		view: <WebAuthMissing />,
		showInNavigation: false,
		hideIfLoggedIn: true,
		authRequired: false,
	},
	austrittsbearbeitungen: {
		path: '/leaving-infos',
		view: <Austrittsbearbeitungen />,
		// only when in UserGroup.Austrittsbearbeitung
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.austrittsbearbeitung.navigationName" />,
		icon: IconType.austrittsbearbeitung,
		allowStatus: [UserGroup.ZF_Sachbearbeiter, UserGroup.ZF_Alle_Rechte],
	},
	austritte: {
		path: '/austrittsbearbeitung',
		view: <PensionerAustritte id={''} />,
		parameters: ['id'],
		parameterValues: austrittsbearbeitungParameters,
		icon: IconType.contract,
		children: {
			austritt: {
				path: '/austritt',
				view: <PensionerAustrittsbearbeitungen />,
				navigationName: <Trans i18nKey="view.pensionerLeavingInformation.navigationName" />,
				icon: IconType.contract,
			},
		},
		hideIfLoggedIn: true,
		allowStatus: [UserGroup.ZF_Sachbearbeiter, UserGroup.ZF_Alle_Rechte],
	},
}

export default routesDictionary
