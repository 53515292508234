import { IconType } from 'components/Icons'
import { useAuth } from 'hooks/useAuth'
import { navigate, QueryParam, Routes, useNavigate, usePath } from 'raviger'
import React, { useMemo } from 'react'
import routesDictionary from 'routes'

export interface RouteItem {
	path: string
	view: React.ReactElement
	showInTopNavigation?: boolean
	showInNavigation?: boolean | (() => boolean)
	navigationName?: React.ReactElement | string
	authRequired?: boolean
	hideIfLoggedIn?: boolean
	hideIfLoggedOut?: boolean
	icon?: IconType
	iconNode?: React.ReactNode
	children?: { [key: string]: RouteItem }
	parameters?: string[]
	parameterValues?: () => { [key: string]: string | number | undefined }
	queryParams?: { [key: string]: string | boolean | number }
	exactPath?: string[]
	blockStatus?: string[]
	allowStatus?: string[]
	controlled?: boolean
	callBack?: () => void
}
export function useRouteHelper(
	parentRoute?: RouteItem,
	basePath?: { parents: RouteItem | RouteItem[]; child?: string; parameters?: { main?: string[]; child?: string[] } }
) {
	const { userData } = useAuth()
	const authenticated = useMemo(() => !!userData, [userData])
	const userGroups = useMemo(() => userData?.groups, [userData])
	const currentPath = usePath()
	const dictionary = useMemo(() => {
		return parentRoute?.children || routesDictionary
	}, [parentRoute])

	const isRouteBlocked = (route?: RouteItem): boolean => {
		if (undefined === route) {
			return false
		}

		if (!userGroups?.length) {
			return false
		}

		// return true if any of the userGroups is added to blockStatus of this route
		if (route.blockStatus) {
			return true === userGroups.some((status) => route.blockStatus!.includes(status))
		}

		return false
	}

	const isRouteAllowed = (route?: RouteItem): boolean => {
		if (undefined === route) {
			return true
		}

		if (!userGroups?.length) {
			return true
		}

		// return true if not any of the userGroups is contained in allowStatus of this route
		if (route.allowStatus) {
			return true === userGroups.some((status) => route.allowStatus!.includes(status))
		}

		return true
	}

	const isRouteAvailable = (route?: RouteItem): boolean => {
		if (undefined === route) {
			return true
		}

		if (!userGroups?.length) {
			return true
		}

		return !isRouteBlocked(route) && isRouteAllowed(route)
	}

	const getMainPath = (route: RouteItem): string | undefined => {
		if (isRouteBlocked(route)) {
			return undefined
		}

		return route?.path
	}

	const getChildPath = (
		parents?: RouteItem | (RouteItem | undefined)[],
		child?: string,
		parameters?: { main?: string[]; child?: string[] }
	): string | undefined => {
		if (undefined === parents || undefined === child) {
			return
		}

		const parentRoutes = [parents].flat().filter(Boolean)

		if (parentRoutes.some((item) => !item!.children)) {
			return undefined
		}

		if (parentRoutes.some(isRouteBlocked) || !parentRoutes.some(isRouteAllowed)) {
			return undefined
		}

		const parentPath = parentRoutes.reduce((path, item) => (item ? `${path}${getMainPath(item)}` : path), '')

		let childPath = getMainPath(parentRoutes[parentRoutes.length - 1]!.children![child])

		if (undefined === parentPath || undefined === childPath) {
			return
		}

		// remove leading "/" from childPath
		childPath = childPath.substring(1)

		return [parentPath, parameters?.main, childPath, parameters?.child].filter(Boolean).join('/')
	}

	const getParentPath = () => {
		const parentPath = window.location.pathname.split('/')
		parentPath.pop()

		return parentPath.join('/')
	}

	const navigateWithBasePath = useNavigate(
		basePath
			? getChildPath(basePath.parents, basePath.child, basePath.parameters)
			: parentRoute
			? getMainPath(parentRoute)
			: undefined
	)

	const redirectTo = (
		fromUrl?: string,
		toUrl?: string,
		query?: {
			[key: string]: string | number | boolean
		},
		replace: boolean = true
	) => {
		if (undefined === fromUrl || undefined === toUrl) {
			return
		}

		if (currentPath === fromUrl) {
			navigateTo(toUrl, replace, query)
		}
	}

	const navigateToRelative = (path?: string, replace?: boolean, query?: QueryParam | URLSearchParams) => {
		if (undefined === path) {
			return
		}

		return navigateWithBasePath(path, {
			replace,
			query,
		})
	}

	const navigateTo = (path?: string, replace?: boolean, query?: QueryParam | URLSearchParams) => {
		if (undefined === path) {
			return
		}

		let cleanedUpQuery

		if (query) {
			cleanedUpQuery = Object.keys(query).length > 0 ? query : undefined
		}

		return navigate(path, {
			replace,
			query: cleanedUpQuery,
		})
	}

	/**
	 * returns only the items from routesDictionary that do not need authenticaton
	 * when the user is not logged in,
	//  * or all items if the user is authenticated
	 *
	 * these items get also filtered by blockStatus and allowStatus,
	 * depending on the current user status
	 *
	 */
	const filteredRoutesDictionary: RouteItem[] = Object.values(dictionary)
		.filter((item: RouteItem) => {
			return (
				authenticated ||
				(!authenticated && (false === item.authRequired || false === parentRoute?.authRequired))
			)
		})
		.filter((item: RouteItem) => isRouteAvailable(item))

	/**
	 * returns the RouteObject for router
	 *
	 */
	const routes: Routes<string> = filteredRoutesDictionary.reduce((result: Routes<string>, item: RouteItem) => {
		let key: string

		if (item.parameters) {
			key = [item.path, ...item.parameters].join('/:')

			if (item.children) {
				key += '*'
			}
		} else if (item.children) {
			key = `${item.path}*`
		} else {
			key = item.path
		}

		result[key] = (params: any) => React.cloneElement(item.view, params)

		return result
	}, {})

	/**
	 
	 * - check if item should not be hidden if logged in
	 */

	const visibleNavigationRoutes = filteredRoutesDictionary.filter(
		(item) => (!userData && !item.hideIfLoggedOut) || (userData && !item.hideIfLoggedIn)
	)

	return {
		getParentPath,
		navigateTo,
		navigateToRelative,
		redirectTo,
		getMainPath,
		getChildPath,
		visibleNavigationRoutes,
		filteredRoutesDictionary,
		routes,
		isRouteBlocked,
		isRouteAllowed,
		isRouteAvailable,
		currentPath,
	}
}
