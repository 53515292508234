import classNames from 'classnames'
import { IconType } from 'components/Icons'
import { useSoftphone } from 'hooks/useSoftphone'
import { FC, HTMLAttributes, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'
import { ConnectSelect } from './ConnectSelect'

export const ConnectActions: FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
	const {
		showSoftphone,
		setShowSoftphone,
		availableRoutingProfiles,
		currentRoutingProfile,
		setCurrentRoutingProfile,
	} = useSoftphone()

	const { t } = useTranslation()

	const dropDownOptions = useMemo(() => {
		return availableRoutingProfiles?.map(({ name: label, routingProfileId: value }) => ({
			label,
			value,
		}))
	}, [availableRoutingProfiles])

	return (
		<div className={classNames(className, 'connect-actions')}>
			{undefined !== dropDownOptions && undefined !== currentRoutingProfile && (
				<ConnectSelect
					initialSelected={currentRoutingProfile}
					options={dropDownOptions}
					onChange={setCurrentRoutingProfile}
				/>
			)}

			<Button
				type={ButtonType.text}
				icon={IconType.phoneNumberpad}
				iconColor="var(--color-blue--medium)"
				label={
					showSoftphone
						? t('component.connect.actions.hideSoftphone')
						: t('component.connect.actions.showSoftphone')
				}
				onClick={setShowSoftphone.bind(null, !showSoftphone)}
			/>

			{/* <Button
				type={ButtonType.text}
				icon={IconType.callHistory}
				iconColor="var(--color-blue--medium)"
				className="text-align--left"
				label={t('component.connect.actions.showHistory')}
				onClick={navigateTo}
			/> */}
		</div>
	)
}
