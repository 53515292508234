import CloseUserRecord from 'components/CloseUserRecord'
import { IconType } from 'components/Icons'
import { useUserRecord } from 'hooks/useUserRecord'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Icon, { IconSize } from 'shared/components/Icon'
import NavigationToggle from './NavigationToggle'
import SidebarNavigation from './SidebarNavigation'

const Sidebar: React.FC = () => {
	const { t } = useTranslation()
	const user = useUserRecord()

	return (
		<aside className="sidebar">
			<CloseUserRecord className="sidebar__close-user-record">
				<Icon size={IconSize.medium} color="var(--color-blue--medium)" type={IconType.close} />
			</CloseUserRecord>
			<div className="sidebar__outer-wrapper">
				<div className="sidebar__inner-wrapper">
					<div className="sidebar__user flex flex--align-items-center padding">
						<Icon
							className="margin--horizontal margin--small no-margin--left"
							color="var(--color-white)"
							type={IconType.profile}
						/>
						<div className="flex flex--direction-column">
							<span className="text-color-gold text-transform-uppercase font-size-s">
								{t('component.sidebarNavigation.user')}
							</span>
							<span className="font-family-regular text-color-white margin--vertical margin--small no-margin--bottom">
								{user.navigationData?.firstName || '\u2007'}
								<br />
								{user.navigationData?.lastName || '\u2007'}
							</span>
						</div>
					</div>

					<hr className="hr sidebar__spacer margin--horizontal no-margin--top no-margin--bottom" />
					<NavigationToggle className="sidebar__navigation-toggle" />
					<SidebarNavigation className="sidebar__navigation" />
				</div>
			</div>
		</aside>
	)
}

export default Sidebar
