import { IconType } from 'components/Icons'
import React, { FunctionComponent } from 'react'
import Icon, { IconSize } from 'shared/components/Icon'
import useNavigationState from '../state/useNavigationState'

const NavigationToggle: FunctionComponent<{ className?: string }> = (props) => {
	const [navigationState, navigationActions] = useNavigationState()

	return (
		<div className={`navigation-toggle ${props.className}`}>
			<label className="navigation-toggle__label" htmlFor="navigation-toggle">
				<Icon
					className="navigation-toggle__icon"
					type={IconType.arrow}
					size={IconSize.small}
					rotate={navigationState.open ? 180 : 0}
				/>
			</label>

			<input
				className="navigation-toggle__checkbox"
				type="checkbox"
				id="navigation-toggle"
				onChange={() => navigationActions.setOpen(!navigationState.open)}
				checked={navigationState.open}
			/>
		</div>
	)
}

export default NavigationToggle
