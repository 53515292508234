import { IDocumentCluster, Ticket } from 'hooks/useApi'
import React, { FunctionComponent, HTMLAttributes, useState } from 'react'
import Button, { ButtonType } from 'shared/components/Button'
import { FormField } from 'shared/components/Form'
import CloseWithoutActionModal from './CloseWithoutActionModal'
import { IconType } from './Icons'

export interface ITicketCloseWithoutAction {
	data: Ticket
	documentTypes: IDocumentCluster[]
	disabled: boolean
	identNumberField: FormField
}

const TicketCloseWithoutAction: FunctionComponent<ITicketCloseWithoutAction & HTMLAttributes<HTMLDivElement>> = ({
	data,
	documentTypes,
	disabled,
	identNumberField,
	...attributes
}) => {
	const [modalOpen, setModalOpen] = useState(false)

	return (
		<div {...attributes}>
			<CloseWithoutActionModal
				open={modalOpen}
				onModalClose={() => {
					setModalOpen(false)
				}}
				data={data}
				documentTypes={documentTypes}
				identNumberField={identNumberField}
			/>
			<Button
				type={ButtonType.round}
				icon={IconType.ablageOhneVerarbeitung}
				disabled={disabled}
				onClick={() => {
					setModalOpen(modalOpen ? false : true)
				}}
			/>
		</div>
	)
}

export default TicketCloseWithoutAction
