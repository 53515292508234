import { useRoutes } from 'raviger'
import React, {useEffect} from 'react'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useNavigationBetriebState } from "../../state/useNavigationState";

const Admin: React.FC = () => {
	const { navigateTo, getChildPath, getMainPath, routes } = useRouteHelper(routesDictionary.admin)
	const routeResult = useRoutes(routes, { basePath: `${getMainPath(routesDictionary.admin)}` })
	const [navigationBetriebState, navigationBetriebActions] = useNavigationBetriebState()

	useEffect(() => {
		if (navigationBetriebState.visible) {
			navigationBetriebActions.setVisible(true)
		}
	}, [navigationBetriebState, navigationBetriebActions])

	const defaultRoute = () => {
		navigateTo(getChildPath(routesDictionary.admin, 'dashboard', {}), true)
	}

	const resultPath = <>{routeResult || defaultRoute()}</>

	return resultPath
}

export default Admin
