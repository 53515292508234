
import useApi from "hooks/useApi"
import { useUserRecord } from "hooks/useUserRecord"
import { FunctionComponent, useRef } from "react"
import { useTranslation } from "react-i18next"
import Button, { ButtonType } from "shared/components/Button"
import TextArea from "shared/components/TextArea"

export interface ITimelineForm {
    refreshHook(): void
}

export const TimelineForm: FunctionComponent<ITimelineForm> = ({ refreshHook }) => {
    const api = useApi();
    const { userId } = useUserRecord()
    const contentInputField = useRef<HTMLTextAreaElement>(null)
    const { t } = useTranslation()

    const handleOnSubmit = () => {
        let { value } = contentInputField.current!
        if (value && userId) {
            api.postNote(userId, value).then(refreshHook)
        }
    }

    return (
        <div className="timeline-form">
            <TextArea
                ref={contentInputField}
                name={"new-note-content-input"}
                disabled={false}
                className={"timeline-form__input"}
                usedInForm={true}
            />
            <Button type={ButtonType.primary} onClick={handleOnSubmit} className="timeline-form__submit">
                {t("view.pensionerTimeline.form.submit")}
            </Button>
        </div>

    )
}
