import classNames from 'classnames'
import { ComponentProps, FC } from 'react'

export type TStatusType = 'error' | 'warning' | 'info' | 'success' | 'default'
interface IDashboardTile {
	status?: {
		type?: TStatusType
		content: string | JSX.Element
	}
	image: {
		src: string
		width?: number
		height?: number
	}
	headline: string | JSX.Element
	disabled?: boolean
}

export const DashboardTile: FC<IDashboardTile & ComponentProps<'div'>> = ({ children, ...props }) => {
	const { className, status, image, headline, disabled, onClick } = props

	return (
		<div
			className={classNames([
				'dashboard-tile',
				className,
				'flex--direction-column',
				'flex--grow',
				{
					'dashboard-tile--disabled': disabled,
				},
			])}
			onClick={disabled ? undefined : onClick}
		>
			<div className={classNames(['flex flex--direction-column flex--align-items-center'])}>
				{status && (
					<p
						className={classNames([
							'flex--align-self-end',
							'text-align--right',
							'no-margin--top',
							'margin--bottom margin--small',
							{
								'opacity--0': undefined === status?.type,
								'opacity--3': 'info' === status?.type,
								'text-color-red bold': 'error' === status?.type,
								'text-color-orange': 'warning' === status?.type,
								'text-color-teal': 'success' === status?.type,
							},
						])}
					>
						{status?.content}
					</p>
				)}
				<div>
					<img {...image} alt="" />
				</div>
				<p className="font-size-s margin--top margin--small text-align--center">
					<b>{headline}</b>
				</p>
			</div>
		</div>
	)
}
