import classNames from 'classnames'
import { IconType } from 'components/Icons'
import { useSoftphone } from 'hooks/useSoftphone'
import { FC, HTMLAttributes } from 'react'
import { Trans } from 'react-i18next'
import Icon, { IconSize } from 'shared/components/Icon'

export const ConnectAfterCallWork: FC<HTMLAttributes<HTMLButtonElement>> = ({ className }) => {
	const { agentState } = useSoftphone()

	return (
		<div className={classNames(className, 'connect-after-call-work', `connect-after-call-work--${agentState}`)}>
			<p className="font-size-l">
				<Trans
					i18nKey={
						'after-call-work-manual' === agentState
							? 'component.connect.afterCallWork.currentStatus'
							: 'component.connect.afterCallWork.question'
					}
				/>
			</p>
			<Icon type={IconType.arrow} rotate={90} size={IconSize.medium} color="var(--color-blue--medium)" />{' '}
		</div>
	)
}
