import { IconType } from 'components/Icons'
import { FunctionComponent, HTMLAttributes, useState } from 'react'
import Button, { ButtonType } from 'shared/components/Button'
import NoteDeleteModal from './NoteDeleteModal'

export interface INoteDelete {
	noteId: string,
	createdAt: string,
	refreshHook(): void,
}

const TicketCloseWithoutAction: FunctionComponent<INoteDelete & HTMLAttributes<HTMLDivElement>> = ({ noteId, createdAt, refreshHook, ...attributes }) => {
	const [modalOpen, setModalOpen] = useState(false)

	return (
		<div {...attributes}>
			<NoteDeleteModal
				open={modalOpen}
				onModalClose={() => {
					setModalOpen(false)
				}}
				noteId={noteId}
				createdAt={createdAt}
				refreshHook={refreshHook}
			/>
			<Button
				type={ButtonType.round}
				icon={IconType.delete}
				iconColor={'var(--color-white)'}
				onClick={() => {
					setModalOpen(modalOpen ? false : true)
				}}
				className="timeline-note__button"
			/>
		</div>
	)
}

export default TicketCloseWithoutAction
