import { t } from 'i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'

type DokumentNaviButtonProps = {
	userId: string
}

export const DokumentNaviButton: React.FC<DokumentNaviButtonProps> = (props) => {
	const { getChildPath, navigateTo } = useRouteHelper()
	const handleDokumentLinkButton = () => {
		navigateTo(getChildPath(routesDictionary.pensioner, 'documents', { main: [props.userId] }), true)
	}
	const handleTicketHistorieLinkButton = () => {
		navigateTo(getChildPath(routesDictionary.pensioner, 'history', { main: [props.userId] }), true)
	}
	return (
		<div className="austritt-dokumente__button-header">
			<Button
				className="austritt-dokumente__button-header__button"
				label={t('view.dokumente.header.dokumentLinkButton')}
				type={[ButtonType.small, ButtonType.tertiary]}
				onClick={handleDokumentLinkButton}
			></Button>
			<Button
				className="austritt-dokumente__button-header__button"
				label={t('view.dokumente.header.ticketHistorieLinkButton')}
				type={[ButtonType.small, ButtonType.tertiary]}
				onClick={handleTicketHistorieLinkButton}
			></Button>
		</div>
	)
}
