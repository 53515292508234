import { IconType } from 'components/Icons'
import { AnalyzedMagicField, MagicLetterDocumentMetaData } from 'components/MagicLetter/magic-letter-metadata.model'
import MagicLetterForm from 'components/MagicLetter/MagicLetterForm'
import { convertStatusClosedWithoutAction } from 'helper/convertStatusClosedWithoutAction'
import useApi, { Editor, QueryKey, TicketModelId, TicketResult } from 'hooks/useApi'
import { useAuth } from 'hooks/useAuth'
import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { uid } from 'react-uid'
import routesDictionary from 'routes'
import Icon from 'shared/components/Icon'
import PdfViewer from 'shared/components/PdfViewer'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import dateFormat from '../shared/helper/dateFormat'
import { openLinkInNewWindow } from 'shared/helper/openLinkInNewWindow'

const Ticket: React.FC<{ id: string }> = ({ id }) => {
	const { navigateTo, getMainPath } = useRouteHelper()
	const api = useApi()
	const { userData } = useAuth()
	const [ticketLocked, setTicketLocked] = useState<boolean>(false)
	const [formLocked, setFormLocked] = useState<boolean>(false)
	const [currentEditor, setCurrentEditor] = useState<string>()
	const { t } = useTranslation()

	if (id === undefined) {
		navigateTo(getMainPath(routesDictionary.globalInbox))
	}

	const [newTicketModel, setNewTicketModel] = useState<
		MagicLetterDocumentMetaData<AnalyzedMagicField<string>> | undefined
	>(undefined)

	function getFieldValues(queryData: TicketResult): Map<string, any> {
		let allFields = new Map<string, any>()
		queryData.ticket.documentContent.content.pages.forEach((page) => {
			page.fields.forEach((field) => {
				allFields.set(field.name, field)
			})
		})
		return allFields
	}

	function fillFieldValues(
		ticketModel: MagicLetterDocumentMetaData<AnalyzedMagicField<string>>,
		values: Map<string, any>
	): MagicLetterDocumentMetaData<AnalyzedMagicField<string>> {
		ticketModel.pages.forEach((page) => {
			page.fields.forEach((field) => {
				if (values.has(field.name)) {
					const oldField = values.get(field.name)
					if (oldField.fieldType === field.fieldType) {
						field.value = oldField.value
					}
				}
			})
		})
		return ticketModel
	}

	const mergeData = (
		ticketModel: MagicLetterDocumentMetaData<AnalyzedMagicField<string>> | undefined,
		queryData: TicketResult | undefined
	): TicketResult | undefined => {
		if (ticketModel && queryData) {
			let fieldValues = getFieldValues(queryData)
			let filledTicketModel = fillFieldValues(ticketModel, fieldValues)
			return {
				...queryData,
				ticket: {
					...queryData.ticket,
					documentContent: {
						...queryData.ticket.documentContent,
						content: {
							...filledTicketModel,
							identNumber: queryData.ticket.documentContent.content.identNumber,
						},
					},
				},
			}
		}
		return queryData
	}

	const { data: ticketData } = useQuery([QueryKey.ticket, { ticketId: id }], api.getTicket, {
		staleTime: 0,
		cacheTime: 0,
	})

	const { data: ticketModelIds } = useQuery([QueryKey.ticketModelIds], api.getTicketModelIds)

	const data = useMemo(() => {
		return mergeData(newTicketModel, ticketData)
		// eslint-disable-next-line
	}, [newTicketModel, ticketData])

	useEffect(() => {
		if (undefined === data || true === ticketLocked) {
			return
		}

		const ticketClosed = 'closed' === data?.ticket.statusInfo.status

		const lockedBy = data?.ticket.lockedBy
		const editedBy = data?.ticket.statusInfo.editedBy

		if (ticketClosed) {
			return setFormLocked(true)
		}

		if (editedBy && userData?.sub === editedBy?.userId) {
			return setFormLocked(true)
		}

		if (lockedBy && userData?.sub !== lockedBy?.userId) {
			setCurrentEditor(getEditorName(lockedBy))

			return setFormLocked(true)
		}

		if (!lockedBy || userData?.sub === lockedBy.userId) {
			return setTicketLocked(true)
		}

		// eslint-disable-next-line
	}, [data])

	const handleDocumentTypeChange = (ticketModelId: TicketModelId) => {
		api.getTicketModel({ queryKey: [{}, ticketModelId] }).then((ticketModel) => {
			setNewTicketModel(ticketModel)
		})
	}

	const buildKey = () => {
		return `${data?.ticket.documentContent.content.type} ${data?.ticket.documentContent.content.version}`
	}

	/**
	 *  Request a new ButtonUrl since the old one is only valid for a short time
	 * @returns {void}
	 */
	const handleDownloadButtonClick = async () => {
		const response = await api.getTicket({ queryKey: [{}, { ticketId: id }] })

		if (!response?.documentUrl) {
			return
		}

		openLinkInNewWindow(response.documentUrl, true)
	}

	return (
		<div className="ticket">
			{data && ticketModelIds && (
				<>
					<PdfViewer
						className="ticket__document"
						document={data.documentUrl}
						onDownloadButtonClick={handleDownloadButtonClick}
					/>
					<div className="ticket__input">
						<div className="flex flex--align-items-center flex--justify-content-between margin--bottom">
							<h1 className="ticket__headline no-margin--bottom no-margin--top">
								<Trans i18nKey={'view.ticket.magicLetter'} />
							</h1>
							{/* <Badge>
								<Trans i18nKey={`generic.detectionType.${data.detectionType.toLowerCase()}`} />
							</Badge> */}
						</div>
						<div className="flex flex--align-items-center">
							<div className="text-color-gold margin--right margin--small">
								<Trans i18nKey={`component.ticket.fieldLabels.receivedAt`} />
								{': '}
								{dateFormat(new Date(data.ticket.receivedAt))}
							</div>
							<hr className="hr hr--gold flex--grow" />
							<div className="text-color-gold margin--left margin--small">
								{currentEditor ? (
									<Trans
										i18nKey="component.ticket.ticketStates.lockedBy"
										values={{ name: currentEditor }}
									/>
								) : (
									<span className="text-transform-uppercase">
										<Trans
											i18nKey={`component.ticket.ticketStates.${convertStatusClosedWithoutAction(
												data.ticket
											)}`}
										/>
									</span>
								)}
							</div>
						</div>

						{(data.ticket.statusInfo.editedBy || data.ticket.statusInfo.approvedBy) && (
							<div className="margin--bottom">
								{data.ticket.statusInfo.editedBy && (
									<div className="font-size-s">
										<Trans
											i18nKey={`component.ticket.ticketStateInfo.editedBy`}
											values={{ name: getEditorName(data.ticket.statusInfo.editedBy) }}
										/>
									</div>
								)}
								{data.ticket.statusInfo.approvedBy && (
									<div className="font-size-s">
										<Trans
											i18nKey={`component.ticket.ticketStateInfo.approvedBy`}
											values={{ name: getEditorName(data.ticket.statusInfo.approvedBy) }}
										/>
									</div>
								)}
							</div>
						)}
						{(data.ticket.predefinedRemarks?.length ||
							data.ticket.customRemarks?.length ||
							data.ticket.problemState) && (
							<div className="flex flex--align-items-start margin--vertical">
								<>
									<Icon
										className="margin margin--small no-margin--left"
										type={IconType.info}
										color={data.ticket.problemState ? 'var(--color-red)' : undefined}
										rotate={data.ticket.problemState ? 180 : undefined}
									/>

									<div>
										{data.ticket.predefinedRemarks?.map((remark, index) => (
											<p
												className="margin--vertical margin--small no-margin--bottom"
												key={uid(remark, index)}
											>
												{t(`component.ticketModal.remarkLabel.${remark}`)}
											</p>
										))}
										{data.ticket.customRemarks?.map((remark, index) => (
											<p
												className="margin--vertical margin--small no-margin--bottom"
												key={uid(remark, index)}
											>
												{remark}
											</p>
										))}
									</div>
								</>
							</div>
						)}

						<div className="ticket__form">
							<MagicLetterForm
								key={buildKey()}
								data={data.ticket}
								ticketModelIds={ticketModelIds}
								formLocked={formLocked}
								ticketLocked={ticketLocked}
								onDocumentTypeChange={handleDocumentTypeChange}
							/>
						</div>
					</div>
				</>
			)}
		</div>
	)
}

function getEditorName(editor: Editor): string {
	return `${editor.salutation.firstName} ${editor.salutation.lastName}`
}

export default Ticket
