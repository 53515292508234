import { useEffect, useState } from 'react'
import { UserGroup } from 'shared/enums'
import { useAuth } from './useAuth'

export const ADMIN_USER_GROUPS = [UserGroup.ZF_Alle_Rechte, UserGroup.ZF_Sachbearbeiter_Betrieb]

export const SOFTPHONE_USER_GROUPS = [
	UserGroup.ZF_Alle_Rechte,
	UserGroup.ZF_Sachbearbeiter_Telefonie,
	UserGroup.ZF_Sachbearbeiter_Telefonie_erweitert,
	UserGroup.Westenergie_FLS_Sachbearbeiter,
	UserGroup.Westenergie_FLS_Sachbearbeiter_erweitert,
]

export const RESET_PASSWORD_GROUPS = [UserGroup.ZF_Alle_Rechte, UserGroup.ZF_Sachbearbeiter]

export const MAGIC_LETTER_DELETE_GROUPS = [UserGroup.ZF_Alle_Rechte, UserGroup.ZF_Tickets_Loeschen]

export const useUserPermission = () => {
	const [canUseSoftphone, setCanUseSoftphone] = useState(false)
	const [canUseAdminDashboard, setCanUseAdminDashboard] = useState(false)
	const [canResetPassword, setCanResetPassword] = useState(false)
	const [canDeleteMagicLetterTickets, setCanDeleteMagicLetterTickets] = useState(false)

	const { userData } = useAuth()

	useEffect(() => {
		const isUserInGroup: (groups: UserGroup[]) => boolean = (groups) =>
			groups.some((group) => userData?.groups.includes(group))

		setCanUseSoftphone(() => isUserInGroup(SOFTPHONE_USER_GROUPS))
		setCanUseAdminDashboard(isUserInGroup(ADMIN_USER_GROUPS))
		setCanResetPassword(isUserInGroup(RESET_PASSWORD_GROUPS))
		setCanDeleteMagicLetterTickets(isUserInGroup(MAGIC_LETTER_DELETE_GROUPS))
	}, [userData])

	return {
		canUseSoftphone,
		canUseAdminDashboard,
		canResetPassword,
		canDeleteMagicLetterTickets,
	}
}
