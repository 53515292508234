import { useUserRecord } from 'hooks/useUserRecord'
import { useRoutes } from 'raviger'
import React, { useEffect } from 'react'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import useNavigationState from 'state/useNavigationState'

const PensionerAustritte: React.FC<{ id: string }> = ({ id }) => {
	const { navigateTo, getChildPath, getMainPath, routes } = useRouteHelper(routesDictionary.austritte)
	const { userId, closeRecord, openRecord } = useUserRecord()
	const routeResult = useRoutes(routes, { basePath: `${getMainPath(routesDictionary.austritte)}/${id}` })
	const [navigationState, navigationActions] = useNavigationState()

	useEffect(() => {
		if (false === navigationState.visible && undefined !== userId) {
			navigationActions.setVisible(true)
		}
	}, [userId, navigationActions, navigationState.visible])

	const defaultRoute = () => {
		navigateTo(getChildPath(routesDictionary.austritte, 'austritt', { main: [id] }), true)
	}

	const renderRoute = () => {
		if (userId !== id) {
			closeRecord().then(async () => {
				sessionStorage.setItem('austrittsbearbeitung', id)

				await openRecord(id)

				return routeResult || defaultRoute()
			})

			return
		}

		return routeResult || defaultRoute()
	}

	return <>{renderRoute()}</>
}

export default PensionerAustritte
