import { IconType } from 'components/Icons'
import { DefaultTFuncReturn } from 'i18next'
import React, { FunctionComponent } from 'react'
import Icon from './Icon'

export interface ErrorProps {
	visible: boolean
	messages: (string | React.ReactElement | DefaultTFuncReturn)[]
	className?: string
	errorIcon?: IconType
}

const ErrorMessage: FunctionComponent<ErrorProps> = (props) => {
	const getClassNames = (): string => {
		const classes: string[] = ['error']

		if (props.className) {
			classes.push(props.className)
		}

		if (props.visible) {
			classes.push('error--visible')
		}

		return classes.join(' ')
	}

	const renderErrorIcon = () => {
		if (undefined === props.errorIcon) {
			return
		}

		return <Icon className="error__icon" type={props.errorIcon} color="var(--error-message-icon-color)" />
	}

	return (
		<div className={getClassNames()}>
			{renderErrorIcon()}
			<div className="error__messages">
				{props.messages.map((message, index: number) => (
					<div className="error__message" key={`message__${index}`}>
						{message}
					</div>
				))}
			</div>
		</div>
	)
}

export default ErrorMessage
