import { IconType } from 'components/Icons'
import { useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'
import { FormFields } from 'shared/components/Form'

export enum ConfirmButtonState {
	Checked = 'checked',
	Unchecked = 'unchecked',
	NotSelectable = 'not-selectable',
	Invalid = 'invalid',
}

const ConfirmIcon = {
	[ConfirmButtonState.Checked]: IconType.checkmarkFilled,
	[ConfirmButtonState.Unchecked]: IconType.checkmark,
	[ConfirmButtonState.Invalid]: IconType.invalid,
	[ConfirmButtonState.NotSelectable]: IconType.notSelectable,
}

export const ConfirmButtonStateChange = {
	[ConfirmButtonState.Checked]: ConfirmButtonState.Unchecked,
	[ConfirmButtonState.Unchecked]: ConfirmButtonState.Checked,
	[ConfirmButtonState.Invalid]: ConfirmButtonState.Invalid,
	[ConfirmButtonState.NotSelectable]: ConfirmButtonState.NotSelectable,
}

export type ConfirmButtonStates = ConfirmButtonState

export const getConfirmButtonsStates = (
	inputFieldsToCheck: FormFields,
	confirmButtonStates: { [key: string]: ConfirmButtonStates } = {}
) => {
	return Object.keys(inputFieldsToCheck).reduce(
		(currentConfirmButtonStates: { [key: string]: ConfirmButtonStates }, key: string) => {
			// do not add disabled fields
			if (true === inputFieldsToCheck[key]?.disabled) {
				if ('Name' === inputFieldsToCheck[key]?.label) {
					currentConfirmButtonStates[key] = ConfirmButtonState.NotSelectable
				}
				return currentConfirmButtonStates
			}

			const inputFieldIsValid = checkInputFieldIsValid(inputFieldsToCheck, key)

			// add new fields
			if (undefined === confirmButtonStates[key]) {
				currentConfirmButtonStates[key] = inputFieldIsValid
					? ConfirmButtonState.Unchecked
					: ConfirmButtonState.Invalid

				return currentConfirmButtonStates
			}

			// check previously invalid input fields
			if (ConfirmButtonState.Invalid === confirmButtonStates[key]) {
				currentConfirmButtonStates[key] = inputFieldIsValid
					? ConfirmButtonState.Unchecked
					: ConfirmButtonState.Invalid

				return currentConfirmButtonStates
			}

			// set valid input fields
			if (!inputFieldIsValid) {
				currentConfirmButtonStates[key] = ConfirmButtonState.Invalid

				return currentConfirmButtonStates
			}

			// add previous confirm button state if no condition is met
			currentConfirmButtonStates[key] = confirmButtonStates[key]

			return currentConfirmButtonStates
		},
		{}
	)
}

const checkInputFieldIsValid = (inputFieldsToCheck: FormFields, key: string): boolean | undefined => {
	if (undefined !== inputFieldsToCheck[key].error) return false

	if (!inputFieldsToCheck[key].required) {
		return true
	} else {
		// accept signature as valid if true
		if (inputFieldsToCheck[key].name === 'signature') {
			return inputFieldsToCheck[key].value === 'true'
		}
		return inputFieldsToCheck[key].required && !!inputFieldsToCheck[key].value
	}
}

export interface ConfirmButtonProps {
	formFields: FormFields
	onAllConfirmed?: (confirmedState: boolean) => void
}

const MagicLetterConfirmButtons = (props: ConfirmButtonProps) => {
	const { onAllConfirmed, formFields } = props

	const [confirmedFields, setConfirmedFields] = useState<{ [key: string]: ConfirmButtonStates }>(
		getConfirmButtonsStates(formFields)
	)

	const [confirmAllIcon, setConfirmAllIcon] = useState<IconType>()

	const allValid = useMemo(() => {
		return Object.keys(confirmedFields).every((key) => confirmedFields[key] !== ConfirmButtonState.Invalid)
	}, [confirmedFields])

	const allConfirmed = useMemo(() => {
		return Object.keys(confirmedFields).every(
			(key) =>
				confirmedFields[key] === ConfirmButtonState.Checked ||
				confirmedFields[key] === ConfirmButtonState.NotSelectable
		)
	}, [confirmedFields])

	useEffect(() => {
		if (onAllConfirmed) {
			onAllConfirmed(allConfirmed)
		}

		if (allConfirmed) {
			return setConfirmAllIcon(ConfirmIcon[ConfirmButtonState.Checked])
		}

		if (allValid) {
			return setConfirmAllIcon(ConfirmIcon[ConfirmButtonState.Unchecked])
		}

		return setConfirmAllIcon(ConfirmIcon[ConfirmButtonState.NotSelectable])
	}, [allValid, allConfirmed, onAllConfirmed])

	useEffect(() => {
		setConfirmedFields({ ...getConfirmButtonsStates(formFields, confirmedFields) })
		// eslint-disable-next-line
	}, [formFields])

	const onHandleConfirm = (key: string) => {
		setConfirmedFields((fields) => ({
			...fields,
			[key]: ConfirmButtonStateChange[confirmedFields[key]],
		}))
	}

	const onHandleConfirmAll = () => {
		if (false === allValid) {
			return
		}

		setConfirmedFields(
			Object.keys(confirmedFields).reduce(
				(currentConfirmButtonStates: { [key: string]: ConfirmButtonStates }, key: string) => {
					currentConfirmButtonStates[key] =
						ConfirmButtonState.NotSelectable === confirmedFields[key]
							? ConfirmButtonState.NotSelectable
							: ConfirmButtonState.Checked

					return currentConfirmButtonStates
				},
				{}
			)
		)
	}

	return (
		<div className="confirmation">
			{Object.keys(confirmedFields).map((key, index) => {
				return (
					<Button
						className={`confirmation__confirm-single ${`confirmation__confirm-single--${confirmedFields[key]}`} `}
						key={`confirmbutton-${index}`}
						type={ButtonType.round}
						icon={ConfirmIcon[confirmedFields[key]]}
						onClick={() => onHandleConfirm(key)}
					/>
				)
			})}
			<div
				className={`confirmation__confirm-all ${allConfirmed ? 'confirmation__confirm-all--active' : ''} ${
					!allValid && !allConfirmed ? 'confirmation__confirm-all--invalid' : ''
				}`}
			>
				{allConfirmed && <Trans i18nKey="view.ticket.allConfirmed" />}
				{allValid && !allConfirmed && <Trans i18nKey="view.ticket.confirmAll" />}
				{!allValid && !allConfirmed && <Trans i18nKey="view.ticket.invalidFields" />}
				<Button type={ButtonType.round} icon={confirmAllIcon} onClick={onHandleConfirmAll} />
			</div>
		</div>
	)
}

export default MagicLetterConfirmButtons
