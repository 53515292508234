import { IconType } from 'components/Icons'
import { navigate } from 'raviger'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'
import { IconSize } from 'shared/components/Icon'
import useNavigationState, { NavigationState } from 'state/useNavigationState'

const BackButton: React.FC = () => {
	const { t } = useTranslation()

	const [backButtonPath] = useNavigationState<NavigationState['backButtonPath']>(
		(state: NavigationState) => state.backButtonPath
	)
	const [backButtonClickHandler] = useNavigationState<NavigationState['backButtonClickHandler']>(
		(state: NavigationState) => state.backButtonClickHandler
	)

	const getClassNames = (): string => {
		const classPrefix: string = 'back-button--'
		const classes: string[] = ['back-button']

		if (undefined === backButtonPath && undefined === backButtonClickHandler) {
			classes.push(classPrefix + 'hidden')
		}

		return classes.join(' ')
	}

	const onClickHandler = () => {
		if (undefined !== backButtonClickHandler) {
			return backButtonClickHandler.name(backButtonClickHandler.parameters)
		}

		if (undefined !== backButtonPath) {
			return navigate(backButtonPath)
		}
	}

	return (
		<Button
			className={getClassNames()}
			type={[ButtonType.primary, ButtonType.small]}
			onClick={onClickHandler}
			label={t('component.backButton.label')}
			icon={IconType.arrow}
			iconColor="var(--color-blue)"
			iconRotate={180}
			iconSize={IconSize.extraSmall}
		/>
	)
}

export default BackButton
