import { Link, usePath } from 'raviger'
import React, { FunctionComponent, useCallback } from 'react'
import routesDictionary from 'routes'
import Icon from 'shared/components/Icon'
import { RouteItem, useRouteHelper } from 'shared/hooks/useRouteHelper'

interface SidebarBetriebNavigationProps {
	className?: string
}

const SidebarBetriebNavigation: FunctionComponent<SidebarBetriebNavigationProps> = (props) => {
	const { filteredRoutesDictionary } = useRouteHelper(routesDictionary.admin)
	const path = usePath()

	const renderNavigationItem = (route: RouteItem, index: number) => {
		const navigationItemPath = path ? '/' + path.split('/')[1] + route.path : route.path

		const activeIcon =
			null !== path &&
			(path === navigationItemPath || (navigationItemPath.length !== 0 && path.startsWith(navigationItemPath)))

		return (
			<div
				className={`sidebar-betrieb-navigation__item sidebar-betrieb-navigation__item--${index} ${
					activeIcon ? 'sidebar-betrieb-navigation__item--active' : ''
				}`}
				key={`sidebar-betrieb-navigation__item--${index}`}
			>
				<Link className="sidebar-betrieb-navigation__link" href={navigationItemPath}>
					{route.icon && <Icon type={route.icon} className="sidebar-betrieb-navigation__icon" />}
					<span className="sidebar-betrieb-navigation__link-text">{route.navigationName || route.path}</span>
				</Link>
			</div>
		)
	}

	const getClassNames = useCallback((): string => {
		const classes: string[] = ['sidebar-betrieb-navigation']

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}, [props.className])

	return (
		<nav className={getClassNames()}>
			{filteredRoutesDictionary
				.filter((item: RouteItem) => {
					/**
					 * - show only the items where showInNavigation is set to true
					 */
					return item.showInNavigation
				})
				.map(renderNavigationItem)}
		</nav>
	)
}

export default SidebarBetriebNavigation
