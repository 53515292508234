import { DisclaimerMessage } from 'components/Disclaimer'
import { IconType } from 'components/Icons'
import { ViewHeader } from 'components/ViewHeader'
import useApi, { MasterDataFlags, PUTAdressdatenRequestBody, QueryKey } from 'hooks/useApi'
import { TFunction, t } from 'i18next'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import Form, { FormFieldType, FormFields } from 'shared/components/Form'
import Icon, { IconSize } from 'shared/components/Icon'
import { TextInputType } from 'shared/components/TextInput'
import dateFormat from 'shared/helper/dateFormat'
import { currencyFormat, numberFormat } from 'shared/helper/numberFormats'
import { IPensionerBaseData, useUserRecord } from '../../hooks/useUserRecord'
import { versorgungsausgleichFlag } from 'shared/components/flags'
import Button, { ButtonType } from 'shared/components/Button'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import { AddressdatenInputFeld } from './SubComponent/AddressChangeInputFeld'
import { wait } from 'shared/helper/wait'

type UserConsent =
	| IPensionerBaseData['pensionPlan']['termsOfService']
	| IPensionerBaseData['pensionPlan']['usageOfContactData']

const processValue =
	(t: TFunction<'translation'>, deceased?: boolean) =>
	(
		value: string,
		{ fieldKey }: { fieldKey: keyof IPensionerBaseData['pensionPlan'] | keyof IPensionerBaseData['contactDetails'] }
	) => {
		switch (fieldKey) {
			case 'gender':
			case 'incomeThresholdRegion':
			case 'isInPartialRetirement':
			case 'isSeverelyDisabled':
			case 'hasTemporaryContract':
			case 'mitarbeiterKreis':
			case 'mitarbeiterGruppe':
			case 'mandatBeendet':
				return t(`view.pensionerProfile.fields.${fieldKey}.${value}`)

			case 'participationState':
				const { state, wayOfParticipation, vo19Key, paidOffOption } =
					value as unknown as IPensionerBaseData['pensionPlan']['participationState']
				const stateValue =
					state === 'komplettAusgezahlt'
						? t(`view.pensionerProfile.fields.participationState.completePaidOffOption.${paidOffOption}`)
						: deceased && vo19Key === 'X0'
						? t(`view.pensionerProfile.fields.participationState.state.deceasedAndX0`)
						: t(`view.pensionerProfile.fields.participationState.state.${state}`)
				const stateDetail = !!paidOffOption
					? t(`view.pensionerProfile.fields.participationState.paidOffOption.${paidOffOption}`)
					: t(`view.pensionerProfile.fields.participationState.wayOfParticipation.${wayOfParticipation}`)
				return `${vo19Key ?? '-'} | ${stateValue} | ${stateDetail}`

			case 'convertibleSalary':
				return currencyFormat(value)
			case 'actualSalary':
				return currencyFormat(value)

			case 'partTimeDegree':
				return numberFormat(value)

			case 'termsOfService':
			case 'usageOfContactData':
				return formatUserConsent(value as unknown as Exclude<UserConsent, ''>)

			case 'fiscalJoiningDate':
			case 'dateOfAgreement':
			case 'firstPossibleParticipation':
			case 'dateOfBirth':
			case 'dateOfDeath':
			case 'enterpriseJoiningDate':
			case 'lastImportDate':
			case 'leavingDate':
			case 'partialRetirementStartDate':
			case 'partialRetirementEndDate':
			case 'transferLetterSent':
			case 'transferConfirmationReceived':
			case 'transferConfirmationSigned':
			case 'transferCutoffDate':
			case 'initialLetterSent':
			case 'leistungsbeginn':
				return dateFormat(new Date(value))
			default:
				return value
		}
	}

const formatUserConsent = (consent: Exclude<UserConsent, ''>): string => {
	return consent.hasAccepted
		? consent.lastUpdateTime
		: t('view.pensionerProfile.fields.userConsentDeclined', { date: consent.lastUpdateTime })
}

const PensionerProfileHint: React.FC<{ data: IPensionerBaseData | undefined }> = ({ data }) => {
	const { t } = useTranslation()
	const isDeceased = data?.pensionPlan.deceased
	const isArchived = data?.archivedStatus.isArchived
	const isNotTransferred = !data?.transferStatus.isTransferred
	const hasDcModell = data?.hatDcModell

	const profileHints = [
		isDeceased ? t('view.pensionerProfile.profileHint.isDead') : '',
		isArchived ? t('view.pensionerProfile.profileHint.isArchived') : '',
		isNotTransferred ? t('view.pensionerProfile.profileHint.isNotTransferred') : '',
		hasDcModell ? t('view.pensionerProfile.profileHint.hasDcModell') : '',
	].filter((item) => item !== '')

	return (
		<>
			{[isDeceased, isArchived, isNotTransferred, hasDcModell].includes(true) && (
				<div className={'profile-hint'}>
					<Icon
						type={IconType.info}
						className="margin--horizontal margin--default no-margin--left profile-hint-icon"
					/>
					{profileHints.map((item, index) => {
						return index < profileHints.length - 1 ? [item, ' | '] : item
					})}
				</div>
			)}
		</>
	)
}

const PensionerFlag: React.FC<{ data: MasterDataFlags; flagKey: string }> = ({ data, flagKey }) => {
	const flagIsAvailable = data.some((flag) => flag.flagKey === flagKey)
	return (
		<>
			{flagIsAvailable && (
				<div className={'profile-hint'}>
					<Icon
						type={IconType.info}
						className="margin--horizontal margin--default no-margin--left profile-hint-icon"
					/>
					<Trans i18nKey="component.pensionerFlags.versorgungsausgleich" />
				</div>
			)}
		</>
	)
}

const PensionerMandatBeendet: React.FC<{ mandatBeendet: boolean }> = ({ mandatBeendet }) => {
	return (
		<>
			{mandatBeendet && (
				<div className={'profile-hint'}>
					<Icon
						type={IconType.info}
						className="margin--horizontal margin--default no-margin--left profile-hint-icon"
					/>
					<Trans i18nKey="view.pensionerProfile.fields.mandatBeendet.label" />
				</div>
			)}
		</>
	)
}

const PensionerContactDataProfile: React.FC<{ data: IPensionerBaseData | undefined }> = ({ data }) => {
	const { t } = useTranslation()
	const [contactDataFields, setContactDataFields] = useState<FormFields>()
	const [updatedValues, setUpdatedValues] = useState<Record<string, string>>()
	useEffect(() => {
		if (data !== undefined) {
			const updatedContactDetails = Object.keys(data.contactDetails).reduce(
				(fields: FormFields, fieldKey: string) => {
					const value = data.contactDetails[fieldKey as keyof IPensionerBaseData['contactDetails']]
					const valueIsNotSet = undefined === value || '' === value

					fields[fieldKey] = {
						value: valueIsNotSet ? '–' : String(value),
						type: TextInputType.text,
						label: t(
							`view.pensionerProfile.fields.${fieldKey}.label`,
							t(`view.pensionerProfile.fields.${fieldKey}`)
						),
						valueFunction: valueIsNotSet
							? undefined
							: {
									name: processValue(t),
									parameters: { fieldKey },
							  },
						fieldType: FormFieldType.text,
						disabled: true,
					}

					return fields
				},
				{}
			)
			const { street, zip, careOf, country, city, phoneNumber, emailAddress } = data.contactDetails
			const newUpdatedValues = { street, zip, careOf, country, city, phoneNumber, emailAddress }
			setUpdatedValues(newUpdatedValues)
			setContactDataFields(updatedContactDetails)
		}
	}, [t, data])

	return (
		<div className="margin--horizontal margin--extra-large no-margin--right">
			{(contactDataFields && (
				<Form
					className={'contact'}
					disabled={true}
					fields={contactDataFields}
					onSubmit={() => {}}
					updatedValues={updatedValues}
				/>
			)) || <Trans i18nKey="generic.noDataAvailable" />}
		</div>
	)
}

const nonVisibleFields: (keyof IPensionerBaseData['pensionPlan'])[] = ['deceased']

const PensionerBaseDataProfile: React.FC<{ data: IPensionerBaseData | undefined }> = ({ data }) => {
	const { t } = useTranslation()
	const [baseDataFields, setBaseDataFields] = useState<FormFields>()
	const deceased = data?.pensionPlan.deceased

	useEffect(() => {
		if (data !== undefined) {
			const updatedBaseDataFields = Object.keys(data.pensionPlan).reduce(
				(fields: FormFields, fieldKey: string) => {
					if (nonVisibleFields.indexOf(fieldKey as keyof IPensionerBaseData['pensionPlan']) > -1) {
						return fields
					}
					const value = data.pensionPlan[fieldKey as keyof IPensionerBaseData['pensionPlan']]
					const valueIsNotSet = undefined === value || '' === value

					fields[fieldKey] = {
						value: valueIsNotSet ? '–' : typeof value !== 'object' ? String(value) : (value as any),
						type: TextInputType.text,
						label: t(
							`view.pensionerProfile.fields.${fieldKey}.label`,
							t(`view.pensionerProfile.fields.${fieldKey}`)
						),
						valueFunction: valueIsNotSet
							? undefined
							: {
									name: processValue(t, deceased),
									parameters: { fieldKey },
							  },
						fieldType: FormFieldType.text,
						disabled: true,
					}
					return fields
				},
				{}
			)

			setBaseDataFields(updatedBaseDataFields)
		}
	}, [t, data, deceased])

	return (
		<div className="margin--horizontal margin--extra-large no-margin--right">
			{(baseDataFields && (
				<Form className={'base-data'} disabled={true} fields={baseDataFields} onSubmit={() => {}} />
			)) || <Trans i18nKey="generic.noDataAvailable" />}
		</div>
	)
}

const PensionerProfile: React.FC = () => {
	const { userId } = useUserRecord()
	const api = useApi()
	const modal = useRef<ModalRefActions>()
	const [adressdaten, setAdressdaten] = useState<PUTAdressdatenRequestBody>({})
	const {
		data,
		status,
		refetch: refetchBaseData,
	} = useQuery([QueryKey.pensionerBaseData, { userId }], api.getPensionerBaseData, {
		enabled: !!userId,
	})

	const { data: flags } = useQuery([QueryKey.masterDataFlag, { userId }], api.getMasterDataFlagsByIdentNumber, {
		enabled: !!userId,
	})

	const mandatBeendet = data?.pensionPlan.mandatBeendet

	const handleOpenChangeAdresseModal = () => {
		modal.current?.openModal()
	}

	const handleChangeAdressdaten = (adressdaten: PUTAdressdatenRequestBody) => {
		setAdressdaten(adressdaten)
	}

	const handleSubmit = async () => {
		if (!userId) {
			return
		}
		await api.putAdressdaten(userId, adressdaten)
		await wait(1500)
		await refetchBaseData()
	}

	return (
		<div className={`pensioner-profile visually-hidden visually-hidden--${String(status !== 'success')}`}>
			<ViewHeader
				headline={<Trans i18nKey="view.pensionerProfile.headline" />}
				subheadline={<Trans i18nKey="view.pensionerProfile.subheadline" />}
			>
				{'success' === status && !data && (
					<DisclaimerMessage text={<Trans i18nKey="view.pensionerProfile.noDataDisclaimer" />} />
				)}
			</ViewHeader>

			{data && status === 'success' && (
				<>
					<PensionerProfileHint data={data} />
					{flags && <PensionerFlag data={flags} flagKey={versorgungsausgleichFlag}></PensionerFlag>}
					{mandatBeendet && <PensionerMandatBeendet mandatBeendet={mandatBeendet}></PensionerMandatBeendet>}
					<div className="flex flex--align-items-center flex--justify-content-between margin--top">
						<h2 className="flex flex--align-items-center margin--top">
							<Icon
								type={IconType.profile}
								className="margin--horizontal margin--default no-margin--left"
							/>
							<Trans i18nKey="view.pensionerProfile.section.contactData" />
						</h2>
						{data.pensionPlan.participationState.ausgeschieden && (
							<>
								<Button
									type={ButtonType.primary}
									iconSize={IconSize.medium}
									className="button--height--small font-size-default"
									onClick={handleOpenChangeAdresseModal}
								>
									<Trans i18nKey="view.pensionerProfile.section.changeAddress" />
								</Button>
							</>
						)}
					</div>

					<PensionerContactDataProfile data={data} />
					<h2 className="flex flex--align-items-center margin--vertical margin--large">
						<Icon type={IconType.baseData} className="margin--horizontal margin--default no-margin--left" />
						<Trans i18nKey="view.pensionerProfile.section.baseData" />
					</h2>

					<PensionerBaseDataProfile data={data} />
				</>
			)}
			<Modal
				ref={modal}
				forceAction={false}
				header={<Trans i18nKey="view.pensionerProfile.section.changeAddress" />}
				buttonLabel={t(`generic.confirm`)}
				onModalClose={() => {}}
				onButtonClick={handleSubmit}
				className="adressdaten-input modal--width-big"
			>
				{data && <AddressdatenInputFeld data={data} onChange={handleChangeAdressdaten}></AddressdatenInputFeld>}
			</Modal>
		</div>
	)
}

export default PensionerProfile
