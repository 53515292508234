import { useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'
import Form, { FormFieldType, FormFields } from 'shared/components/Form'
import useApi, { AuszahlungsjahrGETResponse } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import getLocalizedYears from 'shared/helper/getLocalizedYears'
import { toast } from 'react-toastify'
import { TMonthSchema, TMonth } from 'shared/helper/types/month'
import zfToast, { ZFToastProps } from 'shared/components/ZFToast'
type AuszahlungsjahrProps = {
    auszahlungsjahr: AuszahlungsjahrGETResponse | undefined
    refetchAuszahlungsjahr: any
}

export const Auszahlungsjahr: React.FC<AuszahlungsjahrProps> = (props) => {
    const { userId } = useUserRecord()
    const { auszahlungsjahr, refetchAuszahlungsjahr } = props
    const [updatedValues, setUpdatedValues] = useState<Record<string, string>>()
    const api = useApi()
    const modal = useRef<ModalRefActions>()
    const { t } = useTranslation()

    const getFormFields: () => FormFields = () => {
        const { years } = getSettings()
        return {
            Year: {
                fieldType: FormFieldType.select,
                label: <Trans i18nKey={'view.zusageschreiben.content.entgeltnachweis.jahr'} />,
                alternativeStyle: true,
                value: auszahlungsjahr ? String(auszahlungsjahr) : undefined,
                className: 'auszahlungsjahr__content__date-form__fields__right',
                options: years.map((year) => ({
                    value: String(year),
                    label: year === 0 ? '---' : String(year),
                })),
            },
        }
    }
    const [updatedFormFields, setUpdatedFormFields] = useState<FormFields>(getFormFields)

    const [buttonType, setButtonType] = useState<'update' | 'delete'>('update')

    const getUpdatedValues = () => {
        const year = auszahlungsjahr?.year ? String(auszahlungsjahr.year) : '0'
        const updatedValues: Record<string, string> = {}
        updatedValues['Year'] = year
        return updatedValues
    }
    useEffect(
        () => {
            setUpdatedValues(getUpdatedValues())
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [auszahlungsjahr]
    )

    const AuszahlungsjahrForm: FormFields = useMemo(
        () => {
            return getFormFields()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [auszahlungsjahr]
    )

    const handleOpenModal = (buttonType: 'update' | 'delete') => {
        setButtonType(buttonType)
        modal.current?.openModal()
    }
    const getText = (position: string) => {
        return t(`component.confirmedAuszahlungOption.modell.auszahlungsjahr.${position}.${buttonType}`)
    }
    const handleSubmit = async () => {
        console.log(':::SUBMIT')
        if (!userId) {
            return
        }
        if (buttonType === 'delete') {
            const responseDelete = await api.deleteMasterDataAuszahlungsjahr({ userId })
            if (!!responseDelete.success) {
                const toastProps: ZFToastProps = {
                    title: 'Auszahlungsjahr',
                    content: t('component.confirmedAuszahlungOption.modell.auszahlungsjahr.toast.deleteSucceed'),
                    type: 'success',
                }
                zfToast(toastProps)
            }
            refetchAuszahlungsjahr()
            return
        }
        const year = Number(updatedFormFields?.Year.value)
        if (year === 0) {
            refetchAuszahlungsjahr()
            setUpdatedValues(getUpdatedValues())
            return
        }
        const responsePost = await api.postMasterDataAuszahlungsjahr({ userId, body: { year } })
        if (!!responsePost.success) {
            const toastProps: ZFToastProps = {
                title: 'Auszahlungsjahr',
                content: t('component.confirmedAuszahlungOption.modell.auszahlungsjahr.toast.postSucceed'),
                type: 'success',
            }
            zfToast(toastProps)
        } else {
            refetchAuszahlungsjahr()
            setUpdatedValues(getUpdatedValues())
        }
    }

    return (
        <div className="auszahlungsjahr">
            <div className="auszahlungsjahr__headline">
                <h2 className="auszahlungsjahr__headline__subheadline">
                    <Trans i18nKey="component.confirmedAuszahlungOption.modell.auszahlungsjahr.title" />
                </h2>
            </div>
            <div className="auszahlungsjahr__content">
                <Form
                    fields={AuszahlungsjahrForm}
                    hideSubmit={true}
                    className="auszahlungsjahr__content__date-form"
                    onFormChange={setUpdatedFormFields}
                    onSubmit={() => { }}
                    updatedValues={updatedValues}
                ></Form>
                <div className="auszahlungsjahr__content__buttongroup">
                    <Button
                        type={[ButtonType.highlight]}
                        className="auszahlungsjahr__content__buttongroup__button"
                        onClick={() => handleOpenModal('update')}
                    >
                        <Trans i18nKey="component.confirmedAuszahlungOption.modell.auszahlungsjahr.button.update" />
                    </Button>
                    <Button
                        type={[ButtonType.highlight]}
                        className="auszahlungsjahr__content__buttongroup__button"
                        onClick={() => handleOpenModal('delete')}
                    >
                        <Trans i18nKey="component.confirmedAuszahlungOption.modell.auszahlungsjahr.button.delete" />
                    </Button>
                </div>
            </div>
            <Modal
                ref={modal}
                forceAction={false}
                header={getText('modalTitle')}
                buttonLabel={t(`generic.save`)}
                onModalClose={() => { }}
                onButtonClick={handleSubmit}
                className="modal--start-austrittstask"
            >
                <div>{getText('modalContent')}</div>
            </Modal>
        </div>
    )
}

const getSettings = () => {
    const thisYear = new Date().getFullYear()
    const years = [0].concat(getLocalizedYears(thisYear + 1, thisYear))

    return { years }
}
