import TicketPage from 'components/PageWithTable/TicketPage'
import useApi, { QueryKey } from 'hooks/useApi'
import { Trans } from 'react-i18next'

const GlobalInbox = () => {
	const api = useApi()

	return (
		<TicketPage
			className="no-padding--bottom"
			headline={<Trans i18nKey="view.globalInbox.headline" />}
			subheadline={<Trans i18nKey="view.globalInbox.subtitle" />}
			type="global-inbox"
			queryKey={QueryKey.tickets}
			apiRequest={api.getTickets}
		/>
	)
}

export default GlobalInbox
