import GridList from 'components/GridList'
import GridListCell from 'components/GridListCell'
import GridListRow from 'components/GridListRow'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import dateFormat from 'shared/helper/dateFormat'
import { components } from 'types/api-interface'

type RelationRowType = 'source' | 'target'

const rowTypeSource: RelationRowType = 'source';

const rowTypeTarget: RelationRowType = 'target';

const getRelationType = (relation: components['schemas']['Relation']) => {
	return relation.type
}

const getEmployeeAttribute = (
	relation: components['schemas']['Relation'],
	attributeName: keyof components['schemas']['RelationPerson'],
	type: RelationRowType
) => {
	switch (relation.type) {
		case 'versorgungsausgleich':
			return type === 'source'
				? relation.ausgleichspflichtigePerson[attributeName]
				: type === 'target'
					? relation.ausgleichsberechtigtePerson[attributeName]
					: ''
		case 'erwerbsminderung':
			return type === 'source'
				? relation.originalEmployee[attributeName]
				: type === 'target'
					? relation.erwerbsminderungEmployee[attributeName]
					: ''
		case 'hinterblieben':
			return type === 'source'
				? relation.verstorbenePerson[attributeName]
				: type === 'target'
					? relation.hinterbliebenePerson[attributeName]
					: ''
		case 'weitererAnspruch':
			return type === 'source'
				? relation.alterAnspruch[attributeName]
				: type === 'target'
					? relation.aktuellerAnspruch[attributeName]
					: ''
	}
}

const getMitarbeiterGruppeInaktive = (relation: components['schemas']['Relation'], rowType: RelationRowType, translator: any) => {
	const key = getEmployeeAttribute(relation, 'mitarbeiterGruppeInaktive', rowType)
	return key ? translator(`view.pensionerProfile.fields.mitarbeiterGruppe.${key}`) : ''
}

const getMitarbeiterKreisInaktive = (relation: components['schemas']['Relation'], rowType: RelationRowType, translator: any) => {
	const key = getEmployeeAttribute(relation, 'mitarbeiterKreisInaktive', rowType)
	return key ? translator(`view.pensionerProfile.fields.mitarbeiterKreis.${key}`) : ''
}

const getRelationStartedAt = (relation: components['schemas']['Relation']) => {
	return dateFormat(new Date(relation.relationStartedAt))
}

const getRelationEndedAt = (relation: components['schemas']['Relation']) => {
	let relationEndedAt = ''
	if ('relationStartedEndedAt' in relation) {
		relationEndedAt = relation.relationStartedEndedAt || ''
	}
	return dateFormat(new Date(relationEndedAt))
}

const Relations: React.FC<{
	relationsBySourceEmployee: ReadonlyArray<components['schemas']['Relation']>,
	relationsByTargetEmployee: ReadonlyArray<components['schemas']['Relation']>,
	onRedirectToEmployee: (identNumber: string) => void
}> = ({ relationsBySourceEmployee, relationsByTargetEmployee, onRedirectToEmployee }) => {
	const { t } = useTranslation()
	const cellClasses = 'user-select--all'
	const headerCellClass = 'grid-list__cell--header'
	const data = [...relationsBySourceEmployee, ...relationsByTargetEmployee]
	if (!data?.length || data.length === 0) {
		return <Trans i18nKey="generic.noDataAvailable" />
	}
	return (
		<>
			<GridList className="delegate__results" columnCount={9}>
				<GridListRow className="grid-list__row">
					<GridListCell className={headerCellClass}>
						{t('view.pensionerProfile.relations.tableHeaders.type')}
					</GridListCell>
					<GridListCell className={headerCellClass}>
						{t('view.pensionerProfile.relations.tableHeaders.identNumber')}
					</GridListCell>
					<GridListCell className={headerCellClass}>
						{t('view.pensionerProfile.relations.tableHeaders.firstName')}
					</GridListCell>
					<GridListCell className={headerCellClass}>
						{t('view.pensionerProfile.relations.tableHeaders.lastName')}
					</GridListCell>
					<GridListCell className={headerCellClass}>
						{t('view.pensionerProfile.relations.tableHeaders.mitarbeiterGruppeInaktive')}
					</GridListCell>
					<GridListCell className={headerCellClass}>
						{t('view.pensionerProfile.relations.tableHeaders.mitarbeiterKreisInaktive')}
					</GridListCell>
					<GridListCell className={headerCellClass}>
						{t('view.pensionerProfile.relations.tableHeaders.mitarbeiterKreisAktive')}
					</GridListCell>
					<GridListCell className={headerCellClass}>
						{t('view.pensionerProfile.relations.tableHeaders.relationStartedAt')}
					</GridListCell>
					<GridListCell className={headerCellClass}>
						{t(`view.pensionerProfile.relations.tableHeaders.relationStartedEndedAt`)}
					</GridListCell>
				</GridListRow>

				{relationsBySourceEmployee.map((relation, index) => {
					return (
						<React.Fragment key={`${rowTypeTarget}${index.toString()}`}>
							<GridListRow>
								<GridListCell className={cellClasses}>{t(`view.pensionerProfile.relations.relationType.${getRelationType(relation)}`)}</GridListCell>
								<GridListCell className={`${cellClasses} employee-link`}><span onClick={() => {
									const identNumber = getEmployeeAttribute(relation, 'identNumber', rowTypeTarget)
									if (identNumber) {
										onRedirectToEmployee(identNumber)
									}
								}}>{getEmployeeAttribute(relation, 'identNumber', rowTypeTarget)}</span></GridListCell>
								<GridListCell className={cellClasses}>{getEmployeeAttribute(relation, 'firstName', rowTypeTarget)}</GridListCell>
								<GridListCell className={cellClasses}>{getEmployeeAttribute(relation, 'lastName', rowTypeTarget)}</GridListCell>
								<GridListCell className={cellClasses}>{getMitarbeiterGruppeInaktive(relation, rowTypeTarget, t)}</GridListCell>
								<GridListCell className={cellClasses}>{getMitarbeiterKreisInaktive(relation, rowTypeTarget, t)}</GridListCell>
								<GridListCell className={cellClasses}>{getEmployeeAttribute(relation, 'mitarbeiterKreisAktive', rowTypeTarget)}</GridListCell>
								<GridListCell className={cellClasses}>{getRelationStartedAt(relation)}</GridListCell>
								<GridListCell className={cellClasses}>{getRelationEndedAt(relation)}</GridListCell>
							</GridListRow>
						</React.Fragment>
					)
				})}
				{relationsByTargetEmployee.map((relation, index) => {
					return (
						<React.Fragment key={`${rowTypeSource}${index.toString()}`}>
							<GridListRow>
								<GridListCell className={cellClasses}>{t(`view.pensionerProfile.relations.relationType.${getRelationType(relation)}`)}</GridListCell>
								<GridListCell className={`${cellClasses} employee-link`}><span onClick={() => {
									const identNumber = getEmployeeAttribute(relation, 'identNumber', rowTypeSource)
									if (identNumber) {
										onRedirectToEmployee(identNumber)
									}
								}}>{getEmployeeAttribute(relation, 'identNumber', rowTypeSource)}</span></GridListCell>
								<GridListCell className={cellClasses}>{getEmployeeAttribute(relation, 'firstName', rowTypeSource)}</GridListCell>
								<GridListCell className={cellClasses}>{getEmployeeAttribute(relation, 'lastName', rowTypeSource)}</GridListCell>
								<GridListCell className={cellClasses}>{getMitarbeiterGruppeInaktive(relation, rowTypeSource, t)}</GridListCell>
								<GridListCell className={cellClasses}>{getMitarbeiterKreisInaktive(relation, rowTypeSource, t)}</GridListCell>
								<GridListCell className={cellClasses}>{getEmployeeAttribute(relation, 'mitarbeiterKreisAktive', rowTypeSource)}</GridListCell>
								<GridListCell className={cellClasses}>{getRelationStartedAt(relation)}</GridListCell>
								<GridListCell className={cellClasses}>{getRelationEndedAt(relation)}</GridListCell>
							</GridListRow>
						</React.Fragment>
					)
				})}
			</GridList>
		</>
	)
}

export default Relations
