import classNames from 'classnames'
import { useSoftphone } from 'hooks/useSoftphone'
import { FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

interface IConnectAfterCallWorkButton extends HTMLAttributes<HTMLButtonElement> {
	countDown?: number // in sconds
}

export const ConnectAfterCallWorkButton: FC<IConnectAfterCallWorkButton> = ({ className, countDown }) => {
	const { agentState, changeAgentState } = useSoftphone()
	const { t } = useTranslation()

	return (
		<button
			style={{ '--connect-after-work-countdown': countDown } as React.CSSProperties}
			className={classNames(
				className,
				'connect-after-call-work-button',
				`connect-after-call-work-button--${agentState}`
			)}
			onClick={
				'after-call-work-manual' === agentState
					? changeAgentState.bind(null, 'available')
					: changeAgentState.bind(null, 'after-call-work-manual')
			}
		>
			<span className="connect-after-call-work-button__inner">
				{'after-call-work-manual' === agentState
					? t('component.connect.afterCallWork.buttonLabelsetActive')
					: t('component.connect.afterCallWork.buttonLabelsetAfterCallWork')}
			</span>
		</button>
	)
}
