import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useAuth } from '../hooks/useAuth'

export const WebAuthSetup = () => {
	const { navigateTo, getMainPath } = useRouteHelper()
	const { t } = useTranslation()
	const { webAuthSetupAllowed, userData, userInitialized, setupWebAuth } = useAuth()
	const [webAuthSetupSuccessful, setWebAuthSetupSuccessful] = useState<boolean>()

	const onSetupWebAuth = async () => {
		const setupStatus = await setupWebAuth()

		if (true === setupStatus) {
			return
		}

		const { successful } = setupStatus

		setWebAuthSetupSuccessful(successful)
	}

	if (!webAuthSetupAllowed) {
		return (
			<div className="auth">
				<h1 className="text-color-gold text-transform-uppercase">
					<Trans i18nKey="view.webAuth.pageTitle" />
				</h1>

				<p>
					<Trans i18nKey="view.webAuth.notAuthenticated" />
				</p>

				<div className="flex flex--justify-content-center">
					<Button
						onClick={() => navigateTo(getMainPath(routesDictionary.login))}
						label={t('view.webAuth.goToLogin')}
						type={ButtonType.secondary}
					/>
				</div>
			</div>
		)
	}

	return (
		<div className="auth">
			<h1 className="text-color-gold text-transform-uppercase">
				<Trans i18nKey="view.webAuth.pageTitle" />
			</h1>

			<Trans i18nKey={`view.webAuth.bodytext.${userData?.['custom:publicKeyCred'] ? 'replace' : 'init'}`} />

			{true === webAuthSetupSuccessful ? (
				<div className="flex flex--justify-content-center">
					<Button
						type={ButtonType.secondary}
						label={t('generic.done')}
						onClick={() => navigateTo(getMainPath(routesDictionary.dashboard))}
					/>
				</div>
			) : (
				<div className="flex flex--justify-content-center">
					{userInitialized && (
						<Button
							className="margin--right"
							type={ButtonType.primary}
							label={t('generic.cancel')}
							onClick={() => navigateTo(getMainPath(routesDictionary.dashboard))}
						/>
					)}

					<Button type={ButtonType.secondary} label={t('generic.letsGo')} onClick={onSetupWebAuth} />
				</div>
			)}
		</div>
	)
}
