import GridList from 'components/GridList'
import GridListCell from 'components/GridListCell'
import GridListRow from 'components/GridListRow'
import useApi, { QueryKey } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import React, { useRef } from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import routesDictionary from 'routes'
import { currencyFormat, numberFormat } from 'shared/helper/numberFormats'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import payoutIllustration from 'assets/images/illu-auszahlung.svg'
import Button, { ButtonType } from 'shared/components/Button'
import { activatedFeatures } from 'helper/activatedFeatures'
import { t } from 'i18next'
import { BausteinKontoFinalisierung } from './SubComponents/BausteinKontoFinalisierung'
import dateFormat from 'shared/helper/dateFormat'
import Modal, { ModalRefActions } from 'shared/components/Modal'

type TAssetsProps = {
	isLeistungsfall: boolean
}

const Assets: React.FC<TAssetsProps> = ({ isLeistungsfall }) => {
	const { userId } = useUserRecord()
	const api = useApi()
	const { navigateTo, getChildPath } = useRouteHelper()
	const modal = useRef<ModalRefActions>()

	const { data, refetch: refetchVersorgungsguthaben } = useQuery(
		['assets', { userId }],
		api.getAustrittVersorgungsguthaben,
		{
			enabled: !!userId,
			cacheTime: 0,
			staleTime: 0,
		}
	)

	const { data: payoutModelData } = useQuery(['payoutModel', { userId }], api.getAustrittsAuszahlungsmodell, {
		enabled: !!userId,
	})

	const { data: stammdaten, refetch: refetchStammdaten } = useQuery(
		[QueryKey.austrittsbearbeitungen, { userId }],
		api.getAustrittsbearbeitungenStammdatenByIdentNumber,
		{
			enabled: !!userId,
		}
	)

	const showAuszahlungsModellSection = !data?.istUnterAuszahlungsartWahlGrenze

	const tag = (
		<div className="tag-slot">
			{data?.isVorlaeufig ? (
				<div className="estimate-tag">
					<Trans i18nKey={'view.pensionerProfile.tabs.assets.tag'} />
				</div>
			) : (
				<></>
			)}
		</div>
	)

	const applyPutAbfindungNichtAnwendbar = async () => {
		if (!userId) {
			return
		}
		await api.putAbfindungNichtAnwendbar(userId)
		await refetchVersorgungsguthaben()
		await refetchStammdaten()
	}

	const tableRows = (
		<>
			<GridListRow className="font-size-s">
				<GridListCell>
					<Trans
						i18nKey={'view.pensionerProfile.tabs.assets.table.rows.fond'}
						values={{
							name: t('view.pensionerProfile.tabs.assets.fondsNames.0'),
							share: currencyFormat(data?.kurswerte.fonds1),
						}}
					/>
				</GridListCell>
				<GridListCell className="numeric flex--justify-content-end">
					{numberFormat(data?.fondsanteile.eigenanteile.fonds1, { fixedFractionDigits: 4 })}
				</GridListCell>{' '}
				<GridListCell className="numeric flex--justify-content-end">
					{numberFormat(data?.fondsanteile.arbeitgeberanteile.fonds1, { fixedFractionDigits: 4 })}
				</GridListCell>
				<GridListCell className="numeric flex--justify-content-end b">
					{numberFormat(data?.fondsanteile.gesamtanteile.fonds1, { fixedFractionDigits: 4 })}
					{tag}
				</GridListCell>
			</GridListRow>

			<GridListRow className="font-size-s">
				<GridListCell>
					<Trans
						i18nKey={'view.pensionerProfile.tabs.assets.table.rows.fond'}
						values={{
							name: t('view.pensionerProfile.tabs.assets.fondsNames.1'),
							share: currencyFormat(data?.kurswerte.fonds2),
						}}
					/>
				</GridListCell>
				<GridListCell className="numeric flex--justify-content-end">
					{numberFormat(data?.fondsanteile.eigenanteile.fonds2, { fixedFractionDigits: 4 })}
				</GridListCell>{' '}
				<GridListCell className="numeric flex--justify-content-end">
					{numberFormat(data?.fondsanteile.arbeitgeberanteile.fonds2, { fixedFractionDigits: 4 })}
				</GridListCell>
				<GridListCell className="numeric flex--justify-content-end b">
					{numberFormat(data?.fondsanteile.gesamtanteile.fonds2, { fixedFractionDigits: 4 })} {tag}
				</GridListCell>
			</GridListRow>

			<GridListRow className="font-size-s">
				<GridListCell>
					<Trans
						i18nKey={'view.pensionerProfile.tabs.assets.table.rows.fond'}
						values={{
							name: t('view.pensionerProfile.tabs.assets.fondsNames.2'),
							share: currencyFormat(data?.kurswerte.fonds3),
						}}
					/>
				</GridListCell>
				<GridListCell className="numeric flex--justify-content-end">
					{numberFormat(data?.fondsanteile.eigenanteile.fonds3, { fixedFractionDigits: 4 })}
				</GridListCell>
				<GridListCell className="numeric flex--justify-content-end">
					{numberFormat(data?.fondsanteile.arbeitgeberanteile.fonds3, { fixedFractionDigits: 4 })}
				</GridListCell>
				<GridListCell className="numeric flex--justify-content-end b">
					{numberFormat(data?.fondsanteile.gesamtanteile.fonds3, { fixedFractionDigits: 4 })} {tag}
				</GridListCell>
			</GridListRow>

			<GridListRow className="font-size-s">
				<GridListCell>
					<Trans i18nKey={'view.pensionerProfile.tabs.assets.table.rows.guarantee'} />
				</GridListCell>
				<GridListCell className="numeric flex--justify-content-end">
					{currencyFormat(data?.beitragsgarantie.eigenbeitrag)}
				</GridListCell>
				<GridListCell className="numeric flex--justify-content-end">
					{currencyFormat(data?.beitragsgarantie.arbeitgeberbeitrag)}
				</GridListCell>
				<GridListCell className="numeric flex--justify-content-end b">
					{currencyFormat(data?.beitragsgarantie.gesamtbeitrag)} {tag}
				</GridListCell>
			</GridListRow>

			<GridListRow className="text-color-gold font-size-s">
				<GridListCell>
					<Trans i18nKey={'view.pensionerProfile.tabs.assets.table.rows.relevantCredit'} />
				</GridListCell>
				<GridListCell className="numeric flex--justify-content-end">
					{currencyFormat(data?.relevantesVersorgungsguthaben.eigenbeitrag)}
				</GridListCell>
				<GridListCell className="numeric flex--justify-content-end">
					{currencyFormat(data?.relevantesVersorgungsguthaben.arbeitgeberbeitrag)}
				</GridListCell>
				<GridListCell className="numeric flex--justify-content-end b">
					{currencyFormat(data?.relevantesVersorgungsguthaben.gesamtbeitrag)} {tag}
				</GridListCell>
			</GridListRow>
			<GridListRow className="next-to-last font-size-s">
				<GridListCell>
					<Trans i18nKey={'view.pensionerProfile.tabs.assets.bausteinkonto'} />
				</GridListCell>
				<GridListCell className="numeric flex--justify-content-end">
					{currencyFormat(data?.bausteinkonto.eigenbeitrag)}
				</GridListCell>
				<GridListCell className="numeric flex--justify-content-end">
					{currencyFormat(data?.bausteinkonto.arbeitgeberbeitrag)}
				</GridListCell>
				<GridListCell className="numeric flex--justify-content-end b">
					{currencyFormat(data?.bausteinkonto.gesamtbeitrag)}
					{tag}
				</GridListCell>
			</GridListRow>

			<GridListRow className="sum-row font-size-s">
				<GridListCell>
					<Trans i18nKey={'view.pensionerProfile.tabs.assets.table.rows.sum'} />
				</GridListCell>
				<GridListCell className="numeric flex--justify-content-end"> </GridListCell>{' '}
				<GridListCell className="numeric flex--justify-content-end"> </GridListCell>
				<GridListCell className="numeric flex--justify-content-end b">
					{currencyFormat(data?.gesamtbeitrag)} {tag}
				</GridListCell>
			</GridListRow>
		</>
	)

	const tableHeaders = (
		<GridListRow>
			<GridListCell className={'grid-list__cell--header'}>
				<Trans i18nKey={'view.pensionerProfile.tabs.assets.table.headers.block'} />
			</GridListCell>
			<GridListCell className={'grid-list__cell--header'}>
				<Trans i18nKey={'view.pensionerProfile.tabs.assets.table.headers.employee'} />
			</GridListCell>
			<GridListCell className={'grid-list__cell--header'}>
				<Trans i18nKey={'view.pensionerProfile.tabs.assets.table.headers.employer'} />
			</GridListCell>
			<GridListCell className={'grid-list__cell--header'}>
				<Trans i18nKey={'view.pensionerProfile.tabs.assets.table.headers.sum'} />
			</GridListCell>
		</GridListRow>
	)

	return (
		<div className="austrittsbearbeitungen-versorgungsguthaben assets">
			<div>
				<div className="header">
					<div>
						<h3 className="bold-small-heading">
							<Trans i18nKey="view.pensionerProfile.tabs.assets.hasSeverancePayHeadline" />
						</h3>
						<p className="font-size-m">
							{data?.hasAbfindung ? (
								<Trans i18nKey="view.pensionerProfile.tabs.assets.hasSeverancePay" />
							) : (
								<Trans i18nKey="view.pensionerProfile.tabs.assets.noSeverancePay" />
							)}
						</p>
					</div>
					{data?.fallart === 'UV_FALL' && data?.hasAbfindung && (
						<Button
							className="abfindung"
							type={ButtonType.secondary}
							onClick={() => {
								modal.current?.openModal()
							}}
							promiseTracker={{ area: 'abfindungNichtAnwendbar' }}
						>
							<h3 className="bold-small-heading">
								<Trans i18nKey="view.pensionerProfile.tabs.assets.abfindungNichtAnwendbarButton" />
							</h3>
						</Button>
					)}
					{data?.fallart === 'UV_FALL' && stammdaten?.abfindungNichtAnwendbar && (
						<h3 className="bold-small-heading">
							<Trans i18nKey="view.pensionerProfile.tabs.assets.abfindbarMarkiert" />
						</h3>
					)}
				</div>

				<h3 className="bold-small-heading margin--top margin--large">
					<Trans i18nKey="view.pensionerProfile.tabs.assets.berechnungstabelle" />
				</h3>
				<GridList columnCount={4} className="tab-numbers">
					{tableHeaders}
					{tableRows}
				</GridList>
				<div className="margin--top">
					{data?.kurswerte ? (
						<div>
							<div className="kurswerte-header">
								<h3 className="bold-small-heading">
									<Trans i18nKey="view.pensionerProfile.tabs.assets.sharesHeadline" />
								</h3>
								{data?.kurswertStichtag && (
									<h3 className="bold-small-heading">
										{dateFormat(new Date(data?.kurswertStichtag))}
									</h3>
								)}
							</div>
							<div className="kurswerte">
								{Object.values(data.kurswerte).map((v, i) => (
									<div className="kurswerte__row font-size-default" key={'kurswert-' + i}>
										<Trans
											i18nKey={'view.pensionerProfile.tabs.assets.sharesLabel'}
											values={{ name: t(`view.pensionerProfile.tabs.assets.fondsNames.${i}`) }}
										></Trans>

										<span className=" kurswerte__value text-align--right tab-numbers">
											{currencyFormat(v || 0)}{' '}
										</span>
										{tag}
									</div>
								))}
							</div>
						</div>
					) : (
						'–'
					)}
				</div>

				<BausteinKontoFinalisierung />

				{payoutModelData &&
				showAuszahlungsModellSection &&
				isLeistungsfall &&
				activatedFeatures.austrittsbearbeitungAkkordeonAuszahlungsoptionen ? (
					<>
						<h2 className="margin--top">
							<Trans i18nKey="component.confirmedAuszahlungOption.modell.label" />
						</h2>

						<div className="margin--top payoutModelButton__container">
							<img src={payoutIllustration} alt="payoutIllustration" />
							<div className="payoutModelButton__wrapper">
								<Button
									type={[ButtonType.small, ButtonType.primary]}
									className="payoutModel__button "
									onClick={() => {
										if (userId) {
											const austrittPath = getChildPath(routesDictionary.austritte, 'austritt', {
												main: [userId],
											})
											navigateTo(austrittPath, true, { view: 'auszahlungsmodell' })
											setTimeout(() => {
												navigateTo(austrittPath, true, {})
											})
										}
									}}
								>
									<Trans i18nKey="component.confirmedAuszahlungOption.modell.viewPayoutModel" />
								</Button>
								<Button
									type={[ButtonType.small, ButtonType.primary]}
									className="payoutModel__button "
									onClick={() => {
										if (userId) {
											const austrittPath = getChildPath(routesDictionary.austritte, 'austritt', {
												main: [userId],
											})
											navigateTo(austrittPath, true, { view: 'auszahlungsmodell-simulieren' })
											setTimeout(() => {
												navigateTo(austrittPath, true, {})
											})
										}
									}}
								>
									<Trans i18nKey="component.confirmedAuszahlungOption.modell.payoutOptions.simulatePayoutOptions" />
								</Button>
							</div>
						</div>
					</>
				) : null}
			</div>
			<Modal
				ref={modal}
				forceAction={false}
				header={<Trans i18nKey="view.pensionerProfile.tabs.assets.modalAbfindungTitle" />}
				buttonLabel={t(`generic.confirm`)}
				onModalClose={() => {}}
				onButtonClick={applyPutAbfindungNichtAnwendbar}
				className="modal--start-austrittstask"
			>
				<div>
					<Trans i18nKey="view.pensionerProfile.tabs.assets.modalAbfindungContent" />
				</div>
			</Modal>
		</div>
	)
}

export default Assets
