import useApi, { QueryKey } from 'hooks/useApi'
import React from 'react'
import { Trans } from 'react-i18next'
import { useUserRecord } from 'hooks/useUserRecord'
import TicketPage from 'components/PageWithTable/TicketPage'

const PensionerHistory: React.FC = () => {
	const { userId } = useUserRecord()
	const api = useApi()

	return (
		<TicketPage
			className="no-padding--bottom"
			headline={<Trans i18nKey="view.pensionerHistory.headline" />}
			subheadline={<Trans i18nKey="view.pensionerHistory.subtitle" />}
			type="pensioner-history"
			queryKey={QueryKey.ticketHistory}
			apiRequest={api.getTicketHistory}
			requestData={userId}
		/>
	)
}

export default PensionerHistory
