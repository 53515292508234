import { PUTAdressdatenRequestBody } from 'hooks/useApi'
import { IPensionerBaseData } from 'hooks/useUserRecord'
import { useMemo } from 'react'
import { Trans } from 'react-i18next'
import Form, { FormFieldType, FormFields } from 'shared/components/Form'

type AddressdatenInputFeldProps = {
	data: IPensionerBaseData
	onChange: (adressdaten: PUTAdressdatenRequestBody) => void
}
export const AddressdatenInputFeld: React.FC<AddressdatenInputFeldProps> = ({ data, onChange }) => {
	const {
		contactDetails: { street, careOf, zip, city, country, emailAddress, phoneNumber },
	} = data

	const handleOnFormChange = (fields: FormFields) => {
		const adressdaten: PUTAdressdatenRequestBody = {
			stras: fields['street'].value as string,
			careOf: fields['careOf'].value as string,
			ort: fields['city'].value as string,
			land: fields['country'].value as string,
			plz: fields['zip'].value as string,
			primaryEmailAddress: fields['emailAddress'].value as string,
			primaryPhoneNumber: fields['phoneNumber'].value as string,
		}
		onChange(adressdaten)
	}

	const fields: FormFields = useMemo(() => {
		return {
			street: {
				fieldType: FormFieldType.text,
				label: <Trans i18nKey={`view.pensionerProfile.tabs.person.fields.address`}></Trans>,
				value: street,
				name: 'street',
				className: 'class-street',
			},
			careOf: {
				fieldType: FormFieldType.text,
				label: <Trans i18nKey={`view.pensionerProfile.tabs.person.fields.careOf`}></Trans>,
				value: careOf,
				name: 'careOf',
			},
			zip: {
				fieldType: FormFieldType.text,
				label: <Trans i18nKey={`view.pensionerProfile.tabs.person.fields.postalCode`}></Trans>,
				value: zip,
				name: 'zip',
			},
			city: {
				fieldType: FormFieldType.text,
				label: <Trans i18nKey={`view.pensionerProfile.tabs.person.fields.city`}></Trans>,
				value: city,
				name: 'city',
			},
			country: {
				fieldType: FormFieldType.text,
				label: <Trans i18nKey={`view.pensionerProfile.tabs.person.fields.country`}></Trans>,
				value: country,
				name: 'country',
			},
			emailAddress: {
				fieldType: FormFieldType.text,
				label: <Trans i18nKey={`view.pensionerProfile.tabs.person.fields.email`}></Trans>,
				value: emailAddress,
				name: 'emailAddress',
			},
			phoneNumber: {
				fieldType: FormFieldType.text,
				label: <Trans i18nKey={`view.pensionerProfile.tabs.person.fields.phoneNumber`}></Trans>,
				value: phoneNumber,
				name: 'phoneNumber',
			},
		}
	}, [data])

	return (
		<div className="adressdaten">
			<Form
				fields={fields}
				onSubmit={() => {}}
				hideSubmit={true}
				onFormChange={handleOnFormChange}
				className="addressdaten-form"
			/>
		</div>
	)
}
