import useApi from 'hooks/useApi'
import React, { FunctionComponent, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Modal, { ModalRefActions } from 'shared/components/Modal'

export type WawuExportPayload = {
	month: string
	year: string
}

export interface WawuExportModalProps {
	open: boolean
	payload: WawuExportPayload
	onModalClose: () => void
}

const WawuExportModal: FunctionComponent<WawuExportModalProps> = (props) => {
	const { open, payload, onModalClose } = props
	const { month, year } = payload

	const modal = useRef<ModalRefActions>()
	const api = useApi()
	const { t } = useTranslation()

	useEffect(() => {
		if (open) {
			modal.current?.openModal()
		}
	}, [open])

	const renderMessage = () => {
		return (
			<div className="deletion-disclaimer">
				<h3>{t('view.adminDashboard.wawuExport.modal.text')}</h3>
			</div>
		)
	}

	const onConfirmButtonClick = async () => {
		await api
			.getWawuExport({ month, year, run: 'prod-run' })
			.then((exportUrl: string) => {
				const a = document.createElement('a')
				a.href = exportUrl
				a.target = '_blank'
				a.rel = 'noopener'
				a.click()
			})
	}

	return (
		<Modal
			ref={modal}
			forceAction={false}
			header={t('view.adminDashboard.wawuExport.headline')}
			buttonLabel={t('view.adminDashboard.wawuExport.modal.yes')}
			onModalClose={onModalClose}
			onButtonClick={onConfirmButtonClick}
			className="modal--delete modal--width-big"
			denyButtonLabel={t('view.adminDashboard.wawuExport.modal.no')}
			showDeny={true}
		>
			<div className="padding--horizontal margin--vertical text-align--center">{renderMessage()}</div>
		</Modal>
	)
}

export default WawuExportModal
