import { DefaultTFuncReturn } from 'i18next'
import React from 'react'
import Icon from 'shared/components/Icon'
import { IconType } from './Icons'

interface IDisclaimerMessage extends React.HTMLAttributes<HTMLDivElement> {
	text: string | React.ReactElement | DefaultTFuncReturn
}

export const DisclaimerMessage: React.FC<IDisclaimerMessage> = ({ text, className }) => {
	const getClassNames = (): string => {
		const classes: string[] = ['disclaimer-message']

		if (className) {
			classes.push(className)
		}

		return classes.join(' ')
	}

	return (
		<div className={`${getClassNames()} margin--vertical flex flex--align-items-center`}>
			<Icon className="margin--horizontal no-margin--left" type={IconType.info} />
			{text}
		</div>
	)
}
