import useApi from 'hooks/useApi'
import React, { FunctionComponent, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Modal, { ModalRefActions } from 'shared/components/Modal'

export interface IResetPwAuth2UserModal {
	open: boolean
	onModalClose: () => void
	onSuccess: () => void
	userId: string | undefined
}

const ResetPwAuth2UserModal: FunctionComponent<IResetPwAuth2UserModal> = (props) => {
	const { open, onModalClose, onSuccess, userId } = props

	const modal = useRef<ModalRefActions>()
	const api = useApi()
	const { t } = useTranslation()

	useEffect(() => {
		if (open) {
			modal.current?.openModal()
		}
	}, [open])

	const handleOnSubmit = async () => {
		if (!userId) {
			return
		}
		const response = await api.postResetUserAuth2({ userId });
		if (response) {
			onSuccess();
		}
		return response
	}

	const renderDeleteMessage = () => {
		return (
			<div className="deletion-disclaimer">
				<h3>{t('component.userPwResetAuth2Modal.text')}</h3>
			</div>
		)
	}

	return (
		<Modal
			ref={modal}
			forceAction={false}
			header={t('component.userPwResetAuth2Modal.header')}
			buttonLabel={t('generic.confirm')}
			onModalClose={onModalClose}
			onButtonClick={handleOnSubmit}
			className="modal--delete"
		>
			<div className="padding--horizontal margin--vertical text-align--left">
				{renderDeleteMessage()}
			</div>
		</Modal>
	)
}

export default ResetPwAuth2UserModal
