import useAsyncEffect from '@n1ru4l/use-async-effect'
import { IconType } from 'components/Icons'
import { DefaultTFuncReturn } from 'i18next'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Form, { FormFieldType, FormFields } from 'shared/components/Form'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useClipboard } from 'use-clipboard-copy'
import { AuthError, ErrorType, useAuth } from '../hooks/useAuth'
import Button, { ButtonType } from '../shared/components/Button'
import { TextInputType, ValidityStateType } from '../shared/components/TextInput'

export const MfaSetup = () => {
	const { navigateTo, getMainPath } = useRouteHelper()
	const { t } = useTranslation()
	const { userData, verifyTOTPSetup, getTOTPSetupCode } = useAuth()
	const [TOTPSetupCode, setTOTPSetupCode] = useState<{ token: string; url: string }>()
	const [errorMessages, setErrorMessages] = useState<(string | React.ReactElement | DefaultTFuncReturn)[]>()
	const [copied, setCopied] = useState<boolean>(false)
	const clipboard = useClipboard({
		onSuccess() {
			setCopied(true)
		},
	})

	useAsyncEffect(function* (setErrorHandler: any, c: any) {
		const response = yield* c(getTOTPSetupCode())

		if (false === response.successful) {
			setTimeout(() => {
				navigateTo(getMainPath(routesDictionary.login))
			})
			return
		}

		const url = `otpauth://totp/AWSCognito:${response.username}?secret=${response.token}&issuer=Cognito`

		setTOTPSetupCode({ token: response.token, url })
	}, [])

	const inputElements: FormFields = {
		token: {
			value: '',
			required: true,
			type: TextInputType.tel,
			label: t('view.mfa.formFieldLabels.mfa'),
			autoComplete: 'one-time-code',
			errorMessage: t('generic.formErrors.token'),
			fieldType: FormFieldType.text,
			minLength: 6,
			maxLength: 6,
			validityHints: {
				[ValidityStateType.tooShort]: { type: 'hint', message: t('generic.formHints.token') },
				[ValidityStateType.tooLong]: { type: 'hint', message: t('generic.formHints.token') },
			},
		},
	}

	if (userData) {
		return (
			<div className="auth">
				<h1>
					<Trans i18nKey="view.mfa.pageTitle" />
				</h1>

				<h3>
					<Trans i18nKey="view.login.alreadyLoggedIn" />
				</h3>
				<Button
					onClick={() => navigateTo(routesDictionary.dashboard.path)}
					label={t('view.login.goToDashboard')}
					type={ButtonType.secondary}
				/>
			</div>
		)
	}

	return (
		<div className="auth">
			<div>
				<h1 className="text-color-gold text-transform-uppercase">
					<Trans i18nKey="view.mfa.pageTitle" />
				</h1>

				<p>
					<Trans i18nKey="view.mfa.bodytext.setup" />
				</p>
			</div>

			<div
				className={`margin--vertical margin--center visually-hidden visually-hidden--${String(
					!TOTPSetupCode?.token
				)}`}
			>
				<Button
					className={`auth__copy-string-button ${copied ? 'auth__copy-string-button--active' : ''}`}
					onClick={() => clipboard.copy(TOTPSetupCode?.token)}
					label={t(copied ? 'view.mfa.tokenCopied' : 'view.mfa.copyToken')}
					type={[ButtonType.secondary, ButtonType.small]}
					icon={copied ? IconType.animatedCheckmark : undefined}
				/>
			</div>
			<Form
				className="auth"
				fields={inputElements}
				onSubmit={(submittedFields: { [key: string]: string }) => {
					return verifyTOTPSetup(submittedFields)
				}}
				onError={(error: AuthError) => {
					switch (error.code) {
						case ErrorType.codeMismatch:
						case ErrorType.enableSoftwareTokenMFAException:
						case ErrorType.invalidParameter:
							setErrorMessages([t('generic.formErrors.codeMismatch')])
							break

						case ErrorType.sessionInvalid:
							setErrorMessages([
								t('generic.formErrors.sessionInvalid.text'),
								<Button
									onClick={() => navigateTo(getMainPath(routesDictionary.login))}
									label={t('generic.formErrors.sessionInvalid.link')}
									type={[ButtonType.text, ButtonType.link]}
								/>,
							])
							break

						default:
							setErrorMessages([t('generic.formErrors.default')])
					}
				}}
				errorMessages={errorMessages}
				submitLabel={t('view.setNewPassword.submit')}
			/>
		</div>
	)
}
