import { API } from 'aws-amplify'
import { useCallback } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { QueryFunction } from 'react-query'
import { components, paths } from '../types/api-interface'
import { QueryKey, apiDataObject } from './useApi'

export interface IConnectAuthDetails {
	credentials: {
		AccessToken: string
		AccessTokenExpiration: number
		RefreshToken: string
		RefreshTokenExpiration: number
	}
	destination: string
	agentId: string
}

export interface IConnectRoutingProfile {
	routingProfileId: string
	name: string
}

export interface IConnectMetrics {
	queueId: string
	metrics: {
		Name: string
		Unit: string
		Value: number // in seconds
	}[]
	queueName: string
}

export interface IConnectAgentDetails {
	agentId: string
	firstName: string
	lastName: string
	email: string
	username: string
	routingProfileId: string
	acwTime: number
}

export interface IConnectCallerDetailsRequest {
	idOrPnr: string
	dateOfBirth: string
	zipCode: string
	queue: string
	phone: string
}

export interface IConnectCallerDetailsResponse {
	found: boolean
	identNumber: string
	personalnummer: string
	vorname: string
	nachname: string
	dateOfBirth: string
}

export type CallerHistoryParams = paths['/connect/call-history']['get']['parameters']['query']

// export interface ICallHistoryParams:  {
// 	days?: number
// 	maxItems: number
// 	/** Provide this to paginate a page backwards */
// 	firstToken?: string
// 	/** Provide this to paginate a page forward */
// 	lastToken?: string
// 	/** Sort pages asc or desc */
// 	sortParameter?: string
// 	/** Max items in the result */
// }

const useConnectApi = () => {
	const getAuthDetails = useCallback(async (): Promise<IConnectAuthDetails | undefined> => {
		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('adminApi', 'connect/authorize', data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}, [])

	const getRoutingProfiles = useCallback(async (): Promise<IConnectRoutingProfile[] | undefined> => {
		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('adminApi', 'connect/routing-profiles', data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}, [])

	const getCurrentMetrics = useCallback(async (): Promise<IConnectMetrics[] | undefined> => {
		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('adminApi', 'connect/current-metrics', data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}, [])

	const setRoutingProfile = useCallback(
		async (
			agentId: IConnectAgentDetails['agentId'],
			body: { routingProfileId: IConnectRoutingProfile['routingProfileId'] }
		): Promise<IConnectAgentDetails | undefined> => {
			const data = await trackPromise(apiDataObject({ body }))

			const responseData = await trackPromise(
				API.patch('adminApi', `connect/agent/${agentId}`, data)
					.then((response) => response)
					.catch((err) => {
						console.log(err)
						return undefined
					})
			)

			return responseData
		},
		[]
	)

	const getAgentDetails = useCallback(
		async (agentId: IConnectAgentDetails['agentId']): Promise<IConnectAgentDetails | undefined> => {
			const data = await trackPromise(apiDataObject())

			const responseData = await trackPromise(
				API.get('adminApi', `connect/agent/${agentId}`, data)
					.then((response) => response)
					.catch((err) => {
						console.log(err)
						return undefined
					})
			)

			return responseData
		},
		[]
	)

	const getCallerDetails = useCallback(
		async (
			queryStringParameters: IConnectCallerDetailsRequest
		): Promise<IConnectCallerDetailsResponse | undefined> => {
			const data = await trackPromise(
				apiDataObject({
					queryStringParameters,
				})
			)

			const responseData = await trackPromise(
				API.get('adminApi', 'validate-connect-incoming-call', data)
					.then((response) => response.data)
					.catch((err) => {
						console.log(err)
						return undefined
					})
			)

			return responseData
		},
		[]
	)

	const getCallerHistory: QueryFunction<
		components['schemas']['CallHistory'],
		[QueryKey.callerHistory, paths['/connect/call-history']['get']['parameters']['query']]
	> = useCallback(async ({ queryKey }) => {
		const queryStringParameters = queryKey[1]

		for (const key in queryStringParameters) {
			if (
				queryStringParameters[key as keyof paths['/connect/call-history']['get']['parameters']['query']] ===
				undefined
			) {
				delete queryStringParameters[key as keyof paths['/connect/call-history']['get']['parameters']['query']]
			}
		}

		const data = await trackPromise(apiDataObject({ queryStringParameters }))

		const responseData = await trackPromise(
			API.get('adminApi', 'connect/call-history', data)
				.then((response) => {
					return response
				})
				.catch((err) => {
					console.log('error', err)
					return undefined
				})
		)

		console.log('response', responseData)

		// callEntries: [
		// 	{
		// 		contactId: '12345',
		// 		callDatetime: '2004-06-14T23:34:30',
		// 		caller: {
		// 			firstName: 'Hans',
		// 			lastName: 'Mustermann',
		// 			callerId: '1337',
		// 		},
		// 		queue: 'WestNRJ',
		// 		callDuration: 72,
		// 	},
		// ],
		// pageInfo: {
		// 	firstToken: '1',
		// 	lastToken: '2',
		// },

		return responseData
	}, [])

	return {
		getAuthDetails,
		getRoutingProfiles,
		setRoutingProfile,
		getAgentDetails,
		getCallerDetails,
		getCallerHistory,
		getCurrentMetrics,
	}
}

export default useConnectApi
