import classNames from 'classnames'
import { useSoftphone } from 'hooks/useSoftphone'
import { FC, HTMLAttributes, useEffect, useRef, useState } from 'react'
import Button, { ButtonType } from 'shared/components/Button'
import { IconType } from '../Icons'

export const ConnectSoftphone: FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
	const { showSoftphone, setContainerDiv, setShowSoftphone } = useSoftphone()
	const [dragPosition, _setDragPosition] = useState<{ x: number; y: number; windowWidth: number }>({
		x: 1,
		y: 0,
		windowWidth: 0,
	})
	const [dragImage, setDragImage] = useState<HTMLImageElement | undefined>()
	const ref = useRef<HTMLDivElement>(null)
	const dragPositionRef = useRef(dragPosition)

	useEffect(() => {
		if (ref.current) {
			setContainerDiv(ref.current)
		}
	}, [setContainerDiv])

	const setDragPosition = (data: { x: number; y: number; windowWidth: number }) => {
		dragPositionRef.current = data
		_setDragPosition(data)
	}

	const onDrag = (e: any) => {
		// prevent reset to position 0 - 0
		e.preventDefault()
		if (e.pageY === 0) {
			return
		}

		setDragPosition({ x: e.pageX, y: e.pageY, windowWidth: window.innerWidth })
	}

	const onDragStart = (e: any) => {
		e.dataTransfer.setDragImage(dragImage, 0, 0)
	}

	useEffect(() => {
		let timeout: ReturnType<typeof setTimeout> | undefined

		const onResize = (dragPositionRef: any) => {
			const { x, y, windowWidth } = dragPositionRef.current

			if (timeout) {
				clearTimeout(timeout)
			}

			timeout = setTimeout(() => {
				const xPercent = (x * 100) / windowWidth
				const yPercent = (y * 100) / windowWidth
				const newWindowWidth = window.innerWidth

				setDragPosition({
					x: (newWindowWidth / 100) * xPercent,
					y: (newWindowWidth / 100) * yPercent,
					windowWidth: newWindowWidth,
				})
			}, 200)
		}

		window.addEventListener('resize', () => {
			onResize(dragPositionRef)
		})

		const dragImg = new Image(0, 0)
		dragImg.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
		setDragImage(dragImg)

		return () => {
			window.removeEventListener('resize', onResize)
		}
	}, [])

	return (
		<div
			className={classNames(className, 'connect-softphone', {
				'connect-softphone--visible': showSoftphone,
			})}
			style={
				{
					'--pos-x': dragPosition.x + 'px',
					'--pos-y': dragPosition.y + 'px',
				} as React.CSSProperties
			}
			draggable={true}
			onDrag={onDrag}
			onDragStart={onDragStart}
		>
			<div className={'connect-softphone__top-bar'}>
				<div className="drag-indicator">
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
				</div>
				<Button
					className="connect-softphone__close"
					type={[ButtonType.secondary, ButtonType.small]}
					onClick={() => setShowSoftphone(false)}
					icon={IconType.close}
				/>
			</div>

			<div className="connect-softphone__iframe-wrapper">
				<div className="connect-softphone__iframe" ref={ref} onClick={() => setShowSoftphone(false)} />
			</div>
		</div>
	)
}
