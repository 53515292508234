import { DefaultTFuncReturn } from 'i18next'
import React from 'react'

interface IViewHeader extends React.HTMLAttributes<HTMLDivElement> {
	headline?: string | React.ReactElement | DefaultTFuncReturn
	subheadline?: string | React.ReactElement | DefaultTFuncReturn
	button?: React.ReactElement
}

export const ViewHeader: React.FC<IViewHeader> = ({ headline, subheadline, button, className, children }) => {
	const getClassNames = (): string => {
		const classes: string[] = ['view-header']

		if (className) {
			classes.push(className)
		}

		return classes.join(' ')
	}

	return (
		<div className={getClassNames()}>
			{headline && <h1 className="view-header__headline giant-headline">{headline}</h1>}

			<div className="view-header__subheadline flex flex--align-items-center no-margin--top no-margin--bottom">
				{button}
				{subheadline && (
					<div
						className={`text-transform-uppercase text-color-gold margin--right ${
							button ? 'margin--left' : ''
						}`}
					>
						{subheadline}
					</div>
				)}
				<hr className="hr hr--gold flex--grow" />
			</div>
			{children}
		</div>
	)
}
