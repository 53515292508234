export function getToastMessage(errorCode?: number): string {
	if (errorCode) {
		const code = errorCode as ErrorCode
		switch (code) {
			case 400:
				return ErrorCodesMessage.BadRequest
			case 401:
				return ErrorCodesMessage.Unauthorized
			case 403:
				return ErrorCodesMessage.Forbidden
			case 404:
				return ErrorCodesMessage.NotFound
			case 500:
				return ErrorCodesMessage.InternalServerError
			default:
				return ErrorCodesMessage.InternalServerError
		}
	}
	return ErrorCodesMessage.InternalServerError
}

type ErrorCode = 400 | 401 | 403 | 404 | 500

enum ErrorCodesMessage {
	BadRequest = 'Sie haben fehlerhafte Anfrage eingegeben.',
	Unauthorized = 'Der Zugriff wird verweigert.',
	Forbidden = 'Sie haben keine Berechtigung auf dieser Seite.',
	NotFound = 'Die angeforderten Daten sind nicht gefunden.',
	InternalServerError = 'Ein unbekannter Fehler ist aufgetreten.',
}
