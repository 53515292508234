import { useUserRecord } from 'hooks/useUserRecord'
import { usePath } from 'raviger'
import React, { FunctionComponent, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import routesDictionary from 'routes'
import Icon from 'shared/components/Icon'
import SwitchSelect from 'shared/components/SwitchSelect'
import { RouteItem, useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useAuth } from '../hooks/useAuth'

interface NavigationProps {
	className?: string
}

const Navigation: FunctionComponent<NavigationProps> = (props) => {
	const { filteredRoutesDictionary, getMainPath, navigateTo } = useRouteHelper()
	const { userData } = useAuth()
	const path = usePath()

	const user = useUserRecord()

	const [navigationIndex, setNavigationIndex] = useState<number>()

	const selectOptions = useMemo(() => {
		const handleClick = (route: RouteItem, value: string) => {
			navigateTo(value, false, route.queryParams || undefined)
		}
		let updatedNavigationIndex = -1

		const getNavigationName = (route: RouteItem): RouteItem['navigationName'] => {
			let navigationName

			switch (route.path) {
				case getMainPath(routesDictionary.pensioner):
					navigationName = user.navigationData ? (
						`${user.navigationData.firstName} ${user.navigationData.lastName?.substring(0, 1)}.`
					) : (
						<Trans i18nKey="view.pensioner.navigationName" />
					)

					break

				default:
					navigationName = route.navigationName || route.path
			}

			return navigationName
		}

		const renderNavigationItem = (route: RouteItem, value: string, index: number) => {
			// remove leading slash for comparison
			const currentPath = path?.substring(1)
			const navigationItemPath = route.path?.substring(1)

			const activeIcon =
				undefined !== currentPath &&
				(currentPath === navigationItemPath ||
					(navigationItemPath.length !== 0 && currentPath.startsWith(navigationItemPath)))

			if (activeIcon) {
				updatedNavigationIndex = index
			}

			return (
				<div
					className={`navigation__item navigation__item--${navigationItemPath} ${
						activeIcon ? 'navigation__item--active' : ''
					}`}
					key={`navigation__item--${index}`}
				>
					<span className="navigation__link" onClick={() => handleClick(route, value)}>
						{route.icon && <Icon type={route.icon} className="navigation__icon" />}
						<span className="navigation__link-text">{getNavigationName(route)}</span>
					</span>
				</div>
			)
		}

		const options = filteredRoutesDictionary
			.filter((item: RouteItem) => {
				let showItemInNavigation

				switch (typeof item.showInNavigation) {
					case 'boolean':
						showItemInNavigation = item.showInNavigation
						break

					case 'function':
						showItemInNavigation = item.showInNavigation()
						break

					case 'undefined':
						showItemInNavigation = false
				}

				/**
				 * - show only the items where showInNavigation is set to true
				 * - check if item should not be hidden if logged in
				 */
				return (
					showItemInNavigation && ((!userData && !item.hideIfLoggedOut) || (userData && !item.hideIfLoggedIn))
				)
			})
			.map((route: RouteItem, index: number) => {
				// console.log(route.parameterValues)
				let value = route.path

				if (route.parameterValues) {
					for (const parameter of Object.values(route.parameterValues())) {
						value += `/${parameter}`
					}
				}

				return {
					value: `navigation-item-${index}`,
					label: renderNavigationItem(route, value, index),
				}
			})

		if (navigationIndex !== updatedNavigationIndex) {
			setNavigationIndex(updatedNavigationIndex)
		}

		return options
		// eslint-disable-next-line
	}, [userData, path, user.navigationData])

	const getClassNames = (): string => {
		const classes: string[] = ['navigation']

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}

	if (selectOptions.length <= 1) {
		return null
	}

	return (
		<SwitchSelect
			className={getClassNames()}
			options={selectOptions}
			value={sessionStorage.getItem('initialPath') ?? path ?? ''}
			currentIndex={sessionStorage.getItem('initialPath') ? -1 : navigationIndex}
		/>
	)
}

export default Navigation
