import classNames from 'classnames'
import { useSoftphone } from 'hooks/useSoftphone'
import { FC, HTMLAttributes } from 'react'
import { Trans } from 'react-i18next'

export const ConnectCallHint: FC<HTMLAttributes<HTMLSpanElement>> = ({ className }) => {
	const { contactAttributes, contactState } = useSoftphone()

	return (
		<span className={classNames(className, 'connect-call-hint bold-small-heading text-color-blue')}>
			{contactAttributes?.phoneNumber ? (
				<Trans
					i18nKey={
						contactState === 'accepted'
							? 'component.connect.callHint.accepted'
							: 'component.connect.callHint.incoming'
					}
					values={{ number: contactAttributes?.phoneNumber?.value }}
				/>
			) : (
				'\u2003'
			)}
		</span>
	)
}
