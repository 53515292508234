import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const Toast: React.FC = () => {
	return (
		<>
			<ToastContainer
				icon={false}
				position="bottom-left"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			></ToastContainer>
		</>
	)
}

type ToastMessageProps = {
	title: string
	content: string
}

const ToastMessage: React.FC<ToastMessageProps> = ({ title, content }) => {
	return (
		<div>
			<div className="toast-title">{title}</div>
			<div className="toast-content">{content}</div>
		</div>
	)
}

export type ZFToastProps = {
	title: string
	content: string
	type: 'error' | 'success'
}

function zfToast(props: ZFToastProps) {
	switch (props.type) {
		case 'error':
			return toast.error(<ToastMessage {...props} />)
		case 'success':
			return toast.success(<ToastMessage {...props} />)
		default:
			return toast(<ToastMessage {...props} />)
	}
}

export default zfToast
