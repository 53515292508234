import useAsyncEffect from '@n1ru4l/use-async-effect'
import useApi, { PensionerSearchQueryParams, PensionerSearchResult, QueryKey } from 'hooks/useApi'
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Button, { ButtonType } from 'shared/components/Button'
import dateFormat from 'shared/helper/dateFormat'
import delay from 'shared/helper/delay'
import getParticipationStateValue from 'shared/helper/getParticipationStateValue'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import GridList from './GridList'
import GridListCell from './GridListCell'
import GridListRow from './GridListRow'
import { IconType } from './Icons'
import SearchInput from './SearchInput'

const PensionerSearchResults: FunctionComponent = () => {
	const { getMainPath } = useRouteHelper()

	const api = useApi()
	const { t } = useTranslation()

	const [queryStringParameters, setQueryStringParameters] = useState<PensionerSearchQueryParams>()
	const [searchResults, setSearchResults] = useState<PensionerSearchResult[]>(
		JSON.parse(sessionStorage.getItem('searchResults') || '[]')
	)
	const [searchInputString, setSearchInputString] = useState<string>(
		sessionStorage.getItem('searchInputString') || ''
	)

	const searchHeaders: (keyof PensionerSearchResult)[] = [
		'identNumber',
		'personnelNumber',
		'bavPersonalNummer',
		'hrSystemCode',
		'lastName',
		'firstName',
		'dateOfBirth',
		'participationState',
	]

	useEffect(() => {
		sessionStorage.setItem('searchResults', JSON.stringify(searchResults))
	}, [searchResults])

	useAsyncEffect(
		function* (setErrorHandler: any, c: any) {
			if (undefined === queryStringParameters || 0 === queryStringParameters.values.length) {
				setSearchResults(JSON.parse(sessionStorage.getItem('searchResults') || '[]'))
				return
			}

			yield* c(delay(300))

			const response = yield* c(
				api.getPensionerSearchResults(QueryKey.pensionerSearchResults, queryStringParameters)
			)

			setSearchResults(response)
		},
		[queryStringParameters]
	)

	const renderSearchHeader = () => {
		const headerCellClass = 'grid-list__cell--header'

		return (
			<>
				<SearchInput
					label={t('view.pensionerSearch.labels.searchField')}
					value={searchInputString}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						const inputString = e.target.value
						setSearchInputString(inputString)
						sessionStorage.setItem('searchInputString', inputString)

						const values = e.target.value.split(' ').filter(Boolean)
						setQueryStringParameters({ values })
					}}
				/>

				<GridListRow>
					{searchHeaders.map((label, index) => (
						<GridListCell className={headerCellClass} key={`header-field-${index}`}>
							{t(`view.pensionerSearch.labels.${label}`)}
						</GridListCell>
					))}
					<GridListCell className={headerCellClass} />
				</GridListRow>
			</>
		)
	}

	const renderSearchResults = () => {
		if (!searchResults) {
			return
		}

		return (
			<>
				{searchResults.map((result: PensionerSearchResult, index: number) => (
					<GridListRow key={`result-${index}`}>
						{searchHeaders.map((key, fieldIndex: number) => {
							let value = result[key]

							switch (key) {
								case 'dateOfBirth':
									value = dateFormat(new Date(value))

									break
								case 'participationState':
									value === undefined
										? (value = '-')
										: (value = getParticipationStateValue(value as string))

									break
							}

							return (
								<GridListCell key={`result-field-${index}-${fieldIndex}`} className="user-select--all">
									{String(value === '' ? '-' : value)}
								</GridListCell>
							)
						})}
						<GridListCell>
							<Button
								type={ButtonType.round}
								icon={IconType.open}
								route={`${getMainPath(routesDictionary.pensioner)}/${result.identNumber}`}
							/>
						</GridListCell>
					</GridListRow>
				))}
			</>
		)
	}

	return (
		<GridList columnCount={searchHeaders.length + 1}>
			{renderSearchHeader()}
			{renderSearchResults()}
		</GridList>
	)
}

export default PensionerSearchResults
