import PensionerSearchResults from 'components/PensionerSearchResults'
import React from 'react'
import { Trans } from 'react-i18next'

const PensionerSearch = () => {
	return (
		<div className="pensioner-search">
			<h1 className="pensioner-search__headline margin--bottom no-margin--bottom">
				<Trans i18nKey="view.pensionerSearch.headline" />
			</h1>
			<div className="flex flex--align-items-center font-family-bold ">
				<div className="text-transform-uppercase text-color-gold margin--right">
					<Trans i18nKey="view.pensionerSearch.subheadline" />
				</div>
				<hr className="hr hr--gold flex--grow" />
			</div>
			<PensionerSearchResults />
		</div>
	)
}

export default PensionerSearch
