import { TCallerActionsUserAuthData, TCallerActionsUserDetailsResponse } from 'hooks/useCallerActionsApi'
import { createContext, useContext, useState } from 'react'

interface ICallerActionsContext {
	userDetails?: TCallerActionsUserDetailsResponse
	setUserDetails?: (data: TCallerActionsUserDetailsResponse) => void
	userAuthData?: TCallerActionsUserAuthData
	setUserAuthData?: (data: TCallerActionsUserAuthData) => void
	resetData?: () => void
}

export const CallerActionsContext = createContext<ICallerActionsContext>({})

export function ProvideCallerActions({ children }: any) {
	const value = useProvideCallerActionsContent()
	return <CallerActionsContext.Provider value={value}>{children}</CallerActionsContext.Provider>
}

export const useCallerActionsContext = () => useContext(CallerActionsContext)

const useProvideCallerActionsContent = (): ICallerActionsContext => {
	const [userDetails, setUserDetails] = useState<TCallerActionsUserDetailsResponse>()
	const [userAuthData, setUserAuthData] = useState<TCallerActionsUserAuthData>()

	const resetData = () => {
		setUserDetails(undefined)
		setUserAuthData(undefined)
	}

	return {
		userDetails,
		setUserDetails,
		userAuthData,
		setUserAuthData,
		resetData,
	}
}
