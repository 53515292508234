import { useAuth } from 'hooks/useAuth'
import React, { FunctionComponent } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

interface CaptchaProps {
	onChange?: (token: string | null) => void
}

const Captcha: FunctionComponent<CaptchaProps> = props => {
	const auth = useAuth()

	const onChange = (token: string | null) => {
		if (props.onChange) {
			return props.onChange(token)
		}

		auth.sendChallengeAnswer({ token })
	}

	return (
		<div className="captcha">
			<ReCAPTCHA sitekey={String(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY)} onChange={onChange} />
		</div>
	)
}

export default Captcha
