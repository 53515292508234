import useApi from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'
import SelectInput from 'shared/components/SelectInput'
import TextArea from 'shared/components/TextArea'
import dateFormat from 'shared/helper/dateFormat'
import { ButtonGroup } from './DocumentCard'
import { useQuery } from 'react-query'
import DocumentRequestEntry from './DocumentRequestEntry'
import AddDocument from './AddDocument'
import { TDocumentRequestProps, TDocumentType } from './document-view-types'
import { wait } from 'shared/helper/wait'
import zfToast, { ZFToastProps } from 'shared/components/ZFToast'

const DocumentRequest: FunctionComponent<TDocumentRequestProps> = ({
	dokumente,
	sonstigeDokumente,
	optionen,
	addDocument,
	handleToggleNewDocument,
	toggleNewDocument,
	setState,
	state,
	finalizeDocumentRequest,
	initial,
	handleInitialStateChange,
	sendSchreibenData,
	refetchHistorie,
	onCheckedChange,
	nochAnzufragenChecked,
	sonstigeChecked,
	isAbfindungsFall
}) => {
	const api = useApi()
	const { userId } = useUserRecord()
	const { t } = useTranslation()

	const [message, setMessage] = useState<string>(optionen?.optionaleNachricht ?? '')

	useEffect(() => {
		if (optionen?.optionaleNachricht) {
			setMessage(optionen.optionaleNachricht)
		}
	}, [optionen])

	const { data: schreibenProperties, refetch: refetchSchreibenProperties } = useQuery(
		['austrittDokumenteSchreibenProperties', { userId }],
		() => api.getAustrittDokumenteSchreibenProperties(userId),
		{
			enabled: !!userId,
		}
	)

	useEffect(() => {
		if (isAbfindungsFall) {
			handleInitialStateChange(false)
		}
	}, [isAbfindungsFall])

	useEffect(() => {
		if (!schreibenProperties) {
			setState({})
			return
		}
		const { createdAt, sentAt } = schreibenProperties
		setState({
			isCreated: !!createdAt,
			isSent: !!sentAt,
			sentDate: sentAt && dateFormat(new Date(sentAt.split('T')[0])),
			createdDate: createdAt && dateFormat(new Date(createdAt.split('T')[0])),
		})
	}, [schreibenProperties])

	/**
	 * API zum Erstellen des Schreibens
	 * Im ersten Schritt wird das Schreiben an das Backend gesendet,
	 * (Backend löscht dabei sofort das alte Schreiben)
	 * im zweiten Schritt die Metadaten zum Schreiben aufgerufen.
	 * Weil es aber bis zu 5 Sek Verzögerung kommen kann, dass die Metadaten verfügbar ist,
	 * wird bis zu 5x jede 1.5 Sek die Metadaten aufgerufen, bis sie da ist.
	 */
	const createSchreiben = async () => {
		if (!userId) return
		await sendSchreibenData(versandart, message)
		await api.createAustrittDokumenteSchreiben(userId)
		let properties = await refetchSchreibenProperties()
		setState({})
		await wait(3000)
		for (let i = 0; i < 5; i++) {
			properties = await refetchSchreibenProperties()
			if (properties.data) {
				const { createdAt, sentAt } = properties.data
				setState({
					isCreated: !!properties.data.createdAt,
					isSent: !!properties.data,
					sentDate: sentAt && dateFormat(new Date(sentAt.split('T')[0])),
					createdDate: createdAt && dateFormat(new Date(createdAt.split('T')[0])),
				})
				break
			}
			await wait(1500)
		}
		if (!properties?.data) {
			const toastProps: ZFToastProps = {
				title: 'Austritt Dokumente',
				content: 'Ein Fehler ist beim Erzeugen von Austritt Dokumente aufgetreten.',
				type: 'error',
			}
			zfToast(toastProps)
		}
	}

	// API Handler für das Herunterladen des Schreibens
	const downloadSchreiben = async () => {
		if (!userId) return
		try {
			const downloadUrl = await api.getAustrittDokumenteSchreibenDownloadURL(userId)
			if (downloadUrl) {
				setTimeout(() => {
					const win = window.open(downloadUrl, '_blank', 'noopener,noreferrer')
					if (win) {
						win.focus()
					}
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	/**
	 * API zum Versenden des Schreibens
	 * Nach dem Versenden wird die neue Metadaten geholt (ohne Verzögerung),
	 * den Status wird erneut refetched,
	 * da Backend den Status auf ULA Angefordert setzt.
	 */
	async function sendSchreiben() {
		if (!userId) return
		await api.sendAustrittDokumenteSchreiben(userId)
		await finalizeDocumentRequest()
		await refetchSchreibenProperties()
		await refetchHistorie()
	}

	// Schreiben Buttons für erstellen, herunterladen und senden
	function schreibenButtons() {
		return (
			<div className="document-request__buttons">
				<Button
					type={[ButtonType.medium, ButtonType.primary]}
					className="margin--center"
					onClick={createSchreiben}
					promiseTracker={{ area: 'AustrittDokumenteSchreiben' }}
				>
					<Trans i18nKey="view.dokumente.documentRequest.erzeugen" />
				</Button>
				<Button
					type={[ButtonType.secondary, ButtonType.medium]}
					className="margin--center"
					onClick={downloadSchreiben}
					disabled={!state.isCreated || state.isSent}
					promiseTracker={{ area: 'AustrittDokumenteSchreibenDownloadURL' }}
				>
					<Trans i18nKey="view.dokumente.documentRequest.ansehen" />
				</Button>
				<Button
					type={[ButtonType.highlight, ButtonType.medium]}
					className="margin--center"
					onClick={sendSchreiben}
					disabled={!state.isCreated || state.isSent}
					promiseTracker={{ area: 'AustrittDokumenteSchreibenSend' }}
				>
					<Trans i18nKey="view.dokumente.documentRequest.versenden" />
				</Button>
			</div>
		)
	}
	if (!dokumente || !optionen) return null

	const { versandart, moeglicheVersandarten } = optionen

	// Button Konfiguration für den Initial-Button
	const INITIAL_BUTTONS_CONFIG = {
		left: {
			buttonStatus: 'initial',
			label: <Trans i18nKey="view.dokumente.options.labels.initial" />,
			onClick: () => {
				handleInitialStateChange(true)
			},
		},
		right: {
			buttonStatus: 'erneut',
			label: <Trans i18nKey="view.dokumente.options.labels.erneut" />,
			onClick: () => {
				handleInitialStateChange(false)
			},
		},
	}

	// Finde die sonstigen Nachweise in den Dokumenten und speichere sie in einer Variablen
	const sonstigeNachweiseData = dokumente.find((doc) => doc.type === 'SONSTIGE_NACHWEISE')

	const handleOnChange = (checked: boolean, type: TDocumentType) => {
		onCheckedChange(checked, type)
	}

	const handleOnSonstigesChange = (checked: boolean, type: TDocumentType, title?: string) => {
		onCheckedChange(checked, type, title)
	}

	return (
		<div className="document-request">
			<h2>Dokumente anfragen</h2>
			<ButtonGroup
				className="document-request__button-group"
				status={initial === true ? 'initial' : 'erneut'}
				config={INITIAL_BUTTONS_CONFIG}
				label="Anforderungsschreiben"
				isAbfindungsFall={isAbfindungsFall}
			/>
			<div className="document-request__documents">
				<h3 className="bold-small-heading">Formulare</h3>
				{nochAnzufragenChecked &&
					dokumente
						.filter((doc) => doc.type !== 'SONSTIGE_NACHWEISE')
						.map(({ type, status, nochAnzufragen }, index) => {
							return (
								<DocumentRequestEntry
									initial={initial}
									optionen={optionen}
									key={index}
									type={type}
									status={status}
									statusChangedAt={''}
									nochAnzufragen={nochAnzufragen}
									onChange={handleOnChange}
									isChecked={
										!!nochAnzufragenChecked &&
										!!nochAnzufragenChecked.find((item) => item.type === type)?.checked
									}
								/>
							)
						})}
				{sonstigeChecked &&
					sonstigeDokumente.map((item, i) => (
						<DocumentRequestEntry
							key={`sonstige-nachweise-${i}`}
							type={'SONSTIGE_NACHWEISE'}
							status={sonstigeNachweiseData?.status || 'nochNichtAngefragt'}
							title={item.title}
							nochAnzufragen={false}
							onChange={(checked, type) => handleOnSonstigesChange(checked, type, item.title)}
							isChecked={
								!!sonstigeChecked && !!sonstigeChecked.find((it) => it.title === item.title)?.checked
							}
						/>
					))}

				<AddDocument
					addDocument={addDocument}
					handleToggleNewDocument={handleToggleNewDocument}
					toggleNewDocument={toggleNewDocument}
				/>
			</div>
			<TextArea
				label={'Optional: Nachricht an Empfänger'}
				className="document-request__textarea"
				onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)}
				value={optionen.optionaleNachricht}
			/>
			<SelectInput
				options={
					moeglicheVersandarten
						? moeglicheVersandarten.map((versandart: string) => ({
							value: versandart,
							label: <Trans i18nKey={`view.dokumente.options.versandart.${versandart}`} />,
						}))
						: []
				}
				className="document-request__select"
				value={versandart}
				alternativeStyle={true}
				label={<Trans i18nKey="view.dokumente.options.labels.versandart" />}
			/>
			{schreibenButtons()}
		</div>
	)
}

export default DocumentRequest
