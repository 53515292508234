import classNames from 'classnames'
import GridList from 'components/GridList'
import GridListCell from 'components/GridListCell'
import GridListRow from 'components/GridListRow'
import useApi from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import { useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import Button, { ButtonType } from 'shared/components/Button'
import { currencyFormat } from 'shared/helper/numberFormats'
import { BausteinkontoFinalisierungResponse } from '../../../../hooks/useApi'

export const BausteinKontoFinalisierung = () => {
	const api = useApi()

	const [data, setData] = useState<BausteinkontoFinalisierungResponse | undefined>()
	const mutation = useMutation(api.postBausteinKontoFinalisierung, {
		onSuccess: (data) => {
			setData(data)
		},
	})

	const { userId } = useUserRecord()
	const { data: queryData, isSuccess } = useQuery(
		['bausteinkontoFinalisierung', { userId }],
		api.getBausteinKontoFinalisierung,
		{
			enabled: !!userId,
			cacheTime: 0,
			staleTime: 0,
		}
	)

	const updateBaustienKonto = (userId: string) => {
		userId && mutation.mutate(userId)
	}

	useEffect(() => {
		if (userId) {
			updateBaustienKonto(userId)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId])

	useEffect(() => {
		setData(queryData)
	}, [queryData])

	const headerClass =
		'baustein-konto-finalisierung__header grid-list__cell--header flex--justify-content-end text-align--right padding padding--horizontal b'
	const rowClass = 'numeric flex--justify-content-end font-size-s'

	const tableHeaders = (
		<GridListRow>
			<GridListCell className={headerClass}>
				<Trans i18nKey={'view.pensionerProfile.tabs.assets.bausteinkontoFinalisierung.tableHeaders.name'} />
			</GridListCell>
			<GridListCell className={headerClass}>
				<Trans i18nKey={'view.pensionerProfile.tabs.assets.bausteinkontoFinalisierung.tableHeaders.interval'} />
			</GridListCell>
			<GridListCell className={headerClass}>
				<Trans
					i18nKey={
						'view.pensionerProfile.tabs.assets.bausteinkontoFinalisierung.tableHeaders.transactionAmount'
					}
				/>
			</GridListCell>
			<GridListCell className={headerClass}>
				<Trans
					i18nKey={'view.pensionerProfile.tabs.assets.bausteinkontoFinalisierung.tableHeaders.newAmount'}
				/>
			</GridListCell>
			<GridListCell className={headerClass}>
				<Trans
					i18nKey={'view.pensionerProfile.tabs.assets.bausteinkontoFinalisierung.tableHeaders.originalAmount'}
				/>
			</GridListCell>
			<GridListCell className={headerClass}>
				<Trans i18nKey={'view.pensionerProfile.tabs.assets.bausteinkontoFinalisierung.tableHeaders.rateSum'} />
			</GridListCell>
			<GridListCell className={headerClass}>
				<Trans
					i18nKey={'view.pensionerProfile.tabs.assets.bausteinkontoFinalisierung.tableHeaders.intervalSum'}
				/>
			</GridListCell>
		</GridListRow>
	)

	const tableRows = useMemo(() => {
		if (!data) return null

		return data.map((row, index) => {
			return (
				<GridListRow key={index}>
					<GridListCell className={rowClass}>
						<span>
							{row.longName}
							<br />({row.name})
						</span>
					</GridListCell>
					<GridListCell className={rowClass}>
						{new Date(row.transactionDate).toLocaleDateString()}
					</GridListCell>
					<GridListCell className={rowClass}>{currencyFormat(row.maxPossibleBausteinSum)}</GridListCell>
					<GridListCell className={rowClass}>{currencyFormat(row.currentTransactionSum)}</GridListCell>
					<GridListCell className={rowClass}>{currencyFormat(row.yearlyAmount)}</GridListCell>
					<GridListCell className={rowClass}>{currencyFormat(row.reviewTransactionSum)}</GridListCell>
					<GridListCell className={rowClass}>{currencyFormat(row.finalSum)}</GridListCell>
				</GridListRow>
			)
		})
	}, [data])

	return (
		<div className="baustein-konto-finalisierung margin--top margin--large">
			<h3 className="bold-small-heading">
				<Trans i18nKey="view.pensionerProfile.tabs.assets.bausteinkontoFinalisierung.headline" />
			</h3>
			{isSuccess && data?.length ? (
				<GridList columnCount={7} className="tab-numbers">
					{tableHeaders}
					{tableRows}
				</GridList>
			) : (
				<></>
			)}
			<Button
				className={classNames('baustein-konto-finalisierung__button', 'margin--right-auto')}
				type={[ButtonType.secondary, ButtonType.small]}
				promiseTracker={{ area: 'bausteinkontoFinalisierung' }}
				onClick={() => userId && api.postBausteinKontoFinalisierung(userId, true)}
			>
				<Trans i18nKey="view.pensionerProfile.tabs.assets.bausteinkontoFinalisierung.button" />
			</Button>
		</div>
	)
}
