import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import documentActive from 'assets/images/illu-document-active.svg'
import Button, { ButtonType } from 'shared/components/Button'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import useGlobalModalState from 'shared/hooks/useGlobalModalState'
import useApi, { AustrittsschreibenGetResponse, QueryKey, PostBodyZusageschreibenOptionen, PostUVschreibenOptionenRequestBody } from 'hooks/useApi'
import { useUserRecord } from 'hooks/useUserRecord'
import dateFormat from 'shared/helper/dateFormat'
import classNames from 'classnames'
import { useQuery } from 'react-query'
import { wait } from 'shared/helper/wait'
import { FormFields } from 'shared/components/Form'
import { sanitizeDecimalNumber } from 'shared/helper/sanitizeMaskedValue'
import { TMonth } from 'shared/helper/types/month'
import { LeistungsfallInfo } from 'views/Austrittsbearbeitung/PensionerAustrittsbearbeitungen/FinalLeistungsfall'
import { UVFallInfo } from 'views/Austrittsbearbeitung/PensionerAustrittsbearbeitungen/FinalUVFall'

type TAustrittSchreibenProps = {
	createEndpoint: string
	closeEndpoint: 'Leistungsfall' | 'UVFall'
	headline: string
	refetchHistorie: any
	uvFallInfo?: UVFallInfo
	leistungsfallInfo?: LeistungsfallInfo
	zusageschreibenoption?: FormFields
	uvschreibenoption?: FormFields
	subheadline?: string
	className?: string
	disabled?: boolean
}

type TCreateEndpointType = {
	createAbfindungsSchreiben: (userId: string) => Promise<any>
	createUVSchreiben: (userId: string) => Promise<any>
	createZusageSchreiben: (userId: string) => Promise<any>
}

type TDownloadEndpointType = {
	getDownloadURLAustrittschreiben: (userId: string) => Promise<any>
}

type THPROptions = 'HPR_LIEGT_VOR' | 'HPR_FEHLT' | 'OHNE_HPR' | undefined

const AustrittsSchreibenButtonGroup: FunctionComponent<TAustrittSchreibenProps> = ({
	createEndpoint,
	closeEndpoint,
	headline,
	subheadline,
	className,
	zusageschreibenoption,
	uvschreibenoption,
	uvFallInfo,
	leistungsfallInfo,
	refetchHistorie,
}) => {
	const { userId } = useUserRecord()
	const api = useApi()
	const { t } = useTranslation()
	const [, modalActions] = useGlobalModalState()
	const [openFinalConfirmModal, setOpenFinalConfirmModal] = useState(false)

	const [schreibenProperty, setSchreibenProperty] = useState<{
		isCreated: boolean
		isSent: boolean
		createdDate?: string
		sentDate?: string
	}>({ isCreated: false, createdDate: '', isSent: false, sentDate: '' })
	const { data: austrittschreiben, refetch: refetchAustrittsschreiben } = useQuery(
		[QueryKey.austrittsschreiben, { userId }],
		api.getAustrittsschreiben,
		{
			enabled: !!userId,
		}
	)
	const confirmModal = useRef<ModalRefActions>()

	const getSchreibenProperty = (austrittschreiben?: AustrittsschreibenGetResponse) => {
		let createdDate, sentDate: string | undefined
		if (austrittschreiben) {
			const { createdAt, sentAt } = austrittschreiben
			sentDate = sentAt && dateFormat(new Date(sentAt.split('T')[0]))
			createdDate = createdAt && dateFormat(new Date(createdAt.split('T')[0]))
		}
		return {
			isCreated: !!austrittschreiben?.createdAt,
			isSent: !!austrittschreiben?.sentAt,
			createdDate,
			sentDate,
		}
	}
	useEffect(() => {
		const property = getSchreibenProperty(austrittschreiben)
		setSchreibenProperty(property)
	}, [austrittschreiben])

	const openErrorModal = (errorMessage: string) => {
		modalActions.setClass('modal--error')
		modalActions.setContent(
			<>
				<h2>
					<Trans i18nKey={`view.zusageschreiben.error.headline`} />
				</h2>
				<p>
					<Trans i18nKey={`view.zusageschreiben.error.context`} />: {errorMessage}
				</p>
				<Button
					type={ButtonType.primary}
					label={t('generic.close')}
					onClick={() => {
						modalActions.closeModal()
						refetchAustrittsschreiben()
					}}
				/>
			</>
		)
		modalActions.setHideCloseButtons()
		modalActions.openModal()
		return
	}

	const getAustrittsschreiben = async () => {
		let response
		for (let i = 0; i < 5; i++) {
			response = await refetchAustrittsschreiben()
			if (response.data) {
				break
			}
			await wait(1500)
		}
		if (response?.isError) {
			openErrorModal(`view.zusageschreiben.error.timeoutError`)
		}
	}

	const handleCreate = async () => {
		if (!userId) {
			return
		}
		if (zusageschreibenoption) {
			const zusageschreibenoptionBody = getZusageschreibenOptionBody(zusageschreibenoption)
			await api.postZusageschreibenOptionen(zusageschreibenoptionBody, userId)
		}
		if (uvschreibenoption) {
			const uvschreibenoptionBody = getUVschreibenOptionBody(uvschreibenoption)
			await api.postUVschreibenOptionen(uvschreibenoptionBody, userId)
		}
		const response = await api[`create${createEndpoint}` as keyof TCreateEndpointType](userId)
		if (!response) {
			return
		}
		if (!response.success) {
			openErrorModal(response.response.data.errorMessage)
			return
		}
		if (response && response.success === true) {
			await refetchAustrittsschreiben()
			if (response.data) {
				return
			}
			await wait(2000)
			getAustrittsschreiben()
			return
		}
	}

	const handleDownload = async () => {
		if (!userId) {
			return
		}
		try {
			const downloadUrl = await api['getDownloadURLAustrittschreiben' as keyof TDownloadEndpointType](userId)
			if (downloadUrl) {
				setTimeout(() => {
					const win = window.open(downloadUrl, '_blank', 'noopener,noreferrer')
					if (win) {
						win.focus()
					}
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	async function sendSchreiben() {
		if (!userId) {
			return
		}
		switch (closeEndpoint) {
			case 'UVFall':
				await api.closeUVFall(userId)
				break
			case 'Leistungsfall':
				await api.closeLeistungsFall(userId)
				break
		}
		await refetchAustrittsschreiben()
		await refetchHistorie()
	}

	function handleOpenModalConfirmed() {
		confirmModal.current?.openModal()
	}

	const getModalConfirmedHeader = () => {
		if (uvFallInfo) {
			if (uvFallInfo.abfindung) {
				return t('view.pensionerProfile.tabs.abfindungsfall.modal.header')
			}
			return t('view.pensionerProfile.tabs.uvfall.modal.header')
		}
		if (leistungsfallInfo) {
			if (leistungsfallInfo.underThreshold) {
				return t('view.pensionerProfile.tabs.leistungsfall.modal.header-underthreshold')
			}
			return t('view.pensionerProfile.tabs.leistungsfall.modal.header')
		}
	}

	return (
		<div className={classNames('uv-schreiben', className)}>
			<h2>
				<Trans i18nKey={headline} />
			</h2>
			<p className="uv-schreiben__subheadline">
				<Trans i18nKey={subheadline ? subheadline : headline} />
			</p>
			<div className="uv-schreiben__container">
				<img src={documentActive} alt="documentActive" className="margin--top" />
				<div className="uv-schreiben__container--button-container">
					<h4>
						<Trans i18nKey="view.uvFall.aktionen" />
					</h4>
					<div className="uv-schreiben__container--button-container__states">
						{schreibenProperty.createdDate && (
							<div>
								<Trans
									i18nKey={'view.uvFall.created'}
									values={{ date: schreibenProperty.createdDate }}
								/>
							</div>
						)}
						{schreibenProperty.sentDate && (
							<div>
								<Trans i18nKey={'view.uvFall.sent'} values={{ date: schreibenProperty.sentDate }} />
							</div>
						)}
					</div>
					<Button
						type={[ButtonType.secondary, ButtonType.medium]}
						onClick={handleCreate}
						promiseTracker={{ area: `${createEndpoint}` }}
						disabled={schreibenProperty.isSent}
					>
						<Trans i18nKey="view.uvFall.buttons.create" />
					</Button>
					<Button
						type={[ButtonType.secondary, ButtonType.medium]}
						disabled={!schreibenProperty.isCreated || schreibenProperty.isSent}
						onClick={handleDownload}
						promiseTracker={{ area: 'AustrittschreibenDownloadURL' }}
					>
						<Trans i18nKey="view.uvFall.buttons.check" />
					</Button>
					<Button
						type={[ButtonType.highlight, ButtonType.medium]}
						disabled={!schreibenProperty.isCreated || schreibenProperty.isSent}
						onClick={handleOpenModalConfirmed}
						promiseTracker={{ area: `abschluss${closeEndpoint}` }}
					>
						<Trans i18nKey="view.uvFall.buttons.send" />
					</Button>
					{
						<Modal
							ref={confirmModal}
							forceAction={false}
							header={getModalConfirmedHeader()}
							buttonLabel={t(`generic.confirm`)}
							onModalClose={() => {
								confirmModal.current?.closeModal()
							}}
							onButtonClick={async () => {
								await sendSchreiben()
							}}
							className="modal--final-uvfall-confirm modal--width-bigger"
						>
							<div>
								<h3>{t('view.pensionerProfile.tabs.abfindungsfall.modal.content')}</h3>
								<ul>
									{uvFallInfo && uvFallInfo.abfindung && (
										<li className="text-align--left">
											{t(`view.pensionerProfile.tabs.abfindungsfall.modal.info-status`)}
										</li>
									)}
									{uvFallInfo && !uvFallInfo.abfindung && (
										<li className="text-align--left">
											{t(`view.pensionerProfile.tabs.uvfall.modal.info-status`)}
										</li>
									)}
									{uvFallInfo && uvFallInfo.verfallbar && (
										<li className="text-align--left">
											{t(`view.pensionerProfile.tabs.uvfall.modal.info-verfallbar`)}
										</li>
									)}

									{uvFallInfo && uvFallInfo.bausteinkonto && (
										<li className="text-align--left">
											{t(`view.pensionerProfile.tabs.uvfall.modal.info-bausteinkonto`)}
										</li>
									)}
									{uvFallInfo && uvFallInfo.abfindung && (
										<li className="text-align--left">
											{t(
												`view.pensionerProfile.tabs.abfindungsfall.modal.info-abfindungsschreiben`
											)}
										</li>
									)}
									{uvFallInfo && !uvFallInfo.abfindung && (
										<li className="text-align--left">
											{t(`view.pensionerProfile.tabs.uvfall.modal.info-uvschreiben`)}
										</li>
									)}
									{leistungsfallInfo && leistungsfallInfo.bausteinkonto && (
										<li className="text-align--left">
											{t(`view.pensionerProfile.tabs.leistungsfall.modal.info-bausteinkonto`)}
										</li>
									)}
									{leistungsfallInfo && leistungsfallInfo.hasAuszahlungsplan && (
										<li className="text-align--left">
											{t(
												`view.pensionerProfile.tabs.leistungsfall.modal.info-zusageschreiben-plan`
											)}
										</li>
									)}
									{leistungsfallInfo && !leistungsfallInfo.hasAuszahlungsplan && (
										<li className="text-align--left">
											{t(`view.pensionerProfile.tabs.leistungsfall.modal.info-zusageschreiben`)}
										</li>
									)}
								</ul>
							</div>
						</Modal>
					}
				</div>
			</div>
		</div>
	)
}

const getZusageschreibenOptionBody = (zusageschreibenoption: FormFields): PostBodyZusageschreibenOptionen => {
	const {
		hprStatus,
		r4,
		r10,
		avwl,
		entgeltnachweisSenden,
		entgeltnachweisZeitraum,
		rückwirkendFromMonat,
		rückwirkendFromJahr,
		rückwirkendForMonat,
		rückwirkendForJahr,
		zumAbrechnungsmonatForMonat,
		zumAbrechnungsmonatForJahr,
		ersteller,
		pruefer,
		auslandnachweis,
	} = zusageschreibenoption || {}

	let zeitraumPayload =
		entgeltnachweisSenden?.value === 'ja'
			? entgeltnachweisZeitraum?.value === 'rueckwirkend'
				? {
					from: {
						month: Number(rückwirkendFromMonat?.value) as TMonth,
						year: Number(rückwirkendFromJahr?.value),
					},
					for: {
						month: Number(rückwirkendForMonat?.value) as TMonth,
						year: Number(rückwirkendForJahr?.value),
					},
				}
				: {
					for: {
						month: Number(zumAbrechnungsmonatForMonat?.value) as TMonth,
						year: Number(zumAbrechnungsmonatForJahr?.value),
					},
				}
			: undefined

	if (zeitraumPayload && zeitraumPayload.from) {
		zeitraumPayload.for = zeitraumPayload.for || {}
	} else {
		zeitraumPayload = zeitraumPayload && { for: zeitraumPayload.for }
	}

	const body: PostBodyZusageschreibenOptionen = {
		hprStatus: hprStatus?.value as THPROptions,
		r4: sanitizeDecimalNumber(r4?.value as string) || undefined,
		r10: sanitizeDecimalNumber(r10?.value as string),
		avwl: sanitizeDecimalNumber(avwl?.value as string) || undefined,
		entgeltnachweisZeitraum: zeitraumPayload,
		ersteller: ersteller?.value as string | undefined,
		pruefer: pruefer?.value as string | undefined,
		auslandnachweis: auslandnachweis?.value as PostBodyZusageschreibenOptionen['auslandnachweis'],
	}
	return body
}

const getUVschreibenOptionBody = (uvschreibenoption: FormFields): PostUVschreibenOptionenRequestBody => {
	const {
		betrag,
		dcAnspruch
	} = uvschreibenoption || {}

	const body: PostUVschreibenOptionenRequestBody = {
		betrag: (dcAnspruch.value as PostUVschreibenOptionenRequestBody['dcAnspruch']) === 'ANSPRUCH_VORHANDEN' ? sanitizeDecimalNumber(betrag?.value as string) : undefined,
		dcAnspruch: dcAnspruch.value as PostUVschreibenOptionenRequestBody['dcAnspruch'] ?? 'KEIN_ANPSRUCH'
	}
	return body
}
export default AustrittsSchreibenButtonGroup
