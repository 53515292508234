import { Amplify } from 'aws-amplify'
import { createRoot } from 'react-dom/client'
import './i18n'
import './index.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import App from './views/App'

import(process.env.REACT_APP_AWS_CONFIG as string)
	.then((config) => {
		Amplify.configure(config.default)

		if ('local' !== process.env.REACT_APP_CUSTOM_NODE_ENV) {
			Amplify.configure({
				Auth: {
					cookieStorage: {
						domain: process.env.REACT_APP_COOKIE_DOMAIN,
						expires: '', // this sets the cookie to a session cookie
						secure: true,
					},
				},
			})
		}
	})
	.then(() => {
		const container = document.getElementById('root')
		if (!container) {
			return
		}

		const root = createRoot(container)
		root.render(<App />)

		// If you want your app to work offline and load faster, you can change
		// unregister() to register() below. Note this comes with some pitfalls.
		// Learn more about service workers: https://bit.ly/CRA-PWA
		// serviceWorker.unregister()
		serviceWorkerRegistration.register({
			onUpdate: async (registration) => {
				// We want to run this code only if we detect a new service worker is
				// waiting to be activated.
				// Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
				if (registration && registration.waiting) {
					await registration.unregister()
					window.postMessage({ appUpdateAvailable: true }, window.location.origin)
				}
			},
		})
	})
