import { IconType } from 'components/Icons'
import useApi, { PensionRuleSetCode, QueryKey } from 'hooks/useApi'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { uid } from 'react-uid'
import { AccordionProps } from 'shared/components/Accordion'
import AccordionGroup from 'shared/components/AccordionGroup'
import Icon from 'shared/components/Icon'
import SwitchSelect from 'shared/components/SwitchSelect'
import dateFormat from 'shared/helper/dateFormat'
import getFirstDayOfNextMonth from 'shared/helper/getFirstDayOfNextMonth'
import { currencyFormat } from 'shared/helper/numberFormats'
import { components } from 'types/api-interface'
import { ViewHeader } from '../../components/ViewHeader'
import { useUserRecord } from '../../hooks/useUserRecord'

enum modeOptions {
	TODAY = 'today',
	PENSION_AGE_OR_LEFT_WITH_ENTITLEMENT = 'pensionAgeOrLeftWithEntitlement',
}

type ErrorMessage = 'error' | 'noData' | 'errorEmployeeData'

const formatAusfinanzierungGemeldet = (dateString: string) => {
	const dateOfExportPreparation = new Date(dateString)
	const displayExportDate = new Date(
		dateOfExportPreparation.getUTCFullYear(),
		dateOfExportPreparation.getUTCMonth(),
		dateOfExportPreparation.getUTCDate()
	)
	return dateFormat(displayExportDate, {
		year: 'numeric',
		month: 'long',
		day: undefined,
	})
}

const PensionerBlocksAccount = () => {
	const { userId } = useUserRecord()
	const api = useApi()
	const [mode, setMode] = useState(modeOptions.TODAY)
	const [errorMessage, setErrorMessage] = useState<undefined | ErrorMessage>(undefined)
	const [showDisclaimer, setShowDisclaimer] = useState(false)

	const [ruleSet, setRuleset] = useState<string | undefined>(undefined)
	const [blocksAccountExists, setBlocksAccountExists] = useState<undefined | boolean>(undefined)

	const { t } = useTranslation()

	const pensionerDataQuery = useQuery([QueryKey.pensionerBaseData, { userId }], api.getPensionerBaseData, {
		enabled: !!userId,
	})

	const { status, data } = useQuery([QueryKey.bausteinKonto, { userId }], api.getBausteinKonto, {
		enabled: !!userId,
	})

	const isRetirementAgeReached = data ? data.today >= getFirstDayOfNextMonth(data.retirementDate) : false
	const isLeftWithEntitlement =
		pensionerDataQuery?.status === 'success'
			? pensionerDataQuery.data?.pensionPlan.participationState.state === 'leftWithEntitlement'
			: false
	const ausfinanziert = data?.ausfinanziert

	useEffect(() => {
		if (status === 'error') {
			setErrorMessage('error')
		}
		if (status === 'success' && data === undefined) {
			setErrorMessage('noData')
		}
		if (data && (isRetirementAgeReached || isLeftWithEntitlement)) {
			setMode(modeOptions.PENSION_AGE_OR_LEFT_WITH_ENTITLEMENT)
		}
	}, [data, status, isRetirementAgeReached, isLeftWithEntitlement])

	useEffect(() => {
		if (pensionerDataQuery.status === 'error') {
			setErrorMessage('errorEmployeeData')
		} else if (pensionerDataQuery.data && data) {
			setRuleset(pensionerDataQuery.data.pensionPlan.ruleSets)
			setBlocksAccountExists(data.bausteine.length > 0)
		}
	}, [pensionerDataQuery.data, pensionerDataQuery.status, data, data?.bausteine])

	const getChildBlocks = (v: components['schemas']['BausteinkontoBausteinValue']) => {
		const { label, total, totalAtRetirementDate } = v
		return (
			<div key={uid(v)} className="account-blocks__child-row">
				<div className="account-blocks__today-child">
					<div className="account-blocks__block-label">
						{' '}
						<Trans i18nKey={`view.accountBlocks.blocks.${label}`} />
					</div>
					{!isRetirementAgeReached && !isLeftWithEntitlement ? (
						<div className="account-blocks__block-amount">{currencyFormat(total)}</div>
					) : (
						''
					)}
				</div>
				<div className="account-blocks__pension-child hide-if-today">
					{isRetirementAgeReached || isLeftWithEntitlement ? (
						<div className="account-blocks__block-amount">{currencyFormat(total)}</div>
					) : (
						<div className="account-blocks__block-amount">{currencyFormat(totalAtRetirementDate)}</div>
					)}
				</div>
			</div>
		)
	}

	const getTableHeader = () => {
		return (
			<div>
				<div className="account-blocks__switch">
					<SwitchSelect
						value={modeOptions.TODAY}
						options={[
							{
								label: t(`view.accountBlocks.switch.${modeOptions.TODAY}`),
								value: modeOptions.TODAY,
							},
							{
								label: (
									<Trans
										values={{ age: data?.retirementAge }}
										i18nKey={`view.accountBlocks.switch.${modeOptions.PENSION_AGE_OR_LEFT_WITH_ENTITLEMENT}`}
									/>
								),
								value: modeOptions.PENSION_AGE_OR_LEFT_WITH_ENTITLEMENT,
							},
						]}
						onChange={(e) => {
							setMode(e.target.value as modeOptions)
						}}
					/>
				</div>
				<div className="account-blocks__table-description-header ">
					<div className="account-blocks__table-description-today">
						{!isRetirementAgeReached && !isLeftWithEntitlement && !ausfinanziert ? (
							<>
								<h4 className="text-color-cyan bold">
									<Trans i18nKey="view.accountBlocks.currentBalanceHeadline" />
								</h4>
								<p>
									<Trans
										i18nKey="view.accountBlocks.currentBalanceText"
										values={{
											date: `${dateFormat(new Date(data?.today || 0))}`,
										}}
									/>
								</p>
							</>
						) : (
							''
						)}
					</div>
					<div className="account-blocks__table-description-pension hide-if-today">
						{!isRetirementAgeReached && !isLeftWithEntitlement && !ausfinanziert ? (
							<>
								<h4 className="text-color-cyan bold">
									<Trans
										i18nKey="view.accountBlocks.pensionBalanceHeadline"
										values={{ age: data?.retirementAge }}
									/>
								</h4>
							</>
						) : (
							<>
								<h4 className="text-color-cyan bold">
									<Trans i18nKey="view.accountBlocks.currentBalanceHeadline" />
								</h4>
							</>
						)}
						{!isRetirementAgeReached && !isLeftWithEntitlement && !ausfinanziert ? (
							<p>
								<Trans
									i18nKey="view.accountBlocks.pensionBalanceText"
									values={{
										age: data?.retirementAge,
										pensionDate: `${dateFormat(new Date(data?.retirementDate || 0))}`,
									}}
								/>
							</p>
						) : isLeftWithEntitlement && !ausfinanziert ? (
							<p>
								<Trans
									i18nKey="view.accountBlocks.pensionAndCurrentBalanceText"
									values={{
										date: `${dateFormat(new Date(data?.today || 0))}`,
										age: data?.retirementAge,
										pensionDate: `${dateFormat(new Date(data?.retirementDate || 0))}`,
									}}
								/>
							</p>
						) : (
							<p>
								<Trans
									i18nKey="view.accountBlocks.currentBalanceText"
									values={{
										date: `${dateFormat(new Date(data?.today || 0))}`,
									}}
								/>
							</p>
						)}
					</div>
				</div>
			</div>
		)
	}

	const getDisclaimer = () => {
		return (
			<p className=" margin--bottom margin--large-italic" style={{ fontStyle: 'italic' }}>
				<Trans i18nKey="view.accountBlocks.paidOut" />
			</p>
		)
	}
	const getBlocks = (v: components['schemas']['BausteinkontoBausteinValue']): AccordionProps | undefined => {
		const { total, label, totalAtRetirementDate, bausteine } = v

		if (!bausteine || bausteine.length === 0) {
			return undefined
		}

		/* show the disclaimer if a fuehrungskraefte bausteine is provided*/
		if (!showDisclaimer && label === 'fuehrungskraefte') {
			setShowDisclaimer(true)
		}

		return {
			header: (
				<div className="account-blocks__parent-row">
					<div className="account-blocks__today-parent">
						<div className="account-blocks__block-label">
							<Trans
								i18nKey={`view.accountBlocks.blocks.${
									label === 'sonstige' && showDisclaimer ? 'other_special_blocks' : label
								}`}
							/>
						</div>
						{!isRetirementAgeReached && !isLeftWithEntitlement ? (
							<div className="account-blocks__block-amount">{currencyFormat(total)}</div>
						) : (
							''
						)}
					</div>
					<div className="account-blocks__pension-parent hide-if-today">
						{isRetirementAgeReached || isLeftWithEntitlement ? (
							<div className="account-blocks__block-amount">{currencyFormat(total)}</div>
						) : (
							<div className="account-blocks__block-amount ">{currencyFormat(totalAtRetirementDate)}</div>
						)}
					</div>
				</div>
			),
			content: bausteine?.map((b) => getChildBlocks(b)),
		}
	}

	const renderTable = () => {
		if (!data) return
		return (
			<>
				<div>
					<h3>
						<Trans i18nKey="view.accountBlocks.statusHeader" />
					</h3>
					<div className="disclaimer">
						<p className="caption disclaimer">
							<Trans i18nKey="view.accountBlocks.cacheDisclaimer" />
						</p>
					</div>
					<div className="margin--top">
						<Trans
							i18nKey="view.accountBlocks.ausfinanzierungGemeldet"
							values={{
								ausfinanzierungGemeldet: !!data.ausfinanzierungGemeldet
									? t('generic.yes')
									: t('generic.no'),
								date: data.ausfinanzierungGemeldet
									? `(${formatAusfinanzierungGemeldet(data.ausfinanzierungGemeldet)})`
									: '',
							}}
						/>
					</div>
					<div>
						<Trans
							i18nKey="view.accountBlocks.ausfinanziert"
							values={{
								ausfinanziert: !!data.ausfinanziert ? t('generic.yes') : t('generic.no'),
								date: data.ausfinanziert
									? `(${formatAusfinanzierungGemeldet(data.ausfinanziert)})`
									: '',
							}}
						/>
					</div>
					{ausfinanziert ? getDisclaimer() : ''}
				</div>
				<h3>
					<Trans i18nKey="view.accountBlocks.blocksHeader" />
				</h3>
				<div className={`grid  account-blocks account-blocks account-blocks--${mode}`}>
					<div className="grid__top">
						<div className="account-blocks__intro">
							<p className="text-color-cyan">
								<Trans i18nKey="view.accountBlocks.intro" />
							</p>
						</div>
					</div>
					<div className="grid__center  ">
						<div className="full-width">
							{getTableHeader()}
							<div className="account-blocks__table-header">
								<div className="account-blocks__table-header-today">
									<span className="text--bold-spaced text-align--left">
										<Trans i18nKey="view.accountBlocks.table.block" />
									</span>
									{!isRetirementAgeReached && !isLeftWithEntitlement ? (
										<span className="text--bold-spaced hide-if-today">
											<Trans i18nKey="view.accountBlocks.table.amount" />
										</span>
									) : (
										''
									)}
								</div>
								<div className="account-blocks__table-header-pension ">
									<span className="text--bold-spaced">
										<Trans i18nKey="view.accountBlocks.table.amount" />
									</span>
								</div>
							</div>
							{data && (
								<>
									{options && <AccordionGroup allowMultipleOpen={true} options={options} />}
									<div className="account-blocks__sum moved-by-accordion">
										<div className="account-blocks__today-sum">
											<span className="h4 text-align--left">
												<Trans i18nKey="view.accountBlocks.sum" />
											</span>
											{!isRetirementAgeReached && !isLeftWithEntitlement ? (
												<span className="h4">{currencyFormat(data.total)}</span>
											) : (
												''
											)}
										</div>
										<div className="account-blocks__pension-sum hide-if-today ">
											{isRetirementAgeReached || isLeftWithEntitlement ? (
												<span className="h4">{currencyFormat(data.total)}</span>
											) : (
												<span className="h4">{currencyFormat(data.totalAtRetirementDate)}</span>
											)}
										</div>
									</div>
									{showDisclaimer && (
										<div className="disclaimer">
											<p className="caption disclaimer moved-by-accordion">
												<Trans
													i18nKey="view.accountBlocks.disclaimer"
													values={{ age: data?.retirementAge }}
												/>
											</p>
										</div>
									)}
								</>
							)}
						</div>
					</div>
					<div className="caption disclaimer moved-by-accordion">
						<h3 className="padding--bottom">
							<Trans i18nKey="view.accountBlocks.furtherInformation" />
						</h3>
						<div className="padding--bottom">
							<Trans
								i18nKey="view.accountBlocks.buKennzeichen"
								values={{
									initialBuKennzeichen: data.initialBuKennzeichen
										? t('generic.yes')
										: t('generic.no'),
									buKennzeichen: data.buKennzeichen ? t('generic.yes') : t('generic.no'),
								}}
							/>
						</div>
						<div className="padding--bottom">
							<Trans
								i18nKey="view.accountBlocks.guaranteedPension"
								values={{
									guaranteedPension: currencyFormat(data.guaranteedPension) || '-',
									guaranteedPensionDyn: currencyFormat(data.guaranteedPensionDyn) || '-',
									gekuerzteGarantieRente:
										data.gekuerzteGarantieRente !== undefined
											? currencyFormat(data.gekuerzteGarantieRente)
											: '-',
								}}
							/>
						</div>
					</div>
				</div>
			</>
		)
	}

	const options: AccordionProps[] | undefined =
		(data &&
			([...data.bausteine?.map((v) => getBlocks(v))].filter((el) => el !== undefined) as AccordionProps[])) ||
		[]

	return (
		<div>
			<>
				<ViewHeader
					headline={<Trans i18nKey="view.accountBlocks.headline" />}
					subheadline={<Trans i18nKey="view.accountBlocks.subheadline" />}
				>
					{ruleSet === 'VO2019' && !blocksAccountExists && (
						<div className="margin--vertical flex flex--align-items-start flex--justify-content-center">
							<Icon className="margin--horizontal no-margin--left" type={IconType.info} />
							<Trans i18nKey="view.accountBlocks.onlyAvailableForVo20" />
						</div>
					)}
					{ruleSet === 'VO2019' && (
						<div className="margin--vertical flex flex--align-items-start flex--justify-content-center">
							<Icon className="margin--horizontal no-margin--left" type={IconType.info} />
							<Trans i18nKey="view.accountBlocks.isVo19" />
						</div>
					)}
					{errorMessage && (
						<div className="margin--vertical flex flex--align-items-start flex--justify-content-center">
							<Icon className="margin--horizontal no-margin--left" type={IconType.info} />
							{t(`view.accountBlocks.${errorMessage}`)}
							<br />
						</div>
					)}
				</ViewHeader>
				{ruleSet === 'VO2020' && !blocksAccountExists && (
					<div className="disclaimer">
						<p className="caption disclaimer moved-by-accordion">
							<Trans i18nKey="view.accountBlocks.noData" />
						</p>
					</div>
				)}
				{blocksAccountExists && renderTable()}
			</>
		</div>
	)
}

export default PensionerBlocksAccount
