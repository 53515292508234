import useApi from 'hooks/useApi'
import React, { FunctionComponent, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Modal, { ModalRefActions } from 'shared/components/Modal'

export interface IIdentNumberExportModal {
  open: boolean
  onModalClose: () => void
}

const IdentNumberExportModal: FunctionComponent<IIdentNumberExportModal> = (props) => {
  const { open, onModalClose } = props

  const modal = useRef<ModalRefActions>()
  const api = useApi()
  const { t } = useTranslation()

  useEffect(() => {
    if (open) {
      modal.current?.openModal()
    }
  }, [open])



  const renderMessage = () => {
    return (
      <div className="deletion-disclaimer">
        <h3>{t('view.adminDashboard.identNumberExport.modal.text')}</h3>
      </div>
    )
  }

  const onConfirmButtonClick = async () => {
    await api.getIdentNumberExport({ queryKey: { dryRun: false } }).then((exportUrl: string | undefined) => {
      if (exportUrl) {
        const a = document.createElement('a')
        a.href = exportUrl
        a.target = '_blank'
        a.rel = 'noopener'
        a.click()
      }
    })
  }

  const onDenyButtonClick = async () => {
    await api.getIdentNumberExport({ queryKey: { dryRun: true } }).then((exportUrl: string | undefined) => {
      if (exportUrl) {
        const a = document.createElement('a')
        a.href = exportUrl
        a.target = '_blank'
        a.rel = 'noopener'
        a.click()
      }
    })
  }

  return (
    <Modal
      ref={modal}
      forceAction={false}
      header={t('view.adminDashboard.identNumberExport.modal.header')}
      buttonLabel={t('view.adminDashboard.identNumberExport.modal.yes')}
      onModalClose={onModalClose}
      onButtonClick={onConfirmButtonClick}
      onDenyButtonClick={onDenyButtonClick}
      className="modal--delete modal--width-big"
      denyButtonLabel={t('view.adminDashboard.identNumberExport.modal.no')}
      showDeny
    >
      <div className="padding--horizontal margin--vertical text-align--center">
        {renderMessage()}
      </div>
    </Modal>
  )
}

export default IdentNumberExportModal
