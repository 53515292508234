import { useEffect } from 'react'
import useHeaderState from 'state/useHeaderState'
import useNavigationState from 'state/useNavigationState'
import { useAuth } from '../hooks/useAuth'

const Logout = () => {
	const auth = useAuth()
	const [, headerActions] = useHeaderState()
	const [, navigationActions] = useNavigationState()
	// const [, uiActions] = useUIState()

	useEffect(() => {
		auth.signout()
		headerActions.resetState()
		navigationActions.resetState()
		// uiActions.setBackgroundColor(MainBackgroundColor.dark)
		// eslint-disable-next-line
	}, [auth])

	return null
}

export default Logout
