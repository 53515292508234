import { DefaultTFuncReturn } from 'i18next'
import { FunctionComponent, useEffect, useRef } from 'react'
import { IconSize } from 'shared/components/Icon'
import TextInput from 'shared/components/TextInput'
import { IconType } from './Icons'

export interface SearchInputProps {
	onChange?: any
	placeholder?: string
	label?: string | DefaultTFuncReturn
	className?: string
	value?: string
	iconSize?: IconSize
	dontFocusOnRender?: boolean
	disableDelete?: boolean
}

const SearchInput: FunctionComponent<SearchInputProps> = (props) => {
	const inputRef = useRef<HTMLInputElement | null>(null)

	useEffect(() => {
		if (props.dontFocusOnRender) {
			return
		}
		inputRef.current?.focus()
	})

	const getClasses = () => {
		const classes = ['search-input']

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}

	return (
		<div className={getClasses()}>
			<TextInput
				ref={inputRef}
				label={props.label}
				value={props.value}
				placeholder={props.placeholder}
				onChange={props.onChange}
				icon={IconType.search}
				iconSize={props.iconSize}
				disableDelete={props.disableDelete}
			/>
		</div>
	)
}

export default SearchInput
