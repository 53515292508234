import { API } from 'aws-amplify'
import { trackPromise } from 'react-promise-tracker'
import { apiDataObject } from './useApi'

export enum UserAccountStatus {
	Active = 'active',
	Revoked = 'revoked',
	Deceased = 'deceased',
	Left = 'left',
	Expired = 'expired',
}

export type TCallerActionsUserDetailsResponse = {
	readonly namePrefix?: string | null
	readonly nameAffix?: string | null
	readonly title?: string | null
	readonly firstName?: string | null
	readonly lastName?: string | null
	readonly email?: string | null
	readonly phoneNr?: string | null
	readonly roles?: string[] | null
	readonly decadiaId?: string | null
	readonly foreignId?: string | null
	readonly sendAddress?: {
		readonly addressType: string
		readonly city: string
		readonly countryCode: string
		readonly houseNumber: string
		readonly street: string
		readonly zip: string
	}
	readonly deathDate?: string | null
	readonly leavingDate?: string | null
	readonly userExpireDate?: string | null
	readonly revocationDate?: string | null
	readonly userAccountStatus?: UserAccountStatus
}

export type TCallerActionsUserDetailsRequestParams = {
	tenant: string
	decadiaId?: string
	foreignId?: string
}

export type TCallerActionsUserAuthData = {
	tenant: string
	authInfo: {
		decadiaId?: string
		foreignId?: string
	}
}

export type TCallerActionsPrintFormRequestParams = {
	readonly formType: 'ADDRESS_CHANGE' | 'BANK_DETAILS_CHANGE' | 'INITIAL'
} & TCallerActionsUserAuthData

export type TCallerActionsPrintFormResponse = {
	readonly formType?: string | null
	readonly ticketId?: string
} & { readonly [key: string]: unknown }

export type TCallerActionsRegistrationTicketResponse = {
	readonly ticketId?: string
} & { readonly [key: string]: unknown }

const useCallerActionsApi = () => {
	const getUserDatails = async (
		queryStringParameters: TCallerActionsUserDetailsRequestParams
	): Promise<TCallerActionsUserDetailsResponse | undefined> => {
		const data = await trackPromise(
			apiDataObject({
				queryStringParameters: {
					...queryStringParameters,
				},
			})
		)

		const responseData = await trackPromise(
			API.get('adminApi', 'central-account/user-details', data)
				.then((response) => {
					if (typeof response === 'object' && Object.keys(response).length > 0) {
						return response
					}

					return undefined
				})
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}

	const postPrintedForm = async (
		body: TCallerActionsPrintFormRequestParams
	): Promise<TCallerActionsPrintFormResponse | undefined> => {
		const data = await trackPromise(apiDataObject({ body }))

		const responseData = await trackPromise(
			API.post('adminApi', 'central-account/printed-form', data)
				.then((response) => {
					if (typeof response === 'object' && Object.keys(response).length > 0) {
						return response
					}

					return undefined
				})
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}

	const createRegistrationTicket = async (
		body: TCallerActionsUserAuthData
	): Promise<TCallerActionsRegistrationTicketResponse | undefined> => {
		const data = await trackPromise(apiDataObject({ body }))

		const responseData = await trackPromise(
			API.post('adminApi', 'central-account/tickets/registrations', data)
				.then((response) => {
					if (typeof response === 'object' && Object.keys(response).length > 0) {
						return response
					}

					return undefined
				})
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}

	const unlockAccess = async (
		body: TCallerActionsUserAuthData
	): Promise<TCallerActionsRegistrationTicketResponse | undefined> => {
		const data = await trackPromise(apiDataObject({ body }))

		const responseData = await trackPromise(
			API.post('adminApi', 'central-account/reactivate-user-account', data)
				.then((response) => {
					if (typeof response === 'object' && Object.keys(response).length > 0) {
						return response
					}

					return undefined
				})
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}

	const lockAccess = async (
		body: TCallerActionsUserAuthData
	): Promise<TCallerActionsRegistrationTicketResponse | undefined> => {
		const data = await trackPromise(apiDataObject({ body }))

		const responseData = await trackPromise(
			API.post('adminApi', 'central-account/revoke-user-account', data)
				.then((response) => {
					if (typeof response === 'object' && Object.keys(response).length > 0) {
						return response
					}

					return undefined
				})
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}

	return { lockAccess, unlockAccess, getUserDatails, postPrintedForm, createRegistrationTicket }
}

export default useCallerActionsApi
